import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { AppHttpClient } from '../common/app.http';
import { ImageGenerateSteps, UserAssetGroupListItem, UserAssetListItem } from '../interfaces';
import { ImagePicker } from '../image-picker/image-picker';
import { MediaTypeHelper } from '../helpers/app.helpers';

@Component({
  selector: 'image-selector',
  templateUrl: './image-selector.component.html',
  styleUrl: './image-selector.component.scss',
})
export class ImageSelectorComponent extends BaseComponent implements OnInit {
  @Output() completed = new EventEmitter<any>();
  @Output() stateChanged = new EventEmitter<any>();

  @ViewChild('rawImagePicker') rawImagePicker!: ImagePicker;
  selectedUserAssetId?: string;
  rawImageBlob?: Blob;
  // rawImageUrl?: string;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
  ) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  uploadRawImageClick() {
    this.rawImagePicker.click();
  }

  rawImageChanged(blob: Blob | undefined) {
    if (!blob) {
      return;
    }
    if (blob instanceof File) {
      this.selectedUserAssetId = undefined;
    }
    this.rawImageBlob = blob;

    this.notifyStateChanged();
  }

  userAssetSelected(asset: UserAssetListItem) {
    this.selectedUserAssetId = String(asset.id);
    this.http.get(`/user/assets/${asset.id}`, { responseType: 'blob' })
      .subscribe({
        next: (result: Blob) => {
          this.rawImagePicker.loadFile(result);
        },
      });
  }

  ImageGenerateStepsEnum = ImageGenerateSteps;
  completedClick(next: ImageGenerateSteps) {
    this.completed.emit({
      from: ImageGenerateSteps.Step0010,
      next: next,
    });
  }

  userAssets?: Array<UserAssetGroupListItem>;
  userAssetsLoaded(data: Array<UserAssetGroupListItem>) {
    this.userAssets = data;
  }

  reset() {
    this.rawImageBlob = undefined;
    this.selectedUserAssetId = undefined;
    this.rawImagePicker.reset();
  }

  private notifyStateChanged() {
    let params = [];
    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }
    params.push({
      selectedUserAssetId: this.selectedUserAssetId,
      rawImageUrl: rawImageFilename
    });
    this.stateChanged.emit(params);
  }

  restoreState(data: any, relativeUrl: string) {
    if (!data) {
      this.reset();
      return;
    }
    this.selectedUserAssetId = data.selectedUserAssetId;
    let rawImageUrl = `${relativeUrl}/${data.rawImageUrl}`;
    this.http.get(rawImageUrl, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.rawImageBlob = result;
        this.rawImagePicker.loadFile(result, true);
      },
    });
  }

}
