import { Component, ElementRef, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { AssetGroupTypes, MaterialCategory, TaskCategory, UserNotificationCategory } from '../models';
import { validRange } from '../common/app.forms';
import { UserTaskList } from '../user-task/user-task-list';
import { ImagePicker } from '../image-picker/image-picker';
import { ClothService } from '../services/cloth.service';
import { BlobHelper, FileHelper, ImageHelper, MediaTypeHelper } from '../helpers/app.helpers';
import { UserOperationList } from '../user-operation/user-operation-list';
import { JobTypes, JobSubTypes } from '../models';
import { MaterialTemplateDetails, ModelScaleProps, UserAssetDetails, UserOperationListItem } from '../interfaces';
import { UserAssetInline } from '../user-asset/user-asset-inline';
import { MaterialSelectInline } from '../material/material-select-inline';
import { ActivatedRoute } from '@angular/router';
import { ActionResult, AppHttpClient } from '../common/app.http';

@Component({
  selector: 'clothing-free-tryon',
  templateUrl: './clothing-free-tryon.component.html',
  styleUrl: './clothing-free-tryon.component.scss',
})
export class ClothingFreeTryonComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;
  AssetGroupTypesEnum = AssetGroupTypes;
  MaterialCategoryEnum = MaterialCategory;
  JobTypesEnum = JobTypes;
  JobSubTypesEnum = JobSubTypes;
  @ViewChild('topsImagePicker') topsImagePicker!: ImagePicker;
  @ViewChild('bottomsImagePicker') bottomsImagePicker!: ImagePicker;
  @ViewChild('jumpsuitImagePicker') jumpsuitImagePicker!: ImagePicker;
  @ViewChild('userTaskList') userTaskList!: UserTaskList;
  @ViewChild('userOperationList') userOperationList!: UserOperationList;
  @ViewChild('modelAsset') modelAsset?: UserAssetInline;
  @ViewChild('scenceAsset') scenceAsset?: UserAssetInline;
  @ViewChild('modelSelect') modelSelect!: MaterialSelectInline;
  @ViewChild('scenceSelect') scenceSelect!: MaterialSelectInline;
  @ViewChild('inputMatCard') inputMatCard!: ElementRef;

  userId?: string;
  maxImageEdgeLen = 1024;

  _topsImageBlob?: Blob;
  set topsImageBlob(blob: Blob | undefined) {
    this.topsImageUrl = undefined;
    this._topsImageBlob = blob;
    if (!this._topsImageBlob) {
      return;
    }
    this.topsImageUrl = URL.createObjectURL(this._topsImageBlob);
  }
  get topsImageBlob(): Blob | undefined {
    return this._topsImageBlob;
  }

  _bottomsImageBlob?: Blob;
  set bottomsImageBlob(blob: Blob | undefined) {
    this.bottomsImageUrl = undefined;
    this._bottomsImageBlob = blob;
    if (!this._bottomsImageBlob) {
      return;
    }
    this.bottomsImageUrl = URL.createObjectURL(this._bottomsImageBlob);
  }
  get bottomsImageBlob(): Blob | undefined {
    return this._bottomsImageBlob;
  }

  _jumpsuitImageBlob?: Blob;
  set jumpsuitImageBlob(blob: Blob | undefined) {
    this.jumpsuitImageUrl = undefined;
    this._jumpsuitImageBlob = blob;
    if (!this._jumpsuitImageBlob) {
      return;
    }
    this.jumpsuitImageUrl = URL.createObjectURL(this._jumpsuitImageBlob);
  }
  get jumpsuitImageBlob(): Blob | undefined {
    return this._jumpsuitImageBlob;
  }

  isExpanded: boolean = true;

  topsImageUrl?: string;
  bottomsImageUrl?: string;
  jumpsuitImageUrl?: string;
  modelScale: ModelScaleProps | any = null;

  prompts: string = 'both';
  topsPrompts?: Array<string>;
  bottomsPrompts?: Array<string>;
  jumpsuitPrompts?: Array<string>;

  userNotificationCatecory: UserNotificationCategory = UserNotificationCategory.FreeTryon;

  minQuantity: number = 1;
  maxQuantity: number = 2;
  quantity: string = "1";

  selectedModel?: number;
  selectedScence?: number;

  taskNameEditable: boolean = false;

  modelActiveTab: number = 0;
  scenceActiveTab: number = 0;

  assetModelSelected: boolean = false;
  assetModelId?: number;
  initAssetModelId: number = -1;
  modelPrompts: string = "";

  scencePrompts: string = "";
  scenceLabels?: Array<string> = [];
  assetScenceSelected: boolean = false;
  assetScenceId?: number;
  initAssetScenceId: number = -1;

  stickerBackgroundImageUrl?: string;

  constructor(injector: Injector,
    private clothService: ClothService,
    private activatedroute: ActivatedRoute) {
    super(injector);
    this.userId = this.activatedroute.snapshot.params["user"];
  }

  ngOnInit() {
    this.buildScenceLabels();
  }

  ngOnDestroy() {

  }

  assetModelImageSelected(asset: UserAssetDetails) {
    this.assetModelSelected = true;
    this.selectedModel = -1;
    this.modelPrompts = "";
    this.assetModelId = asset.id;
    this.modelSelect.reset();
  }

  assetScenceImageSelected(asset: UserAssetDetails) {
    this.assetScenceSelected = true;
    this.selectedScence = -1;
    this.scencePrompts = "";
    this.assetScenceId = asset.id;
    this.modelScale = { width: asset.width, height: asset.height, samScale: 1.0 };
    this.scenceSelect.reset();
  }

  templateModelSeleted(template: MaterialTemplateDetails | undefined) {
    if (!template) {
      this.selectedModel = undefined;
      this.modelPrompts = "";
      return;
    }
    this.selectedModel = template.id;
    this.assetModelSelected = false;
    this.assetModelId = -1;
    this.modelPrompts = template.promptsEnglish ?? '';
    this.modelAsset?.reset();
  }

  templateScenceSelected(template: MaterialTemplateDetails | undefined) {
    if (!template) {
      return;
    }
    this.selectedScence = template.id;
    this.assetScenceSelected = false;
    this.assetScenceId = -1;
    this.scencePrompts = template.promptsEnglish ?? '';
    this.modelScale = { width: template.width, height: template.height, samScale: 1.0 };
    this.scenceAsset?.reset();
  }

  expandedChange(newExpanded: boolean) {
    this.isExpanded = newExpanded;
  }

  createObjectURL(data: ArrayBuffer, type: string) {
    return URL.createObjectURL(new Blob([data], { type: type }));;
  }

  generateClick() {
    if (!this.isInputValid()) {
      return;
    }

    this.generate();
  }

  generate() {
    let formData = new FormData();
    let blob = undefined, blob2 = undefined;
    switch (this.prompts) {
      case 'tops':
        blob = this.topsImageBlob;
        break;
      case 'bottoms':
        blob = this.bottomsImageBlob;
        break;
      case 'jumpsuit':
        blob = this.jumpsuitImageBlob;
        break;
      case 'both':
        blob = this.topsImageBlob;
        blob2 = this.bottomsImageBlob;
        break;
    }
    if (!!blob) {
      let filename = 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      formData.set('imageFile', blob, filename);
    }
    if (!!blob2) {
      let filename = 'raw_image_2' + MediaTypeHelper.resolveFileExtension(blob2.type);
      formData.set('imageFile2', blob2, filename);
    }

    if (!!this.assetModelId && this.assetModelId >= 0) {
      formData.set('modelSource', '0');
      formData.set('modelId', this.assetModelId.toString());
      formData.set('modelPrompts', this.modelPrompts);
    }
    if (!!this.selectedModel && this.selectedModel >= 0) {
      formData.set('modelSource', '1');
      formData.set('modelId', this.selectedModel.toString());
      formData.set('modelPrompts', this.modelPrompts);
    }

    if (!!this.assetScenceId && this.assetScenceId >= 0) {
      formData.set('scenceSource', '0');
      formData.set('scenceId', this.assetScenceId.toString());
    } else {
      formData.set('scenceSource', '1');
      formData.set('scenceId', this.selectedScence!.toString());
    }

    formData.set('taskId', this.taskId!.toString());
    formData.set('scencePrompts', this.scencePrompts);
    formData.set('prompts', this.prompts);
    formData.set('width', this.modelScale.width?.toString());
    formData.set('height', this.modelScale.height?.toString());
    // formData.set('quantity', this.quantity);
    formData.set('quantity', '1');//The quantity is fixed at1

    let operation = this.clothService.freeTryon(formData, (o) => {
      this.saveOperationState(o.id!);
    });
    let zone = this.injector.get(NgZone);
    zone.run(() => {
      this.userOperationList.addOperation(operation);
      this.scrollToTop();
    });
  }

  isInputValid(): boolean {
    if (!this.prompts) {
      this.showError("Please select clothing type。");
      return false;
    }
    if ((this.prompts == 'tops' || this.prompts == 'both') && !this.topsImageBlob) {
      this.showError("Please upload the top tiling image。");
      return false;
    }
    if ((this.prompts == 'bottoms' || this.prompts == 'both') && !this.bottomsImageBlob) {
      this.showError("Please upload the bottom tile map。");
      return false;
    }
    if (this.prompts == 'jumpsuit' && !this.jumpsuitImageBlob) {
      this.showError("Please upload a one-piece flat lay。");
      return false;
    }
    if (this.selectedScence === -1 && this.assetScenceId === -1) {
      this.showError("Please select a reference image。")
      return false;
    }
    // if (this.selectedModel === -1 && this.assetModelId === -1) {
    //   this.showError("Please select a model。")
    //   return false;
    // }
    if (!validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return false;
    }
    return true;
  }

  topsImageChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }
    this.topsImageBlob = file;

    this.mergeInputImages((mergedImageBlob) => {
      this.saveTaskThumbnail(mergedImageBlob);
    });
    // Commented since chatgpt recognization is in the backend AI process
    // this.recognitionClothing(this.topsImageBlob, (prompts) => {
    //   this.topsPrompts = prompts;
    //   if (this.prompts == 'tops' || this.prompts == 'both') {
    //     this.buildScenceLabels();
    //   }
    //   this.saveTaskState();
    // });
  }

  bottomsImageChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }
    this.bottomsImageBlob = file;

    this.mergeInputImages((mergedImageBlob) => {
      this.saveTaskThumbnail(mergedImageBlob);
    });
    // Commented since chatgpt recognization is in the backend AI process
    // this.recognitionClothing(this.bottomsImageBlob, (prompts) => {
    //   this.bottomsPrompts = prompts;
    //   if (this.prompts == 'bottoms' || this.prompts == 'both') {
    //     this.buildScenceLabels();
    //   }
    //   this.saveTaskState();
    // });
  }

  jumpsuitImageChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }
    this.jumpsuitImageBlob = file;

    this.saveTaskThumbnail(this.jumpsuitImageBlob);
    // Commented since chatgpt recognization is in the backend AI process
    // this.recognitionClothing(this.jumpsuitImageBlob, (prompts) => {
    //   this.jumpsuitPrompts = prompts;
    //   if (this.prompts == 'jumpsuit') {
    //     this.buildScenceLabels();
    //   }
    //   this.saveTaskState();
    // });
  }

  mergeInputImages(callback: (paramBlob: Blob | undefined) => void) {
    if (this.prompts == 'tops') {
      callback(this.topsImageBlob);
      return;
    } else if (this.prompts == 'bottoms') {
      callback(this.bottomsImageBlob);
      return;
    } else if (this.prompts != 'both') {
      callback(undefined);
      return;
    }
    let topsImageBlob = this.topsImageBlob;
    if (!topsImageBlob) {
      callback(this.bottomsImageBlob);
      return;
    }
    let bottomsImageBlob = this.bottomsImageBlob;
    if (!bottomsImageBlob) {
      callback(this.topsImageBlob);
      return;
    }
    createImageBitmap(topsImageBlob).then((topsImage) => {
      const canvas = document.createElement('canvas');
      canvas.width = topsImage.width;
      canvas.height = topsImage.height;
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      ctx?.drawImage(
        topsImage, 0, 0, topsImage.width, topsImage.height,
        0, 0, topsImage.width, topsImage.height
      );
      createImageBitmap(bottomsImageBlob!).then((bottomsImage) => {
        let width = canvas.width * 0.5;
        let height = canvas.height * 0.5;
        let wRatio = bottomsImage.width / width;
        let hRatio = bottomsImage.height / height;
        let ratio = Math.max(wRatio, hRatio);
        if (ratio > 1) {
          width = bottomsImage.width / ratio;
          height = bottomsImage.height / ratio;
        } else {
          width = bottomsImage.width;
          height = bottomsImage.height;
        }
        let x = canvas.width - width;
        let y = canvas.height - height;
        ctx?.drawImage(bottomsImage, x, y, width, height);
        canvas.toBlob((newImageBlob) => {
          if (!newImageBlob) {
            callback(undefined);
          } else {
            callback(newImageBlob);
          }
        });
      });
    });
  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  reset() {
    this.modelSelect.reset();
    this.scenceSelect.reset();
    this.topsImagePicker.reset();
    this.bottomsImagePicker.reset();
    this.jumpsuitImagePicker.reset();
    this.topsImageBlob = undefined;
    this.topsPrompts = undefined;
    this.bottomsImageBlob = undefined;
    this.bottomsPrompts = undefined;
    this.jumpsuitImageBlob = undefined;
    this.jumpsuitPrompts = undefined;
    this.modelActiveTab = 0;
    this.scenceActiveTab = 0;
    this.selectedScence = -1;
    this.selectedModel = -1;
    this.modelAsset?.reset();
    this.scenceAsset?.reset();
    this.assetModelId = -1
    this.assetScenceId = -1;
    this.initAssetModelId = -1;
    this.initAssetScenceId = -1;
    this.modelPrompts = '';
    this.scencePrompts = '';
    this.prompts = 'both';
    this.quantity = '1';
    this.buildScenceLabels();
  }

  buildState(): any {
    let params = [];

    let topsImageFilename: string | undefined = undefined;
    let blob = this.topsImageBlob;
    if (!!blob && blob.size > 0) {
      topsImageFilename = 'tops_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], topsImageFilename));
    }

    let bottomsImageFilename: string | undefined = undefined;
    blob = this.bottomsImageBlob;
    if (!!blob && blob.size > 0) {
      bottomsImageFilename = 'bottoms_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], bottomsImageFilename));
    }

    let jumpsuitImageFilename: string | undefined = undefined;
    blob = this.jumpsuitImageBlob;
    if (!!blob && blob.size > 0) {
      jumpsuitImageFilename = 'jumpsuit_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], jumpsuitImageFilename));
    }

    params.push({
      topsImageUrl: topsImageFilename,
      topsPrompts: this.topsPrompts,
      bottomsImageUrl: bottomsImageFilename,
      bottomsPrompts: this.bottomsPrompts,
      jumpsuitImageUrl: jumpsuitImageFilename,
      jumpsuitPrompts: this.jumpsuitPrompts,
      modelPrompts: this.modelPrompts ?? '',
      scencePrompts: this.scencePrompts ?? '',
      prompts: this.prompts ?? '',
      selectedModel: this.selectedModel ?? null,
      selectedScence: this.selectedScence,
      assetModelId: this.assetModelId,
      assetScenceId: this.assetScenceId,
      modelScale: this.modelScale,
      quantity: this.quantity,
    });

    return params;
  }

  saveOperationState(id: number): void {
    let params = this.buildState();
    this.patchOperationState(id, params);
  }

  override saveTaskState(): void {
    this.patchTaskState(this.buildState());
  }

  applyState(state: any, operationId?: number) {
    this.isExpanded = false;

    this.reset();
    if (!state) {
      this.restoreComplete();
      return;
    }

    this.modelPrompts = state.modelPrompts ?? '';
    this.scencePrompts = state.scencePrompts ?? '';
    this.prompts = state.prompts ?? 'both';
    this.modelScale = state.modelScale;
    this.quantity = state.quantity ?? 1;
    this.topsPrompts = state.topsPrompts;
    this.bottomsPrompts = state.bottomsPrompts;
    this.jumpsuitPrompts = state.jumpsuitPrompts;
    this.buildScenceLabels();

    setTimeout(() => {
      this.selectedModel = state.selectedModel ?? -1;
      this.initAssetModelId = state.assetModelId ?? -1;
      this.assetModelId = state.assetModelId ?? -1;
      this.selectedScence = state.selectedScence ?? -1;
      this.initAssetScenceId = state.assetScenceId ?? -1;
      this.assetScenceId = state.assetScenceId ?? -1;
      this.modelActiveTab = 0;//Temporarily hide definition functions state.assetModelId === -1 ? 1:0;
      this.scenceActiveTab = state.assetScenceId === -1 ? 1 : 0;
    }, 0);

    if (!!state.topsImageUrl) {
      let topsImageUrl = `${state.topsImageUrl}`;
      this.restoreImageFromUrl(topsImageUrl, operationId).subscribe({
        next: (result: Blob) => {
          this.topsImageBlob = result;
        },
      });
    }
    if (!!state.bottomsImageUrl) {
      let bottomsImageUrl = `${state.bottomsImageUrl}`;
      this.restoreImageFromUrl(bottomsImageUrl, operationId).subscribe({
        next: (result: Blob) => {
          this.bottomsImageBlob = result;
        },
      });
    }
    if (!!state.jumpsuitImageUrl) {
      let jumpsuitImageUrl = `${state.jumpsuitImageUrl}`;
      this.restoreImageFromUrl(jumpsuitImageUrl, operationId).subscribe({
        next: (result: Blob) => {
          this.jumpsuitImageBlob = result;
        },
      });
    }
  }

  override restoreTaskState(): void {
    this.fetchTaskState().subscribe((result: any) => this.applyState(result));
  }

  override scrollToTop(): void {
    const containerElement: HTMLElement = this.inputMatCard.nativeElement;
    containerElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  cloneClick(operation: UserOperationListItem) {
    this.fetchOperationState(operation.id!).subscribe((result: any) => this.applyState(result, operation.id));
    this.scrollToTop();
  }

  userOperationStatusChange(processing: boolean) {
    this.processing = processing;
  }

  clothingTypeChanged(value: string) {
    this.prompts = value;
    this.buildScenceLabels();
  }

  topsSelected(template: MaterialTemplateDetails | undefined) {
    this.loadClothing(template?.id, this.topsImagePicker);
  }

  bottomsSelected(template: MaterialTemplateDetails | undefined) {
    this.loadClothing(template?.id, this.bottomsImagePicker);
  }

  jumpsuitSelected(template: MaterialTemplateDetails | undefined) {
    this.loadClothing(template?.id, this.jumpsuitImagePicker);
  }

  private loadClothing(id: number | undefined, imagePicker: ImagePicker) {
    if (!id) {
      return;
    }
    let http = this.injector.get(AppHttpClient);
    http.get(`/material/templates/${id}`, { responseType: 'blob' })
      .subscribe({
        next: (result: Blob) => {
          imagePicker.loadFile(result);
        },
      });
  }

  private buildScenceLabels() {
    let scenceLabels: Array<string> = [];
    switch (this.prompts) {
      case 'tops':
        scenceLabels = ["Tops", ...this.topsPrompts ?? []];
        break;
      case 'bottoms':
        scenceLabels = ["Bottoms", ...this.bottomsPrompts ?? []];
        break;
      case 'jumpsuit':
        scenceLabels = ["Bodysuits", ...this.jumpsuitPrompts ?? []];
        break;
      case 'both':
        scenceLabels = ["Suits", ...this.topsPrompts ?? [], ...this.bottomsPrompts ?? []];
        break;
    }
    scenceLabels = scenceLabels.filter((item, index) => scenceLabels.indexOf(item) === index); // remove duplicate elements
    this.scenceLabels = scenceLabels;
  }

  private recognitionClothing(imageBlob: Blob | undefined, callback: (prompts: Array<string>) => void) {
    if (!imageBlob) {
      return;
    }
    ImageHelper.fitFromBlob(imageBlob, 512, 512, (resizedImageBlob) => {
      let formData = new FormData();
      formData.set('imageFile', resizedImageBlob);
      let http = this.injector.get(AppHttpClient);
      http.post(`/cloth/recognition`, formData).subscribe({
        next: (result: ActionResult) => {
          if (!http.isValidResult(result)) {
            return;
          }
          let prompts = result.data;
          console.log(`Clothing analysis completed。Features：[${prompts}]`);
          callback(prompts);
        },
      });
    });
  }

}
