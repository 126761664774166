<mat-card class="flex-grow-1">

  <button class="w-100 mb-3 app-button" mat-button color="primary" (click)="addUserTaskClick()">
    <div class = "d-flex align-items-center justify-content-center">
      <mat-icon class="icon-scale" color="primary" svgIcon="app:add_task"></mat-icon>
      <span>Create a new task</span>
    </div>
  </button>

  <div>
    <div class="task-item" [class.active]="task.selected" matRipple [matRippleCentered]="true"
      *ngFor="let task of tasks" (click)="selectTask(task)">
      <div class="m-2 task-image background-lighter image-area"><img [src]="task.url"/></div>
      <span class="mat-body">{{task.name}}</span>
      <div class="flex-grow-1"></div>
      <button mat-icon-button class="btn-sm" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{task}"
        (click)="$event.stopPropagation();">
        <mat-icon>more_horiz</mat-icon>
      </button>
    </div>
    <div class="text-end" *ngIf="tasks.length>0 && hasMore">
      <button mat-button class="fw-bold" color="primary" (click)="fetchMoreClick()">Show more...</button>
    </div>
  </div>

</mat-card>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-task="task">
    <button mat-menu-item (click)="editUserTaskClick($event, task)">
      <mat-icon>edit</mat-icon><span>Rename</span>
    </button>
    <button mat-menu-item (click)="removeUserTaskClick($event, task)">
      <mat-icon>delete</mat-icon><span>Delete</span>
    </button>
  </ng-template>
</mat-menu>
