<div class="d-flex flex-row p-2">
  <div mat-dialog-title>Package Upgrade</div>
  <div class="flex-1"></div>
  <div class="btn-dialog-close">
    <button mat-icon-button class="btn-sm" color="accent" (click)="cancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="d-flex px-3">

  <div class="mx-2">
    <div class="mb-3">
      <div class="d-inline-block">
        <div class="background-accent rounded-2 d-flex p-1">
          <div class="member-period px-3" [class.active]="period=='month'" matRipple
            (click)="memeberPeriodChanged('month')">Monthly subscription</div>
          <div class="mx-1"></div>
          <div class="member-period px-3" [class.active]="period=='year'" matRipple
            (click)="memeberPeriodChanged('year')">Yearly Package</div>
        </div>
      </div>
    </div>

    <div>
      <div class="member-type px-3 py-5 mb-3" [class.active]="type=='person'" matRipple
        (click)="memeberTypeChanged('person')">
        <div>Professional Edition</div>
        <div class="price my-3">{{period=='month'?'￥188':'￥1888'}}</div>
        <div>Suitable for individual users</div>
      </div>
      <div class="member-type px-3 py-5" [class.active]="type=='team'" matRipple (click)="memeberTypeChanged('team')">
        <div>Team Edition</div>
        <div class="price my-3">{{period=='month'?'￥688':'￥5888'}}</div>
        <div>Suitable for small team users（3-4people）</div>
      </div>
    </div>
  </div>

  <div class="background-accent mx-2 rounded-2">
    <div>
      <div class="qrcode mx-4 my-3" [class.background-white]="!url">
        <div class="m-auto" *ngIf="!url"><mat-spinner></mat-spinner></div>
        <img [src]="url" *ngIf="!!url" />
      </div>
    </div>
    <div class="text-white text-center my-4">
      <span>Scan code to pay：</span><span class="price">{{type=='person'? (period=='month'?'￥188':'￥1888'):period=='month'?'￥688':'￥5888'}}</span>
    </div>
    <div class="d-flex my-2">
      <div class="d-flex align-items-center m-auto">
        <mat-icon class="me-2" svgIcon="app:wechat"></mat-icon>
        <span class="text-white">Please use WeChat to scan the QR code to pay</span>
      </div>
    </div>
    <div class="d-flex">
      <div class="fs-small m-auto">
        <span>After payment is completed, you agree</span><a class="text-primary" href="#">Related Agreement Terms</a>
      </div>
    </div>
    <div class="text-center fs-smaller my-4">
      <span>Virtual Goods，No refunds，The final right of interpretation belongs to Zhuanxinzhizhi</span>
    </div>
  </div>

</mat-dialog-content>
