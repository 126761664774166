import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MatDialog } from '@angular/material/dialog';
import { testItem, evaFeedback } from '../interfaces';
import { AppFormGroup } from '../common/app.forms';
import { FormControl } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'picture-audit-param-compare',
  templateUrl: './picture-audit-param-compare.component.html',
  styleUrl: './picture-audit-param-compare.component.scss',
})
export class PictureAuditParamCompareComponent extends BaseComponent implements OnInit {

  testList: Map<string, testItem[]> = new Map<string, testItem[]>;

  uuidList: string[] = [];
  inputList: testItem[] = [];
  resultList: testItem[] = [];

  nameMap: Map<number, string> = new Map<number, string>;

  curindex?: number;
  feedbackList: evaFeedback[] = [];
  displayedColumns: string[] = ['uuid', 'betterCount', 'equalCount', 'worseCount', 'feedbackDate'];
  dataSource?: MatTableDataSource<evaFeedback>;
  @ViewChild(MatTable) table!: MatTable<evaFeedback>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  betterRate: number = 0;
  equalRate: number = 0;
  worseRate: number = 0;

  evaForm: AppFormGroup[] = [];

  chartData: any[][] = [];
  qChartData: any[][] = [];
  paramName: string[] = [];

  paramMap: Map<number, Map<string, string>> = new Map<number, Map<string, string>>;
  paramBetterMap: Map<string, Map<string, number>> = new Map<string, Map<string, number>>;
  paramEquaMap: Map<string, Map<string, number>> = new Map<string, Map<string, number>>;
  paramWorseMap: Map<string, Map<string, number>> = new Map<string, Map<string, number>>;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog
  ) {
    super(injector);
    for (let i = 0; i <= 10; i++) {
      this.evaForm[i] = new AppFormGroup({
        eva: new FormControl(''),
      });
    }

    this.dataSource = new MatTableDataSource();

  }



  ngOnInit() {
    this.http.get('/evaluation/param/assets').subscribe({
      next: (result: ActionResult) => {
        if (!!result) {
          let imageName: string[] = result.data.imageName;
          let csvContent: string[] = result.data.csvContent;

          let paramLine = csvContent[0] ?? '';
          this.paramName = paramLine.split(',');
          this.paramName.splice(0, 1);

          for (let i = 1; i < csvContent.length; i++) {
            let params = csvContent[i].split(',');
            let id = this.getWorkId(params[0]);

            if (!this.paramMap.get(id)) {
              params.splice(0, 1);
              let tempMap: Map<string, string> = new Map<string, string>;

              for (let j = 0; j < this.paramName.length; j++) {
                let key = this.paramName[j];
                tempMap.set(key, params[j]);
              }

              this.paramMap.set(id, tempMap);
            }
          }

          imageName.forEach(image => {

            let workId = this.getWorkId(image);
            if (!this.nameMap.get(workId)) {
              this.nameMap.set(workId, this.cutOffExt(image));
            }

            this.http.get('/evaluation/param/assets/' + image, { responseType: 'blob' }).subscribe({
              next: (result: Blob) => {
                if (!!result) {
                  let item: testItem = { path: undefined, url: undefined };
                  item.path = image;
                  item.url = URL.createObjectURL(result);

                  let prefix = this.getPrefixUuid(item.path);
                  if (!this.testList.get(prefix)) {
                    this.uuidList.push(prefix);
                  }
                  let curList = this.testList.get(prefix) ?? [];
                  curList.push(item);
                  this.testList.set(prefix, curList);
                }
              },
            });
          });
        }
      },
    });

    this.http.get('/evaluation/feedback').subscribe({
      next: (result: ActionResult) => {
        if (!!result) {
          let data: Record<string, any> = result.data;
          for (let key in data) {
            let value = data[key];
            let count: number = value.better_count + value.worse_count + value.equal_count;
            if (value.uuid.lastIndexOf(".") !== -1) {
              continue;
            }

            for (let i = 0; i < this.paramName.length; i++) {
              let key = this.paramName[i];
              let id = this.getWorkId(value.uuid);
              let cur_val = this.paramMap.get(id)!.get(key);

              let map = this.paramBetterMap.get(key) ?? new Map<string, number>;
              let cur_num = map.get(cur_val!) ?? 0;
              cur_num = cur_num + value.better_count;
              map.set(cur_val!, cur_num);
              this.paramBetterMap.set(key, map);

              let qMap = this.paramEquaMap.get(key) ?? new Map<string, number>;
              cur_num = qMap.get(cur_val!) ?? 0;
              cur_num = cur_num + value.equal_count;
              qMap.set(cur_val!, cur_num);
              this.paramEquaMap.set(key, qMap);

              let wMap = this.paramWorseMap.get(key) ?? new Map<string, number>;
              cur_num = wMap.get(cur_val!) ?? 0;
              cur_num = cur_num + value.worse_count;
              wMap.set(cur_val!, cur_num);
              this.paramWorseMap.set(key, wMap);
            }

            this.feedbackList.push({
              uuid: this.getWorkId(value.uuid).toString(), betterCount: value.better_count / count,
              equalCount: value.equal_count / count, worseCount: value.worse_count / count, feedbackDate: new Date(value.feedback_date)
            });
          }
          this.dataSource = new MatTableDataSource(this.feedbackList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.table.renderRows();
          this.calcResult();

          for (let i = 0; i < this.paramName.length; i++) {
            let arr: any[] = [];
            let qArr: any[] = [];

            let fKey = this.paramName[i];
            let map = this.paramBetterMap.get(fKey);
            let eMap = this.paramEquaMap.get(fKey);
            let wMap = this.paramWorseMap.get(fKey);

            map!.forEach((value, key) => {
              let eVal = eMap!.get(key);
              let wVal = wMap!.get(key);
              let content = {
                "name": key,
                "value": value / (value + eVal! + wVal!),
              }
              arr.push(content);

              content = {
                "name": key,
                "value": (value + eVal!) / (value + eVal! + wVal!),
              }
              qArr.push(content);
            });

            this.chartData.push(arr);
            this.qChartData.push(qArr);
          }
        }
      },
    });
  }

  getPrefixUuid(str: string) {
    let index = str.indexOf('_');
    return index !== -1 ? str.substring(0, index) : str;
  }

  getWorkId(str: string) {
    let index = str.indexOf('_');
    let new_str = index !== -1 ? str.substring(index + 1) : str;
    index = new_str.indexOf('_');
    return index !== -1 ? parseInt(new_str.substring(0, index)) : -1;
  }

  cutOffExt(str: string) {
    let index = str.indexOf('.');
    return index !== -1 ? str.substring(0, index) : str;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource!.filter = filterValue.trim().toLowerCase();

    if (this.dataSource!.paginator) {
      this.dataSource!.paginator.firstPage();
    }

    this.calcResult();
  }

  calcResult() {
    let filteredData = this.dataSource?.filteredData;
    let tot_better = 0, tot_equal = 0, tot_worse = 0;
    if (!!filteredData) {
      filteredData.forEach(element => {
        tot_better = tot_better + element.betterCount;
        tot_equal = tot_equal + element.equalCount;
        tot_worse = tot_worse + element.worseCount;
      });
    }
    const tot = tot_better + tot_equal + tot_worse;

    this.betterRate = tot_better / tot;
    this.equalRate = tot_equal / tot;
    this.worseRate = tot_worse / tot;
  }

  getSuffix(str: string): string {
    let lastIndex: number = str.lastIndexOf("/");

    if (lastIndex !== -1 && lastIndex < str.length - 1) {
      let afterLastSlash: string = str.substr(lastIndex + 1);
      return afterLastSlash;
    } else {
      return "";
    }
  }

  navClick(uuid: string, index: number) {
    this.resultList = []
    this.inputList = []

    this.evaForm.forEach((form) => {
      form.reset();
    });

    this.curindex = index;
    this.resultList = this.testList.get(uuid)!;
  }

  nextClick() {
    this.submitEva();
    if (this.curindex! + 1 >= this.uuidList.length) {
      this.resultList = []
      return;
    }
    this.navClick(this.uuidList[this.curindex! + 1], this.curindex! + 1);
  }

  submitEva() {
    let evaList: string[] = [];
    let uuidList: string[] = [];

    for (let i = 0; i < this.resultList.length; i++) {

      if (!this.evaForm[i].get('eva')?.value) {
        continue;
      }

      evaList.push(this.evaForm[i].get('eva')?.value);
      uuidList.push(this.nameMap.get(this.getWorkId(this.resultList[i].path!))!);

    }

    let params = {
      eva: evaList,
      uuid: uuidList,
    }

    this.http.post('/evaluation/feedback/list', params, { silence: false }).subscribe(response => {
    });
  }

}
