<div class="max-width-xl m-auto">
    <br>
    <br>
    <div class="text-center mb-3">
        <h2 class="my-2">Smart Beans/Order History</h2>
        <span class="text-accent">You can view the consumption of Zhidou and package purchase records here</span>
    </div>
    <div class="d-flex mb-3">
        <div class="background-accent rounded-2 d-flex p-1 m-auto">
            <div class="list-type" [class.active]="type=='transaction'" matRipple (click)="typeChanged('transaction')">
                Smart Beans Consumption Record</div>
            <div class="mx-1"></div>
            <div class="list-type" [class.active]="type=='order'" matRipple (click)="typeChanged('order')">Order History</div>
        </div>
    </div>
    <div>
        <my-transaction-list [class.d-none]="type!='transaction'" />
        <my-order-list [class.d-none]="type!='order'" />
    </div>
</div>
