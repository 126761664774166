<page-header title="Fixed pose mannequin" />

<div class="flex-grow-1 h-0 d-flex flex-column flex-lg-row">

  <div class="me-1" [class.d-none]="!isExpanded">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.swapMannequin" [userId]="userId"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <user-favorite-list [type]="JobTypesEnum.swapMannequin" [userId]="userId" *ngIf="!taskId" />

  <div [class]="!taskId? 'd-none':'d-flex flex-column'">

    <flexible-accordion class="h-lg-100">
      <div #inputMatCard class="input-container flexible-accordion-header">

        <mat-card class="p-0 d-flex flex-column">

          <div class="px-2 task-header background-lighter">

            <div class="d-flex flex-row align-items-center">
              <div class="d-flex align-items-center" matTooltip="task bar" (click)="isExpanded=!isExpanded">
                <mat-icon class="cursor-pointer" matRipple color="primary" svgIcon="app:my_tasks" />
              </div>
              <div class="ms-2 me-3" *ngIf="!taskNameEditable">
                <h3 class="my-0">{{userTaskList.curTask?.name}}</h3>
              </div>

              <!--<text-area class="text-area background-lighter task-name-area ms-2 me-2 mat-subtitle-1" 
                [(ngModel)]="taskName" matInput rows="4" cols="10" placeholder=""
                *ngIf="taskNameEditable"></text-area>-->

              <mat-icon color="accent" class="cursor-pointer" matTooltip="Duplicate naming" svgIcon="app:edit"
                (click)="userTaskList.editUserTaskClick($event,userTaskList.curTask!)"> </mat-icon>
            </div>
            <div class="flex-grow-1"></div>
          </div>

          <div class="mx-2">

            <div class="d-flex my-2">
              <span class="mat-small hint-text"> Upload a model picture or mannequin，Contains clothing and human body parts</span>
            </div>


            <div class="d-flex flex-wrap">

              <div class="raw-image-zone col-12 col-lg-6 ps-0 pe-lg-1">
                <div class="ratio ratio-1x1 background-lighter image-area">
                  <div class="image-label d-flex my-0 px-2 py-1">
                    <p class="mat-small my-0"> Original image</p>
                  </div>

                  <image-picker #imagePicker (changed)="rawImageChanged($event)" [class.d-none]="rawImageUrl" />
                  <img class="raw-image" [class.d-none]="!rawImageUrl" [src]="rawImageUrl"
                    (load)="onRawImageLoaded($event)" (click)="rawImagePicker.click()" />
                </div>
              </div>

              <div class="sticker-image-zone col-12 col-lg-6 ps-lg-1 pe-0">

                <div
                  class="sticker-image-area flex-column d-flex align-items-center justify-content-center background-lighter"
                  [ngClass]="{'background-alpha-dark': !stickerEmpty()}">

                  <div class="image-label d-flex my-0 px-2 py-1">
                    <p class="mat-small my-0"> District map</p>
                  </div>

                  <div class="sticker-area-mask d-flex flex-column align-items-center justify-content-center"
                    [class.d-none]="!stickerEmpty()">

                    <p class="mat-body my-0">Curing area</p>
                    <p class="mat-small mt-1 mb-0 hint-text">Please select the area you want to keep unchanged</p>

                    <button mat-button class="app-button rounded-pill my-3" color="primary"
                      (click)="segmentPickerClick($event);">
                      <div class="d-flex align-items-center">
                        <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:edit'"></mat-icon>
                        <p class="button-text my-0">Edit Selection&nbsp;</p>
                      </div>
                    </button>
                  </div>

                  <div class="position-absolute start-0 bottom-0" [class.d-none]="stickerEmpty()">
                    <button mat-flat-button class="app-button rounded-pill m-1 ps-0 pe-2" color="primary"
                      (click)="segmentPickerClick($event);">
                      <div class="d-flex align-items-center">
                        <mat-icon class="attri-icon-size" [svgIcon]="'app:edit'"></mat-icon>
                        <p class="button-text my-0 fs-smaller">Re-edit</p>
                      </div>
                    </button>
                  </div>

                  <div class="sticker-container">
                    <img class="sticker-background-image" [src]="stickerBackgroundImageUrl"
                      [class.d-none]="!stickerBackgroundImageUrl" />
                    <image-sticker-input [modelScale]="modelScale" [enableEdit]="false" #stickers />
                  </div>
                  <element-floatable>
                    <div class="bottom-right d-flex">
                      <!-- <button mat-icon-button class="btn-sm bg-white m-1" color="primary" matTooltip="delete"
                        (click)="deleteStickerImageClick()">
                        <mat-icon>delete</mat-icon>
                      </button> -->
                      <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="download"
                        (click)="exportStickerImageClick()">
                        <mat-icon svgIcon="app:download"></mat-icon>
                      </div>
                    </div>
                  </element-floatable>

                </div>
              </div>
            </div>

            <div class="my-3">
              <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [dynamicHeight]="true"
                fitInkBarToContent="true">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 class="mt-0 mb-2">Quick Templates </h4>
                  </ng-template>

                  <!--<textarea readonly class="text-area w-100 mt-3 background-lighter" matInput rows="4" [(ngModel)]="prompts"
                  placeholder=""></textarea>-->

                  <div class="d-flex mt-3 mb-2 flex-row align-items-center">
                    <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:model'"></mat-icon>
                    <p class="my-0 mat-body">Model selection</p>
                    <p class="mx-2 mt-1 mb-0 mat-small hint-text">Asia/Europe/Africa, etc.</p>
                  </div>

                  <mat-tab-group class="mt-2" mat-stretch-tabs="false" mat-align-tabs="start"
                    [selectedIndex]="modelActiveTab" [dynamicHeight]="true" fitInkBarToContent="true">
                    <!-- Temporarily hide this feature -->
                    <!-- <mat-tab>
                      <ng-template mat-tab-label>
                        <span class="mat-small">Custom Models </span>
                      </ng-template>

                      <div class="mt-2"> </div>
                      
                      <user-asset-inline #modelAsset [groupType]="AssetGroupTypesEnum.MyModel" [inlineHintText]="'Upload Model'" [selected]="assetModelSelected"
                      [initSelectedId]="initAssetModelId" (selectionChange)="assetModelImageSelected($event)"/>

                      <div class="mb-1"></div>

                    </mat-tab> -->

                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span class="mat-small">Model Library </span>
                      </ng-template>

                      <div class="mt-2 mb-1">
                        <material-select-inline #modelSelect [category]="MaterialCategoryEnum.ClothingModel"
                          [selectedTemplateId]="selectedModel" (selectionChange)="templateModelSeleted($event)" />
                      </div>

                    </mat-tab>

                  </mat-tab-group>



                  <div class="d-flex mt-3 flex-row align-items-center">
                    <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:scence'"></mat-icon>
                    <p class="my-0 mat-body">Select background</p>
                    <p class="mx-2 mt-1 mb-0 mat-small hint-text">Product image background parameter settings </p>
                  </div>

                  <mat-tab-group class="mt-2" mat-stretch-tabs="false" mat-align-tabs="start"
                    [selectedIndex]="scenceActiveTab" [dynamicHeight]="true" fitInkBarToContent="true">
                    <!-- Temporarily hide this feature -->
                    <!-- <mat-tab>
                      <ng-template mat-tab-label>
                        <span class="mat-small">Custom background</span>
                      </ng-template>

                      <div class="mt-2"> </div>

                      <user-asset-inline #scenceAsset [groupType]="AssetGroupTypesEnum.MyBackground" [inlineHintText]="'Upload background'" [selected]="assetScenceSelected"
                      [initSelectedId]="initAssetScenceId" (selectionChange)="assetScenceImageSelected($event)"/>

                      <div class="mb-1"></div>

                    </mat-tab> -->

                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span class="mat-small">Background Library</span>
                      </ng-template>

                      <div class="mt-2 mb-1">
                        <material-select-inline #scenceSelect [category]="MaterialCategoryEnum.ClothingBackground"
                          [selectedTemplateId]="selectedScence" (selectionChange)="templateScenceSelected($event)" />
                      </div>

                    </mat-tab>

                  </mat-tab-group>

                  <!--<p class="mt-3 mb-0 mat-small">Background Description</p>
                  <textarea class="text-area w-100 mt-3 background-lighter" matInput rows="4" [(ngModel)]="prompts"
                  placeholder="Please enter the background text description you would like to generate for the product image"></textarea>-->

                </mat-tab>

                <!--<mat-tab>
                  <ng-template mat-tab-label>
                    <h4 class="mt-0 mb-2">Freedom to create </h4>
                  </ng-template>
                  <textarea class="text-area w-100 mt-3 background-lighter" matInput rows="4" [(ngModel)]="prompts"
                  placeholder="Please enter the text description you wish to generate for the product image"></textarea>
                </mat-tab>-->
              </mat-tab-group>
            </div>

          </div>

          <div class="flex-grow-1"></div>

          <div class="px-2 py-1 background-lighter task-footer w-100 d-flex flex-column">

            <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />

            <div class="d-flex mt-2">
              <cost-estimator category="image" item="paint" [quantity]="quantity" />
              <div class="flex-grow-1"></div>
              <button mat-flat-button class="px-4" color="primary" (click)="generateClick()"
                [disabled]="processing || !!userId">AI Generate</button>
            </div>

          </div>

        </mat-card>

      </div>

      <div class="h-lg-100 user-operation-area" *ngIf="taskId">

        <div class="d-flex flex-column w-100 h-100 p-lg-2">
          <user-favorite-list [type]="JobTypesEnum.swapMannequin" [userId]="userId" [taskId]="taskId" />
          <user-operation-list #userOperationList [userId]="userId" [taskId]="taskId"
            [notificationCategory]="userNotificationCatecory" (clone)="cloneClick($event)"
            (statusChange)="userOperationStatusChange($event)" />
        </div>

      </div>

    </flexible-accordion>

  </div>

</div>
