import { Component, EventEmitter, Input, Output } from '@angular/core';
import { validRange } from '../common/app.forms';

@Component({
  selector: 'image-scale-input',
  templateUrl: './image-scale-input.html',
})
export class ImageScaleInput {
  @Input() min?: number;
  @Input() max?: number;
  @Input() value?: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {

  }

  addScaleClick(step: number) {
    let value = this.value?.replace('%', '');
    if (!validRange(value, this.min, this.max)) {
      this.value = '100%';
      return;
    }
    var v = Number(value) + step;
    if (!!this.min && v < this.min || !!this.max && v > this.max) {
      return;
    }
    this.value = v.toString() + '%';
    this.valueChange.emit(this.value);
  }

  valueChaned() {
    this.valueChange.emit(this.value);
  }

}