import { Component, ElementRef, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { AssetGroupTypes, MaterialCategory, TaskCategory, UserNotificationCategory } from '../models';
import { validRange } from '../common/app.forms';
import { UserTaskList } from '../user-task/user-task-list';
import { ImagePicker } from '../image-picker/image-picker';
import { ClothService } from '../services/cloth.service';
import { MediaTypeHelper } from '../helpers/app.helpers';
import { UserOperationList } from '../user-operation/user-operation-list';
import { JobTypes } from '../models';
import { Size, UserOperationListItem } from '../interfaces';
import { aspectToSize } from '../helpers/image-helper';
import { ImageMaskInput } from '../image-generate-input/image-mask-input';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'design-to-clothing',
  templateUrl: './design-to-clothing.component.html',
  styleUrl: './design-to-clothing.component.scss',
})
export class DesignToClothingComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;
  expanded: boolean = true;
  expanded2: boolean = true;
  UserNotificationCategoryEnum = UserNotificationCategory;
  AssetGroupTypesEnum = AssetGroupTypes;
  MaterialCategoryEnum = MaterialCategory;
  JobTypesEnum = JobTypes;

  @ViewChild('imagePicker') rawImagePicker!: ImagePicker;
  @ViewChild('userTaskList') userTaskList!: UserTaskList;
  @ViewChild('userOperationList') userOperationList!: UserOperationList;

  userNotificationCatecory: UserNotificationCategory = UserNotificationCategory.ClothDesign;
  userId?: string;
  rawImageUrl?: string;
  maxImageEdgeLen = 1024;
  isExpanded: boolean = true;

  _rawImageBlob?: Blob;
  set rawImageBlob(blob: Blob | undefined) {
    this.rawImageUrl = undefined;
    this._rawImageBlob = blob;
    if (!this._rawImageBlob) {
      return;
    }
    this.rawImageUrl = URL.createObjectURL(this._rawImageBlob);
  }
  get rawImageBlob(): Blob | undefined {
    return this._rawImageBlob;
  }

  @ViewChild('rawImage') rawImageRef!: ElementRef;
  @ViewChild('maskInput') maskInput!: ImageMaskInput;

  aspect: string = "16:9";
  // imageSize?: Size;
  // resolution: string = "1500*1500";
  imageSize?: Size;

  prompts: string = "";
  negativePrompts: string = "";

  minQuantity: number = 1;
  maxQuantity: number = 4;
  quantity: string = "4";

  clothingGender: string = "Unlimited";
  clothingStyle: string = "Unlimited";
  content: string = "Unlimited";

  segmenting: boolean = false;

  constructor(injector: Injector,
    private clothService: ClothService,
    private activatedroute: ActivatedRoute) {
    super(injector);
    this.userId = this.activatedroute.snapshot.params["user"];
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {

  }

  createObjectURL(data: ArrayBuffer, type: string) {
    return URL.createObjectURL(new Blob([data], { type: type }));;
  }

  generateClick() {
    if (!this.isInputValid()) {
      return;
    }
    this.generate();
  }

  generate() {
    let formData = new FormData();
    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;

    this.expanded = false;

    if (!!blob) {
      rawImageFilename = 'image_reference' + MediaTypeHelper.resolveFileExtension(blob.type);
      formData.set('imageReference', blob, rawImageFilename);
    }

    let clothingGender = this.clothingGender === 'Unlimited' ? '' : this.clothingGender;
    let clothingStyle = this.clothingStyle === 'Unlimited' ? '' : this.clothingStyle;
    let content = this.content === 'Unlimited' ? '' : this.content;
    let prompts = `${this.prompts} ${clothingGender} ${clothingStyle} ${content}`;
    formData.set('prompts', prompts.trim());

    formData.set('negativePrompts', this.negativePrompts);
    let size = aspectToSize(this.aspect);
    if (!!size) {
      formData.set('width', size.width.toString());
      formData.set('height', size.height.toString());
    }
    if (!!this.aspect) {
      formData.set('aspect', this.aspect);
    }
    formData.set('quantity', this.quantity);
    formData.set('taskId', this.taskId!.toString());

    let operation = this.clothService.generate(formData, (o) => {
      this.saveOperationState(o.id!);
    });
    let zone = this.injector.get(NgZone);
    zone.run(() => {
      this.userOperationList.addOperation(operation);
      this.scrollToTop();
    });
  }

  saveOperationState(id: number): void {
    let params = this.buildState();
    this.patchOperationState(id, params);
  }

  override saveTaskState(): void {
    this.patchTaskState(this.buildState());
  }

  isInputValid(): boolean {
    if (!this.prompts) {
      this.showError("Please enter a text description。")
      return false;
    }
    if (!this.aspect) {
      this.showError("Please select the image ratio。")
      return false;
    }
    if (!validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return false;
    }

    return true;
  }

  rawImageChanged(file: Blob | undefined) {
    if (!file) {
      this.removeTaskThumbnail();
      this.rawImageBlob = undefined;
      return;
    }
    this.rawImageBlob = file;
    this.saveTaskThumbnail(file);
  }

  clothingGenderChanged(clothingGender: string) {
    this.clothingGender = clothingGender;
  }

  clothingStyleChanged(clothingStyle: string) {
    this.clothingStyle = clothingStyle;
  }

  contentChanged(content: string) {
    this.content = content;
  }

  imageAspectChanged(aspect: string) {
    this.aspect = aspect;
  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  reset() {
    this.rawImageBlob = undefined;
    this.clothingGender = "Unlimited";
    this.clothingStyle = "Unlimited";
    this.content = "Unlimited";
    this.prompts = '';
    this.negativePrompts = '';
    this.aspect = '16:9';
    this.quantity = '4';
    this.segmenting = false;
  }
  
  buildState(): any {
    let params = [];
    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'image_reference' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }

    params.push({
      rawImageUrl: rawImageFilename,
      clothingGender: this.clothingGender,
      clothingStyle: this.clothingStyle,
      prompts: this.prompts,
      content: this.content,
      negativePrompts: this.negativePrompts,
      aspect: this.aspect,
      quantity: this.quantity,
    });

    return params;
  }

  applyState(state: any, operationId?: number) {
    this.isExpanded = false;
    this.reset();
    if (!state) {
      this.restoreComplete();
      return;
    }
    this.clothingGender = state.clothingGender;
    this.clothingStyle = state.clothingStyle;
    this.content = state.content;
    this.prompts = state.prompts;
    this.negativePrompts = state.negativePrompts;
    this.aspect = state.aspect;
    this.quantity = state.quantity;

    if (!!state.rawImageUrl) {
      let rawImageUrl = `${state.rawImageUrl}`;
      this.restoreImageFromUrl(rawImageUrl, operationId).subscribe({
        next: (result: Blob) => {
          this.rawImageBlob = result;
          this.rawImagePicker.loadFile(result);
        },
      });
    }
  }

  override restoreTaskState(): void {
    this.fetchTaskState().subscribe((result: any) => this.applyState(result));
  }

  cloneClick(operation: UserOperationListItem) {
    this.fetchOperationState(operation.id!).subscribe((result: any) => this.applyState(result, operation.id));
    this.scrollToTop();
  }

  userOperationStatusChange(processing: boolean) {
    this.processing = processing;
  }

  expandedChange(newExpanded: boolean) {
    this.isExpanded = newExpanded;
  }
}

