import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { UserJobListItem, UserOperationListItem } from '../interfaces';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { JobStatus, UserNotification, UserNotificationCategory } from '../models';
import { Subscription } from 'rxjs';
import { RxStompService } from '../stomp/rx-stomp.service';
import { IMessage } from '@stomp/rx-stomp';
import { DateTimeHelper } from '../helpers/app.helpers';
import { MessageService } from '../common/message.service';
import { JobService } from '../services/job.service';

@Component({
  selector: 'user-operation-list',
  templateUrl: './user-operation-list.html',
  styleUrl: './user-operation-list.scss',
})
export class UserOperationList implements OnInit {
  JobStatusEnum = JobStatus;
  dailyOperations: Array<DailyOperationListItem> = [];
  operations: Array<UserOperationListItem> = [];
  hasMore = false;
  @Input() userId?: string;
  @Input() notificationCategory: UserNotificationCategory = UserNotificationCategory.Paint;
  @Input() editable = false;
  @Output() edit = new EventEmitter<UserJobListItem>();
  @Input() useBackgroundAlpha: boolean = false;
  @Input() enableSave: boolean = true;
  @Input() showTiffMenu: boolean = false;
  private paintSubscription?: Subscription;
  @Output() clone = new EventEmitter<UserOperationListItem>();
  @Output() statusChange = new EventEmitter<boolean>();

  private timer?: NodeJS.Timeout;

  _taskId?: number;
  @Input() set taskId(value: number | undefined) {
    let changed = value != this._taskId;
    this._taskId = value;
    if (!this.taskId) {
      return;
    }
    if (changed) {
      this.reset();
      this.fetch();
    }
  }
  get taskId() {
    return this._taskId;
  }

  private jobSubscription?: Subscription;

  constructor(
    private http: AppHttpClient,
    private dialog: MatDialog,
    private rxStompService: RxStompService,
    private messageService: MessageService,
    private jobService: JobService,
    private ngZone: NgZone,
  ) {

  }

  ngOnInit() {
    this.paintSubscription = this.rxStompService
      .watch(this.notificationCategory)
      .subscribe((message: IMessage) => {
        let notification = JSON.parse(message.body) as UserNotification;
        let jobId = notification.id;
        let result = notification.data as ActionResult;
        let jobStatus = result.status;
        let job = this.findJobInDailyOperations(jobId);
        if (!job) {
          return;
        }
        job.status = jobStatus;
        job.statusDesc = result.data?.statusDesc;
        this.jobService.notify(job);
      });

    this.jobSubscription = this.jobService.subscribe(job => this.jobChanged(job));

    this.timer = setInterval(() => this.checkOperationStatus(), 60 * 1000);
  }

  ngOnDestroy() {
    this.paintSubscription?.unsubscribe();

    this.jobSubscription?.unsubscribe();

    if (!!this.timer) {
      clearTimeout(this.timer);
    }
  }

  fetch() {
    var params = {
      userId: this.userId,
      taskId: this.taskId,
      startIndex: this.operations.length,
    }
    this.http.post(`/user/operations/fetch`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let operations = [...this.operations];
        result.data.operations.forEach((operation: UserOperationListItem) => {
          for (let i = 0; i < operations.length; i++) {
            if (operation.id == operations[i].id) {
              return;
            }
          }
          operations.push(operation);
          operation.jobs?.forEach(job => {
            job.parent = operation.jobs;
            this.downloadThumbnail(job)
          });
          this.addToDailyOperationList(operation);
        });
        this.operations = operations;
        this.hasMore = result.data.hasMore;
        this.statusChange.emit(this.processing);
      }
    });
  }

  downloadThumbnail(job: UserJobListItem) {
    if (job.status != JobStatus.success) {
      return;
    }
    if (!!job.thumbnailUrl) {
      return;
    }
    this.http.get(`/job/${job.id}/thumbnail`, { responseType: 'blob' }).subscribe({
      next: async (result: Blob) => {
        job.thumbnailUrl = URL.createObjectURL(result);
        // this.dailyOperations = [...this.dailyOperations];
      },
      error(err) {
        console.error(err);
        job.status = JobStatus.failed;
      },
    });
  }

  checkOperationStatus() {
    let notCompletedOpertions = this.operations.filter(operation => {
      let notCompletedJobs = operation.jobs?.filter((value) => value.status != JobStatus.success && value.status != JobStatus.failed) ?? [];
      return notCompletedJobs.length > 0;
    });
    notCompletedOpertions.forEach(operation => {
      let id = operation.id;
      if (!id) {
        return;
      }
      this.http.get(`/user/operations/${id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          let operation = result.data;
          if (!operation) {
            return;
          }
          operation.jobs.forEach((job: UserJobListItem) => {
            if (job.status != JobStatus.success && job.status != JobStatus.failed) {
              return;
            }
            this.jobService.notify(job);
          });
        }
      });
    });
  }

  findJobInDailyOperations(id: string | undefined) {
    if (!id) {
      return undefined;
    }
    for (let i = 0; i < this.dailyOperations.length; i++) {
      let operations = this.dailyOperations[i].operations;
      if (!operations) {
        continue;
      }
      for (let j = 0; j < operations.length; j++) {
        let operation = operations[j];
        if (!operation.jobs) {
          continue;
        }
        for (let k = 0; k < operation.jobs.length; k++) {
          let job = operation.jobs[k];
          if (id != job.id?.toString()) {
            continue;
          }
          return job;
        }
      }
    }
    return undefined;
  }

  addToDailyOperationList(operation: UserOperationListItem) {
    let dailyOperationList = this.dailyOperations.find(item => this.formatDate(item.createdDate) == this.formatDate(operation.modifiedDate));
    if (!dailyOperationList) {
      dailyOperationList = {
        createdDate: operation.modifiedDate,
        operations: [],
      };
      this.dailyOperations.push(dailyOperationList);
    }
    if (!dailyOperationList.operations) {
      dailyOperationList.operations = [];
    }
    dailyOperationList.operations.push(operation);
    this.dailyOperations.sort((o1, o2) => (o2.createdDate ?? 0) - (o1.createdDate ?? 0));
    this.dailyOperations.forEach(element => {
      element.operations?.sort((o1, o2) => (o2.modifiedDate ?? 0) - (o1.modifiedDate ?? 0));
      element.operations?.forEach(operation => {
        operation.jobs?.sort((o1, o2) => (o1.modifiedDate ?? 0) - (o2.modifiedDate ?? 0));
      })
    });
  }

  removeFromDailyOperationList(operation: UserOperationListItem) {
    let dailyOperationList = this.dailyOperations;
    for (let i = dailyOperationList.length - 1; i >= 0; i--) {
      let operations = dailyOperationList[i].operations ?? [];
      for (let j = operations.length - 1; j >= 0; j--) {
        if (operation.id != operations[j].id) {
          continue;
        }
        operations.splice(j, 1);
        if (operations.length == 0) {
          dailyOperationList.splice(i, 1);
        }
        return;
      }
    }
  }

  addOperation(operation: UserOperationListItem) {
    // this.addAll([operation]);
    operation.jobs?.forEach(job => {
      job.parent = operation.jobs;
    });
    this.operations = [operation, ...this.operations];
    this.addToDailyOperationList(operation);
    this.statusChange.emit(this.processing);
  }

  reset() {
    this.dailyOperations = [];
    this.operations = [];
    this.hasMore = false;
    // this.init();
  }

  removeUserOperationClick(event: MouseEvent, operation: UserOperationListItem) {
    event.stopPropagation();
    // this.taskMenu.closeMenu();

    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the generated results?？',
      },
    }).afterClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }
      this.http.delete(`/user/operations/${operation.id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.dailyOperations.forEach(d => {
            d.operations = (d.operations ?? []).filter(function (o) {
              return o.id != operation.id;
            });
            this.messageService.showInformation('The generated results have been successfully deleted。');
          });
        }
      });
    });
  }

  fetchMoreClick() {
    this.fetch();
  }

  calculateAspect(job: UserJobListItem) {
    var width = Number(job.imageWidth);
    var height = Number(job.imageHeight);
    if (isNaN(width) || isNaN(height)) {
      return 'auto';
    } else {
      return `${width}/${height}`;
    }
  }

  onEdit(item: UserJobListItem) {
    this.edit.emit(item);
  }

  formatDate(value: number | undefined) {
    if (!value) {
      return "";
    }
    return DateTimeHelper.format(value, "yyyy-MM-dd");
  }

  formatTime(value: number | undefined) {
    if (!value) {
      return "";
    }
    return DateTimeHelper.format(value, "HH:mm");
  }

  get processing() {
    for (let i = 0; i < this.operations.length; i++) {
      var jobs = this.operations[i].jobs;
      if (!jobs) {
        continue;
      }
      for (let j = 0; j < jobs.length; j++) {
        let job = jobs[j];
        if (job.status == JobStatus.waiting || job.status == JobStatus.running) {
          return true;
        }
      }
    }
    return false;
  }

  jobChanged(job: UserJobListItem) {
    for (let i = this.operations.length - 1; i >= 0; i--) {
      let operation = this.operations[i];
      let jobs = operation.jobs ?? [];
      for (let j = jobs.length - 1; j >= 0; j--) {
        let item = jobs[j];
        if (item.id != job.id) {
          continue;
        }
        this.ngZone.run(() => {
          if ((job.feedbackRate ?? 0) < 0) {
            jobs.splice(j, 1);
            if (jobs.length == 0) {
              this.removeFromDailyOperationList(this.operations[i]);
              this.operations.splice(i, 1);
            }
            return;
          }
          item.status = job.status;
          item.statusDesc = job.statusDesc;
          if (item.status != JobStatus.success) {
            return;
          }
          this.downloadThumbnail(item);
          item.favoriteRate = job.favoriteRate;
          item.favoriteDate = job.favoriteDate;
          item.feedbackRate = job.feedbackRate;
        });
      }
    }
    this.statusChange.emit(this.processing);
  }

  onClone(operation: UserOperationListItem) {
    this.clone.emit(operation);
  }

}

interface DailyOperationListItem {
  createdDate?: number;
  operations?: Array<UserOperationListItem>;
}
