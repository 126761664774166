<div class="page-header">
  <h4 class="title me-5">Order Management - Modify order</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="backClick()"><mat-icon color="primary"
      aria-label="return">arrow_back</mat-icon><span>return</span>
  </button>
</div>

<form class="d-flex flex-wrap align-items-start" [formGroup]="inputForm">
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Order Number</mat-label>
      <input matInput formControlName="orderNo" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="employeeName" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Work No.</mat-label>
      <input matInput formControlName="employeeNo" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>User Account</mat-label>
      <user-input formControlName="user" />
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Payment Methods</mat-label>
      <mat-select formControlName="payType">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let item of payTypeList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Payment Account</mat-label>
      <input matInput formControlName="payAccount" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Payment voucher</mat-label>
      <!-- <input matInput formControlName="payVoucherFile"> -->
      <file-input formControlName="payVoucherFile" />
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Arrival Date</mat-label>
      <input matInput formControlName="payReceivedDate" [matDatepicker]="payReceivedDatePicker" maxlength="10"
        autocomplete="off">
      <mat-datepicker-toggle matSuffix [for]="payReceivedDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #payReceivedDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Order Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let item of statusList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Remark</mat-label>
      <textarea matInput formControlName="description" rows="4" maxlength="150"></textarea>
    </mat-form-field>
  </div>

  <mat-card class="mb-2">
    <mat-card-header>
      <div class="flex-grow-1">
        <mat-card-title>Order Details</mat-card-title>
      </div>
      <div>
        <button mat-button type="button" color="primary" (click)="addOrderItem()" *ngIf="canEdit">
          <span class="me-2">Add details</span><mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <hr />
    <mat-card-content>
      <div formArrayName="items">
        <div class="d-flex flex-wrap" *ngFor="let materialRelease of itemFormArray.controls; let i = index"
          formGroupName="{{i}}">
          <div class="col-8 col-lg-2">
            <mat-form-field>
              <mat-label>Service Classification</mat-label>
              <mat-select formControlName="itemType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let item of itemTypeList" [value]="item.key">{{item.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4 col-lg-2">
            <mat-form-field>
              <mat-label>Amount</mat-label>
              <input matInput formControlName="amount" maxlength="11" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="col-4 col-lg-2">
            <mat-form-field>
              <mat-label>Buy Smart Beans</mat-label>
              <input matInput formControlName="totalPoints" maxlength="8" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="col-4 col-lg-2">
            <mat-form-field>
              <mat-label>Remaining Smart Beans</mat-label>
              <input matInput formControlName="points" maxlength="8" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field>
              <mat-label>start date</mat-label>
              <input matInput formControlName="startDate" [matDatepicker]="startDatePicker" maxlength="10"
                autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input matInput formControlName="endDate" [matDatepicker]="endDatePicker" maxlength="10"
                autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field>
              <mat-label>Service Description</mat-label>
              <input matInput formControlName="description" maxlength="256">
            </mat-form-field>
          </div>
          <div class="col-2 col-lg-2">
            <button mat-icon-button type="button" color="warn" matTooltip="Delete Details" (click)="removeOrderItem(i)" *ngIf="canEdit">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</form>

<div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="warn" type="button" (click)="deleteClick()"
    *ngIf="!!id">delete</button>
  <div class="d-inline-block mx-2"></div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="primary" type="button"
    (click)="saveClick()">keep</button>
</div>

<br />
<br />
