import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaintedImage, UserNotificationCategory } from '../models';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'painted-image-list',
  templateUrl: './painted-image-list.html',
  styleUrl: './painted-image-list.scss',
})
export class PaintedImageList implements OnInit {

  @Input() value?: PaintedImage[];
  @Output() valueChange = new EventEmitter<PaintedImage[]>();
  @Output() completed = new EventEmitter<PaintedImage>();
  @Input() editable = false;
  @Output() edit = new EventEmitter<PaintedImage>();
  @Input() visible: boolean = true;
  @Input() notificationCategory: UserNotificationCategory = UserNotificationCategory.Paint;
  @Input() gridCalculationMode: 'aspect' | 'responsive' | 'fixed' = 'aspect';
  @Input() backgroundColor: string =  'rgba(0, 0, 0, 0)';
  @Input() useBackgroundAlpha: boolean = false;
  @Input() enableSave: boolean = true;
  @Input() showTiffMenu: boolean = false;

  private breakpoint = '(max-width: 992px)';

  constructor(private breakpointObserver: BreakpointObserver) {

  }

  ngOnInit() {
    this.breakpointObserver.observe(this.breakpoint).subscribe(() => {
      switch (this.gridCalculationMode) {
        case 'aspect':
          this.caculateGrid(this.quantity, this.aspect);
          break;
        case 'responsive':
          this.responsiveGrid(this.quantity, this.inputImgWidth!, this.inputImgHeight!);
          break;
        case 'fixed':
          this.calcFixedGrid(this.quantity, this.inputImgWidth!, this.inputImgHeight!);
          break;
      }
    }
    );
  }

  onCompleted(item: PaintedImage) {
    this.completed.emit(item);
  }

  onEdit(item: PaintedImage) {
    this.edit.emit(item);
  }

  imageWidth?: string = '50%';
  imageHeight?: string = '50%';
  quantity?: string;
  aspect?: string;
  inputImgWidth?: number;
  inputImgHeight?: number;
  caculateGrid(quantity: string | undefined, aspect: string | undefined) {
    this.quantity = quantity;
    this.aspect = aspect;
    if (this.breakpointObserver.isMatched(this.breakpoint)) {
      this.imageWidth = '100%';
      this.imageHeight = '100%';
      return;
    }
    this.imageWidth = '50%';
    this.imageHeight = '50%';
    if(!quantity || !aspect){
      return;
    }
    switch (Number(quantity)) {
      case 1:
        this.imageWidth = '100%';
        this.imageHeight = '100%';
        return;
      case 2:
        switch (aspect) {
          case '16:9':
            this.imageWidth = '100%';
            this.imageHeight = '50%';
            return;
          case '9:16':
            this.imageWidth = '50%';
            this.imageHeight = '100%';
            return;
        }
    }
  }

  calcFixedGrid(quantityStr: string | undefined, width: number, height: number) {
    this.quantity = quantityStr;
    this.inputImgWidth = width;
    this.inputImgHeight = height;

    if (this.breakpointObserver.isMatched(this.breakpoint)) {
      this.imageWidth = '100%';
      this.imageHeight = '100%';
      return;
    }
    
    if (!quantityStr || !width || !height) {
      return;
    }

    this.imageWidth = `${width}px`;
    this.imageHeight = `${height}px`;

  }

  responsiveGrid(quantityStr: string | undefined, width: number, height: number) {
    this.quantity = quantityStr;
    this.inputImgWidth = width;
    this.inputImgHeight = height;

    if (this.breakpointObserver.isMatched(this.breakpoint)) {
      this.imageWidth = '100%';
      this.imageHeight = '100%';
      return;
    }

    if (!quantityStr || !this.inputImgWidth || !this.inputImgHeight) {
      return;
    }
    let quantity = Number(quantityStr);
    quantity += quantity % 2;

    let numberPerDirection = Math.max(1, quantity / 2 );
    let numberPerReverseDirection = quantity === 1 ? 1 : 2;

    let longEdgeImgNum = Math.min(numberPerDirection, numberPerReverseDirection);
    let shortEdgeImgNum = Math.max(numberPerDirection, numberPerReverseDirection);

    if (this.inputImgWidth >= this.inputImgHeight) {
      this.imageWidth = `${100 / longEdgeImgNum}%`;
      this.imageHeight = `${100 / shortEdgeImgNum}%`;
    }
    else {
      this.imageWidth = `${100 / shortEdgeImgNum}%`;
      this.imageHeight = `${100 / longEdgeImgNum}%`;
    }

  }

}