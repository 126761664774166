import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppHttpClient } from '../common/app.http';

@Component({
  selector: 'wechat-contact-dialog',
  templateUrl: './wechat-contact-dialog.html',
  styleUrl: './wechat-contact-dialog.scss',
})
export class WechatContactDialog implements OnInit {
  url?: string;

  constructor(
    private http: AppHttpClient,
    public dialogRef: MatDialogRef<WechatContactDialog>,
  ) {

  }

  ngOnInit() {
    this.http.get('/public/contact-us/wechat', { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.url = URL.createObjectURL(result);
      },
    });
  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }

}
