<div class="h-100 d-flex flex-column">
  <app-header (menuToggleChanged)="sidenav.toggle()" [menuToggleVisible]="!sidenavVisible"></app-header>

  <mat-sidenav-container class="h-100">
    <mat-sidenav class="side-bar" #sidenav [opened]="!mobileQuery.matches"
      [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      (openedChange)="sidenavOpenedChange()">
      <div class="my-2"></div>

      <a class="menu-item" routerLink="/home" routerLinkActive="active" (click)="menuClick()">
        <div class="pe-2"><mat-icon svgIcon="app:home"></mat-icon></div>
        <div class="text-center">Home</div>
      </a>

      <a class="menu-item" routerLink="/material" routerLinkActive="active" (click)="menuClick()"
        *ngIf="isDevelopment && hasPermission(UserFunctionEnum.MaterialTemplates)">
        <div class="pe-2"><mat-icon svgIcon="app:image"></mat-icon></div>
        <div class="text-center" i18n="@@menuLabelAssetLib">Asset Library</div>
      </a>

      <a class="menu-item" routerLink="/assets" routerLinkActive="active" (click)="menuClick()"
        *ngIf="isDevelopment && hasPermission(UserFunctionEnum.UserAssets)">
        <div class="pe-2"><mat-icon svgIcon="app:image_favorite"></mat-icon></div>
        <div class="text-center" i18n="@@menuLabelIdeasLib">Ideas Library</div>
      </a>

      <cdk-accordion *ngIf="hasPermission(UserFunctionEnum.ImageDesign) || hasPermission(UserFunctionEnum.Text2Image)">
        <mat-expansion-panel class="shadow-none m-0" [expanded]="getMenuExpaned('products', true)"
          (afterCollapse)="afterMenuCollapse('products')" (afterExpand)="afterMenuExpand('products')"
          *ngIf="isDevelopment">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="pe-2"><mat-icon svgIcon="app:image_library"></mat-icon></div>
              <div class="text-center" i18n="@@menuLabelProductSynth">Visionary Studio</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="menu-item" routerLink="/image/design" routerLinkActive="active" (click)="menuClick()"
            *ngIf="hasPermission(UserFunctionEnum.ImageDesign)">
            <div class="pe-2"></div>
            <div class="text-center" i18n="@@menuLabelProductSceneSwap">Scene Swap</div>
          </a>
          <a class="menu-item" routerLink="/image/text" routerLinkActive="active" (click)="menuClick()"
            *ngIf="hasPermission(UserFunctionEnum.Text2Image)">
            <div class="pe-2"></div>
            <div class="text-center" i18n="@@menuLabelProductFreeMaker">Free Maker</div>
          </a>
        </mat-expansion-panel>
      </cdk-accordion>

      <cdk-accordion
        *ngIf="hasPermission(UserFunctionEnum.ClothDesign) || hasPermission(UserFunctionEnum.FaceReplace) || hasPermission(UserFunctionEnum.ClothReplace)">
        <mat-expansion-panel class="shadow-none m-0" [expanded]="getMenuExpaned('cloths', true)"
          (afterCollapse)="afterMenuCollapse('cloths')" (afterExpand)="afterMenuExpand('cloths')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="pe-2"><mat-icon svgIcon="app:cloth"></mat-icon></div>
              <div class="text-center" i18n="@@menuLabelFashionSynth">Fashion Synth</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="menu-item" routerLink="/clothing/tryon" routerLinkActive="active" (click)="menuClick()"
            *ngIf="hasPermission(UserFunctionEnum.FaceReplace)">
            <div class="pe-2"></div>
            <div i18n="@@menuLabelTryon">Try On</div>
          </a>
          <a class="menu-item" routerLink="/clothing/model" routerLinkActive="active" (click)="menuClick()"
            *ngIf="isDevelopment && hasPermission(UserFunctionEnum.FaceReplace)">
            <div class="pe-2"></div>
            <div i18n="@@menuLabelFashionModel">Model</div>
          </a>
          <a class="menu-item" routerLink="/clothing/mannequin" routerLinkActive="active" (click)="menuClick()"
            *ngIf="isDevelopment && hasPermission(UserFunctionEnum.FaceReplace)">
            <div class="pe-2"></div>
            <div i18n="@@menuLabelFashionMannequin">Mannequin</div>
          </a>
          <a class="menu-item" routerLink="/clothing/color" routerLinkActive="active" (click)="menuClick()"
            *ngIf="isDevelopment">
            <div class="pe-2"></div>
            <div i18n="@@menuLabelColorTransform">Color Transform</div>
          </a>
          <a class="menu-item" routerLink="/clothing/design" routerLinkActive="active" (click)="menuClick()"
            *ngIf="isDevelopment && hasPermission(UserFunctionEnum.ClothDesign)">
            <div class="pe-2"></div>
            <div i18n="@@menuLabelCouture">Couture AI</div>
          </a>
          <a class="menu-item" routerLink="/clothing/facelift" routerLinkActive="active" (click)="menuClick()"
            *ngIf="isDevelopment && hasPermission(UserFunctionEnum.ClothReplace)">
            <div class="pe-2"></div>
            <div i18n="@@menuLabelOutfitShifter">Outfit Shifter</div>
          </a>
        </mat-expansion-panel>
      </cdk-accordion>

      <a class="menu-item" routerLink="/toolkit" routerLinkActive="active" (click)="menuClick()"
        *ngIf="isDevelopment && hasPermission(UserFunctionEnum.ToolList)">
        <div class="pe-2"><mat-icon svgIcon="app:tools"></mat-icon></div>
        <div class="text-center">Creative Kits</div>
      </a>

      <a class="menu-item" routerLink="/image/simidesign" routerLinkActive="active" (click)="menuClick()"
        *ngIf="isDevelopment && hasPermission(UserFunctionEnum.ImageSimulator)">
        <div class="pe-2"><mat-icon svgIcon="app:agile"></mat-icon></div>
        <div class="text-center">Micro Engine</div>
      </a>

      <a class="menu-item" routerLink="/fitting-room" routerLinkActive="active" (click)="menuClick()" *ngIf="isAdmin">
        <div class="pe-2">
          <mat-icon class="m-auto">styler</mat-icon>
        </div>
        <div class="text-center">Fitting Room</div>
      </a>

      <a class="menu-item" routerLink="/admin/material/search" routerLinkActive="active" (click)="menuClick()"
        *ngIf="isAdmin">
        <div class="pe-2">
          <mat-icon class="m-auto">wallpaper_slidershow</mat-icon>
        </div>
        <div class="text-center">Materials</div>
      </a>

      <a class="menu-item" routerLink="/admin/orders/search" routerLinkActive="active" (click)="menuClick()"
        *ngIf="isAdmin">
        <div class="pe-2">
          <mat-icon class="m-auto">request_quote</mat-icon>
        </div>
        <div class="text-center">Orders</div>
      </a>

      <cdk-accordion *ngIf="isAdmin">
        <mat-expansion-panel class="shadow-none m-0" [expanded]="getMenuExpaned('users')"
          (afterCollapse)="afterMenuCollapse('users')" (afterExpand)="afterMenuExpand('users')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="pe-2">
                <mat-icon class="m-auto">people</mat-icon>
              </div>
              <div class="text-center">Access</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a class="menu-item" routerLink="/admin/companies/search" routerLinkActive="active" (click)="menuClick()"
            *ngIf="true">
            <div class="pe-2"></div>
            <div class="text-center">Company List</div>
          </a>
          <a class="menu-item" routerLink="/admin/users/search" routerLinkActive="active" (click)="menuClick()"
            *ngIf="hasPermission(UserFunctionEnum.ClothReplace)">
            <div class="pe-2"></div>
            <div class="text-center">User List</div>
          </a>
        </mat-expansion-panel>
      </cdk-accordion>

      <a class="menu-item" routerLink="/audit/common" routerLinkActive="active" (click)="menuClick()" *ngIf="isAdmin">

        <div class="pe-2">
          <mat-icon class="m-auto">analytics</mat-icon>
        </div>
        <div class="text-center">Image Review</div>
      </a>

      <a class="menu-item" routerLink="/audit/param" routerLinkActive="active" (click)="menuClick()" *ngIf="isAdmin">
        <div class="pe-2">
          <mat-icon class="m-auto">analytics</mat-icon>
        </div>
        <div class="text-center">Parameters</div>
      </a>

      <div class="h-100"></div>

      <div>
        <mat-card class="m-2 shadow-sm box-border rounded-3">
          <span class="fw-bold">Free Version</span>
          <div class="rounded-2 w-100">
            <mat-progress-bar color="primary" [value]="20"></mat-progress-bar>
          </div>
          <div><span>1.35 / 1,000.00</span></div>
        </mat-card>

        <mat-card class="m-2 box-border rounded-3" *ngIf="isCostTypeTimes">
          <a class="menu-item m-0 p-2">
            <div class="pe-2"><mat-icon svgIcon="app:points"></mat-icon></div>
            <div class="text-center text-orange fw-bold">{{points}}</div>
          </a>
        </mat-card>

        <a class="menu-item">
          <div class="pe-2"><mat-icon svgIcon="app:notifications"></mat-icon></div>
          <div class="text-center">notify</div>
        </a>

        <a class="menu-item">
          <div class="pe-2"><mat-icon svgIcon="app:settings"></mat-icon></div>
          <div class="text-center">set up</div>
        </a>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <div class="h-100 p-0">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
