<div *ngIf="enableEdit">
  <element-resizable [(left)]="item.left" [(top)]="item.top" [(width)]="item.width" [(height)]="item.height" unit="%"
    [(angle)]="item.angle" [border]="true" (contextmenu)="onStickerContextMenu($event, item)" *ngFor="let item of value">
    <img class="sticker-image" [src]="item.src" />
  </element-resizable>
</div>

<div *ngIf="!enableEdit">
  <element-resizable [(left)]="item.left" [(top)]="item.top" [(width)]="item.width" [(height)]="item.height" unit="%"
    [(angle)]="item.angle" [border]="false" [dot]="false" (contextmenu)="onStickerContextMenu($event, item)" *ngFor="let item of value">
    <img class="sticker-image" [src]="item.src" />
  </element-resizable>
</div>

<div class="invisible position-fixed" [style.left]="stickerContextMenuPosition.x"
  [style.top]="stickerContextMenuPosition.y" [matMenuTriggerFor]="stickerContextMenu" *ngIf="enableEdit"></div>

<div class="invisible position-fixed" [style.left]="stickerContextMenuPosition.x"
  [style.top]="stickerContextMenuPosition.y" *ngIf="!enableEdit"></div>

<mat-menu #stickerContextMenu="matMenu">
  <ng-template matMenuContent let-sticker="sticker">
    <button mat-menu-item (click)="bringStickerToTopClick(sticker)">
      <mat-icon class="transform-flip-y">low_priority</mat-icon><span>Move to the front</span>
    </button>
    <button mat-menu-item (click)="bringStickerToFrontClick(sticker)">
      <mat-icon>move_up</mat-icon><span>Moving forward</span>
    </button>
    <button mat-menu-item (click)="bringStickerToBehindClick(sticker)">
      <mat-icon>move_down</mat-icon><span>Move Backward</span>
    </button>
    <button mat-menu-item (click)="bringStickerToBottomClick(sticker)">
      <mat-icon>low_priority</mat-icon><span>Move to the end</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="exportStickerImageClick(sticker)">
      <mat-icon>save_alt</mat-icon><span>download</span>
    </button>
    <button mat-menu-item (click)="removeStickerClick(sticker)">
      <mat-icon>delete</mat-icon><span>delete</span>
    </button>
  </ng-template>
</mat-menu>
