<div class="page-header">
  <h4 class="title me-5">Business Management - Modify company information</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="backClick()"><mat-icon color="primary"
      aria-label="return">arrow_back</mat-icon><span>return</span>
  </button>
</div>

<form class="d-flex flex-wrap align-items-start" [formGroup]="inputForm">
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Corporate Account</mat-label>
      <input matInput formControlName="username" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>password</mat-label>
      <input matInput formControlName="password" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Company Name</mat-label>
      <input matInput formControlName="nickname" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Phone number</mat-label>
      <input matInput formControlName="mobilePhone" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Enterprise Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let item of statusList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>

<div class="page-header">
  <h4 class="title me-5">List of Enterprise Users</h4>
</div>

<div class="d-flex flex-wrap mb-3">
  <mat-card class="company-user-add" matRipple (click)="operUserPickerDialog()">
    <div class="d-flex align-items-center h-100">
      <mat-icon class="company-user-icon" color="primary">add_circle_outline</mat-icon>
      <span class="text-primary">Adding Users</span>
    </div>
  </mat-card>
  <mat-card class="company-user" *ngFor="let user of newUsers??[]; let i = index">
    <div class="d-flex flex-row h-100">
      <div class="d-flex align-items-center">
        <mat-icon class="company-user-icon">account_circle</mat-icon>
      </div>
      <div class="flex-grow-1 d-flex flex-column mx-2">
        <div class="flex-grow-1"></div>
        <div>
          <span class="me-2">account:</span><span>{{user.username}}</span>
        </div>
        <div>
          <span class="me-2">Nick name:</span><span>{{user.nickname}}</span>
        </div>
        <div class="flex-grow-1"></div>
      </div>
      <div>
        <button mat-icon-button class="btn-sm" color="warn" matTooltip="Remove User" (click)="removeCompanyUser(i)"
          *ngIf="canEdit">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
  </mat-card>
</div>

<br/>
<br/>

<div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="warn" type="button" (click)="deleteClick()"
    *ngIf="!!id">delete</button>
  <div class="d-inline-block mx-2"></div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="primary" type="button"
    (click)="saveClick()">keep</button>
</div>

<br />
<br />
