<div>
  <div class="container-fluid">

    <div class="container">
      <button mat-button class="tl-button text-center" (click)="backtoHome($event)" style = "color: black; font-weight: 900;">  
        &lt;&nbsp;&nbsp;Back to Home
      </button>
    </div>

    <div class="container center-img">
      <div class="row content-center">
        <img src="{{baseUrl}}assets/images/account/register-success.png" alt="register-success" 
         style="width:252px; height:219px; object-fit: scale-down;">
      </div>
      <div class="row text-center">
        <p style="font-weight:600; font-size: 20px;">Congratulations on your successful registration~</p>
      </div>
      <div class="row">
        <div class="col-md-auto content-center">
          <button mat-button class="back-to-home text-center" (click)="backtoHome($event)" style = "color: white; font-weight: 400;">  
            Back to Home({{countdown}}s)
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
