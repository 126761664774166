import { Component, Input } from '@angular/core';
import { SessionService } from 'src/app/common/session.service';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.html',
  styleUrl: './page-header.scss',
})
export class PageHeader {
  @Input() title?: string;
  points?: number;

  constructor(private sessionService: SessionService) {
    sessionService.getUser().subscribe(user => {
      this.points = user?.points;
    });
  }

  get isCostTypeTimes() {
    return this.sessionService.isCostTypeTimes;
  }
}
