<mat-card class="h-100">

  <div class="ratio ratio-16x9 mb-3">
    <image-picker #rawImagePicker (changed)="rawImageChanged($event)" />
  </div>

  <div class="d-flex" [class.d-none]="!rawImageBlob">
    <div class="col">
      <button class="btn-md w-100" mat-stroked-button (click)="uploadRawImageClick()">
        <img src="{{ baseUrl }}assets/images/redo.png" />
        <span>Re-upload</span>
      </button>
    </div>
    <div class="mx-2"></div>
    <div class="col">
      <button class="btn-md w-100" mat-stroked-button color="primary"
        (click)="completedClick(ImageGenerateStepsEnum.Step0020)">
        <img src="{{ baseUrl }}assets/images/image-generate0020.png" />
        <span>Smart Cutout</span>
      </button>
    </div>
    <div class="mx-2"></div>
    <div class="col">
      <button class="btn-md w-100" mat-stroked-button color="primary"
        (click)="completedClick(ImageGenerateStepsEnum.Step0040)">
        <img src="{{ baseUrl }}assets/images/image-generate0040.png" />
        <span>Retouched pictures</span>
      </button>
    </div>
  </div>

  <br />

  <div class="mb-2" [class.d-none]="!userAssets || userAssets.length==0">
    <h5>Quick upload</h5>
    <user-asset-select src="/user/assets/category/1" [value]="selectedUserAssetId" (loaded)="userAssetsLoaded($event)"
      (selectionChange)="userAssetSelected($event)" />
  </div>

</mat-card>
