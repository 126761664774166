import { Size } from "../interfaces";

export const ImageAspects = [
  { key: '1:1', value: '1:1', selected: false, width: 1024, height: 1024, ratio: 1024 / 1024 },
  { key: '4:3', value: '4:3', selected: false, width: 1024, height: 768, ratio: 1024 / 768 },
  { key: '3:4', value: '3:4', selected: false, width: 768, height: 1024, ratio: 768 / 1024 },
  { key: '16:9', value: '16:9', selected: true, width: 1024, height: 576, ratio: 1024 / 576 },
  { key: '9:16', value: '9:16', selected: false, width: 576, height: 1024, ratio: 576 / 1024 },
];

export function handleImageScale(image: HTMLImageElement) {
  // Input images to SAM must be resized so the longest side is 1024
  const LONG_SIDE_LENGTH = 1024;
  let w = image.naturalWidth;
  let h = image.naturalHeight;
  const samScale = LONG_SIDE_LENGTH / Math.max(h, w);
  return { height: h, width: w, samScale };
};

export function caculateNearAspect(width: number, height: number): string | undefined {
  let ratios = [...ImageAspects].sort((o1: any, o2: any) => o1.ratio - o2.ratio);
  let ratio = width / height;
  for (let i = 0; i < ratios.length; i++) {
    if (i == 0) {
      if (ratio < (ratios[i].ratio + ratios[i + 1].ratio) / 2) {
        return ratios[i].key;
      } else {
        continue;
      }
    }
    if (i == ratios.length - 1) {
      return ratios[i].key;
    }
    if (ratio >= (ratios[i - 1].ratio + ratios[i].ratio) / 2
      && ratio < (ratios[i].ratio + ratios[i + 1].ratio) / 2) {
      return ratios[i].key;
    }
  }
  return undefined;
}

export function aspectToSize(aspect: string | undefined): Size | undefined {
  let size: Size | undefined = undefined;
  let item = ImageAspects.find(o => o.key == aspect);
  if (!!item) {
    size = { width: item.width, height: item.height };
  }
  return size;
}

export function createBackgroundImage(width?: number, height?: number): HTMLCanvasElement | undefined {
  if (!width || !height) {
    return undefined;
  }
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}
