import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-gender-input',
  templateUrl: './image-gender-input.html',
})
export class ImageGenderInput implements OnInit {
  genders: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'male', value: 'male', selected: false },
    { key: 'woman', value: 'woman', selected: false },
  ];
  _gender?: string;
  @Input() set gender(value: string | undefined) {
    this._gender = value;
    let item = this.genders.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.genders.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get gender() {
    return this._gender;
  }

  @Output() genderChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  genderClick(selectedGender: SelectListItem) {
    this.gender = selectedGender.key;
    this.genderChange.emit(this.gender);
  }

}
