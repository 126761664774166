<div class="d-flex flex-column h-100">
  <div class="h-25"></div>
  <div class="col-12 col-lg-6 mx-auto">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><mat-icon color="warn">error</mat-icon></div>
        <mat-card-title>{{title}}</mat-card-title>
        <mat-card-subtitle *ngFor="let item of messages">{{item}}</mat-card-subtitle>
      </mat-card-header>
      <br />
      <mat-card-content>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="back()">Back to Home</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
