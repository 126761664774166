<div class="d-flex flex-wrap w-100">
  <div class="col-3 image-area template-image mt-2 ps-1 pe-1 position-relative" *ngFor="let asset of groupAssets"
    (click)="imageClick(asset)">
    <div [class]="itemClass">
      <img [src]="asset.url" *ngIf="!!asset.url" [class.selected]="!!asset.selected" />
    </div>
    <element-floatable>
      <div class="top-right mx-2 my-1 d-flex">
        <mat-icon [color]="undefined" svgIcon="app:delete" matTooltip="delete"
          (click)="deleteImage(asset, $event)"></mat-icon>
      </div>
    </element-floatable>
  </div>
  <div class="col-3 image-area template-image mt-2 ps-1 pe-1">
    <div [class]="itemClass">
      <image-picker #scenceImagePicker [style]="imagePickerInline" [inlineHintText]="inlineHintText"
        (changed)="imageUploaded($event)" />
    </div>
  </div>
</div>
