<div class="d-flex">
  <div><span>Scenario Description</span></div>
  <div class="flex-grow-1"></div>
  <div><span>{{value?.length||0}} / 150</span></div>
</div>
<div class="mt-2">
  <textarea class="w-100" matInput rows="4" [(ngModel)]="value" (change)="valueChanged()"
    placeholder={{placeHolder}}></textarea>
  <!--"TIPS:Scene description tilt model area、age、Style, etc.，And describe the background description you want to switch&#10;&#10;For example：An Asian model，About25age，Sweet style，On the beach，The sea in the distance"-->
  <div class="d-flex" *ngIf="!negativeVisible">
    <div class="flex-grow-1"></div>
    <div class="negative-title" (click)="negativeVisible=!negativeVisible">
      <mat-icon class="icon-xs">arrow_left</mat-icon><span>Adding unwanted content</span>
    </div>
  </div>
</div>

<div class="d-flex" *ngIf="negativeVisible">
  <div class="negative-title" (click)="negativeVisible=!negativeVisible">
    <mat-icon class="icon-xs">arrow_right</mat-icon><span>Adding unwanted content</span>
  </div>
  <div class="flex-grow-1"></div>
  <div *ngIf="negativeVisible">{{value?.length}} / 150</div>
</div>
<div class="my-2" *ngIf="negativeVisible">
  <textarea class="w-100" matInput rows="3" [(ngModel)]="negative" (change)="negativeChanged()"
    placeholder="Please enter the content you do not want to appear"></textarea>
</div>
