<div class="d-flex py-3">
  <div class="my-info mx-auto my-3">

    <div class="col p-4 d-flex flex-column">
      <div class="d-flex">
        <div><mat-icon svgIcon="app:membership"></mat-icon></div>
        <span class="fs-large">Current Packages</span>
      </div>
      <div class="info-card my-4">
        <div class="d-flex my-2">
          <div class="text-accent">My Package</div>
          <div class="flex-grow-1"></div>
          <div>{{membership?.name}}</div>
        </div>
        <div class="d-flex my-2">
          <div class="text-accent">price</div>
          <div class="flex-grow-1"></div>
          <div>{{membership?.price}}</div>
        </div>
        <div class="d-flex my-2">
          <div class="text-accent">Package duration</div>
          <div class="flex-grow-1"></div>
          <div>{{membership?.period}}</div>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div>
        <div class="background-primary text-black p-3 rounded-2 text-center fs-larger cursor-pointer" matRipple
          (click)="purchaseMembershipClick()"><span>Upgrade Package</span>
        </div>
      </div>
    </div>

    <div class="col p-4 d-flex flex-column">
      <div class="d-flex">
        <div><mat-icon svgIcon="app:account_balance"></mat-icon></div>
        <span class="fs-large">Remaining Smart Beans</span>
      </div>
      <div class="info-card my-4">
        <div class="d-flex align-items-baseline my-2">
          <span class="fw-bold account-balance">{{points}}</span>
          <span class="mx-1">/</span>
          <span class="text-accent">{{totalPoints}}</span>
        </div>
        <div class="d-flex my-2">
          <span>about</span><span class="fw-bold px-1">{{(points??0)/10 | number : '1.0-0'}}</span><span>Zhang Tu</span>
        </div>
        <div class="d-flex my-2">
          <span class="text-accent">{{memberStartDate}} to {{memberEndDate}}</span>
        </div>
      </div>
      <div class="text-accent">Free gift for the first month for registered users1000Design Coin</div>
      <div class="flex-grow-1"></div>
      <div>
        <div class="background-primary text-black p-3 rounded-2 text-center fs-larger cursor-pointer" matRipple
          (click)="purchasePointsClick()">
          <span>Buy Smart Beans</span>
        </div>
      </div>
    </div>

    <div class="col p-4">
      <div class="d-flex">
        <div><mat-icon svgIcon="app:benefits"></mat-icon></div>
        <span class="fs-large">Personal rights</span>
      </div>
      <div class="feature" *ngFor="let feature of getFeatures(accountType)">
        <div><mat-icon svgIcon="{{feature[1]===true? 'app:check_circle':'app:remove_circle'}}"></mat-icon></div>
        <div>{{feature[0]}}</div>
      </div>
    </div>
  </div>
</div>

<div class="gradient-background d-flex py-3">
  <div class="d-flex flex-wrap mx-auto my-3">

    <div class="membership-item">
      <div class="d-flex">
        <div class="title free">Trial Version</div>
      </div>
      <div class="d-flex mb-2 invisible">
        <div class="flex-grow-1"></div>
        <div>
          <div class="background-gold text-black px-2 me-5 rounded-2">&nbsp;<span class="fw-bold">&nbsp;</span></div>
          <div class="arrow-container ms-3">
            <div class="arrow-icon"></div>
          </div>
        </div>
      </div>
      <div class="text-center mb-3"><span class="price text-orange">￥0.00</span><span class="text-accent">/moon</span>
      </div>
      <div class="text-center my-3 fs-smaller">Suitable for experiencing system effects</div>
      <div class="m-4">
        <div class="background-orange p-2 rounded-5 text-center fs-larger cursor-pointer" matRipple><span>Your current plan</span>
        </div>
      </div>
      <div class="mx-4 my-3 px-1">
        <div class="feature" *ngFor="let feature of getEnabledFeatures(OrderItemTypesEnum.Free)">
          <div><mat-icon svgIcon="app:check_circle"></mat-icon></div>
          <div>{{feature[0]}}</div>
        </div>
      </div>
    </div>

    <div class="membership-item">
      <div class="d-flex">
        <div class="title">Professional Edition</div>
      </div>
      <div class="d-flex mb-2">
        <div class="flex-grow-1"></div>
        <div class="cursor-pointer" (click)="personMembershipClick()">
          <div class="background-gold text-black px-2 me-5 rounded-2">
            <span>{{personMembership!=OrderItemTypesEnum.PersonMonth?'Monthly Fees':'Annual Fee'}}</span>
            <span class="fw-bold">{{personMembership!=OrderItemTypesEnum.PersonMonth?'￥188':'￥1888'}}</span>
          </div>
          <div class="arrow-container ms-3">
            <div class="arrow-icon"></div>
          </div>
        </div>
      </div>
      <div class="text-center mb-3">
        <span class="price text-orange">{{personMembership==OrderItemTypesEnum.PersonMonth?'￥188':'￥1888'}}</span>
        <span class="text-accent">{{personMembership==OrderItemTypesEnum.PersonMonth?'/moon':'/Year'}}</span>
      </div>
      <div class="text-center my-3 fs-smaller">Suitable for individual users</div>
      <div class="m-4">
        <div class="background-primary text-black p-2 rounded-5 text-center fs-larger cursor-pointer" matRipple
          (click)="purchaseMembershipClick(personMembership)"><span>Upgrade this plan now</span>
        </div>
      </div>
      <div class="mx-4 my-3 px-1">
        <div class="feature" *ngFor="let feature of getEnabledFeatures(OrderItemTypesEnum.PersonMonth)">
          <div><mat-icon svgIcon="app:check_circle"></mat-icon></div>
          <div>{{feature[0]}}</div>
        </div>
      </div>
    </div>

    <div class="membership-item">
      <div class="d-flex">
        <div class="title">Team Edition</div>
      </div>
      <div class="d-flex mb-2">
        <div class="flex-grow-1"></div>
        <div class="cursor-pointer" (click)="teamMembershipClick()">
          <div class="background-gold text-black px-2 me-5 rounded-2">
            <span>{{teamMembership!=OrderItemTypesEnum.TeamMonth?'Monthly Fees':'Annual Fee'}}</span>
            <span class="fw-bold">{{teamMembership!=OrderItemTypesEnum.TeamMonth?'￥688':'￥5888'}}</span>
          </div>
          <div class="arrow-container ms-3">
            <div class="arrow-icon"></div>
          </div>
        </div>
      </div>
      <div class="text-center mb-3">
        <span class="price text-orange">{{teamMembership==OrderItemTypesEnum.TeamMonth?'￥688':'￥5888'}}</span>
        <span class="text-accent">{{teamMembership==OrderItemTypesEnum.TeamMonth?'/moon':'/Year'}}</span>
      </div>
      <div class="text-center my-3 fs-smaller">Suitable for small team users（3-4people）</div>
      <div class="m-4">
        <div class="background-primary text-black p-2 rounded-5 text-center fs-larger cursor-pointer" matRipple
          (click)="purchaseMembershipClick(teamMembership)"><span>Upgrade this plan now</span>
        </div>
      </div>
      <div class="mx-4 my-3 px-1">
        <div class="feature" *ngFor="let feature of getEnabledFeatures(OrderItemTypesEnum.TeamMonth)">
          <div><mat-icon svgIcon="app:check_circle"></mat-icon></div>
          <div>{{feature[0]}}</div>
        </div>
      </div>
    </div>

    <div class="membership-item">
      <div class="d-flex">
        <div class="title">Customized version</div>
      </div>
      <div class="text-center"><span class="text-orange fs-large">Customization</span></div>
      <div class="text-center my-2 fs-smaller">Suitable for corporate customers with customized needs</div>
      <div class="mx-4">
        <div class="w-75 m-auto text-center">
          <wechat-barcode />
          <div class="text-accent my-2 fs-smaller">You can add dedicated and intelligent customized solutions</div>
        </div>
      </div>
      <div class="mx-4 my-3 px-1">
        <div class="feature" *ngFor="let feature of getEnabledFeatures(OrderItemTypesEnum.Customize)">
          <div><mat-icon svgIcon="app:check_circle"></mat-icon></div>
          <div>{{feature[0]}}</div>
        </div>
      </div>
    </div>

  </div>
</div>
