import { Component, OnInit, ViewChild, Injector, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MessageResource } from '../common/message.service';
import { SelectListItem, UserTypes } from '../models';
import { UserListItem } from '../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { PointsTransferDialog } from '../points-transfer-dialog/points-transfer-dialog';

@Component({
  selector: 'user-search',
  templateUrl: './user-search.component.html',
  styleUrl: './user-search.component.scss',
})
export class UserSearchComponent extends BaseComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['username', 'companyName', 'totalPoints', 'usedPoints', 'points', 'createdDate', 'lastLoginDate', 'action'];
  statusList?: SelectListItem[];
  inputForm: FormGroup;
  dataSource = new MatTableDataSource<UserListItem>([]);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      companyName: new FormControl(),
      username: new FormControl(),
      status: new FormControl(""),
      type: new FormControl(UserTypes.individual),
    });
  }

  ngOnInit() {
    this.http.get('/users/options').subscribe(response => {
      this.statusList = response.data.statusList;
    });

    var preSearchParams = this.sessionService.getItem("admin.users.search");
    if (!!preSearchParams) {
      this.inputForm.patchValue(preSearchParams);
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }
    this.search()
  }

  search() {
    var params = this.inputForm.value;
    this.http.post('/users/search', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dataSource.data = result.data;
        if (this.dataSource.data.length == 0) {
          this.messageService.showInformation(MessageResource.NO_DATA_MACHED);
        } else {
          this.sessionService.setItem("admin.users.search", params);
        }
      }
    });
  }

  editClick(row: UserListItem) {
    this.router.navigate([`/admin/users/${row.id}/edit`]);
  }

  addNewClick() {
    this.router.navigate(['/admin/users/add']);
  }

  getDisplayName(row : UserListItem) {
    var companyName = row.companyName;
    var nickname = row.nickname;
    if(!nickname) {
      return companyName;
    }
    if(!companyName) {
      return nickname;
    }
    return `${companyName} (${nickname})`
  }

  transferPointsClick(row: UserListItem) {
    this.dialog.open(PointsTransferDialog, {
      disableClose: true,
      data: {
        fromUserId: row.id,
      },
    }).afterClosed().subscribe((success) => {
      if (!success) {
        return;
      }
      this.search();
    });
  }

}
