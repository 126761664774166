<page-header title="Freelance Studio" />

<flexible-accordion class="flex-grow-1 h-0 d-flex flex-column flex-lg-row" [(expanded)]="expanded">

  <div class="flexible-accordion-header">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.txt2Image"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <user-favorite-list [type]="JobTypesEnum.paint" [subType]="JobSubTypesEnum.txt2img" *ngIf="!taskId" />

  <div class="flex-grow-1 h-lg-100 d-flex flex-column flex-lg-row" *ngIf="!!taskId">

    <flexible-accordion class="flex-grow-1 h-lg-100" [(expanded)]="expanded2">

      <div class="flexible-accordion-header">
        <mat-card class="input-container min-h-100">

          <div class="mb-2">
            <material-select src="/material/templates" [selectedTemplateId]="selectedTemplateId"
              (selectionChange)="onMaterialSelected($event)" />
          </div>

          <div class="mb-2">
            <image-prompt-input [(value)]="prompts" [(negative)]="negativePrompts" />
          </div>

          <div class="mb-2">
            <image-size-input [aspect]="aspect" (aspectChange)="imageAspectChanged($event)" />
          </div>

          <div class="mb-2">
            <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
          </div>

          <div class="d-flex py-2">
            <cost-estimator category="image" item="paint" [quantity]="quantity" />
            <div class="flex-grow-1"></div>
            <button mat-flat-button color="primary" (click)="generateClick()">AI Generate</button>
          </div>
        </mat-card>
      </div>

      <div class="output-container h-lg-100">
        <user-favorite-list [type]="JobTypesEnum.paint" [subType]="JobSubTypesEnum.txt2img" [taskId]="taskId" />
        <user-operation-list #userOperationList [taskId]="taskId" (clone)="cloneClick($event)" />
      </div>

    </flexible-accordion>

  </div>

</flexible-accordion>
