import { Injectable } from "@angular/core";
import { AppHttpClient } from "../common/app.http";
import { UserJobListItem } from "../interfaces";
import { Subject, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private jobSubject = new Subject<UserJobListItem>();

  constructor(private http: AppHttpClient) {

  }

  changeFavorite(job: UserJobListItem) {
    const params = {
      id: job.id,
      rate: job.favoriteRate,
    };
    return this.http.post('/user/favorites/change', params, { silence: true }).pipe(map((result) => {
      if (this.http.isValidResult(result)) {
        job.favoriteRate = result.data.favoriteRate;
        job.favoriteDate = result.data.favoriteDate;
        this.jobSubject.next(job);
      }
      return result;
    }));
  }

  changeFeedback(job: UserJobListItem) {
    const params = {
      id: job.id,
      rate: job.feedbackRate,
    };
    return this.http.post('/user/feedback/change', params, { silence: true }).pipe(map((result) => {
      if (this.http.isValidResult(result)) {
        job.feedbackRate = result.data.feedbackRate;
        this.jobSubject.next(job);
      }
      return result;
    }));
  }

  subscribe(next: (value: UserJobListItem) => void) {
    return this.jobSubject.subscribe(next);
  }

  notify(job: UserJobListItem) {
    this.jobSubject.next(job);
  }
}

