<mat-sidenav-container class="h-100">
  <mat-sidenav class="side-bar" #sidenav mode="side" opened="true">
    <mat-nav-list>
      <a class="menu-item" *ngFor="let folder of testList; let i = index" (click)="navClick(folder, i)"
        [class.active]="i === curindex">
        <span>{{i}}</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [class.d-none]="resultList.length != 0">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. " #input>
      </mat-form-field>

      <span>better：{{betterRate | percent}}</span>
      <span class="ms-2">Consistency：{{equalRate | percent}}</span>
      <span class="ms-2">Worse：{{worseRate | percent}}</span>

      <table mat-table [dataSource]="dataSource!" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef> uuid </th>
          <td mat-cell *matCellDef="let element"> {{element.uuid}} </td>
        </ng-container>

        <ng-container matColumnDef="betterCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> better </th>
          <td mat-cell *matCellDef="let element"> {{element.betterCount | percent}} </td>
        </ng-container>

        <ng-container matColumnDef="equalCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Consistency </th>
          <td mat-cell *matCellDef="let element"> {{element.equalCount | percent}} </td>
        </ng-container>

        <ng-container matColumnDef="worseCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Worse </th>
          <td mat-cell *matCellDef="let element"> {{element.worseCount | percent}} </td>
        </ng-container>

        <ng-container matColumnDef="feedbackDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> date </th>
          <td mat-cell *matCellDef="let element"> {{element.feedbackDate}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>

    <div [class.d-none]="resultList.length === 0">
      <div class="row">
        <div class="col-2" *ngFor="let item of inputList; let i = index">
          <div class="d-flex flex-column">
            <span>{{getSuffix(item.path!)}}</span>
            <div class="ratio ratio-1x1">
              <img [src]="item.url" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3" *ngFor="let item of resultList">
          <div class="d-flex flex-column">
            <span>{{resultNameMap.get(item.path!)}}</span>
            <img class="result-image" [src]="item.url" />
          </div>
        </div>
      </div>

      <div class="row">
        <form [formGroup]="evaForm">
          <mat-radio-group formControlName="eva">
            <mat-radio-button value="1">better</mat-radio-button>
            <mat-radio-button value="2">Consistency</mat-radio-button>
            <mat-radio-button value="3">Worse</mat-radio-button>
          </mat-radio-group>

        </form>

        <button mat-button (click)="nextClick()"><span>Next group</span></button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
