import { Component, Injector, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { AppFormGroup } from '../common/app.forms';
import { interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginResult, UserIdentity } from '../interfaces';

@Component({
  selector: 'account-login',
  templateUrl: './account-login.component.html',
  styleUrl: './account-login.component.scss',
})
export class AccountLoginComponent extends BaseComponent implements OnDestroy {
  environmentEnum = environment;
  AuthStepEnum = AuthStep;
  authStep = AuthStep.UserSigin;
  hidePassword = true;
  rememberPassword = false;
  checkboxColor = 'primary';
  checkboxError = false;
  passwordform: AppFormGroup;
  phoneform: AppFormGroup;
  loginMethod: string = environment.authByPhoneNumber ? 'phone' : 'password';
  optCountdown: number = 0;
  countdownSub: Subscription;

  constructor(injector: Injector, private http: AppHttpClient) {
    super(injector);

    this.passwordform = new AppFormGroup({
      username: new FormControl('', [Validators.required,]),// Validators.pattern("^[0-9]{11}$")]),
      password: new FormControl('', Validators.required),
      // rememberMe: new FormControl(false),
    });

    this.phoneform = new AppFormGroup({
      phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]{11}$")]),
      otp: new FormControl('', [Validators.required]),
      checkbox: new FormControl(false),
    });

    this.countdownSub = new Subscription();
  }

  ngOnDestroy() {
    if (this.countdownSub) {
      this.countdownSub.unsubscribe();
    }
  }

  loginClick() {
    if (this.loginMethod == "password") {
      return this.loginViaPassword();
    }

    if (this.loginMethod == "phone") {
      return this.loginViaPhone();
    }

  }

  loginViaPassword() {
    if (!this.passwordform.validWithTouched()) {
      return;
    }

    const params = {
      username: this.passwordform.get('username')?.value,
      password: this.passwordform.get('password')?.value,
    }

    this.http.post('/account/login', params, { silence: false }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }
      this.signin(response.data);
    });
  }

  sendOtp(event: MouseEvent) {
    event.stopPropagation();
    this.phoneform.controls['otp'].clearValidators();
    this.phoneform.controls['otp'].updateValueAndValidity();
    this.checkboxError = false;

    if (!this.phoneform.validWithTouched()) {
      return;
    }

    const params = {
      username: this.phoneform.get('phone')?.value,
      purpose: 'login',
    };

    this.http.post('/account/otp/send', params, { silence: true }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }

    });


    this.optCountdown = 60;

    this.countdownSub = interval(1000).subscribe(() => {
      if (this.optCountdown > 0) {
        this.optCountdown--;
      } else {
        this.countdownSub.unsubscribe();
      }
    });

  }

  checkboxChange(selected: any) {
    // selected.stopPropagation();
    if (this.phoneform.get('checkbox')?.value) {
      this.checkboxError = false;
    }
  }

  loginViaPhone() {
    this.phoneform.controls['otp'].addValidators([Validators.pattern("^[0-9]{6}$"), Validators.required]);
    this.phoneform.controls['otp'].updateValueAndValidity();

    if (!this.phoneform.validWithTouched()) {
      return;
    }

    const params = {
      username: this.phoneform.get('phone')?.value,
      password: this.phoneform.get('otp')?.value,
    }

    this.http.post('/account/login/phone', params, { silence: false }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }
      this.signin(response.data);
    });
  }

  private loginResult?: LoginResult;
  signin(loginResult: LoginResult) {
    this.loginResult = loginResult;
    if (loginResult.chargeConfirm) {
      this.sessionService.login(loginResult);
      this.sessionService.navigateToReturnUrl();
      return;
    }
    var identities = loginResult.identities;
    if (identities.length > 1) {
      this.authStep = AuthStep.IdentityConfirm;
      return;
    }
    this.sessionService.login(this.loginResult!);
    this.sessionService.navigateToReturnUrl();
    let params = {
      chargeTo: identities[0].id,
    };
    this.http.post(`/users/${this.loginResult?.id}/charge/switch`, params).subscribe();
  }

  get identities() {
    return this.loginResult?.identities ?? [];
  }

  public changeIdentity(identity: UserIdentity) {
    this.sessionService.login(this.loginResult!);
    var chargeTo = identity.id;
    let params = {
      chargeTo: chargeTo,
    };
    this.http.post(`/users/${this.loginResult?.id}/charge/switch`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.sessionService.navigateToReturnUrl();
        let user = this.sessionService.user;
        if (!!user) {
          user?.identities.forEach(element => {
            element.active = element.id == chargeTo;
          });
        }
        this.sessionService.user = user;
      },
      error: (err: any) => {
        this.sessionService.logout();
        throw err;
      },
    });
  }

  public backToSignin() {
    this.authStep = AuthStep.UserSigin;
  }

}

enum AuthStep {
  UserSigin = 'UserSigin',
  IdentityConfirm = 'IdentityConfirm',
}