import { ChangeDetectorRef, Component, Injector, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseComponent } from '../../common/base.component';
import { AppHttpClient } from '../../common/app.http';
import { UserFunction } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'master-layout',
  templateUrl: './master-layout.component.html',
  styleUrl: './master-layout.component.scss',
})
export class MasterLayoutComponent extends BaseComponent {
  nickname: any;
  points?: number;

  @ViewChild('sidenav') sidenav!: MatSidenav;
  public mobileQuery: MediaQueryList;
  sidenavVisible: boolean;
  private mobileQueryListener: () => void;

  private _isAuthenticated: boolean = false;
  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  constructor(
    injector: Injector,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private http: AppHttpClient) {
    super(injector);
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    this.sidenavVisible = !this.mobileQuery.matches;
    this.sessionService.getUser().subscribe(user => {
      this.points = user?.points;
    });
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);

    this.sidenav.close();
  }

  public signInEmit(): void {

  }

  public logoutClick() {
    if (!this.sessionService.isAuthenticated) {
      this.router.navigate(['/account/login']);
      return;
    }

    const params = {};
    this.http.post('/account/logout', params, { silence: false }).subscribe((result: any) => {
      this.sessionService.logout();
      this.router.navigate(['/account/login']);
    });
  }

  public menuClick() {
    if (this.mobileQuery.matches) {
      this.sidenav.toggle();
    }
  }

  public sidenavOpenedChange() {
    this.sidenavVisible = this.sidenav.opened;
  }

  get isAdmin() {
    return this.sessionService.isAdmin;
  }

  UserFunctionEnum = UserFunction;
  hasPermission(functionId: UserFunction) {
    return this.sessionService.hasPermission(functionId);
  }

  get isCostTypeTimes() {
    return this.sessionService.isCostTypeTimes;
  }

  getMenuExpaned(key: string, defaultOpened?: boolean) {
    let value = this.sessionService.getItem(`menu.${key}.expanded`);
    return ((value == undefined || value == null) && defaultOpened) || value === true;
  }

  afterMenuCollapse(key: string) {
    this.sessionService.setItem(`menu.${key}.expanded`, false);
  }

  afterMenuExpand(key: string) {
    this.sessionService.setItem(`menu.${key}.expanded`, true);
  }

  get isDevelopment() {
    return !environment.production;
  }

}
