<div class="image-zone col-12 col-lg-6 p-2" #rawImageBox>

  <div class="image-container">
    <img #rawImage [src]="rawImageUrl" (load)="rawImageLoaded($event)" />
    <svg class="mask-container" [attr.viewBox]="'0 0 '+modelScale!.width+' '+modelScale!.height"
      xmlns="http://www.w3.org/2000/svg" *ngIf="!!maskSvgPaths && maskSvgPaths.length>0">
      <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
        <feDropShadow dx="0" dy="0" stdDeviation="2" flood-color="#1d85bb" />
        <feDropShadow dx="0" dy="0" stdDeviation="4" flood-color="#1d85bb" />
        <feDropShadow dx="0" dy="0" stdDeviation="6" flood-color="#1d85bb" />
      </filter>
      <path class="mask-path" [attr.d]="maskSvgPaths.join(' ')" stroke-linecap="round" stroke-linejoin="round"
        stroke-opacity=".8" fill-opacity="0.05" stroke="#1d85bb" stroke-width="3" filter="url(#glow)" />
    </svg>
    <div class="click-point-container" *ngIf="segmentClickType==SegmentClickTypesEnum.MultipleClick">
      <div [class.click-point-positive]="click.clickType==1" [class.click-point-negative]="click.clickType==0"
        [style.left]="(click.x/modelScale.width*100)+'%'" [style.top]="(click.y/modelScale.height*100)+'%'"
        *ngFor="let click of clicks;"></div>
    </div>
    <div class="box-select" [style.left]="boxSelect.x+'px'" [style.top]="boxSelect.y+'px'"
      [style.width]="boxSelect.width+'px'" [style.height]="boxSelect.height+'px'" *ngIf="!!boxSelect"></div>
    <div class="cover-image" (mouseenter)="onMouseEnter($event)" (mousedown)="onMouseDown($event)"
      (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)" (mouseout)="handleMouseOut($event)"
      (mouseleave)="handleMouseOut($event)" (contextmenu)="$event.preventDefault()">
    </div>
  </div>
  <ng-content select=".image-segment-input-bar"></ng-content>

</div>

<div class="image-zone col-12 col-lg-6 p-2" #stickerBox>

  <div class="image-container background-alpha-dark">

    <div class="segmenting-message flex-column justify-content-center align-items-center w-100 h-100"
      *ngIf="_segmenting && processDisplayMode === 'inline' ">
      <mat-spinner></mat-spinner>
      <!--<div class="my-3">{{segmentingMessage}}</div>-->
    </div>

    <img #stickerBackgroundImage [src]="stickerBackgroundImageUrl">

    <div [class.d-none]="_segmenting">

      <image-sticker-input [modelScale]="modelScale" #stickers />
      <div class="empty-sticker-message" *ngIf="!stickers || stickers.value.length === 0 ">
        <div class="m-auto">
          <div>Analysis Complete，</div>
          <div>Please click on the left area</div>
          <div>Make a selection</div>
        </div>
      </div>

    </div>

  </div>
  <ng-content select=".image-segment-result-bar"></ng-content>

</div>
