import { Injectable } from "@angular/core";
import { IServiceCatalogListItem } from "../interfaces";

@Injectable({
  providedIn: 'root'
})
export class PricingCaculatorService {
  serviceCatalog?: { [key: string]: { [key: string]: IServiceCatalogListItem } };

  public caculate(category: string, item: string, quantity: number): number {
    return this.getPrice(category, item) * quantity;
  }

  private getPrice(category: string, item: string): number {
    if(!this.serviceCatalog){
      return 0;
    }
    let items = this.serviceCatalog[category];
    if(!items){
      return 0;
    }
    return items[item]?.price ?? 0;
  }
}