import { Component, Inject, OnInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'points-transfer-dialog',
  templateUrl: './points-transfer-dialog.html',
  styleUrl: './points-transfer-dialog.scss',
})
export class PointsTransferDialog extends BaseComponent implements OnInit {
  inputForm: FormGroup;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    public dialogRef: MatDialogRef<PointsTransferDialog>,
    @Inject(MAT_DIALOG_DATA) private data: { fromUserId?: number },
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      fromUserId: new FormControl(data.fromUserId, [Validators.required]),
      totalPoints: new FormControl({ value: '', disabled: true }),
      points: new FormControl(''),
      toUserId: new FormControl('', [Validators.required]),
      toUsername: new FormControl('', [Validators.required], [this.usernameValidator()]),
      remainingPoints: new FormControl({ value: '', disabled: true }),
      modifiedDate: new FormControl(),
    });

  }

  ngOnInit() {
    this.http.get(`/users/${this.data.fromUserId}/details`).subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      this.inputForm.patchValue({
        totalPoints: result.data.points,
        modifiedDate: result.data.modifiedDate,
      });
    });
    this.inputForm.get('points')?.valueChanges.subscribe((points) => {
      this.calculateRemainingPoints(points);
    });
  }

  transferClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let params = this.inputForm.value;
    this.http.post(`/users/${this.data.fromUserId}/points/transfer`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dialogRef.close(true);
      }
    });
  }

  userValidated: boolean | undefined = undefined;
  validateUser() {
    let username = this.inputForm.controls['toUsername'];
    if (!username) {
      return;
    }
  }

  private calculateRemainingPoints(points: string | undefined): void {
    if (this.inputForm.controls['points'].invalid) {
      this.inputForm.patchValue({
        remainingPoints: undefined,
      });
      return;
    }
    let totalPoints = this.inputForm.controls['totalPoints'].value
    var numTotalPoints = Number(totalPoints);
    var numPoints = Number(points);
    let remainingPoints = undefined;
    if (!isNaN(numTotalPoints) && !isNaN(Number(numPoints))) {
      remainingPoints = numTotalPoints - numPoints;
    }
    this.inputForm.patchValue({
      remainingPoints: remainingPoints,
    });
  }

  private checkIfUsernameExists(username: string): Observable<boolean> {
    let params = {
      username: username,
    }
    return this.http.post('/users/validate', params).pipe(map(result => {
      if (!this.http.isValidResult(result)) {
        this.inputForm.patchValue({
          toUserId: undefined,
        });
        return false;
      }
      let toUserId = result.data?.id;
      this.inputForm.patchValue({
        toUserId: toUserId,
      });
      return !toUserId;
    }));
  }

  private usernameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkIfUsernameExists(control.value).pipe(
        map(res => {
          return res ? { exists: true } : null;
        })
      );
    };
  }

}
