import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-clothing-gender-input',
  templateUrl: './image-clothing-gender-input.html',
})
export class ImageClothingGenderInput implements OnInit {
  clothingGenders: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'Men&#39;s', value: 'Men&#39;s', selected: false },
    { key: 'Women', value: 'Women', selected: false },
    { key: 'Children&#39;s Wear', value: 'Children&#39;s Wear', selected: false },
  ];
  _clothingGender?: string;
  @Input() set clothingGender(value: string | undefined) {
    this._clothingGender = value;
    let item = this.clothingGenders.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.clothingGenders.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get clothingGender() {
    return this._clothingGender;
  }

  @Output() clothingGenderChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  genderClick(selectedGender: SelectListItem) {
    this.clothingGender = selectedGender.key;
    this.clothingGenderChange.emit(this.clothingGender);
  }

}
