import { Component, Input } from '@angular/core';
import { PricingCaculatorService } from '../services/pricing.service';
import { SessionService } from '../common/session.service';

@Component({
  selector: 'cost-estimator',
  templateUrl: './cost-estimator.html',
  styleUrl: './cost-estimator.scss',
})
export class CostEstimator {
  @Input({ required: true }) category!: string;
  @Input({ required: true }) item!: string;
  _quantity?: number;
  cost?: number;
  @Input() set quantity(value: number | string | undefined) {
    if (typeof value === 'string') {
      value = Number(value);
    }
    this._quantity = value;
    this.caculate();
  }

  constructor(private pricingCaculator: PricingCaculatorService,
    private sessionService: SessionService,) {

  }

  caculate() {
    this.cost = 0;
    let quantity = this._quantity;
    if (!quantity) {
      return;
    }
    let cost = this.pricingCaculator.caculate(this.category, this.item, quantity);
    this.cost = cost;
  }

  get isCostTypeTimes() {
    return this.sessionService.isCostTypeTimes;
  }

}
