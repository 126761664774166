<div class="h-100 position-relative">
  <div class="mx-3 mt-3 d-flex flex-row">
    <div class="help-info" (click)="openHelpInfoDialog()">

      <svg class="align-self-center" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1558_553)">
        <path d="M1.66406 2.33301C1.66406 1.78072 2.11178 1.33301 2.66406 1.33301H10.6641C11.2164 1.33301 11.6641 1.78072 11.6641 2.33301V14.6663H2.66406C2.11178 14.6663 1.66406 14.2186 1.66406 13.6663V2.33301Z" stroke="#5F4DF3" stroke-width="1.5" stroke-linejoin="round"/>
        <path d="M11.6641 7.99967C11.6641 7.63147 11.9625 7.33301 12.3307 7.33301H13.6641C14.0323 7.33301 14.3307 7.63147 14.3307 7.99967V13.6663C14.3307 14.2186 13.883 14.6663 13.3307 14.6663H11.6641V7.99967Z" stroke="#5F4DF3" stroke-width="1.5" stroke-linejoin="round"/>
        <path d="M3.66406 4H6.33073" stroke="#5F4DF3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.66406 6.33301H7.66406" stroke="#5F4DF3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_1558_553">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
      </svg>
        
      <span class="align-self-center help-info-text">Instructions</span>

    </div>

    <p class="mx-3 align-self-center hint-text">Select objects by clicking on them，Or select the object by dragging the rectangle。</p>

  </div>

  <div [class.d-none] = "hideClickTypeRadio">
    <mat-radio-group [(ngModel)]="segmentClickType" (change)="segmentClickTypeChange()">
      <mat-radio-button [value]="SegmentClickTypesEnum.SignleClick">Click to cut out</mat-radio-button>
      <mat-radio-button [value]="SegmentClickTypesEnum.MultipleClick">Free Cutout</mat-radio-button>
    </mat-radio-group>
  </div>

  <image-segment #imageSegment [rawImageUrl]="rawImageUrl" 
  [segmentClickType]="hideClickTypeRadio?SegmentClickTypesEnum.MultipleClick: segmentClickType" 
  (predictCompleted)="segmentComplete($event)">
    
    <div class="image-segment-input-bar d-flex p-2 w-100">
      <button mat-stroked-button class="mx-2" (click)="cancelClick()">Cancel</button>
      <button mat-stroked-button class="mx-2" (click)="resetClick()">Reset</button>
      <div class="flex-grow-1"></div>
      <button mat-stroked-button class="mx-2" (click)="backClick()" [disabled]="!isMultipleClick|| !canBack">Back</button>
      <button mat-flat-button class="mx-2" color="primary" (click)="pickupClick()"
        [disabled]="!isMultipleClick && !hideClickTypeRadio">extract</button>
    </div>

    <div class="image-segment-result-bar d-flex p-2 w-100">
      <div class="col">
        <button mat-stroked-button class="mx-2" (click)="previousSegmentClick()" [disabled]="imageSegment?.isEmpty">Previous</button>
      </div>
      <div>
        <button mat-flat-button class="mx-2" color="primary" (click)="confirmClick()" [disabled]="imageSegment?.isEmpty">Sure</button>
      </div>
      <div class="col"></div>
    </div>

  </image-segment>
</div>
