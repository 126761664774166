import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerComponent } from './progress-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerDialog {
  dialogRef: MatDialogRef<ProgressSpinnerComponent> | null = null;

  constructor(private dialog: MatDialog) {

  }

  private _timerId: any;
  private _showing: boolean = false;
  get showing(): boolean {
    return this._showing;
  }

  public open(data?: any): boolean {
    if (this._showing) {
      return false;
    } else {
      this._showing = true;
      this._timerId = setTimeout(() => {
        this._showing = false;
        this._timerId = undefined;
      }, 3000);
    }

    this.dialogRef = this.dialog.open(ProgressSpinnerComponent, {
      //width: '250px',
      data: data,
      disableClose: true,
      panelClass: 'progress-spinner',
      backdropClass: 'opacity-0',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      this._showing = false;
      if (!this._timerId) {
        clearTimeout(this._timerId);
        this._timerId = undefined;
      }
    });

    return true;
  }

  public close() {
    this.dialogRef?.close();

    this._showing = false;
    if (!this._timerId) {
      clearTimeout(this._timerId);
      this._timerId = undefined;
    }
  }
}
