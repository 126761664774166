<div class="d-flex flex-wrap">
  <div class="top-right">
    <button mat-icon-button class="d-flex" (click)="cancelClick()"><mat-icon>close</mat-icon></button>
  </div>

  <div class="col-12 col-lg-8 g-0">
    <div class="image-container" mat-dialog-content>
      <img [src]="url" *ngIf="!!url" />
    </div>
  </div>

  <div class="d-flex flex-column col-12 col-lg-4 g-0">

    <div *ngIf="!!template">
      <div class="fs-4">{{template.name}}</div>
      <div>{{template.description}}</div>
    </div>

    <div class="flex-grow-1"></div>

    <div class="d-flex align-items-center p-2">
      <button mat-icon-button color="primary" (click)="favoriteClick()">
        <mat-icon>{{!template.favorite? 'favorite_border':'favorite'}}</mat-icon>
      </button>
      <div class="flex-grow-1"></div>
      <!-- <button mat-flat-button color="primary" (click)="selectTemplateClick()">
        <img class="icon-sm me-2" src="{{ baseUrl }}assets/images/menu030.png" />
        <span>Start creating</span>
      </button> -->
    </div>

  </div>
  
</div>
