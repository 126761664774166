import { Component, Inject, OnInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { CompanyInfo, OrderItem } from '../interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { AppValidators } from '../common/app.forms';

@Component({
  selector: 'order-withdraw-dialog',
  templateUrl: './order-withdraw-dialog.html',
  styleUrl: './order-withdraw-dialog.scss',
})
export class OrderWithdrawDialog extends BaseComponent implements OnInit {
  orderItemList?: Array<OrderItem>;
  userList?: Array<CompanyInfo>;
  inputForm: FormGroup;
  private url: string;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    public dialogRef: MatDialogRef<OrderWithdrawDialog>,
    @Inject(MAT_DIALOG_DATA) private order: { id?: number },
  ) {
    super(injector);
    this.url = `/orders/${this.order.id}/withdraw`;

    this.inputForm = this.formBuilder.group({
      id: new FormControl(),
      orderItemId: new FormControl('', [Validators.required]),
      userId: new FormControl('', [Validators.required]),
      points: new FormControl(''),
      remainingPoints: new FormControl({ value: '', disabled: true }),
    });

  }

  ngOnInit() {
    this.http.get(this.url).subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      let orderItemList: Array<OrderItem> = result.data.orderItemList ?? [];
      this.orderItemList = orderItemList;
      if (orderItemList.length == 1) {
        this.inputForm.patchValue({
          orderItemId: orderItemList[0].id,
        });
      }
      let userList = result.data.userList ?? [];
      this.userList = userList;
      if (userList.length == 1) {
        this.inputForm.patchValue({
          userId: userList[0].id,
        });
      }
    });
    this.inputForm.get('orderItemId')?.valueChanges.subscribe((orderItemId) => {
      this.calculateRemainingPoints(orderItemId, undefined);
      let totalPoints = this.getTotalPoints(orderItemId);
      let numTotalPoints = Number(totalPoints);
      if (isNaN(numTotalPoints)) {
        return;
      }
      this.inputForm.get('points')?.setValidators([Validators.required, AppValidators.range(0, numTotalPoints)]);
    });
    this.inputForm.get('points')?.valueChanges.subscribe((points) => {
      this.calculateRemainingPoints(undefined, points);
    });
  }

  withdrawClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let params = this.inputForm.value;
    params = {
      ...params,
      modifiedDate: this.orderItemList?.find(element => element.id + '' == params.orderItemId)?.modifiedDate,
    }
    this.http.post(this.url, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dialogRef.close(true);
      }
    });
  }

  private calculateRemainingPoints(orderItemId: string | undefined, points: string | undefined): void {
    if (this.inputForm.controls['orderItemId'].invalid || this.inputForm.controls['points'].invalid) {
      this.inputForm.patchValue({
        remainingPoints: undefined,
      });
      return;
    }
    orderItemId = orderItemId ?? this.inputForm.controls['orderItemId'].value;
    let totalPoints = this.getTotalPoints(orderItemId);
    var numTotalPoints = Number(totalPoints);
    points = points ?? this.inputForm.controls['points'].value;
    var numPoints = Number(points);
    let remainingPoints = undefined;
    if (!isNaN(numTotalPoints) && !isNaN(Number(numPoints))) {
      remainingPoints = numTotalPoints - numPoints;
    }
    this.inputForm.patchValue({
      remainingPoints: remainingPoints,
    });
  }

  private getTotalPoints(orderItemId: string | undefined): number | undefined {
    return this.orderItemList?.find(element => element.id + '' == orderItemId)?.points;
  }

}
