import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MaterialCategoryListItem, MaterialTemplateListItem } from '../interfaces';

@Component({
  selector: 'material-select',
  templateUrl: './material-select.html',
  styleUrl: './material-select.scss',
})
export class MaterialSelect {
  @Input({ required: true }) src!: string;
  categories: MaterialCategoryListItem[] = [];
  selectedTabIndex = 0;

  _selectedTemplateId?: number;
  @Input() set selectedTemplateId(value: number | undefined) {
    this._selectedTemplateId = value;
    this.changeSelectedTemplate(value);
  }
  get selectedTemplateId() {
    return this._selectedTemplateId;
  }
  @Output() selectionChange = new EventEmitter<MaterialTemplateListItem>();

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {
    this.http.get(this.src).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.categories = result.data;
        this.changeSelectedTemplate(this.selectedTemplateId);
      }
    });
  }

  onTemplateClick(template: MaterialTemplateListItem) {
    if (this.selectedTemplateId == template.id) {
      return;
    }

    this.changeSelectedTemplate(template.id);
    this.selectedTemplateId = template.id;
    this.selectionChange.emit(template);
  }

  getTemplateThumbnailUrl(template: MaterialTemplateListItem) {
    return this.http.getAbsoluteUrl(`/public/material/templates/${template.id}/thumbnail`);
  }

  private changeSelectedTemplate(selectedTemplateId: number | undefined) {
    if(!selectedTemplateId){
      this.selectedTabIndex = 0;
    }
    this.categories.forEach((category, index) => {
      category.templates.forEach(item => {
        item.selected = item.id == selectedTemplateId ? true : false;
        if (item.selected) {
          this.selectedTabIndex = index;
        }
      });
    });
  }

}

