import { Component, Injector, OnDestroy } from '@angular/core';
import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { AppHttpClient } from '../common/app.http';
import { AppFormGroup } from '../common/app.forms';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss',
})
export class PasswordResetComponent extends BaseComponent implements OnDestroy {
  hidePassword = true;
  form: AppFormGroup;
  optCountdown: number = 0;
  countdownSub: Subscription;

  constructor(injector: Injector, private http: AppHttpClient) {
    super(injector);

    this.form = new AppFormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern("^[0-9]{11}$")]),
      password: new FormControl('', Validators.required),
      repeatpassword: new FormControl('', Validators.required),
      otp: new FormControl(''),
      // rememberMe: new FormControl(false),
    }, {
      validators: this.passwordMatchValidator
    });

    this.countdownSub = new Subscription();

  }

  ngOnDestroy() {
    if (this.countdownSub) {
      this.countdownSub.unsubscribe();
    }
  }

  passwordMatchValidator(control: AbstractControl) {
    const password = control.get('password')?.value;
    const repeatpassword = control.get('repeatpassword')?.value;

    return password === repeatpassword ? null : { passwordMismatch: true };
  }

  sendOtp(event: MouseEvent) {
    this.form.controls['otp'].clearValidators();
    this.form.controls['otp'].updateValueAndValidity();

    if (!this.form.validWithTouched()) {
      return;
    }

    const params = {
      username: this.form.get('username')?.value,
      purpose: 'resetpassword',
    };

    this.http.post('/account/otp/send', params, { silence: false }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }

    });

    this.optCountdown = 60;

    this.countdownSub = interval(1000).subscribe(() => {
      if (this.optCountdown > 0) {
        this.optCountdown--;
      } else {
        this.countdownSub.unsubscribe();
      }
    });

  }

  resetpasswordClick() {
    this.form.controls['otp'].addValidators([Validators.pattern("^[0-9]{6}$"), Validators.required]);
    this.form.controls['otp'].updateValueAndValidity();

    if (!this.form.validWithTouched()) {
      return;
    }

    const params = this.form.value;

    /*const params = {
      username: this.form.get('username')?.value,
      password: this.form.get('password')?.value,
      otp: this.form.get('otp')?.value,
    }*/

    this.http.post('/account/password/reset', params, { silence: false }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }

      this.messageService.showInformation('Password reset successful');

      this.sessionService.login(response.data);
      this.sessionService.navigateToReturnUrl();

    });
  }

}
