<page-header title="Asset Library" />

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab [label]="category.name" *ngFor="let category of categories;">
    <div class="template-list">
      <div *ngFor="let template of category.templates;">
        <div class="m-1">
          <div class="template-item">
            <img [src]="getTemplateThumbnailUrl(template)" />
            <element-floatable>
              <button mat-icon-button class="top-left btn-sm bg-white m-1" color="primary"
                (click)="favoriteClick($event, template)">
                <mat-icon>{{!template.favorite? 'favorite_border':'favorite'}}</mat-icon>
              </button>
              <!-- <button mat-stroked-button class="bg-light top-right btn-md bg-white m-1" color="primary"
                (click)="selectTemplateClick($event, template)">
                <img src="{{ baseUrl }}assets/images/menu030.png" />
                <span>Start creating</span>
              </button> -->
              <button mat-icon-button class="bottom-right btn-sm bg-white m-1" color="primary" matTooltip="enlarge"
                (click)="showTemplateDetails(template)">
                <mat-icon>zoom_out_map</mat-icon>
              </button>
            </element-floatable>
          </div>
          <div>
            <span>{{template.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
