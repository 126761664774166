<div>Image size</div>
<div>
  <button class="m-2" [class.selected]="!!item.selected" mat-stroked-button (click)="aspectClick(item)"
    *ngFor="let item of aspects;">{{item.value}}</button>
</div>
<!-- <div class="aspect-container">
  <div class="aspect-item" [class.selected]="!!item.selected" (click)="aspectClick(item)" *ngFor="let item of aspects;">
    <div class="text-center">
      {{item.value}}
    </div>
    <div class="aspect-item-inner" >
      <div class="aspect-item-image" [class.w-100]="item.width>=item.height" [class.h-100]="item.width<item.height" [style.aspect-ratio]="item.key.replace(':','/')">
        <div class="m-auto">
          <svg width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 5.55C0 2.4975 2.50714 0 5.57143 0C8.63571 0 11.1429 2.4975 11.1429 5.55C11.1429 8.6025 8.63571 11.1 5.57143 11.1C2.50714 11.1 0 8.6025 0 5.55ZM22.2857 22.2H7.28929L14.7643 9.25L19.0357 16.6038L26.4643 3.7925L37.1429 22.2H22.2857Z"
              fill="white" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div> -->
