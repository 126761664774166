import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { UserAssetCategoryListItem, UserAssetGroupListItem } from '../interfaces';
import { UserAssetCategoryName } from '../models';

@Component({
  selector: 'user-asset-group-select',
  templateUrl: './user-asset-group-select.html',
  styleUrl: './user-asset-group-select.scss',
})
export class UserAssetGroupSelect {
  categories: Array<UserAssetCategoryListItem> = [];

  @Input() value?: string | UserAssetGroupListItem;
  @Output() selectionChange = new EventEmitter<UserAssetGroupListItem>();

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {
    this.http.get('/user/asset-group').subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let selectedCategoryId: string | undefined = undefined;
        if (typeof this.value === 'string') {
          selectedCategoryId = this.value;
        } else {
          selectedCategoryId = this.value?.category;
        }
        result.data.forEach((element: any) => {
          element.name = UserAssetCategoryName.get(element.id);
          element.selected = selectedCategoryId == element.id ? 'true' : 'false';
        });
        this.categories = result.data;
        this.changeSelectedGroup(undefined);
      }
    });
  }

  onGroupClick(category: UserAssetCategoryListItem, group: UserAssetGroupListItem) {
    if (this.value == group) {
      return;
    }
    group.category = category.id;
    this.changeSelectedGroup(group);
    this.value = group;
    this.selectionChange.emit(group);
  }

  private changeSelectedGroup(value: UserAssetGroupListItem | undefined) {
    this.categories.forEach(category => {
      category.groups.forEach(group => {
        group.selected = category.id == value?.category && group.id == value?.id;
      });
    });
  }

}

