<div class="py-5">

  <h1 mat-dialog-title [class.d-none]="!data.title">{{data.title??""}}</h1>

  <div mat-dialog-content>
    <p class=" mx-5">{{data.string}}</p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div mat-dialog-actions [class.d-none]="!(data.closable??true)">
    <button mat-stroked-button class="m-auto" [mat-dialog-close]="true">Cancel</button>
  </div>

</div>