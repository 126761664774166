import { Component, ElementRef, Optional, Self, ViewChild, Input, OnDestroy, DoCheck } from '@angular/core';
import { NgControl, ControlValueAccessor, NgForm, FormGroupDirective } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { InputBaseComponent } from './input-base.component';
import { FileInputInfo, InputStatus } from '../models';

@Component({
  selector: 'file-input',
  templateUrl: './file-input.html',
  styleUrl: './file-input.scss',
  providers: [{ provide: MatFormFieldControl, useExisting: FileInput }],
})
export class FileInput
  extends InputBaseComponent<FileInputInfo>
  implements ControlValueAccessor, MatFormFieldControl<FileInputInfo>, OnDestroy, DoCheck, CanUpdateErrorState {
  static nextId = 0;
  id = `file-input-${FileInput.nextId++}`;

  constructor(
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
  ) {
    super(_elementRef, ngControl, _parentForm, _parentFormGroup, _defaultErrorStateMatcher);
  }

  override get empty(): boolean {
    return !this.value?.name;
  }

  @Input()
  override get value(): FileInputInfo | null {
    return this._value;
  }
  override set value(value: FileInputInfo | null) {
    if (value !== this._value) {
      this._value = value;
      if (this.ngControl?.control) {
        this.ngControl.control.setValue(value);
      }

      this.stateChanges.next();
    }
  }
  private _value: FileInputInfo | null = null;

  override get shouldLabelFloat(): boolean {
    return true;
  }

  @ViewChild('fileBrowser') fileBrowser!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  chooseFileClick() {
    this.openFileBrowser()
  }

  resetClick(event: any) {
    this.fileBrowser.nativeElement.value = "";
    event.stopPropagation();
    let value = new FileInputInfo();
    value.id = this.value?.id;
    value.name = '';
    value.file = undefined;
    if (!value.id) {
      value.status = InputStatus.None;
    } else {
      value.status = InputStatus.Removed;
    }
    this.value = value;
    this.onTouched();
  }

  openFileBrowser() {
    this.fileBrowser.nativeElement.click();
  }

  async onFileChanged(e: any) {

    let selectedFiles = e.target.files || e.dataTransfer.files;

    if (selectedFiles.length == 0) {
      return;
    }
    let selectedFile = selectedFiles[0];

    let value = new FileInputInfo();
    value.id = undefined;
    value.name = selectedFile.name;
    value.file = selectedFile;
    if (!value.id) {
      value.status = InputStatus.AddNew;
    } else {
      value.status = InputStatus.Replaced;
    }
    this.value = value;
    this.onTouched();
  }

}
