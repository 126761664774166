import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-clothing-style-input',
  templateUrl: './image-clothing-style-input.html',
})
export class ImageClothingStyleInput implements OnInit {
  clothingStyles: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'logopattern', value: 'logopattern', selected: false },
    { key: 'Hand drawn doodles', value: 'Hand drawn doodles', selected: false },
    { key: 'insstyle', value: 'insstyle', selected: false },
    { key: 'Pastoral style', value: 'Pastoral style', selected: false },
    { key: 'Business', value: 'Business', selected: false },
    { key: 'Fault Wind', value: 'Fault Wind', selected: false },
    { key: 'Flat Wind', value: 'Flat Wind', selected: false },
    { key: 'painting', value: 'painting', selected: false },
    { key: 'Pixel Style', value: 'Pixel Style', selected: false },
    { key: 'watercolor', value: 'watercolor', selected: false },
  ];
  _clothingStyle?: string;
  @Input() set clothingStyle(value: string | undefined) {
    this._clothingStyle = value;
    let item = this.clothingStyles.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.clothingStyles.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get clothingStyle() {
    return this._clothingStyle;
  }

  @Output() clothingStyleChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  clothingStyleClick(selectedclothingStyle: SelectListItem) {
    this.clothingStyle = selectedclothingStyle.key;
    this.clothingStyleChange.emit(this.clothingStyle);
  }

}
