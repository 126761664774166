import { Component } from '@angular/core';

@Component({
  selector: 'comming-soon',
  templateUrl: './comming-soon.component.html',
  styleUrl: './comming-soon.component.scss',
})
export class CommingSoonComponent {

  constructor() {

  }

}