
<div class="d-flex flex-row p-2 text-white">
  <h2 class="m-0 ps-3 pt-3">If you have any questions，Please scan the QR code to contact us</h2>
  <div class="flex-1"></div>
  <div class="btn-dialog-close m-1">
    <button mat-icon-button class="btn-sm" color="accent" (click)="cancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="wechat-container">
  <wechat-barcode class="mb-3" />
  <div class="text-white text-center">WeChat Customer Service</div>
</mat-dialog-content>
