<input #fileInput type="file" class="d-none" (change)="onFileChanged($event)">

<div class="image-border position-relative"
    [ngClass]="{ 'border-dashed': style === 'normal','border-lighter': style === 'inline' }"
    (click)="chooseFileClick($event)" (drop)="dropFile($event)" (dragover)="dragoverFile($event)" *ngIf="hasDropZone">
    <div class="d-flex flex-column align-items-center m-auto" *ngIf="!url && style==='normal'">
        <div class="image-icon mb-2">
            <img src="{{ baseUrl }}assets/images/image.svg" />
        </div>
        <!-- <h3 class="my-1 hint-text">{{title}}</h3> -->
        <div [class.d-none]="!showHint">
            <!-- <div class="hint-text">supportpng、jpg、jpeg、webpFormat，</div> -->
            <div class="hint-text mat-body my-0">Size500KB ~ 10MB<!--，Aspect ratio0.5 ~ 2。--></div>
        </div>
        <button mat-button class="app-button rounded-pill my-2 px-3" color="primary">
            <div class="d-flex align-items-center">
                <!-- <mat-icon color="primary">upload</mat-icon> -->
                <p class="button-text my-0">{{title}}</p>
            </div>
        </button>
        <ng-content></ng-content>
    </div>
    <img [src]="url" (load)="onLoaded($event)" *ngIf="hasImageZone && !!url && style==='normal'" />
    <div class="d-flex flex-column background-lighter align-items-center justify-content-center w-100"
        *ngIf="style==='inline'">
        <mat-icon class="hint-text upload-icon mb-1">add</mat-icon>
        <span class="mat-body my-0 hint-text">{{inlineHintText}}</span>
    </div>
    <element-floatable *ngIf="hasImageZone && !!url">
        <button mat-icon-button class="position-absolute end-0" color="primary" matTooltip="Remove"
            (click)="resetClick($event)" *ngIf="canRemove">
            <mat-icon>delete</mat-icon>
        </button>
        <!-- <button mat-icon-button class="position-absolute end-0 bottom-0" color="primary" matTooltip="Reselect the file" (click)="chooseFileClick($event)">
            <mat-icon>upload</mat-icon>
        </button> -->
    </element-floatable>
</div>
