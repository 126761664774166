import { Component, Input, OnInit } from '@angular/core';
import { createBackgroundImage } from '../helpers/image-helper';

@Component({
  selector: 'image-container',
  templateUrl: './image-container.html',
  styleUrl: './image-container.scss',
})
export class ImageContainer implements OnInit {
  @Input() width: number | undefined;
  @Input() height: number | undefined;
  @Input() loading: boolean = false;
  backgroundImageUrl?: string;

  constructor() {

  }

  ngOnInit() {
    createBackgroundImage(this.width, this.height)?.toBlob(blob => {
      if (!blob) {
        return;
      }
      this.backgroundImageUrl = URL.createObjectURL(blob);
    });
  }
}
