import { Component, ElementRef, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { TaskCategory, UserNotificationCategory } from '../models';
import { AppHttpClient } from '../common/app.http';
import { ModelScaleProps, Size, UserOperationListItem } from '../interfaces';
import { UserTaskList } from '../user-task/user-task-list';
import { handleImageScale } from '../helpers/image-helper';
import { ImageMaskInput } from '../image-generate-input/image-mask-input';
import { ImagePicker } from '../image-picker/image-picker';
import { ClothService } from '../services/cloth.service';
import { MediaTypeHelper, FileHelper } from '../helpers/app.helpers';
import { ImageStickerInput } from '../image-generate-input/image-sticker-input';
import { ImageSegmentPicker } from '../image-segment-picker/image-segment-picker';
import { format } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { UserOperationList } from '../user-operation/user-operation-list';
import { JobTypes, JobSubTypes } from '../models';

@Component({
  selector: 'cloth-change-color',
  templateUrl: './cloth-change-color.component.html',
  styleUrl: './cloth-change-color.component.scss',
})
export class ClothChangeColorComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;
  @ViewChild('imagePicker') rawImagePicker!: ImagePicker;
  @ViewChild('userTaskList') userTaskList!: UserTaskList;
  @ViewChild('stickers') stickers!: ImageStickerInput;
  @ViewChild('userOperationList') userOperationList!: UserOperationList;
  @ViewChild('inputMatCard') inputMatCard!: ElementRef;

  JobTypesEnum = JobTypes;
  JobSubTypesEnum = JobSubTypes;
  maxImageEdgeLen = 1024;

  colorArray: string[] = [];
  newColor: string = '#000000';
  get curColorNum() {
    return this.colorArray.length;
  }

  _rawImageBlob?: Blob;
  set rawImageBlob(blob: Blob | undefined) {
    this.rawImageUrl = undefined;
    this._rawImageBlob = blob;
    if (!this._rawImageBlob) {
      return;
    }
    this.rawImageUrl = URL.createObjectURL(this._rawImageBlob);
  }
  get rawImageBlob(): Blob | undefined {
    return this._rawImageBlob;
  }

  segmentTaskId?: string;

  isExpanded: boolean = true;

  rawImageUrl?: string;
  modelScale: ModelScaleProps | any = null;

  @ViewChild('rawImage') rawImageRef!: ElementRef;
  @ViewChild('maskInput') maskInput!: ImageMaskInput;

  userNotificationCatecory: UserNotificationCategory = UserNotificationCategory.ClothChangeColor;

  segmenting: boolean = false;

  taskNameEditable: boolean = false;

  stickerBackgroundImageUrl?: string;

  constructor(injector: Injector,
    private http: AppHttpClient,
    private clothService: ClothService,
    private dialog: MatDialog,) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  updateColorState() {
    let params = [];
    params.push({ colorArray: this.colorArray });
    this.patchTaskState(params);
  }

  addNewColor(newColor: string) {
    this.newColor = newColor;
    this.colorArray = [...this.colorArray, newColor];
    this.updateColorState();
  }

  changeColor(newColor: string, index: number) {
    this.colorArray[index] = newColor;
    this.updateColorState();
  }

  deleteColor(index: number) {
    this.colorArray.splice(index, 1);
    this.updateColorState();
  }

  expandedChange(newExpanded: boolean) {
    this.isExpanded = newExpanded;
  }

  createObjectURL(data: ArrayBuffer, type: string) {
    return URL.createObjectURL(new Blob([data], { type: type }));;
  }

  resetStickers() {
    if (!!this.stickers) {
      this.stickers.reset();
    }
    this.segmentTaskId = undefined;
  }

  reuploadClick() {
    this.rawImagePicker.click();
  }

  calcGenerateSize(originWidth: number, originHeight: number): Size {
    const scaleWidth = this.maxImageEdgeLen / originWidth;
    const scaleHeight = this.maxImageEdgeLen / originHeight;

    let scaledWidth: number;
    let scaledHeight: number;

    if (scaleWidth < scaleHeight) {
      scaledWidth = this.maxImageEdgeLen;
      scaledHeight = Math.floor(originHeight * this.maxImageEdgeLen / originWidth);
    } else {
      scaledHeight = this.maxImageEdgeLen;
      scaledWidth = Math.floor(originWidth * this.maxImageEdgeLen / originHeight);
    }

    return { width: scaledWidth, height: scaledHeight };
  }


  generateClick() {
    if (!this.isInputValid()) {
      return;
    }

    this.generate();
  }

  generate() {
    //this.stickersToCanvas().toBlob((maskBlob) => {
    this.stickers.drawOrigToCanvas().toBlob((maskBlob) => {
      if (!maskBlob) {
        return;
      }
      let formData = new FormData();
      let rawImageFilename: string | undefined = undefined;
      let blob = this.rawImageBlob;
      if (!!blob) {
        rawImageFilename = 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type);
        formData.set('imageFile', blob, rawImageFilename);
      }

      let maskFilename = 'mask_image' + MediaTypeHelper.resolveFileExtension(maskBlob.type);
      formData.set('maskFile', maskBlob, maskFilename);
      //formData.set('clothingGender', this.clothingGender);
      //formData.set('clothingStyle', this.clothingStyle);
      //formData.set('content', this.content);
      formData.set('taskId', this.taskId!.toString());
      let size = { width: this.modelScale.width, height: this.modelScale.height };
      if (!!size) {
        formData.set('width', size.width.toString());
        formData.set('height', size.height.toString());
      }

      let operation = this.clothService.changeColor(formData, this.colorArray, (o) => {
        this.saveOperationState(o.id!);
      });
      let zone = this.injector.get(NgZone);
      zone.run(() => {
        this.userOperationList.addOperation(operation);
        this.scrollToTop();
      });
    });
  }

  isInputValid(): boolean {
    if (!this.rawImageUrl) {
      this.showError("Please upload a picture。")
      return false;
    }

    if (this.stickerEmpty()) {
      this.showError('Please edit the selection and generate it。');
      return false;
    }

    if (this.curColorNum === 0) {
      this.showError('Please select a color to generate。');
      return false;
    }

    return true;
  }

  rawImageChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }

    /*if (!this.isRestoring()) {
      BlobHelper.resizeBlob(file, this.maxImageEdgeLen, this.maxImageEdgeLen, (blob) => {
        this.rawImageBlob = blob;
        this.rawImageUrl = URL.createObjectURL(this.rawImageBlob);
      }, true);
    }
    else {*/
    this.rawImageBlob = file;
    this.rawImageUrl = URL.createObjectURL(this.rawImageBlob);
    //}
  }

  onRawImageLoaded(event: Event) {
    let img = event.target as HTMLImageElement;

    let modelScale = handleImageScale(img);
    this.modelScale = modelScale;
    this.stickerBackgroundImageUrl = this.createStickerBackgroundImageUrl();

    if (!this.isRestoring()) {
      this.resetStickersAndSave();
      this.saveTaskThumbnail(this.rawImageBlob);
      this.saveTaskState();
    }
    else {
      this.restoreComplete();
    }
  }

  createStickerBackgroundImageUrl(): string {
    const { width, height, samScale } = this.modelScale;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    return canvas.toDataURL();
  }

  resetStickersAndSave() {
    this.resetStickers();
    this.StickerChanged();
  }

  deleteStickerImageClick() {
    this.resetStickersAndSave();
  }

  exportStickerImageClick() {
    let imageUrl = this.stickersToCanvas().toDataURL('image/png', 1.0);
    FileHelper.download(
      imageUrl,
      `${format(new Date(), 'yyyyMMddHHmmss')}_sticker.png`
    );
  }

  private stickersToCanvas() {
    return this.stickers.drawToCanvas();
  }

  segmentPickerClick(e: any) {
    let rawImageUrl = this.rawImageUrl;
    if (!rawImageUrl) {
      return;
    }
    this.dialog.open(ImageSegmentPicker, {
      disableClose: true,
      data: {
        imageUrl: rawImageUrl,
        segmentTaskId: this.segmentTaskId,
        // clickTypes: "multi", //TODO(nianxin)::Undelete later
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      //this.stickers.value = [...this.stickers.value, ...result.stickers];
      this.stickers.value = result.stickers;
      this.segmentTaskId = result.taskId;
      this.StickerChanged();
    });
  }

  initRawImage(blob: Blob) {
    this.rawImageBlob = blob;
    this.rawImageUrl = URL.createObjectURL(blob);
  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  reset() {
    this.rawImagePicker.reset();
    this.resetStickers();
    this.rawImageBlob = undefined;
    this.segmenting = false;
    this.colorArray = [];
  }

  stickerEmpty(): boolean {
    if (!this.stickers || !this.stickers.value) {
      return true;
    }
    return this.stickers.value.length === 0;
  }

  StickerChanged() {
    this.patchTaskState(this.buildStickerState(this.stickers.getState(), this.segmentTaskId));
  }

  buildState(): any {
    let params = [];

    params.push({ colorArray: this.colorArray });

    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob && blob.size > 0) {
      rawImageFilename = 'image_reference' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }

    params.push({
      rawImageUrl: rawImageFilename,
      modelScale: this.modelScale,
    });

    return params;
  }

  saveOperationState(id: number): void {
    let params = this.buildState();
    params = [...params, ...this.buildStickerState(this.stickers.getState(), this.segmentTaskId)];
    this.patchOperationState(id, params);
  }

  override saveTaskState(): void {
    this.patchTaskState(this.buildState());
  }

  applyState(state: any, operationId?: number) {
    this.isExpanded = false;

    this.reset();
    if (!state) {
      this.restoreComplete();
      return;
    }

    this.modelScale = state.modelScale;
    this.colorArray = state.colorArray;

    if (!!state.rawImageUrl) {
      let rawImageUrl = `${state.rawImageUrl}`;
      this.restoreImageFromUrl(rawImageUrl, operationId).subscribe({
        next: (result: Blob) => {
          this.rawImageBlob = result;
        },
      });
    }

    this.segmentTaskId = state.segmentTaskId;

    if (!!state.stickersUrl) {
      let retoreStickerUrl = `${state.stickersUrl}`;
      this.restoreStickersFromUrl(this.stickers, retoreStickerUrl, operationId).subscribe({
        next: (result: any) => {
          this.stickers.setState(result);
        }
      });
    }

  }

  override restoreTaskState(): void {
    this.fetchTaskState().subscribe((result: any) => this.applyState(result));
  }

  override scrollToTop(): void {
    const containerElement: HTMLElement = this.inputMatCard.nativeElement;
    containerElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  cloneClick(operation: UserOperationListItem) {
    this.fetchOperationState(operation.id!).subscribe((result: any) => this.applyState(result, operation.id));
    this.scrollToTop();
  }

  userOperationStatusChange(processing: boolean) {
    this.processing = processing;
  }

}
