import { Component, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { PaintedImage, UserNotificationCategory } from '../models';
import { validRange } from '../common/app.forms';
import { ActionResult, ActionStatus, AppHttpClient } from '../common/app.http';
import { PaintedImageList } from '../components/painted-image-list';

@Component({
  selector: 'fittingg-room',
  templateUrl: './fittingg-room.component.html',
  styleUrl: './fittingg-room.component.scss',
})
export class FittingRoomComponent extends BaseComponent implements OnInit {

  UserNotificationCategoryEnum = UserNotificationCategory;

  aspect: string = "16:9";
  paintedImages: PaintedImage[] = [];
  @ViewChild('paintedImageList') paintedImageList!: PaintedImageList;

  minQuantity: number = 1;
  maxQuantity: number = 4;
  quantity: string = "4";

  minScale: number = 10;
  maxScale: number = 300;
  scale: string = "100%";

  constructor(injector: Injector,
    private http: AppHttpClient,) {
    super(injector);
  }

  ngOnInit() {
    this.http.get('/fitting-room/things').subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.models = result.data.models;
        this.fetchThings('models', this.models);
        this.tops = result.data.tops;
        this.fetchThings('tops', this.tops);
        this.bottoms = result.data.bottoms;
        this.fetchThings('bottoms', this.bottoms);
      }
    });
  }

  private fetchThings(category: string, data: Array<ThingSelectListItem> | undefined) {
    if (!data) {
      return;
    }
    data.forEach(item => {
      this.http.get(`/fitting-room/things/${category}/${item.key}`, { responseType: 'blob' }).subscribe({
        next: (result: Blob) => {
          item.url = URL.createObjectURL(result);
        },
      });
    });
  }

  ngOnDestroy() {

  }

  createObjectURL(data: ArrayBuffer, type: string) {
    return URL.createObjectURL(new Blob([data], { type: type }));;
  }

  generateClick() {
    if (!this.isInputValid()) {
      return;
    }

    this.generate();
  }

  generate() {
    if (this.validateProcessing()) {
      return;
    }
    this.processing = true;

    let formData = new FormData();
    formData.set('model', this.modelImageBlob!, this.modelFilename);
    if (!!this.topsImageBlob) {
      formData.set('tops', this.topsImageBlob, this.topsFilename);
    }
    if (!!this.bottomsImageBlob) {
      formData.set('bottoms', this.bottomsImageBlob, this.bottomsFilename);
    }
    let scale = this.scale.replace('%', '');
    let numScale = `${(Number(scale) / 100).toFixed(2)}`;
    formData.set('upscaleRatio', numScale);
    formData.set('quantity', '1');

    let paintedImages = new Array<PaintedImage>();
    for (let i = 0; i < Number(this.quantity); i++) {
      let item = new PaintedImage(this.injector);
      item.start();
      paintedImages.push(item);
    }

    paintedImages.forEach((item, index) => {
      setTimeout(() => {
        this.http.post(`/fitting-room/try-on`, formData).subscribe({
          next: (result: ActionResult) => {
            if (!this.http.isValidResult(result)) {
              item.stop(result);
              return;
            }
            item.id = result.data;
          },
          error: (err: any) => this.onError(item, err),
        });
      }, index * 1000);
    });

    let zone = this.injector.get(NgZone);
    zone.run(() => {
      this.paintedImages = paintedImages;
      this.paintedImageList.caculateGrid(this.quantity, this.aspect);
    });
  }

  private onError(target: PaintedImage, err: any) {
    target.stop({
      status: ActionStatus.Failed,
      message: err.message ?? err.toString(),
    });
  }

  onPaintCompleted() {
    let paintedImages = this.paintedImages;
    if (!paintedImages) {
      return;
    }

    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status == ActionStatus.Running) {
        return;
      }
    }
    this.processing = false;
  }

  isInputValid(): boolean {
    if (!this.modelImageBlob) {
      this.showError("Please select a model。")
      return false;
    }
    if (!this.topsImageBlob) {
      this.showError("Please select a top。")
      return false;
    }
    // if (!this.bottomsImageBlob) {
    //   this.showError("Please select bottoms。")
    //   return false;
    // }
    let scale = this.scale.replace('%', '');
    if (!validRange(scale, this.minScale, this.maxScale)) {
      this.showError(`Please specify the image scaling，The range is${this.minScale}%～${this.maxScale}%。`);
      return false;
    }

    return true;
  }

  // @ViewChild('modelImagePicker') modelImagePicker!: ImagePicker;
  models?: Array<ThingSelectListItem>;
  modelFilename?: string;
  modelImageBlob?: Blob;
  modelImageUrl?: string;
  // modelImageChanged(blob: Blob | undefined) {
  //   this.modelImageBlob = blob;
  // }

  async modelClick(model: ThingSelectListItem) {
    if (!model.url) {
      return;
    }
    this.modelFilename = model.key;
    this.modelImageUrl = model.url;
    let result = await fetch(model.url);
    this.modelImageBlob = await result.blob();
    // this.modelImagePicker.loadFile(await result.blob());
    return;
  }

  // @ViewChild('topsImagePicker') topsImagePicker!: ImagePicker;
  tops?: Array<ThingSelectListItem>;
  topsFilename?: string;
  topsImageBlob?: Blob;
  topsImageUrl?: string;
  // topsImageChanged(blob: Blob | undefined) {
  //   this.topsImageBlob = blob;
  // }

  async topsClick(tops: ThingSelectListItem) {
    if (!tops.url) {
      return;
    }
    this.topsFilename = tops.key;
    this.topsImageUrl = tops.url;
    let result = await fetch(tops.url);
    this.topsImageBlob = await result.blob();
    // this.topsImagePicker.loadFile(await result.blob());
    return;
  }

  // @ViewChild('bottomsImagePicker') bottomsImagePicker!: ImagePicker;
  bottoms?: Array<ThingSelectListItem>;
  bottomsFilename?: string;
  bottomsImageBlob?: Blob;
  bottomsImageUrl?: string;
  // bottomsImageChanged(blob: Blob | undefined) {
  //   this.bottomsImageBlob = blob;
  // }

  async bottomsClick(bottoms: ThingSelectListItem) {
    if (!bottoms.url) {
      return;
    }
    this.bottomsFilename = bottoms.key;
    this.bottomsImageUrl = bottoms.url;
    let result = await fetch(bottoms.url);
    this.bottomsImageBlob = await result.blob();
    // this.bottomsImagePicker.loadFile(await result.blob());
    return;
  }

}

interface ThingSelectListItem {
  key?: string;
  value?: string;
  url?: string;
}
