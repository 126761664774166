<div class="mb-4">
  <h5>Please select a picture group</h5>
</div>
<mat-accordion multi="true">
  <mat-expansion-panel class="shadow-none m-0" [expanded]="category.selected"
    *ngFor="let category of categories; let i = index">
    <mat-expansion-panel-header class="text-bg-light">
      <mat-panel-title>{{ category.name }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let group of category.groups;">
      <button mat-button class="justify-content-start ms-4 w-100" [class.active]="group.selected"
        (click)="onGroupClick(category, group)">{{group.name}}</button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
