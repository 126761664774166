<page-header title="Fitting room demonstration" />

<div class="d-flex flex-column flex-lg-row">

  <mat-card class="input-container mx-2">
    <div class="d-flex flex-column p-2">
      <div class="fs-larger">Please select a model：</div>
      <div class="image-border ratio ratio-16x9 mb-1">
        <!-- <image-picker #modelImagePicker (changed)="modelImageChanged($event)" /> -->
        <img [src]="modelImageUrl" *ngIf="!!modelImageUrl"/>
      </div>
      <div class="thing-container">
        <div class="d-flex">
          <div class="thing-item" matRipple [matRippleCentered]="true" *ngFor="let item of models;"
            (click)="modelClick(item)">
            <img [src]="item.url" *ngIf="!!item.url" />
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column p-2">
      <div class="fs-larger">Please select a top：</div>
      <div class="image-border ratio ratio-16x9 mb-1">
        <!-- <image-picker #topsImagePicker (changed)="topsImageChanged($event)" /> -->
        <img [src]="topsImageUrl" *ngIf="!!topsImageUrl"/>
      </div>
      <div class="thing-container">
        <div class="d-flex">
          <div class="thing-item" matRipple [matRippleCentered]="true" *ngFor="let item of tops;"
            (click)="topsClick(item)">
            <img [src]="item.url" *ngIf="!!item.url" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="d-flex flex-column p-2">
      <div class="fs-larger">Please select bottoms：</div>
      <div class="image-border ratio ratio-16x9 mb-1">
        <image-picker #bottomsImagePicker (changed)="bottomsImageChanged($event)" />
        <img [src]="bottomsImageUrl" *ngIf="!!bottomsImageUrl"/>
      </div>
      <div class="thing-container">
        <div class="thing-item" matRipple [matRippleCentered]="true" *ngFor="let item of bottoms;"
          (click)="bottomsClick(item)">
          <img [src]="item.url" *ngIf="!!item.url" />
        </div>
      </div>
    </div> -->

    <div class="d-flex flex-column p-2">
      <!--<br>
      <div class="mb-2">
        <image-scale-input [(value)]="scale" [min]="minScale" [max]="maxScale" />
      </div>
      <div class="mb-2">
        <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
      </div>-->
      <div class="d-flex py-2">
        <cost-estimator category="image" item="paint" [quantity]="quantity" />
        <div class="flex-grow-1"></div>
        <button mat-flat-button color="primary" (click)="generateClick()">Smart fitting</button>
      </div>
    </div>
  </mat-card>

  <div>
    <mat-card class="mx-2">
      <div class="d-flex flex-column flex-1 p-2">
        <div class="fs-larger">Exhibition area：</div>
        <div class="output-container d-flex mb-1">
          <div [class.m-auto]="processing">
            <painted-image-list #paintedImageList [(value)]="paintedImages"
              [notificationCategory]="UserNotificationCategoryEnum.FittingRoom" (completed)="onPaintCompleted()" />
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- <div class="flex-grow-1 output-container">

    <div class="image-border ratio ratio-16x9">
      <div class="d-flex flex-column" [class.d-none]="paintedImages.length==0">
        <painted-image-list #paintedImageList [(value)]="paintedImages"
          [notificationCategory]="UserNotificationCategoryEnum.FittingRoom" (completed)="onPaintCompleted()" />
      </div>
    </div>

  </div> -->

</div>
