import { Sticker } from "../models";
import { ModelScaleProps, Point } from "../interfaces";
import { angleBetween, distanceBetween } from "./mask-utils";

export function cropCanvasToBoundingBox(
  canvas: HTMLCanvasElement,
  x: number,
  y: number,
  width: number,
  height: number
): HTMLCanvasElement {
  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  // Set the dimensions of the cropped canvas
  croppedCanvas.width = width;
  croppedCanvas.height = height;

  // Draw the portion of the original canvas that falls within the bounding box
  croppedCtx?.drawImage(canvas, x, y, width, height, 0, 0, width, height);

  return croppedCanvas;
}

export function cropImageBySvg(
  image: HTMLImageElement,
  modelScale: ModelScaleProps,
  svgPaths: string[]
): Sticker {
  return cropImageByMultiplePath(image, modelScale, svgPaths);
}

export function cropImageByMultiplePath(
  image: HTMLImageElement,
  modelScale: ModelScaleProps,
  svgPaths: string[],
): Sticker {
  const scaleX = 1;
  const scaleY = 1;
  const multiplePathPoints = svgPaths.map(svg => parsePathData(svg, scaleX, scaleY));
  let allPoints: Point[] = [];
  multiplePathPoints.forEach(element => {
    allPoints = allPoints.concat(element);
  });
  const { width, height, samScale } = modelScale;
  const {
    x,
    y,
    width: cropWidth,
    height: cropHeight,
  } = getBoundingBox(allPoints);
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");
  const path = new Path2D();
  multiplePathPoints.forEach(points => {
    path.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      path.lineTo(points[i].x, points[i].y);
    }
    path.lineTo(points[0].x, points[0].y);
  });
  if(!!ctx){
    ctx.clip(path);
    ctx.drawImage(image, 0, 0, width, height);
  }

  // if (!environment.production && !!ctx) {// TODO::Cutout effect debugging code，Delete later
  //   multiplePathPoints.forEach(points => {
  //     ctx.beginPath();
  //     ctx.strokeStyle = "green";
  //     ctx.moveTo(points[0].x, points[0].y);
  //     for (let i = 1; i < points.length; i++) {
  //       ctx.lineTo(points[i].x, points[i].y);
  //     }
  //     ctx.lineTo(points[0].x, points[0].y);
  //     ctx.stroke();
  //   });
  //   multiplePathPoints.forEach(points => {
  //     for (let i = 0; i < points.length; i++) {
  //       ctx.beginPath();
  //       ctx.fillStyle = "red";
  //       ctx.arc(points[i].x, points[i].y, 1, 0, 2 * Math.PI);
  //       ctx.fill();
  //     }
  //   });
  // }

  // return canvas;
  const stickerImage = cropCanvasToBoundingBox(canvas, x, y, cropWidth, cropHeight);

  return new Sticker(
    // points,
    stickerImage,
    x / width * 100,
    y / height * 100,
    cropWidth / width * 100,
    cropHeight / height * 100,
  );
}

export function cropImageByPath(
  image: HTMLImageElement,
  modelScale: ModelScaleProps,
  points: Point[],
): Sticker {
  const { width, height, samScale } = modelScale;
  const {
    x,
    y,
    width: cropWidth,
    height: cropHeight,
  } = getBoundingBox(points);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const path = new Path2D();
  path.moveTo(points[0].x, points[0].y);
  for (let i = 1; i < points.length; i++) {
    path.lineTo(points[i].x, points[i].y);
  }
  path.closePath();
  canvas.width = width;
  canvas.height = height;
  ctx?.clip(path);
  ctx?.drawImage(image, 0, 0, width, height);
  // return canvas;
  const stickerImage = cropCanvasToBoundingBox(canvas, x, y, cropWidth, cropHeight);

  return new Sticker(
    // points,
    stickerImage,
    x / width * 100,
    y / height * 100,
    cropWidth / width * 100,
    cropHeight / height * 100,
  );
}

export function cropImageByRect(
  image: HTMLImageElement,
  modelScale: ModelScaleProps,
  points: Point[],
): Sticker {
  const { width, height, samScale } = modelScale;
  const {
    x,
    y,
    width: cropWidth,
    height: cropHeight,
  } = getBoundingBox(points);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const path = new Path2D();
  path.rect(x, y, cropWidth, cropHeight);
  canvas.width = width;
  canvas.height = height;
  ctx?.clip(path);
  ctx?.drawImage(image, 0, 0, width, height);
  // return canvas;
  const stickerImage = cropCanvasToBoundingBox(canvas, x, y, cropWidth, cropHeight);

  return new Sticker(
    // points,
    stickerImage,
    x / width * 100,
    y / height * 100,
    cropWidth / width * 100,
    cropHeight / height * 100
  );
}

export function cropImageByEraseBrush(
  image: HTMLImageElement,
  modelScale: ModelScaleProps,
  points: Point[],
  lineWidth: number,
): Sticker {
  const { width, height, samScale } = modelScale;
  let {
    x,
    y,
    width: cropWidth,
    height: cropHeight,
  } = getBoundingBox(points);
  x = Math.max(x - lineWidth / 2, 0);
  y = Math.max(y - lineWidth / 2, 0);
  cropWidth = Math.min(x + cropWidth + lineWidth / 2, width);
  cropHeight = Math.min(y + cropHeight + lineWidth / 2, height);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  let path = buildEraseBrushPath(points, lineWidth);
  canvas.width = width;
  canvas.height = height;
  ctx?.clip(path);
  ctx?.drawImage(image, 0, 0, width, height);
  // return canvas;
  const stickerImage = cropCanvasToBoundingBox(canvas, x, y, cropWidth, cropHeight);

  return new Sticker(
    // points,
    stickerImage,
    x / width * 100,
    y / height * 100,
    cropWidth / width * 100,
    cropHeight / height * 100,
  );
}

export function buildEraseBrushPath(points: Point[],
  lineWidth: number,): Path2D {
  let path = new Path2D();
  let startPoint = points[0];
  let distance: number, angle: number;
  let rx: number, ry: number;
  let cosA: number, sinA: number;
  path.arc(startPoint.x, startPoint.y, lineWidth / 2, 0, Math.PI * 2);
  let endPoint: Point;
  for (let i = 1; i < points.length; i++) {
    endPoint = points[i];
    distance = distanceBetween(startPoint, endPoint);
    angle = Math.PI / 2 - angleBetween(startPoint, endPoint);
    cosA = Math.cos(angle);
    sinA = Math.sin(angle);
    rx = startPoint.x + 0 * cosA + lineWidth / 2 * sinA;
    ry = startPoint.y + 0 * sinA - lineWidth / 2 * cosA;
    path.moveTo(rx, ry);
    rx = startPoint.x + distance * cosA + lineWidth / 2 * sinA;
    ry = startPoint.y + distance * sinA - lineWidth / 2 * cosA;
    path.lineTo(rx, ry);
    rx = startPoint.x + distance * cosA - lineWidth / 2 * sinA;
    ry = startPoint.y + distance * sinA + lineWidth / 2 * cosA;
    path.lineTo(rx, ry);
    rx = startPoint.x + 0 * cosA - lineWidth / 2 * sinA;
    ry = startPoint.y + 0 * sinA + lineWidth / 2 * cosA;
    path.lineTo(rx, ry);

    path.moveTo(endPoint.x, endPoint.y);
    path.arc(endPoint.x, endPoint.y, lineWidth / 2, 0, Math.PI * 2);

    startPoint = endPoint;
  }

  return path;
}

export function cropImageByEllipse(
  image: HTMLImageElement,
  modelScale: ModelScaleProps,
  points: Point[],
): Sticker {
  const { width, height, samScale } = modelScale;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const path = new Path2D();
  const {
    x,
    y,
    width: cropWidth,
    height: cropHeight,
  } = getBoundingBox(points);
  path.ellipse(x + cropWidth / 2, y + cropHeight / 2, cropWidth / 2, cropHeight / 2, 0, 0, Math.PI * 2);
  canvas.width = width;
  canvas.height = height;
  ctx?.clip(path);
  ctx?.drawImage(image, 0, 0, width, height);
  // return canvas;
  const stickerImage = cropCanvasToBoundingBox(canvas, x, y, cropWidth, cropHeight);

  return new Sticker(
    // points,
    stickerImage,
    x / width * 100,
    y / height * 100,
    cropWidth / width * 100,
    cropHeight / height * 100,
  );
}

export function getBoundingBox(
  points: Point[]
): {
  x: number;
  y: number;
  width: number;
  height: number;
} {
  let minX = Number.POSITIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;

  points.forEach((point) => {
    minX = Math.min(minX, point.x);
    minY = Math.min(minY, point.y);
    maxX = Math.max(maxX, point.x);
    maxY = Math.max(maxY, point.y);
  });

  return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
}

function parsePathData(
  pathData: string,
  scaleX: number,
  scaleY: number
): Point[] {
  const commands = pathData.split(/(?=[A-Za-z])/);
  const points: Point[] = [];
  let currentPoint: Point = { x: 0, y: 0 };

  commands.forEach((command) => {
    const type = command.charAt(0);
    const args = command
      .substring(1)
      .trim()
      .split(/[ ,]+/)
      .map((arg) => parseFloat(arg));

    // Based on svgCoordToInt() in mask_utils.tsx, we only use the "M" and "L" commands
    switch (type) {
      case "M":
        currentPoint = { x: args[0] * scaleX, y: args[1] * scaleY };
        points.push(currentPoint);
        break;
      case "L":
        for (let i = 0; i < args.length; i += 2) {
          const x = args[i] * scaleX;
          const y = args[i + 1] * scaleY;
          currentPoint = { x, y };
          points.push(currentPoint);
        }
        break;
      default:
        break;
    }
  });

  return points;
}

export function trimCanvas(canvas: HTMLCanvasElement): Sticker | null {
  let ctx = canvas.getContext('2d');
  if (!ctx) {
    return null;
  }
  let width = canvas.width;
  let height = canvas.height;
  let stickerCanvas = document.createElement('canvas');
  let stickerCtx = stickerCanvas.getContext('2d');
  if (!stickerCtx) {
    return null;
  }
  let pixels = ctx.getImageData(0, 0, width, height);
  let l = pixels.data.length;
  let bound: {
    top: number | null,
    left: number | null,
    right: number | null,
    bottom: number | null,
  } = {
    top: null,
    left: null,
    right: null,
    bottom: null
  };
  let x: number, y: number;

  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (let i = 0; i < l; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      x = (i / 4) % width;
      y = ~~((i / 4) / width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  if (bound.top == null || bound.left == null || bound.right == null || bound.bottom == null) {
    return null;
  }

  // Calculate the height and width of the content
  var trimHeight = bound.bottom - bound.top,
    trimWidth = bound.right - bound.left,
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

  stickerCtx.canvas.width = trimWidth;
  stickerCtx.canvas.height = trimHeight;
  stickerCtx.putImageData(trimmed, 0, 0);

  // Return trimmed canvas
  return new Sticker(
    // [],
    stickerCanvas,
    bound.left / width * 100,
    bound.top / height * 100,
    trimWidth / width * 100,
    trimHeight / height * 100,
  );
}
