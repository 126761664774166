import { Component, ElementRef, Injector, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { InferenceSession, Tensor } from 'onnxruntime-web';
const ort = require("onnxruntime-web");
/* @ts-ignore */
import npyjs from "npyjs";
import { ActionResult, AppHttpClient } from '../common/app.http';
import { Subject, Subscription, auditTime, Observable } from 'rxjs';
import { MediaTypeHelper } from '../helpers/app.helpers';
import { MatDialog } from '@angular/material/dialog';
import { cropImageBySvg } from '../helpers/create-cutouts';
import { ModelInputProps, ModelScaleProps } from '../interfaces';
import { UserNotification, UserNotificationCategory } from '../models';
import { handleImageScale } from '../helpers/image-helper';
import { traceOnnxMaskToSVG } from '../helpers/mask-utils';
import { modelData } from '../helpers/model-api';
import { RxStompService } from '../stomp/rx-stomp.service';
import { IMessage } from '@stomp/rx-stomp';
import { ImageStickerInput } from '../image-generate-input/image-sticker-input';
import { ProgressDialogComponent } from '../progress-dialog/progress-dialog.component';

@Component({
  selector: 'image-segment',
  templateUrl: './image-segment.html',
  styleUrl: './image-segment.scss',
})
export class ImageSegment extends BaseComponent implements OnInit {
  SegmentClickTypesEnum = SegmentClickTypes;

  @ViewChild('rawImage') rawImageRef!: ElementRef;
  @ViewChild('stickerBackgroundImage') stickerBackgroundImageRef!: ElementRef;
  @Input() rawImageUrl: string | null = null;
  model: InferenceSession | null = null;
  multiMaskModel: InferenceSession | null = null;
  tensor: Tensor | null = null;
  modelScale: ModelScaleProps | any = null;
  clicks: Array<ModelInputProps> = [];
  stickerBackgroundImageUrl: string | null = null;
  @ViewChild('stickers') stickers!: ImageStickerInput;
  newAnnotation: ModelInputProps | null = null;
  boxSelect: ModelInputProps | null = null;
  private static cachedSegments = new Map<string, string>();
  @Input() segmentClickType: SegmentClickTypes = SegmentClickTypes.SignleClick;
  maskSvgPaths?: string[];
  segmentMouseMoveSubject = new Subject<MouseEvent>();
  boxSelectMouseMoveSubject = new Subject<MouseEvent>();

  @Input() processDisplayMode: ProcessDisplayMode = ProcessDisplayMode.inline;
  segmentingMessage: string = "Mission in progress，Just a moment please...";

  segmentingSubject?: Subject<boolean>;
  segmentingObservable?: Observable<boolean>;

  segmentTaskId?: string;

  repredicted: boolean = false;

  @Output() modelScaleChange = new EventEmitter<ModelScaleProps>();
  @Output() predictCompleted = new EventEmitter<string>();

  private jobId?: number;
  private segmentSubscription!: Subscription;

  _segmenting: boolean = false;

  set segmenting(segmenting: boolean) {
    if (this.segmenting && !segmenting) {
      if (this.processDisplayMode === ProcessDisplayMode.dialog) {
        if (!!this.segmentingSubject) {
          this.segmentingSubject.next(segmenting);
        }
      }

    } else if (!this.segmenting && segmenting) {
      if (this.processDisplayMode === ProcessDisplayMode.dialog) {
        this.dialog.open(ProgressDialogComponent, {
          disableClose: true,
          data: {
            string: this.segmentingMessage,
            closable: false,
            process: this.segmentingObservable,
          }
        })
      }
    }

    this._segmenting = segmenting;
  }

  get segmenting() {
    return this._segmenting;
  }

  @ViewChild('rawImageBox', { static: false }) rawImageBox!: ElementRef;
  @ViewChild('stickerBox', { static: false }) stickerBox!: ElementRef;

  constructor(injector: Injector, private http: AppHttpClient,
    private rxStompService: RxStompService,
    public dialog: MatDialog) {
    super(injector);
    this.segmentMouseMoveSubject.pipe(auditTime(500)).subscribe((event) => this.handleSegmentMouseMove(event));
    this.boxSelectMouseMoveSubject.pipe(auditTime(50)).subscribe((event) => this.handleBoxSelectMouseMove(event));
  }

  ngOnInit() {

    if (this.processDisplayMode === ProcessDisplayMode.dialog) {
      this.segmentingSubject = new Subject<boolean>();
      this.segmentingObservable = this.segmentingSubject.asObservable();
    }

    this.segmentSubscription = this.rxStompService
      .watch(UserNotificationCategory.Segment)
      .subscribe((message: IMessage) => {
        var notification = JSON.parse(message.body) as UserNotification;
        if (notification.id != this.jobId) {
          return;
        }
        var result = notification.data as ActionResult;
        if (!this.http.isValidResult(result)) {
          this.segmenting = false;
          return;
        }
        this.completedPredict(notification.id!);
      });

    //this.predict();
  }

  ngOnDestroy() {
    this.segmentSubscription.unsubscribe();
  }

  get rawImage(): HTMLImageElement {
    return this.rawImageRef.nativeElement;
  }

  resetSegment() {
    this.resetMask();
    this.stickers.reset();
    this.newAnnotation = null;
  }

  createStickerBackgroundImageUrl(): string {
    const { width, height, samScale } = this.modelScale;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    return canvas.toDataURL();
  }

  private isMultiMaskMode: boolean = false;
  private MODEL_URL = "/assets/model/sam_vit_h_4b8939.onnx";
  // private MODEL_URL = "/assets/model/sam_hq_vit_h.onnx";
  // private MODEL_URL = "/assets/model/sam_hq_vit_l.onnx";
  // private MODEL_URL = "/assets/model/sam_hq_vit_b.onnx";
  // private MODEL_URL = "assets/model/sam_onnx_quantized.onnx";
  // private MODEL_URL = "assets/model/interactive_module_quantized_592547_2023_03_19_sam6_long_uncertain.onnx";
  // private MODEL_URL = "https://segment-anything.com/model/interactive_module_quantized_592547_2023_03_20_sam6_long_all_masks_extra_data_with_ious.onnx";
  private MULTI_MASK_MODEL_DIR = undefined; //"assets/model/interactive_module_quantized_592547_2023_03_20_sam6_long_all_masks_extra_data_with_ious.onnx";

  async predict() {
    let rawImageUrl = this.rawImageUrl;
    if (!rawImageUrl) {
      this.predictCompleted.emit(SegmentResults.fail);
      return;
    }

    this.segmenting = true;

    if (!this.model && !!this.MODEL_URL) {
      this.model = await InferenceSession.create(this.MODEL_URL);
    }

    if (!this.multiMaskModel && !!this.MULTI_MASK_MODEL_DIR) {
      this.multiMaskModel = await InferenceSession.create(this.MULTI_MASK_MODEL_DIR);
    }

    let cachedSegmentUrl = ImageSegment.cachedSegments.get(rawImageUrl);
    if (!!cachedSegmentUrl) {
      let result = await fetch(cachedSegmentUrl);
      this.initSegment(await result.blob());
      this.predictCompleted.emit(SegmentResults.cached);
      return;
    }

    let response = await fetch(rawImageUrl);
    let blob = await response.blob();

    var formData = new FormData();
    formData.set('file', blob, 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type));

    this.http.post("/segment/predict", formData).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          this.segmenting = false;
          this.predictCompleted.emit(SegmentResults.fail);
          return;
        }
        this.jobId = result.data;
        this.predictCompleted.emit(this.jobId?.toString());
      },
      error: (err: any) => {
        this.segmenting = false;
        this.predictCompleted.emit(SegmentResults.fail);
        return;
      },
    });
  }

  async restoreSegment(segmentTaskId: string) {
    if (!segmentTaskId) {
      this.predict();
      return;
    }

    this.segmenting = true;

    if (!this.model && !!this.MODEL_URL) {
      this.model = await InferenceSession.create(this.MODEL_URL);
    }

    if (!this.multiMaskModel && !!this.MULTI_MASK_MODEL_DIR) {
      this.multiMaskModel = await InferenceSession.create(this.MULTI_MASK_MODEL_DIR);
    }
    this.completedPredict(segmentTaskId);
  }

  get predicted() {
    return !!this.tensor;
  }

  completedPredict(id: string) {

    if (!id) {
      this.predictCompleted.emit(SegmentResults.fail);
      return;
    }

    this.segmentTaskId = id;
    this.http.get(`/segment/${id}/download`, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        ImageSegment.cachedSegments.set(this.rawImageUrl!, URL.createObjectURL(result));
        this.initSegment(result);
      },
      error: (error: any) => {
        if (!this.repredicted) {
          this.repredicted = true;
          this.predict();
        }
        else {
          this.segmenting = false;
          this.predictCompleted.emit(SegmentResults.fail);
          return;
        }
      },
    });
  }

  initSegment(blob: Blob) {
    this.segmenting = false;
    blob.arrayBuffer().then(async (data) => {
      let npLoader = new npyjs();
      const npArray = await npLoader.load(data);
      this.tensor = new ort.Tensor("float32", npArray.data, npArray.shape);
    });
  }

  onMouseEnter(e: any) {
    if (this.timerMouseOut) {
      clearTimeout(this.timerMouseOut);
      this.timerMouseOut = undefined;
    }
  }

  onMouseDown(e: any) {
    this.newAnnotation = this.getMouseInput(e);
    // this.handleMouseDown(e);
  }

  onMouseUp(e: any) {
    // if (this.stickerTabBool) {
    //   this.stickerTabBool = false;
    //   return;
    // }
    // if (this.segmentTypes === "All") return;
    // if (this.isMultiMaskMode && this.clicks) return;
    // this.isLoading = true;
    if (e.button === 2 && this.segmentClickType != SegmentClickTypes.MultipleClick) {
      return;
    }
    this.handleMouseUp(e);
  }

  handleMouseDown = (e: any) => {
    // if (this.stickerTabBool) return;
    // if (this.clicksHistory) this.clicksHistory = null;
    // if (this.predMasksHistory) this.predMasksHistory = null;
    // if (this.segmentTypes !== "Box") return;
    // const { x, y } = this.getPointerPosition(e);
    // this.numOfDragEvents = 0;
    // if (this.newAnnotation.length === 0) {
    //   this.newAnnotation = [{ x, y, width: 0, height: 0, clickType: -1 }];
    // }
  }

  onMouseMove(e: MouseEvent) {
    // let newAnnotation = this.newAnnotation;
    // if (!newAnnotation) {
    //   return;
    // }
    this.segmentMouseMoveSubject.next(e);
    this.boxSelectMouseMoveSubject.next(e);
  }

  // handleMouseMove(e: MouseEvent) {
  //   this.handleBoxSelectPreview(e);
  //   this.handleSegmentPreview(e);
  // }

  async handleMouseUp(e: any) {
    this.handleSegmentMouseMove(e, true);

    this.boxSelect = null;
    this.newAnnotation = null;
  }

  handleBoxSelectMouseMove(e: any) {
    let newAnnotation = this.newAnnotation;
    if (!newAnnotation) {
      return;
    }
    let endAnnotation = this.getMouseInput(e);
    let widthScale = this.rawImage.width / this.modelScale.width;
    let heightScale = this.rawImage.height / this.modelScale.height;
    this.boxSelect = {
      x: Math.min(endAnnotation.x, newAnnotation.x) * widthScale,
      y: Math.min(endAnnotation.y, newAnnotation.y) * heightScale,
      width: Math.abs(endAnnotation.x - newAnnotation.x) * widthScale,
      height: Math.abs(endAnnotation.y - newAnnotation.y) * heightScale,
      clickType: 2
    };
  }

  async handleSegmentMouseMove(e: any, shouldSetClick?: boolean) {
    let endAnnotation = this.getMouseInput(e);
    if (!endAnnotation) {
      return;
    }
    let ex = endAnnotation.x;
    let ey = endAnnotation.y;

    let startAnnotation = this.newAnnotation;
    let sx = endAnnotation.x;
    let sy = endAnnotation.y;
    let isClick = true;
    if (!!startAnnotation) {
      sx = startAnnotation.x;
      sy = startAnnotation.y;
      if (ex < sx) {
        let x = sx;
        sx = ex;
        ex = x;
      }
      if (ey < sy) {
        let y = sy;
        sy = ey;
        ey = y;
      }
      const width = ex - sx;
      const height = ey - sy;
      isClick = width < 3 && height < 3;
    }
    let clicks = [];
    if (isClick) {
      let click = {
        x: ex,
        y: ey,
        width: 0,
        height: 0,
        clickType: e.button === 2 ? 0 : 1,
      };
      if (this.segmentClickType == SegmentClickTypes.SignleClick) { // Single click cutout
        clicks = [click];
        this.maskSvgPaths = await this.buildMaskSvgPaths(clicks);
      } else { // Click more to cut out
        clicks = [...this.clicks];
        clicks.push(click);
        this.maskSvgPaths = await this.buildMaskSvgPaths(clicks);
      }
    } else {// Rectangular frame selection
      clicks = [{
        x: sx,
        y: sy,
        width: ex - sx,
        height: ey - sy,
        clickType: 2,
      }];
      this.maskSvgPaths = await this.buildMaskSvgPaths(clicks);
    }
    if (shouldSetClick) {
      this.clicks = clicks;
      if (this.segmentClickType != SegmentClickTypes.MultipleClick) {
        this.handleSegmentByClicks();
      }
    }
  }

  private timerMouseOut?: NodeJS.Timeout;
  handleMouseOut(e: any) {
    this.newAnnotation = null;
    this.boxSelect = null;
    this.timerMouseOut = setTimeout(async () => {
      if (this.segmentClickType == SegmentClickTypes.MultipleClick) {
        this.maskSvgPaths = await this.buildMaskSvgPaths(this.clicks);
      } else {
        this.maskSvgPaths = undefined;
      }
      this.timerMouseOut = undefined;
    }, 550); // Need to be slightly larger thanmousemoveThe sampling interval
  }

  handleSegmentByClicks() {
    let maskSvgPaths = this.maskSvgPaths;
    maskSvgPaths = maskSvgPaths?.filter(element => !!element);
    if (!maskSvgPaths || maskSvgPaths.length == 0) {
      return;
    }

    this.cutoutSegment(maskSvgPaths);
    this.resetMask();
  }

  async buildMaskSvgPaths(clicks: Array<ModelInputProps>): Promise<string[] | undefined> {
    if (!this.predicted) {
      return undefined;
    }

    if (clicks.length == 0) {
      return undefined;
    }

    const feeds = modelData(
      clicks,
      this.tensor,
      this.modelScale,
    );
    if (feeds === undefined) {
      return undefined;
    };
    let model = this.model;
    if (model == null) {
      return undefined;
    }

    const results = await model.run(feeds);
    const output = results[model.outputNames[0]];
    let maskSvgPaths = traceOnnxMaskToSVG(
      output.data,
      output.dims[3],
      output.dims[2]
    );

    return maskSvgPaths;
  }

  getMouseInput(event: MouseEvent): ModelInputProps {
    let el = event.target as HTMLElement;
    const rect = el.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    const imageScale = this.modelScale ? this.modelScale.width / el.offsetWidth : 1;
    x *= imageScale;
    y *= imageScale;
    const clickType = 1;
    return { x, y, width: 0, height: 0, clickType };
  };

  rawImageLoaded(event: Event) {
    let img = event.target as HTMLImageElement;

    let modelScale = handleImageScale(img);
    this.modelScale = modelScale;
    this.modelScaleChange.emit(modelScale);
    this.stickerBackgroundImageUrl = this.createStickerBackgroundImageUrl();

    /* const rawImageHeight = this.rawImageBox.nativeElement.clientHeight;
     this.stickerBox.nativeElement.style.height = `${rawImageHeight}px`;*/

  }

  cutoutSegment(maskSvgPaths: string[]) {
    let image = this.rawImage;
    let sticker = cropImageBySvg(image, this.modelScale!, maskSvgPaths);
    this.stickers.value = [...this.stickers?.value, sticker];
  }

  pickup() {
    if (this.clicks.length == 0) {
      return;
    }
    this.handleSegmentByClicks();
  }

  resetMask() {
    this.clicks = [];
    this.maskSvgPaths = undefined;
  }

  async back() {
    if (this.clicks.length == 0) {
      return;
    }
    this.clicks.splice(-1, 1);
    if (this.clicks.length == 0) {
      this.maskSvgPaths = undefined;
    } else {
      this.maskSvgPaths = await this.buildMaskSvgPaths(this.clicks);
    }
  }

  previous() {
    let stickers = this.stickers?.value;
    if (!stickers || stickers.length == 0) {
      return;
    }
    stickers.pop();
    this.stickers.value = stickers;
  }

  get isEmpty() {
    let stickers = this.stickers?.value;
    return !stickers || stickers.length == 0;
  }
}

export enum SegmentClickTypes {
  SignleClick,
  MultipleClick,
}

export enum ProcessDisplayMode {
  inline = 'inline',
  dialog = 'dialog',
}

export enum SegmentResults {
  fail = 'fail',
  cached = 'cached',
}
