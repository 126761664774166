import { Component, ElementRef, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { PaintedImage, TaskCategory } from '../models';
import { validRange } from '../common/app.forms';
import { ActionResult, ActionStatus, AppHttpClient } from '../common/app.http';
import { MaterialTemplateListItem, ModelScaleProps, PathTypes, Size } from '../interfaces';
import { UserTaskList } from '../user-task/user-task-list';
import { aspectToSize } from '../helpers/image-helper';
import { ImageMaskInput } from '../image-generate-input/image-mask-input';
import { MatMenuTrigger } from '@angular/material/menu';
import { MediaTypeHelper } from '../helpers/app.helpers';
import { ClothService } from '../services/cloth.service';
import { ImagePicker } from '../image-picker/image-picker';

@Component({
  selector: 'facelift-to-clothing',
  templateUrl: './facelift-to-clothing.component.html',
  styleUrl: './facelift-to-clothing.component.scss',
})
export class FaceliftToClothingComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;
  @ViewChild('imagePicker') rawImagePicker!: ImagePicker;
  @ViewChild('userTaskList') userTaskList!: UserTaskList;
  _rawImageBlob?: Blob;
  set rawImageBlob(blob: Blob | undefined) {
    this.rawImageUrl = undefined;
    this._rawImageBlob = blob;
    if (!this._rawImageBlob) {
      return;
    }
    this.rawImageUrl = URL.createObjectURL(this._rawImageBlob);
  }
  get rawImageBlob(): Blob | undefined {
    return this._rawImageBlob;
  }

  rawImageUrl?: string;
  modelScale: ModelScaleProps | any = null;
  pathType?: PathTypes;

  @ViewChild('rawImage') rawImageRef!: ElementRef;
  @ViewChild('maskInput') maskInput!: ImageMaskInput;
  aspect: string = "16:9";
  // imageSize?: Size;
  // resolution: string = "1500*1500";
  imageSize?: Size;

  prompts: string = "";
  negativePrompts: string = "";
  paintedImages: PaintedImage[] = [];

  minQuantity: number = 1;
  maxQuantity: number = 4;
  quantity: string = "4";

  selectedTemplateId?: number;
  segmenting: boolean = false;

  models?: Array<ThingSelectListItem>;
  modelFilename?: string;
  modelImageBlob?:Blob;
  modelImageUrl?:string;

  constructor(injector: Injector,
    private http: AppHttpClient,
    private clothService: ClothService,) {
    super(injector);
  }

  ngOnInit() {
    this.http.get('/fitting-room/things').subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.models = result.data.models;
        this.fetchThings('models', this.models);
      }
    });
  }

  private fetchThings(category: string, data: Array<ThingSelectListItem> | undefined) {
    if (!data) {
      return;
    }
    data.forEach(item => {
      this.http.get(`/fitting-room/things/${category}/${item.key}`, { responseType: 'blob' }).subscribe({
        next: (result: Blob) => {
          item.url = URL.createObjectURL(result);
        },
      });
    });
  }
  
  ngOnDestroy() {

  }

  createObjectURL(data: ArrayBuffer, type: string) {
    return URL.createObjectURL(new Blob([data], { type: type }));;
  }

  refactorClick(image: PaintedImage) {
    this.router.navigate(['../refactor', { imageUrl: image.url }], { relativeTo: this.route });
  }

  generateClick() {
    if (!this.isInputValid()) {
      return;
    }

    this.generate();
  }

  generate() {
    if (this.validateProcessing()) {
      return;
    }
    this.processing = true;

    let formData = new FormData();
    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'image_reference' + MediaTypeHelper.resolveFileExtension(blob.type);
      formData.set('imageReference', blob, rawImageFilename);
    }
    //formData.set('clothingGender', this.clothingGender);
    //formData.set('clothingStyle', this.clothingStyle);
    //formData.set('content', this.content);
    formData.set('prompts', this.prompts);
    formData.set('negativePrompts', this.negativePrompts);
    let size = aspectToSize(this.aspect);
    if (!!size) {
      formData.set('width', size.width.toString());
      formData.set('height', size.height.toString());
    }
    if (!!this.aspect) {
      formData.set('aspect', this.aspect);
    }
    formData.set('quantity', this.quantity);

    let zone = this.injector.get(NgZone);
    let operation = this.clothService.generate(formData, (o) => {
      // to do
      // this.saveOperationState(o.id!);
    });
    zone.run(() => {
      // to do
      // this.userOperationList.addOperation(operation);
      // this.scrollToTop();
    });
  }

  onPaintCompleted() {
    let paintedImages = this.paintedImages;
    if (!paintedImages) {
      return;
    }

    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status == ActionStatus.Running) {
        return;
      }
    }
    this.processing = false;

    this.saveTaskState();
  }

  isInputValid(): boolean {
    if (!this.rawImageUrl) {
      this.showError("Please upload a picture。")
      return false;
    }
    if (!this.prompts) {
      this.showError("Please enter a text description。")
      return false;
    }
    if (!this.aspect) {
      this.showError("Please select the image ratio。")
      return false;
    }
    if (!validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return false;
    }

    return true;
  }

  onMaskCompleted(event: MouseEvent) {
    this.showPaintModeContextMenu(event);
    this.pathType = undefined;
  }

  @ViewChild(MatMenuTrigger) paintModeContextMenu!: MatMenuTrigger;
  paintModeContextMenuPosition = { x: '0px', y: '0px' };
  showPaintModeContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.paintModeContextMenuPosition.x = event.clientX + 'px';
    this.paintModeContextMenuPosition.y = event.clientY + 'px';
    this.paintModeContextMenu.openMenu();
  }

  rawImageChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }
    this.rawImageBlob = file;
    this.rawImageUrl = URL.createObjectURL(this.rawImageBlob);
  }

  initRawImage(blob: Blob) {
    this.rawImageBlob = blob;
    this.rawImageUrl = URL.createObjectURL(blob);
  }

  async modelClick(model: ThingSelectListItem) {
    if (!model.url) {
      return;
    }
    this.modelFilename = model.key;
    this.modelImageUrl = model.url;
    let result = await fetch(model.url);
    this.modelImageBlob = await result.blob();
    // this.modelImagePicker.loadFile(await result.blob());
    return;
  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  onMaterialSelected(template: MaterialTemplateListItem) {
    this.selectedTemplateId = template.id;
    this.http.get(`/material/templates/${template.id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        var data = result.data;
        this.prompts = data.prompts;
        this.negativePrompts = data.negativePrompts;
      }
    });
  }

  reset() {
    this.rawImagePicker.reset();
    this.rawImageBlob = undefined;
    this.selectedTemplateId = undefined;
    this.prompts = '';
    this.negativePrompts = '';
    this.aspect = '16:9';
    this.quantity = '4';
    this.paintedImages = [];
    this.segmenting = false;
  }

  override saveTaskState(): void {
    let params = [];

    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'image_reference' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }
    params.push({
      rawImageUrl: rawImageFilename,
      selectedTemplateId: this.selectedTemplateId,
      negativePrompts: this.negativePrompts,
      aspect: this.aspect,
      quantity: this.quantity,
      paintedImages: this.paintedImages,
    });

    this.patchTaskState(params);
  }

  override restoreTaskState(): void {
    this.fetchTaskState().subscribe((data: any) => {
      if (!data) {
        this.reset();
        return;
      }

      this.rawImageUrl = data.rawImageUrl;
      this.selectedTemplateId= this.selectedTemplateId,
      this.prompts = data.prompts;
      this.negativePrompts = data.negativePrompts;
      this.aspect = data.aspect;
      this.quantity = data.quantity;
      this.paintedImages = PaintedImage.fromArray(data.paintedImages ?? [], this.injector);

      let rawImageUrl = `${this.taskStateUrl}/${data.rawImageUrl}`;
      this.http.get(rawImageUrl, { responseType: 'blob' }).subscribe({
        next: (result: Blob) => {
          this.rawImageBlob = result;
          this.rawImagePicker.loadFile(result, true);
        },
      });
    });
  }

}

interface ThingSelectListItem {
  key?: string;
  value?: string;
  url?: string;
}
