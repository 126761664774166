import { Injector } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MessageResource, MessageService } from './message.service';
import { SessionService } from './session.service';
import { FormBuilder } from '@angular/forms';
import { ConfigService } from './config.service';
import { BASE_URL } from '../app.module';

export abstract class BaseComponent {
  protected baseUrl: string;
  protected formBuilder: FormBuilder;
  protected router: Router;
  protected route: ActivatedRoute;
  protected location: Location;
  protected breakpointObserver: BreakpointObserver;
  protected messageService: MessageService;
  protected sessionService: SessionService;
  protected configService: ConfigService;
  public isMobile: boolean = false;
  protected processing:boolean = false;

  constructor(protected injector: Injector) {
    this.baseUrl = this.injector.get(BASE_URL);
    this.formBuilder = this.injector.get(FormBuilder);
    this.router = this.injector.get(Router);
    this.location = this.injector.get(Location);
    this.route = this.injector.get(ActivatedRoute);
    this.messageService = this.injector.get(MessageService);
    this.sessionService = this.injector.get(SessionService);
    this.configService = this.injector.get(ConfigService);
    this.breakpointObserver = this.injector.get(BreakpointObserver);
    this.breakpointObserver.observe('(min-width: 768px)').subscribe(value => { this.isMobile = !value.matches; });
  }

  protected showInformation(message: string | undefined) {
    this.messageService.showInformation(message);
  }

  protected showWarning(message: string | undefined) {
    this.messageService.showWarning(message);
  }

  protected showError(message: string | undefined) {
    this.messageService.showError(message);
  }
  
  protected validateProcessing(): boolean {
    if(this.processing){
      this.messageService.showWarning(MessageResource.WAITING_FOR_PROCESS);
      return true;
    }
    return false;
  }

  protected scrollToTop() {
    document.getElementsByTagName("mat-sidenav-content")[0].scrollTo({
      top:0,
      behavior: "smooth"
    });
  }
}
