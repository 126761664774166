import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MaterialTemplateDetails, MaterialTemplateListItem } from '../interfaces';
import { ActionResult, AppHttpClient } from '../common/app.http';

@Component({
  selector: 'image-style-input',
  templateUrl: './image-style-input.html',
  styleUrl: './image-style-input.scss',
})
export class ImageStyleInput implements OnInit {
  @Input() selectedTemplateId?: number;
  @Input() value?: MaterialTemplateDetails;
  @Output() valueChange = new EventEmitter<MaterialTemplateDetails>();

  showMoreTemplates?: boolean;
  initSelectedTemplate?:MaterialTemplateDetails

  constructor(private http: AppHttpClient,) {

  }

  ngOnInit() {
    if (!!this.selectedTemplateId) {
      this.fetchMaterialTemplate(this.selectedTemplateId);
    }else{
      this.showMoreTemplates= true;
    }
  }

  materialTemplateSelected(template: MaterialTemplateListItem) {
    let id = template.id;
    if (!id) {
      return;
    }
    this.fetchMaterialTemplate(id);
  }

  private fetchMaterialTemplate(id: number) {
    this.http.get(`/material/templates/${id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        var data = result.data;
        this.value = { ...this.value, ...data };
        this.valueChange.emit(this.value);
        if(this.selectedTemplateId==id){
          this.initSelectedTemplate ={...this.value};
        }
      }
    });
    this.http.get(`/material/templates/${id}`, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.value = { ...this.value, ...{ url: URL.createObjectURL(result) } };
        this.valueChange.emit(this.value);
        if(this.selectedTemplateId==id){
          this.initSelectedTemplate ={...this.value};
        }
      },
    });
  }

  cancelClick() {
    this.value = this.initSelectedTemplate;
    this.valueChange.emit(this.value);
    this.showMoreTemplates=!this.showMoreTemplates;
  }

}