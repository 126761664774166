import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-hair-color-input',
  templateUrl: './image-hair-color-input.html',
})
export class ImageHairColorInput implements OnInit {
  hairColors: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'black', value: 'black', selected: false },
    { key: 'gold', value: 'gold', selected: false },
    { key: 'red', value: 'red', selected: false },
    { key: 'White', value: 'White', selected: false },
    { key: 'Khaki', value: 'Khaki', selected: false },
    { key: 'brown', value: 'brown', selected: false },
    { key: 'Brown', value: 'Brown', selected: false },
    { key: 'Purple', value: 'Purple', selected: false },
    { key: 'brown', value: 'brown', selected: false },
  ];
  _hairColor?: string;
  @Input() set hairColor(value: string | undefined) {
    this._hairColor = value;
    let item = this.hairColors.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.hairColors.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get hairColor() {
    return this._hairColor;
  }

  @Output() hairColorChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  hairColorClick(selectedHairColor: SelectListItem) {
    this.hairColor = selectedHairColor.key;
    this.hairColorChange.emit(this.hairColor);
  }

}
