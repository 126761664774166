<div class="page-header">
  <h4 class="title me-5">User List</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="addNewClick()">
    <mat-icon color="primary">add_circle</mat-icon><span>Add a new user</span>
  </button>
</div>

<form class="d-flex flex-wrap" [formGroup]="inputForm">
  <!-- <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>User Account</mat-label>
      <input matInput formControlName="username" maxlength="32">
    </mat-form-field>
  </div> -->
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>User Account / Phone number</mat-label>
      <input matInput formControlName="username" maxlength="32">
    </mat-form-field>
  </div>
  <!-- <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>User Category</mat-label>
      <mat-select formControlName="type">
        <mat-option value="">(All)</mat-option>
        <mat-option *ngFor="let item of userTypeList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>Company Name</mat-label>
      <input matInput formControlName="companyName" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>User Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value="">(All)</mat-option>
        <mat-option *ngFor="let item of statusList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-4 col-xl-2 text-center py-2">
    <button mat-flat-button mat-button-base color="primary" (click)="searchClick()">Query</button>
  </div>
</form>

<div [class.d-none]="dataSource.data.length==0">

  <mat-table [dataSource]="dataSource" matSort>
    <!-- <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">User Account</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.username}}</mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">User Account</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.username}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="companyName">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-3">Affiliated companies / personal</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-3">{{getDisplayName(row)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalPoints">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Recharge Zhidou</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.totalPoints}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="usedPoints">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Smart Beans used</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.totalPoints - row.points}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="points">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Remaining Smart Beans</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.points}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Creation time</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.createdDate}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastLoginDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">Last used time</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.lastLoginDate}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">
        <button mat-icon-button class="btn-sm m-1" matTooltip="More Operations" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{row}">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="row g-0"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row g-0"></mat-row>
  </mat-table>
  <mat-paginator pageSize="100" [pageSizeOptions]="[10, 25, 100, 200]"></mat-paginator>

  <br />
  <br />

</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-row="row">
    <button mat-menu-item color="primary" (click)="editClick(row)">
      <mat-icon>edit</mat-icon>User information modification
    </button>
    <button mat-menu-item color="primary" (click)="transferPointsClick(row)">
      <mat-icon>attach_money</mat-icon>Smart Bean Transfer
    </button>
    <button mat-menu-item [matMenuTriggerFor]="userMenus" [matMenuTriggerData]="{row}">
      <mat-icon>apps</mat-icon>User Functions
    </button>
  </ng-template>
</mat-menu>

<mat-menu class="text-white" #userMenus="matMenu">
  <ng-template matMenuContent let-row="row">
    <div class="mx-3">
      <div class="my-3">AIClothing business auction</div>
      <button class="m-2 rounded-2" mat-menu-item [routerLink]="['/clothing/tryon', {user: row.id}]">Free pose</button>
      <button class="m-2 rounded-2" mat-menu-item [routerLink]="['/clothing/model', {user: row.id}]">Fixed pose real model picture</button>
      <button class="m-2 rounded-2" mat-menu-item [routerLink]="['/clothing/mannequin', {user: row.id}]">Fixed pose mannequin</button>
    </div>
  </ng-template>
</mat-menu>
