<div class="image-generating" *ngIf="generating">
  <img src="{{ baseUrl }}assets/images/progress-bar.gif" />
</div>
<div class="d-flex w-100 h-100" *ngIf="hasError">
  <div class="m-auto">Generating failed</div>
</div>
<div class="mh-100 mw-100 m-auto" *ngIf="!!value.url" [ngClass]="{ 'background-alpha' : useBackgroundAlpha}">
  <div class="w-100 h-100 position-relative">
    <img [src]="value.url" />
    <element-floatable *ngIf="actionBarVisible">
      <div class="top-left d-flex">
        <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="Like"
          (click)="userFeedbackClick('0000')" [disabled]="!!value.thumbUp">
          <mat-icon>thumb_up</mat-icon>
        </button>
        <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="Negative reviews"
          (click)="userFeedbackClick('1000')" [disabled]="!!value.thumbDown">
          <mat-icon>thumb_down</mat-icon>
        </button>
      </div>
       <div class="bottom-left">
        <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="enlarge"
          (click)="zoomoutPaintedImageClick()">
          <mat-icon>zoom_out_map</mat-icon>
        </button>
      </div> 
      <div class="bottom-right d-flex">
        <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="download"
          (click)="exportPaintedImageClick()" *ngIf="!showTiffMenu">
          <mat-icon>save_alt</mat-icon>
        </button>

        <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="download"
          [matMenuTriggerFor]="tiffMenu" *ngIf="showTiffMenu">
          <mat-icon>save_alt</mat-icon>
        </button>

        <mat-menu #tiffMenu="matMenu">

          <div class="container p-2" (click)="handleExportContainClick($event)">
            
              <image-format-input [imageFormat]="'RGB'" (imageFormatChange)="downloadFormatChanged($event)"/>

              <div class="m-2" *ngIf="curDownloadFormat==='CMYK'"></div>

              <image-dpi-input [dpi]="'300'" (dpiChange)="downloadResolutionChanged($event)" *ngIf="curDownloadFormat==='CMYK'"/>

              <div class="mb-3"> </div>

              <div class="d-flex justify-content-start px-2">
                <button mat-raised-button class="export-button" color="primary" (click)="exportClick()">Download Now</button>
              </div>

          </div>

        </mat-menu>

        <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="Save to the History Library"
          (click)="saveToUserAssetClick()" [class.d-none]="!enableSave">
          <mat-icon>photo_libaray</mat-icon>
        </button>
      </div>
    </element-floatable>
    <button mat-stroked-button class="position-absolute top-0 end-0 btn-md bg-white m-1" color="primary"
      (click)="editClick()" *ngIf="editable">
      <img src="/assets/images/image-generate0040.png" />
      <span>Continue to refine</span>
    </button>
  </div>
</div>
