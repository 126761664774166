import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-complexion-input',
  templateUrl: './image-complexion-input.html',
})
export class ImageComplexionInput implements OnInit {
    complexions: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'White', value: 'White', selected: false },
    { key: 'Black person', value: 'Black person', selected: false },
    { key: 'Yellow people', value: 'Yellow people', selected: false },
    { key: 'other', value: 'other', selected: false },
  ];
  _complexion?: string;
  @Input() set complexion(value: string | undefined) {
    this._complexion = value;
    let item = this.complexions.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.complexions.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get complexion() {
    return this._complexion;
  }

  @Output() complexionChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  complexionClick(selectedComplexion: SelectListItem) {
    this.complexion = selectedComplexion.key;
    this.complexionChange.emit(this.complexion);
  }

}
