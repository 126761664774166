import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayerDialog } from '../video-player-dialog/video-player-dialog';

@Component({
  selector: 'user-manual',
  templateUrl: './user-manual.component.html',
  styleUrl: './user-manual.component.scss',
})
export class UserManualComponent implements OnInit {
  categories = [
    {
      name: 'Fashion Photoshoot',
      manuals: [
        { title: 'Free pose', url: '/assets/videos/clothing/clothing-free-tryon.mp4', },
        { title: 'Real person picture', url: '/assets/videos/clothing/change-model.mp4', },
        { title: 'Figure', url: '/assets/videos/clothing/change-mannequin.mp4', },
      ],
    }
  ];

  constructor(private dialog: MatDialog,) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  playClick(manual: any) {
    this.dialog.open(VideoPlayerDialog, {
      // width: width,
      // height: height,
      maxWidth: '96vw',
      maxHeight: '96vh',
      data: manual,
    });
  }

}
