import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserFunctionSiteMap, UserNotification, UserNotificationCategory } from '../models';
import { RxStompService } from '../stomp/rx-stomp.service';
import { IMessage } from '@stomp/rx-stomp';
import { ActionResult, AppHttpClient } from './app.http';
import { PricingCaculatorService } from '../services/pricing.service';
import { IUser, LoginResult, UserFunction } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private RETURN_URL: string = 'returnUrl';
  private USER_IDENTITY: string = 'userIdentity';
  private SERVICE_CATALOG: string = 'serviceCatalog';
  private SESSION_PREFIX: string = 'wefocus.ai.';
  private regexSessionPrefix: RegExp = new RegExp('^' + this.SESSION_PREFIX, 'i');

  private _userSubject: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
  private pointsSubscription?: Subscription;

  constructor(private router: Router,
    private http: AppHttpClient,
    private injector: Injector) {
    //this.reuseIdentityIfExists();
  }

  public canAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (this.isAuthenticated) {
      return true;
    }
    return this.router.navigate(['/account/login']);
  }

  get isAuthenticated(): boolean {
    if (!!this.user) {
      return true;
    }

    var user = this.getItem(this.USER_IDENTITY);
    if (!!user?.token) {
      this._userSubject.next(user);

      let pricingCaculator = this.injector.get(PricingCaculatorService);
      pricingCaculator.serviceCatalog = this.getItem(this.SERVICE_CATALOG);

      return true;
    }

    return false;
  }

  getUser(): Observable<IUser | null> {
    return this._userSubject.asObservable();
  }

  get user(): IUser | null {
    return this._userSubject.value;
  }

  set user(value: IUser | null) {
    this.setItem(this.USER_IDENTITY, value);
    this._userSubject.next(value);
  }

  clear() {
    var keys = Object.keys(sessionStorage)
    for (let i = 0; i < keys.length; i++) {
      if (this.regexSessionPrefix.test(keys[i])) {
        sessionStorage.removeItem(keys[i]);
      }
    }
  }

  login(loginResult: LoginResult) {
    let pricingCaculator = this.injector.get(PricingCaculatorService);
    pricingCaculator.serviceCatalog = loginResult.serviceCatalog;
    this.setItem(this.SERVICE_CATALOG, pricingCaculator.serviceCatalog);

    var user: IUser = {
      id: loginResult.id,
      username: loginResult.username,
      nickname: loginResult.nickname,
      token: loginResult.token,
      xsrfToken: loginResult.xsrfToken,
      points: loginResult.points,
      totalPoints: loginResult.totalPoints,
      accountType: loginResult.accountType,
      memberStartDate: loginResult.memberStartDate,
      memberEndDate: loginResult.memberEndDate,
      userType: loginResult.userType,
      permissionGroup: loginResult.permissionGroup,
      permissions: loginResult.permissions,
      costType: loginResult.costType,
      hasPassword: loginResult.hasPassword,
      identities: loginResult.identities,
      chargeConfirm: loginResult.chargeConfirm,
    };
    this.setItem(this.USER_IDENTITY, user);
    this._userSubject.next(user);

    let rxStompService = this.injector.get(RxStompService);
    if (!this.pointsSubscription) {
      this.pointsSubscription = rxStompService.watch(UserNotificationCategory.Points)
        .subscribe((message: IMessage) => {
          var notification = JSON.parse(message.body) as UserNotification;
          var result = notification.data as ActionResult;
          if (!this.http.isValidResult(result)) {
            return;
          }
          let u = this.user;
          if (!!u) {
            u.points = result.data;
            this.user = u;
          }
        });
    }
    rxStompService.activate();
  }

  logout() {
    this.removeItem(this.USER_IDENTITY);
    this.removeItem(this.SERVICE_CATALOG);
    this._userSubject.next(null);
    this.clear();
    // this.navigateToReturnUrl();
    this.pointsSubscription?.unsubscribe();
    this.pointsSubscription = undefined;
    let rxStompService = this.injector.get(RxStompService);
    rxStompService.deactivate();
  }

  get isAdmin() {
    return this.user?.permissionGroup == PermissionGroup.Admin;
  }

  hasPermission(functionId: UserFunction): boolean {
    let user = this.user;
    if (this.isAdmin) {
      return true;
    }
    if (!user) {
      return false;
    }
    if (!user.permissions) {
      return false;
    }
    return !!user.permissions.find(o => o.functionId == functionId);
  }

  get isCostTypeTimes(): boolean {
    let user = this.user;
    return user?.costType == CostTypes.Times;
  }

  get isCostTypePeriod(): boolean {
    let user = this.user;
    return user?.costType == CostTypes.Period;
  }

  async navigateToReturnUrl() {
    let returnUrl = this.getItem(this.RETURN_URL);
    this.removeItem(this.RETURN_URL);
    if (!!returnUrl) {
      await this.router.navigateByUrl(returnUrl);
      return;
    }
    if (this.isAdmin) {
      returnUrl = '/';
    } else {
      returnUrl = '/home';
      let user = this.user;
      let func = user?.permissions.find(o => o.functionId == UserFunction.UserAssets);
      if (!func) {
        let funcId = user?.permissions[0].functionId;
        if (!!funcId) {
          returnUrl = UserFunctionSiteMap.get(funcId);
        }
      }
    }
    await this.router.navigateByUrl(returnUrl);
  }

  private getSessionKey(key: string) {
    return this.SESSION_PREFIX + key;
  }

  public setItem(key: string, value: any): void {
    if (value == undefined) {
      value = null;
    }
    sessionStorage.setItem(this.getSessionKey(key), JSON.stringify(value));
  }

  public getItem(key: string): any {
    let item = sessionStorage.getItem(this.getSessionKey(key));
    if (!item) {
      return item;
    }
    return JSON.parse(item);
  }

  public removeItem(key: string): void {
    sessionStorage.removeItem(this.getSessionKey(key));
  }
}

enum AccountTypes {
  Golden = 'golden',
  Silver = 'silver',
  Basic = 'basic',
  Free = 'free',
}

enum PermissionGroup {
  Admin = 'admin',
  Free = 'free',
}

enum CostTypes {
  Times = 'times',
  Period = 'period',
}
