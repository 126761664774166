<mat-sidenav-container class="h-100">
  <mat-sidenav class="side-bar" #sidenav mode="side" opened="true">
    <mat-nav-list>
      <a class="menu-item" *ngFor="let uuid of uuidList; let i = index" (click)="navClick(uuid, i)"
        [class.active]="i === curindex">
        <span>{{i}}</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [class.d-none]="resultList.length != 0">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. " #input>
      </mat-form-field>

      <table mat-table [dataSource]="dataSource!" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="uuid">
          <th mat-header-cell *matHeaderCellDef> workflow </th>
          <td mat-cell *matCellDef="let element"> {{element.uuid}} </td>
        </ng-container>

        <ng-container matColumnDef="betterCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> good </th>
          <td mat-cell *matCellDef="let element"> {{element.betterCount | percent}} </td>
        </ng-container>

        <ng-container matColumnDef="equalCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> middle </th>
          <td mat-cell *matCellDef="let element"> {{element.equalCount | percent}} </td>
        </ng-container>

        <ng-container matColumnDef="worseCount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Difference </th>
          <td mat-cell *matCellDef="let element"> {{element.worseCount | percent}} </td>
        </ng-container>

        <ng-container matColumnDef="feedbackDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> date </th>
          <td mat-cell *matCellDef="let element"> {{element.feedbackDate}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>

      <div class="chart-area" *ngFor="let item of chartData; let i = index">
        <ngx-charts-bar-vertical [view]="[600, 400]" [results]="chartData[i]" [xAxis]="true" [yAxis]="true"
          [yAxisLabel]="'Good proportion'" [showYAxisLabel]="true" [showDataLabel]="true">
        </ngx-charts-bar-vertical>
        <ngx-charts-bar-vertical [view]="[600, 400]" [results]="qChartData[i]" [xAxis]="true" [yAxis]="true"
          [yAxisLabel]="'good+Middle proportion'" [showYAxisLabel]="true" [showDataLabel]="true">
        </ngx-charts-bar-vertical>
        <h1 class="chart-font">{{paramName[i]}}</h1>
      </div>

    </div>

    <div [class.d-none]="resultList.length === 0">
      <div class="row">
        <div class="col-2" *ngFor="let item of inputList; let i = index">
          <div class="d-flex flex-column">
            <span>{{getSuffix(item.path!)}}</span>
            <div class="ratio ratio-1x1">
              <img [src]="item.url" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3" *ngFor="let item of resultList; let i = index">
          <div class="d-flex flex-column">
            <!--<span>{{item.path}}</span>-->
            <img class="result-image" [src]="item.url" />
            <form [formGroup]="evaForm[i]">
              <mat-radio-group formControlName="eva">
                <mat-radio-button value="1">good</mat-radio-button>
                <mat-radio-button value="2">middle</mat-radio-button>
                <mat-radio-button value="3">Difference</mat-radio-button>
              </mat-radio-group>

            </form>
          </div>
        </div>
      </div>

      <div class="row">
        <button mat-button (click)="nextClick()"><span>Next group</span></button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
