<div mat-dialog-title>
  {{!id.value?'Add a new image group':'Rename image groups'}}
</div>

<mat-dialog-content>
  <form [formGroup]="inputForm">
    <mat-form-field>
      <mat-label>name</mat-label>
      <input matInput formControlName="name" maxlength="32">
      <mat-error *ngIf="inputForm.controls['name'].invalid">required.</mat-error>
    </mat-form-field>
  </form>
  
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancelClick()">Cancel</button>
  <button mat-flat-button color="primary" (click)="saveClick()">keep</button>
</mat-dialog-actions>
