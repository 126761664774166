import { Component, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { JobSubTypes, JobTypes, TaskCategory } from '../models';
import { validRange } from '../common/app.forms';
import { PaintService } from '../services/paint.service';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MaterialTemplateListItem, UserOperationListItem } from '../interfaces';
// import { PaintedImageList } from '../components/painted-image-list';
import { aspectToSize } from '../helpers/image-helper';
import { UserTaskList } from '../user-task/user-task-list';
import { UserOperationList } from '../user-operation/user-operation-list';
import { MessageResource } from '../common/message.service';

@Component({
  selector: 'text-to-image',
  templateUrl: './text-to-image.component.html',
  styleUrl: './text-to-image.component.scss',
})
export class TextToImageComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;
  JobTypesEnum = JobTypes;
  JobSubTypesEnum = JobSubTypes;
  expanded: boolean = true;
  expanded2: boolean = true;
  @ViewChild('userTaskList') userTaskList!: UserTaskList;

  selectedTemplateId?: number;
  aspect: string = '16:9';
  // resolution: string = "1500*1500";
  prompts: string = '';
  negativePrompts: string = '';
  // paintedImages: PaintedImage[] = [];
  // @ViewChild('paintedImageList') paintedImageList!: PaintedImageList;
  @ViewChild('userOperationList') userOperationList!: UserOperationList;

  minQuantity: number = 1;
  maxQuantity: number = 4;
  quantity: string = '4';

  segmenting: boolean = false;

  constructor(injector: Injector,
    private http: AppHttpClient,
    private paintService: PaintService,) {
    super(injector);
    this.reset();
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  createObjectURL(data: ArrayBuffer, type: string) {
    return URL.createObjectURL(new Blob([data], { type: type }));;
  }

  // refactorClick(image: PaintedImage) {
  //   this.router.navigate(['../refactor', { imageUrl: image.url }], { relativeTo: this.route });
  // }

  generateClick() {
    if (!this.isInputValid()) {
      return;
    }

    this.generate();
  }

  generate() {
    if (this.userOperationList.processing) {
      this.messageService.showWarning(MessageResource.WAITING_FOR_PROCESS);
      return;
    }

    this.expanded = false;

    let formData = new FormData();
    formData.set('prompts', this.prompts);
    formData.set('negativePrompts', this.negativePrompts);
    let size = aspectToSize(this.aspect);
    if (size) {
      formData.set('width', size.width.toString());
      formData.set('height', size.height.toString());
    }
    if (!!this.aspect) {
      formData.set('aspect', this.aspect);
    }
    formData.set('quantity', this.quantity);
    formData.set('mode', 'txt2img');
    formData.set('taskId', this.taskId!.toString());

    // let paintedImages = this.paintService.generate(formData);
    // let zone = this.injector.get(NgZone);
    // zone.run(() => {
    //   this.paintedImages = paintedImages;
    //   // this.paintedImageList.caculateGrid(this.quantity, this.aspect);
    // });
    let operation = this.paintService.execute(formData, (o) => {
      this.saveOperationState(o.id!);
    });
    let zone = this.injector.get(NgZone);
    zone.run(() => {
      this.userOperationList.addOperation(operation);
      this.scrollToTop();
    });
  }

  // onPaintCompleted() {
  //   let paintedImages = this.paintedImages;
  //   if (!paintedImages) {
  //     return;
  //   }

  //   for (let i = 0; i < paintedImages.length; i++) {
  //     if (paintedImages[i].result?.status == ActionStatus.Running) {
  //       return;
  //     }
  //   }

  //   this.saveTaskState();
  // }

  isInputValid(): boolean {
    if (!this.prompts) {
      this.showError("Please enter a description of the scene。")
      return false;
    }
    if (!this.aspect) {
      this.showError("Please select the image ratio。")
      return false;
    }
    if (!validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return false;
    }

    return true;
  }

  onMaterialSelected(template: MaterialTemplateListItem) {
    this.selectedTemplateId = template.id;
    this.http.get(`/material/templates/${template.id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        var data = result.data;
        this.prompts = data.prompts;
        this.negativePrompts = data.negativePrompts;
      }
    });
  }

  imageAspectChanged(aspect: string) {
    this.aspect = aspect;
  }

  reset() {
    this.selectedTemplateId = undefined;
    this.prompts = '';
    this.negativePrompts = '';
    this.aspect = '16:9';
    this.quantity = '4';
    // this.paintedImages = [];
    this.segmenting = false;
  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  saveOperationState(id: number): void {
    this.patchOperationState(id, this.buildState());
  }

  override saveTaskState(): void {
    this.patchTaskState(this.buildState());
  }

  buildState(): any {
    let params = [];

    params.push({
      selectedTemplateId: this.selectedTemplateId,
      prompts: this.prompts,
      negativePrompts: this.negativePrompts,
      aspect: this.aspect,
      quantity: this.quantity,
      // paintedImages: this.paintedImages,
    });

    return params;
  }

  override restoreTaskState(): void {
    // let taskStateUrl = this.taskStateUrl;
    this.fetchTaskState().subscribe((result: any) => this.applyState(result));
  }

  applyState(state: any) {
    this.reset();
    if (!state) {
      return;
    }
    setTimeout(() => {
      this.selectedTemplateId = state.selectedTemplateId;
    }, 0);
    this.prompts = state.prompts;
    this.negativePrompts = state.negativePrompts;
    this.aspect = state.aspect;
    this.quantity = state.quantity;
    // this.paintedImages = PaintedImage.fromArray(result.paintedImages ?? [], this.injector);
    // this.paintedImageList.caculateGrid(this.quantity, this.aspect);
  }

  cloneClick(operation: UserOperationListItem) {
    this.fetchOperationState(operation.id!).subscribe((result: any) => this.applyState(result));
    this.scrollToTop();
  }
}

