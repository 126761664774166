import { Sticker } from "./models";

export interface ModelScaleProps {
  samScale: number;
  height: number;
  width: number;
}

export interface ModelInputProps {
  x: number;
  y: number;
  width: null | number;
  height: null | number;
  clickType: number;
}

export interface PicturePaintInput {
  id: number;
  prompts: string;
  negativePrompts: string;
  name: string;
  templateId: number;
  imageFile: File;
  maskFile: File;
  width: number;
  height: number;
  aspect: string;
  quantity: number;
}

export interface Point {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface UserAssetCategoryListItem {
  id: string;
  name: string;
  groups: UserAssetGroupListItem[];
  selected: boolean;
}

export interface UserAssetGroupListItem {
  id: number;
  category: string; // category_id
  name: string;
  type?: string;
  assets: UserAssetListItem[];
  selected: boolean;
}

export interface UserAssetListItem {
  id: number;
  name: string;
  url: string;
  selected: boolean;
}

export interface UserAssetDetails {
  id: number;
  userId?: number;
  groupId?: number;
  name?: string;
  assetType?: string;
  relatedId?: number;
  width?: number;
  height?: number;
}

export interface MaterialCategoryListItem {
  id: number;
  name: string;
  templates: MaterialTemplateListItem[];
}

export interface MaterialSearchResults {
  id: number;
  name: string;
  categoryName: string;
}

export interface MaterialTemplateListItem {
  id: number;
  name: string;
  selected: boolean;
  favorite: number | undefined;  // favorite_id
  category: number;
}

export interface MaterialTemplateDetails {
  id?: number;
  category?: number;
  name?: string;
  prompts?: string;
  negativePrompts?: string;
  promptsEnglish?: string;
  negativePromptsEnglish?: string;
  url?: string;
  width?: number;
  height?: number;
}

export interface UserTaskListItem {
  id: number;
  name?: string;
  url?: string;
  selected?: boolean;
}

export interface UserTaskDetails {
  id?: number;
  name?: string;
  category?: string;
  data?: string;
  thumbnail?: string;
}

export interface UserOperationListItem {
  id?: number;
  modifiedDate?: number;
  hasState?: boolean;
  jobs?: Array<UserJobListItem>;
}

export interface UserJobListItem {
  id?: number;
  type?: string;
  subType?: string;
  status?: string;
  modifiedDate?: number;
  url?: string;
  thumbnailUrl?: string;
  imageWidth?: number;
  imageHeight?: number;
  favoriteRate?: string;
  favoriteDate?: string;
  feedbackRate?: number;
  parent?: Array<UserJobListItem>;
  statusDesc?: string;
}

export enum PathTypes {
  FreeLine = 'free',
  Rect = 'rect',
  Circle = 'circle',
  EraseBrush = 'brush',
  Sticker = 'sticker',
}

export enum PaintMode {
  Remove = 'remove',
  Replace = 'replace',
  Add = 'add',
  Zoom = 'zoom',
  Merge = 'merge',
}

export enum ImageGenerateSteps {
  Step0010 = 0, // image select
  Step0020 = 1, // generate input
  Step0030 = 2, // generated
  Step0040 = 3, // refact input
  Step0050 = 4, // refacted
}

export enum UserFunction {
  MaterialTemplates = 'material_templates',
  UserAssets = 'user_assets',
  ImageDesign = 'image_design',
  Text2Image = 'text2image',
  ImageSimulator = 'image_simulator',
  ClothDesign = 'cloth_design',
  FaceReplace = 'face_replace',
  ClothReplace = 'cloth_replace',
  ToolList
  = 'tool_list',
}

export enum FavoriteTypes {
  unlike = '0',
  like = '1',
}

export interface testItem {
  path?: string;
  url?: string;
}

export interface testForder {
  fileList: testItem[];
}

export interface evaFeedback {
  uuid: string;
  betterCount: number;
  equalCount: number;
  worseCount: number;
  feedbackDate: Date;
}

export interface LoginResult {
  id: number;
  username: string;
  nickname: string;
  token: string;
  xsrfToken: string;
  points: number;
  totalPoints: number;
  accountType: string;
  memberStartDate: string;
  memberEndDate: string;
  userType: string;
  serviceCatalog: { [key: string]: { [key: string]: IServiceCatalogListItem } };
  permissionGroup: string;
  permissions: Array<IUserPermissionListItem>;
  costType: string;
  hasPassword?: boolean;
  identities: Array<UserIdentity>;
  chargeConfirm: boolean;
}

export interface IUser {
  id: number;
  username: string;
  nickname: string;
  token: string;
  xsrfToken: string;
  points: number;
  totalPoints: number;
  accountType: string;
  memberStartDate: string;
  memberEndDate: string;
  userType: string;
  permissionGroup: string;
  permissions: Array<IUserPermissionListItem>;
  costType: string;
  hasPassword?: boolean;
  identities: Array<UserIdentity>;
  chargeConfirm: boolean;
}

export interface IUserPermissionListItem {
  functionId: UserFunction;
  level: string;
}

export interface UserIdentity {
  id: number;
  username: string;
  nickname: string;
  active: boolean;
}

export interface IServiceCatalogListItem {
  id: string;
  category: string;
  item: string;
  price: number;
}

export interface UserInfo {
  id: number;
  username: string;
  nickname: string;
  type: string;
  companyName: string;
  mobilePhone: string;
  status: string;
}

export interface UserListItem {
  id: number;
  username: string;
  nickname: string;
  type: string;
  points: number;
  totalPoints: number;
  createdDate: string;
  lastLoginDate: string;
  companyName: string;
  mobilePhone: string;
  status: string;
}

export interface CompanyListItem {
  id: number;
  username: string;
  nickname: string;
  type: string;
  points: number;
  totalPoints: number;
  createdDate: string;
  lastLoginDate: string;
  mobilePhone: string;
  status: string;
}

export interface OrderListItem {
  id: number;
  orderNo: string;
  username: string;
  description: string;
  amount: number;
  points: number;
  payType: string;
  payAccount: string;
  createdDate: string;
  status: string;
  employeeName: string;
}

export interface OrderItem {
  id: number;
  orderNo: string;
  itemType: string;
  amount: number;
  points: number;
  startDate: string;
  endDate: string;
  description: string;
  modifiedDate: number;
}

export interface UserInfo {
  id: number;
  username: string;
  nickname: string;
  type: string;
  status: string;
  mobilePhone: string;
  modifiedDate: number;
}

export interface CompanyInfo {
  id: number;
  username: string;
  nickname: string;
  type: string;
  status: string;
  mobilePhone: string;
  modifiedDate: number;
  users: Array<UserInfo>;
}

export interface UserTransactionListItem {
  id: number;
  type: string;
  occurredTime: string;
  usedPoints: number;
  points: number;
  status: string;
  statusName: string;
  description: string;
  relatedId: string;
}

export interface UserOrderListItem {
  id: number;
  subId: number;
  orderNo: string;
  status: string;
  statusName: string;
  payReceivedDate: string;
  itemType: string;
  itemTypeName: string;
  amount: number;
}