import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserTransactionListItem } from '../interfaces';

@Component({
  selector: 'my-transaction-list',
  templateUrl: './my-transaction-list.html',
  styleUrl: './my-transaction-list.scss',
})
export class MyTransactionList implements OnInit, AfterViewInit {
  // startIndex: number = 0;
  // count: number = 20;
  loading = true;
  displayedColumns: string[] = ['occurredTime', 'usedPoints', 'points', 'statusName', 'description'];
  dataSource = new MatTableDataSource<UserTransactionListItem>([]);
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {
    this.fetchTransactions();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  hasMore = false;
  private fetchTransactions() {
    if (this.dataSource.data.length == 0) {
      this.loading = true;
    }
    var params = {
      startIndex: this.dataSource.data.length, //this.startIndex,
      // count: this.count,
    }
    this.http.post('/user/transactions/fetch', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dataSource.data = [...this.dataSource.data, ...result.data.transactions];
        this.hasMore = result.data.hasMore;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  fetchMoreClick() {
    this.fetchTransactions();
  }
}
