<div class="m-2" *ngIf="favorites.length>0">
  <div class="d-flex align-items-center m-2">
    <mat-icon color="accent" svgIcon="app:my_favorites"></mat-icon>
    <span class="mx-2">My Collection</span>
  </div>
  <div class="favorite-container">
    <div class="favorite-item" *ngFor="let job of favorites">
      <user-job-item [value]="job"></user-job-item>
    </div>
  </div>
  <div class="text-end" *ngIf="favorites.length>0 && hasMore">
    <button mat-button class="fw-bold" color="primary" (click)="fetchMoreClick()">Show more...</button>
  </div>
</div>
