import { RxStompService } from './rx-stomp.service';
import { SessionService } from '../common/session.service';
import { RxStomp, RxStompConfig } from '@stomp/rx-stomp';
import { environment } from 'src/environments/environment';

export function rxStompServiceFactory(sessionService: SessionService) {
  

  let rxStompConfig: RxStompConfig = {
    // Which server?
    brokerURL: environment.wsServerUrl,
  
    // How often to heartbeat?
    // Interval in milliseconds, set to 0 to disable
    heartbeatIncoming: 0, // Typical value 0 - disabled
    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds
  
    // Wait in milliseconds before attempting auto reconnect
    // Set to 0 to disable
    // Typical value 500 (500 milli seconds)
    reconnectDelay: 10000,

    // Set it to log the actual raw communication with the broker.
    // When unset, it logs only the headers of the parsed frames.
    logRawCommunication: true,
  
    // Will log diagnostics on console
    // It can be quite verbose, not recommended in production
    // Skip this key to stop logging to console
    debug: (msg: string): void => {
      console.log(new Date(), msg);
    },
    beforeConnect: (client:RxStomp) => {
      let token = sessionService.user?.token;
      client.stompClient.connectHeaders['Authorization'] = `Bearer ${token}`;
    },
  };

  let brokerUrl = rxStompConfig.brokerURL;
  if(brokerUrl?.startsWith('/')){
    rxStompConfig.brokerURL = `${window.location.protocol.replace('http', 'ws')}//${window.location.host}${brokerUrl}`;
  }

  const rxStomp = new RxStompService();
  rxStomp.configure(rxStompConfig);
  // rxStomp.configure({...myRxStompConfig,
  //   connectHeaders: {
  //     Authorization: `Bearer ${token}`
  //   }
  // });
  // rxStomp.activate();
  return rxStomp;
}