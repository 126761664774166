<div class="flex-grow-1"></div>
<div class="flex-grow-1"></div>

<div class="d-flex flex-row">
  <div class="flex-grow-1"></div>
  <div class="flex-lg-grow-1"></div>
  <div class="flex-lg-grow-1"></div>
  <div class="flex-lg-grow-1"></div>
  <mat-card class="user-signin" *ngIf="authStep == AuthStepEnum.UserSigin">

    <mat-card-content class="p-1 p-lg-3">

      <div class="logo">
        <img src="{{baseUrl}}assets/images/logo.svg">
      </div>

      <mat-tab-group dynamicHeight fitInkBarToContent="true">
        <mat-tab *ngIf="environmentEnum.authByPhoneNumber">
          <ng-template mat-tab-label>
            <h2 class="mt-0 mb-2">SMS login</h2>
          </ng-template>
          <form [formGroup]="phoneform" novalidate class="mt-5">
            <mat-form-field>
              <mat-label>Please enter your mobile number</mat-label>
              <span class="hint-text" matPrefix>+86&nbsp;&nbsp;&nbsp;</span>
              <input matInput formControlName="phone" maxlength="11">
              <!-- <mat-error
                *ngIf="phoneform.controls['phone'].hasError('required') && phoneform.controls['phone'].touched">
                Please enter your mobile number</mat-error> -->
              <mat-error *ngIf="phoneform.controls['phone'].hasError('pattern') && phoneform.controls['phone'].touched">
                Please enter your phone number in the correct format</mat-error>
            </mat-form-field>
            <mat-form-field class="otp-form">
              <mat-label>Please enter the verification code you received</mat-label>
              <div class="d-flex flex-row">
                <input matInput formControlName="otp" maxlength="6">
                <button mat-button color="primary" class="otp-button" (click)="sendOtp($event)"
                  [disabled]="optCountdown > 0">
                  <span class="mat-body-1">Get verification code</span>
                  <span class="mat-body-1" *ngIf="optCountdown > 0">({{ optCountdown }} s)</span>
                </button>
              </div>
              <!-- <mat-error
                *ngIf="phoneform.controls['otp'].hasError('required') && phoneform.controls['otp'].touched">Please enter the verification code</mat-error> -->
              <mat-error
                *ngIf="phoneform.controls['otp'].hasError('pattern') && phoneform.controls['otp'].touched">Please enter the verification code in the correct format</mat-error>
            </mat-form-field>
            <div class="my-3 mat-body hint-text">
              <!-- <mat-checkbox [color]="checkboxColor" formControlName="checkbox"
                (change)="checkboxChange($event.checked)"> -->
              Log in/By registering you agree<a href="{{baseUrl}}/account/useragent" class="text-primary" target="_blank"> User Agreement </a>
              and<a href="{{baseUrl}}/account/privacypolicy" class="text-primary" target="_blank"> Privacy Policy </a>
              <!-- </mat-checkbox> -->
              <mat-error class="mat-small" *ngIf="checkboxError">
                Please tick the box to agree to the terms and conditions</mat-error>
            </div>
          </form>
          <div class="row mt-4">
            <div class="w-100">
              <button mat-flat-button class="w-100 login-button" color="primary" (click)="loginViaPhone()">
                <h3 class="my-0">Login</h3>
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="w-100">
              <button mat-button class="w-100 login-button" color="primary" routerLink="/account/register">
                <h4 class="my-0">Register</h4>
              </button>
            </div>
          </div>
        </mat-tab>

        <mat-tab *ngIf="environmentEnum.authByUserName">
          <ng-template mat-tab-label>
            <h2 class="mt-0 mb-2">Account Login</h2>
          </ng-template>
          <form [formGroup]="passwordform" novalidate class="mt-5">
            <mat-form-field>
              <mat-label>account</mat-label>
              <input matInput formControlName="username" maxlength="24">
              <!-- <mat-error
                *ngIf="passwordform.controls['username'].hasError('required') && passwordform.controls['username'].touched">
                Please enter your account number</mat-error> -->
            </mat-form-field>
            <mat-form-field>
              <mat-label>password</mat-label>
              <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" maxlength="64">
              <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <!-- <mat-error
                *ngIf="passwordform.controls['password'].hasError('required') && passwordform.controls['password'].touched">Please enter your password</mat-error> -->
            </mat-form-field>
          </form>
          <div class="d-flex">
            <!-- <div>
              <mat-checkbox [(ngModel)]="rememberPassword" [color]="checkboxColor">Remember Password</mat-checkbox>
            </div> -->
            <div class="flex-grow-1"></div>
            <div>
              <a mat-button color="primary" routerLink="/account/password/reset">
                <span class="mat-body my-0">
                  forget the password？
                </span>
              </a>
            </div>
          </div>
          <div class="row mt-4">
            <div class="w-100">
              <button mat-flat-button class="w-100 login-button" color="primary" (click)="loginViaPassword()">
                <h3 class="my-0">Login</h3>
              </button>
            </div>
          </div>
          <div class="row mt-2" *ngIf="environmentEnum.authByPhoneNumber">
            <div class="w-100">
              <button mat-button class="w-100 login-button" color="primary" routerLink="/account/register">
                <h4 class="my-0">Register</h4>
              </button>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="row justify-content-center mt-4 mb-3 d-none">
        <div class="col-md-8 text-center">
          <div class="d-flex align-items-center justify-content-center">
            <div class="gradient-line-r"></div>
            <p class="text mx-3 mb-0 hint-text">Other login methods</p>
            <div class="gradient-line-l"></div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-4 mb-5 d-none">
        <div class="col-md-8 text-center">
          <a mat-button routerLink="/path1">
            <img src="{{baseUrl}}assets/images/account/alipay.png" alt="wechat">
          </a>
          <a mat-button routerLink="/path2">
            <img src="{{baseUrl}}assets/images/account/wechat.png" alt="alipay">
          </a>
        </div>
      </div>

    </mat-card-content>
  </mat-card>

  <mat-card class="identity-confirm" *ngIf="authStep == AuthStepEnum.IdentityConfirm">
    <div class="d-flex">
      <div class="d-flex align-items-center p-2 rounded-1 text-accent fs-smaller cursor-pointer" matRipple
        (click)="backToSignin()">
        <mat-icon>navigate_before</mat-icon>
        <span class="pe-3">return</span>
      </div>
    </div>
    <mat-card-content>
      <div class="fs-5 text-white my-2">You can choose the following identities</div>
      <div class="text-accent mb-2">Because your team has changed，Please re-select your login identity</div>

      <div class="d-flex align-items-center my-2 p-2 rounded-1 cursor-pointer" [class.selected]="identity.active"
        matRipple *ngFor="let identity of identities" (click)="changeIdentity(identity)">
        <div>
          <img class="icon-md mx-2" src="{{baseUrl}}assets/images/user.png" />
        </div>
        <div class="flex-grow-1 lh-sm">
          <div class="text-white">{{identity.nickname}}</div>
          <div class="fs-smaller text-accent">{{identity.username}}</div>
        </div>
        <div *ngIf="identity.active">
          <mat-icon class="icon-sm" color="primary">done</mat-icon>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="flex-grow-1"></div>
</div>

<div class="flex-grow-1"></div>
<div class="flex-grow-1"></div>
<div class="flex-grow-1"></div>

<div class="d-flex">
  <div class="m-auto text-white text-center mt-2 mb-4">
    <div>Copyright &#64; 2024 Wefocus.ai.</div>
  </div>
</div>
