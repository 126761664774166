import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppHttpClient } from '../common/app.http';
import { UserJobListItem } from '../interfaces';
import { JobStatus, JobSubTypes, JobTypes } from '../models';
import { JobService } from '../services/job.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'image-preview-dialog',
  templateUrl: './image-preview-dialog.html',
  styleUrl: './image-preview-dialog.scss',
})
export class ImagePreviewDialog implements OnInit {
  job: UserJobListItem;
  rawImageUrls: Array<string | undefined> = [];
  generatedImageUrl?: string;
  private jobSubscription?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<ImagePreviewDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private http: AppHttpClient,
    private jobService: JobService,) {
    this.job = data;
  }

  ngOnInit() {
    this.jobSubscription = this.jobService.subscribe(job => this.jobChanged(job));

    this.downloadRawImage(this.job);
    this.downloadGeneratedImage(this.job);
  }

  ngOnDestroy() {
    this.jobSubscription?.unsubscribe();
  }

  downloadRawImage(job: UserJobListItem) {
    if (!this.hasRawImage(job)) {
      return;
    }
    if (this.job.type == JobTypes.freeTryon && this.job.subType == JobSubTypes.both) {
      // Combination Set(AIChange Model-Free pose)
      this.rawImageUrls = [undefined, undefined];
    }else{
      this.rawImageUrls = [undefined];
    }
    
    setTimeout(() => {
      this.rawImageUrls.forEach((value, index) => {
        let url = `/job/${this.job.id}/raw-image${index > 0 ? '?index=' + (index + 1) : ''}`;
        this.http.get(url, { responseType: 'blob' }).subscribe({
          next: (result: Blob) => {
            this.rawImageUrls[index] = URL.createObjectURL(result);
          },
        });
      });
    }, 0);
  }

  hasRawImage(job: UserJobListItem): boolean {
    if (this.job.type == JobTypes.paint && this.job.subType == JobSubTypes.txt2img) {
      // No original image when creating a new image
      return false;
    }
    return true;
  }

  downloadGeneratedImage(job: UserJobListItem) {
    this.http.get(`/job/${job.id}/download`, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.generatedImageUrl = URL.createObjectURL(result);
      },
    });
  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }

  get hasRelatedJobs(): boolean {
    return (this.job.parent ?? []).length > 1;
  }

  get hasPreviousJob(): boolean {
    return this.findPreviousJob() != undefined;
  }

  get hasNextJob(): boolean {
    return this.findNextJob() != undefined;
  }

  findPreviousJob(): UserJobListItem | undefined {
    let relatedJobs = this.job.parent ?? [];
    for (let i = 0; i < relatedJobs.length; i++) {
      if (relatedJobs[i].id != this.job.id) {
        continue;
      }
      for (; i > 0; i--) {
        if (relatedJobs[i - 1].status != JobStatus.success) {
          continue;
        }
        return relatedJobs[i - 1];
      }
      break;
    }
    return undefined;
  }

  findNextJob(): UserJobListItem | undefined {
    let relatedJobs = this.job.parent ?? [];
    for (let i = 0; i < relatedJobs.length; i++) {
      if (relatedJobs[i].id != this.job.id) {
        continue;
      }
      for (; i < relatedJobs.length - 1; i++) {
        if (relatedJobs[i + 1].status != JobStatus.success) {
          continue;
        }
        return relatedJobs[i + 1];
      }
      break;
    }
    return undefined;
  }

  previousJobClick() {
    let previousJob = this.findPreviousJob();
    if (!previousJob) {
      return;
    }
    this.job = previousJob;
    this.downloadRawImage(this.job);
    this.downloadGeneratedImage(this.job);
  }

  nextJobClick() {
    let nextJob = this.findNextJob();
    if (!nextJob) {
      return;
    }
    this.job = nextJob;
    this.downloadRawImage(this.job);
    this.downloadGeneratedImage(this.job);
  }

  jobChanged(job: UserJobListItem) {
    let relatedJobs = this.job.parent ?? [];
    relatedJobs.forEach(item => {
      if (item.id != job.id) {
        return;
      }
      item.status = job.status;
    });
  }

  // get imageAspect() {
  //   let width = this.job.imageWidth;
  //   let height = this.job.imageHeight;
  //   return (!width || !height) ? 'auto' : `${this.job.imageWidth}/${this.job.imageHeight}`;
  // }

}
