import { Component, Inject } from '@angular/core';
import { UserAssetGroupListItem } from '../interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '../common/message.service';

@Component({
  selector: 'user-asset-group-picker',
  templateUrl: './user-asset-group-picker.html',
})
export class UserAssetGroupPicker {
  group?: UserAssetGroupListItem;

  constructor(private dialogRef: MatDialogRef<UserAssetGroupPicker>,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.group = data
  }

  cancelClick() {
    this.dialogRef.close();
  }

  private selectedGroup?: UserAssetGroupListItem;
  selectionChange(group: UserAssetGroupListItem) {
    this.selectedGroup = group;
  }

  confirmClick() {
    if(!this.selectedGroup){
      this.messageService.showError('Please select an image group。');
      return;
    }
    this.dialogRef.close({
      data: this.selectedGroup,
    });
  }

}

