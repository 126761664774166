import { Component, Injector } from '@angular/core';
import { SelectListItem, UserStatus, UserTypes } from '../models';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { UserPickerDialog } from '../user-picker-dialog/user-picker-dialog';
import { UserInfo } from '../interfaces';

@Component({
  selector: 'company-edit',
  templateUrl: './company-edit.component.html',
  styleUrl: './company-edit.component.scss',
})
export class CompanyEditComponent extends BaseComponent {
  statusList?: SelectListItem[];
  inputForm: FormGroup;
  canEdit = true;
  private oldUsers: UserInfo[] = [];
  newUsers: UserInfo[] = [];

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      id: new FormControl(),
      username: new FormControl('', [Validators.required]),
      password: new FormControl(''),
      nickname: new FormControl(''),
      mobilePhone: new FormControl('', [Validators.required]),
      status: new FormControl(UserStatus.validated, [Validators.required]),
      modifiedDate: new FormControl(),
    });
  };

  ngOnInit() {
    this.http.get('/companies/options').subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      this.statusList = result.data.statusList;
    });

    this.route.paramMap.subscribe(params => {
      this.init(params);
    });
  }

  init(params: any) {
    let id = params == undefined ? "" : params.get('id');

    if (!id) { //add new
      this.inputForm.patchValue({
        status: UserStatus.validated,
      });
    } else { //edit
      this.find(id);
    }
  }

  find(id: any) {
    this.http.get(`/companies/${id}/details`).subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      this.patchToForm(result.data);
    });
  };

  saveClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let id = this.id.value
    let url = '/companies' + (!id ? '' : `/${id}`);
    let values = this.inputForm.value;
    values.users = this.extractDiffUsers();
    let params = this.http.object2FormData(values);
    this.http.post(url, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        // if (!id) {
        //   this.inputForm.patchValue({
        //     id: result.data,
        //   });
        // }
        this.messageService.showInformation('The enterprise information has been successfully saved。');
        this.router.navigate(['/admin/companies/search']);
      }
    });
  }

  deleteClick() {
    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the company?？',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      let id = this.id.value
      this.http.delete(`/companies/${id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.messageService.showInformation('The enterprise has been successfully deleted。');
          this.router.navigate(['/admin/companies/search']);
        }
      });
    });
  }

  backClick() {
    this.router.navigate(['/admin/companies/search']);
  };

  get id() { return this.inputForm.controls['id']; }

  operUserPickerDialog() {
    this.dialog.open(UserPickerDialog, {
      maxWidth: '96vw',
      maxHeight: '96vh',
      data: {
        type: UserTypes.individual,
      },
    }).afterClosed().subscribe((user) => {
      if (!user) {
        return;
      }
      this.newUsers.push(user);
    });
  }

  removeCompanyUser(index: number) {
    this.newUsers.splice(index, 1);
  }

  private patchToForm(values: any) {
    this.oldUsers = [...values.users];
    this.newUsers = [...values.users];
    // values.shiftStartDate = this.parseDate(values.shiftStartDate);
    // values.shiftEndDate = this.parseDate(values.shiftEndDate);
    // values.startDate = this.parseDate(values.startDate);
    // values.endDate = this.parseDate(values.endDate);
    this.inputForm.patchValue(values);
  }

  private extractDiffUsers(): UserInfo[] {
    let newUsers = this.newUsers ?? [];
    let oldUsers = this.oldUsers ?? [];
    let users = newUsers.filter((newUser: any) => {
      let oldUser = oldUsers.find((oldUser: any) => oldUser.id == newUser.id);
      if (!oldUser) {
        newUser.action = 'add';
      }
      return !oldUser;
    });
    users.push(...oldUsers.filter((oldUser: any) => {
      let newUser = newUsers.find((newUser: any) => oldUser.id == newUser.id);
      if (!newUser) {
        oldUser.action = 'remove';
      }
      return !newUser;
    }));
    return users;
  }

}
