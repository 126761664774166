import { Injectable, Injector } from "@angular/core";
import { ActionResult, ActionStatus, AppHttpClient } from "../common/app.http";
import { JobStatus, PaintedImage } from "../models";
import { forkJoin, of } from "rxjs";
import { UserJobListItem, UserOperationListItem } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PaintService {

  constructor(private injector: Injector,
    private http: AppHttpClient,) {

  }

  generate(formData: FormData) {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');
    let paintedImages = new Array<PaintedImage>();
    for (let i = 0; i < Number(quantity); i++) {
      let item = new PaintedImage(this.injector);
      item.start();
      paintedImages.push(item);
    }

    let taskId = formData.get('taskId');
    let prompts = formData.get('prompts');
    let negativePrompts = formData.get('negativePrompts');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let chatObserable;
    if (!prompts && !negativePrompts) {
      chatObserable = of({
        status: ActionStatus.Success,
        data: {},
      } as ActionResult);
    } else {
      let chatInput = {
        'prompts': prompts,
        'negativePrompts': negativePrompts,
      };
      chatObserable = this.http.post(`/picture/chat`, chatInput);
    }

    forkJoin([operationObserable, chatObserable]).subscribe({
      next: ([opeartionResult, chatResult]: [ActionResult, ActionResult]) => {
        if (!this.http.isValidResult(opeartionResult)) {
          paintedImages.forEach((item) => {
            item.stop(opeartionResult);
          });
          return;
        }
        if (!this.http.isValidResult(chatResult)) {
          paintedImages.forEach((item) => {
            item.stop(chatResult);
          });
          return;
        }

        formData.set('operationId', opeartionResult.data.id);
        formData.set('prompts', chatResult.data.prompts ?? '');
        formData.set('negativePrompts', chatResult.data.negativePrompts ?? '');

        paintedImages.forEach((item, index) => {
          setTimeout(() => {
            this.http.post(`/picture/paint`, formData).subscribe({
              next: (result: ActionResult) => {
                if (!this.http.isValidResult(result)) {
                  item.stop(result);
                  return;
                }
                item.id = result.data.id;
              },
              error: (err: any) => this.onError(item, err),
            });
          }, index * 250);
        });
      },
      error: (err: any) => {
        paintedImages.forEach((item) => {
          this.onError(item, err)
        });
      },
    });

    return paintedImages;
  }


  execute(
    formData: FormData,
    onInit?: (operation: UserOperationListItem) => void,
  ): UserOperationListItem {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');

    let taskId = formData.get('taskId');
    let prompts = formData.get('prompts');
    let negativePrompts = formData.get('negativePrompts');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let chatObserable;
    if (!prompts && !negativePrompts) {
      chatObserable = of({
        status: ActionStatus.Success,
        data: {},
      } as ActionResult);
    } else {
      let chatInput = {
        'prompts': prompts,
        'negativePrompts': negativePrompts,
      };
      chatObserable = this.http.post(`/picture/chat`, chatInput);
    }

    let now = new Date();
    let jobs: Array<UserJobListItem> = [];
    let operation: UserOperationListItem = {
      modifiedDate: now.getTime(),
      jobs: jobs,
    };
    for (let i = 0; i < Number(quantity); i++) {
      jobs.push({
        status: JobStatus.waiting,
        modifiedDate: now.getTime(),
        imageWidth: Number(formData.get('width')),
        imageHeight: Number(formData.get('height')),
      });
    }
    forkJoin([operationObserable, chatObserable]).subscribe({
      next: ([opeartionResult, chatResult]: [ActionResult, ActionResult]) => {
        if (!this.http.isValidResult(opeartionResult)) {
          return;
        }
        if (!this.http.isValidResult(chatResult)) {
          return;
        }

        operation.id = opeartionResult.data.id;
        operation.modifiedDate = opeartionResult.data.modifiedDate;
        onInit?.call(this, operation); // Callback notification after user operation initialization is completed

        formData.set('operationId', opeartionResult.data.id);
        formData.set('prompts', chatResult.data.prompts ?? '');
        formData.set('negativePrompts', chatResult.data.negativePrompts ?? '');

        jobs.forEach((job, index) => {
          this.http.post(`/picture/paint`, formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                job.status = JobStatus.failed;
                return;
              }
              var data = result.data;
              job.id = data.id;
              job.type = data.type;
              job.subType = data.subType;
              job.modifiedDate = data.modifiedDate;
              job.status = data.status;
              job.statusDesc = data.statusDesc;
            },
            error: (err: any) => {
              job.status = JobStatus.failed;
            },
          });
        });
      },
      error: (err: any) => {
        jobs.forEach(job => {
          job.status = JobStatus.failed;
        });
      },
    });
    return operation;
  }

  private onError(target: PaintedImage, err: any) {
    target.stop({
      status: ActionStatus.Failed,
      message: err.message ?? err.toString(),
    });
  }
}
