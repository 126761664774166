import { Component, Injector, Input, OnInit } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { FavoriteTypes, UserJobListItem } from '../interfaces';
import { BaseComponent } from '../common/base.component';
import { JobService } from '../services/job.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'user-favorite-list',
  templateUrl: './user-favorite-list.html',
  styleUrl: './user-favorite-list.scss',
})
export class UserFavoriteList extends BaseComponent implements OnInit {
  favorites: Array<UserJobListItem> = [];
  hasMore = false;
  initialized = false;

  @Input() userId?: string;
  @Input() type?: String;
  @Input() subType?: String;

  _taskId?: number;
  @Input() set taskId(value: number | undefined) {
    let changed = value != this._taskId;
    this._taskId = value;
    if (this.initialized && changed) {
      this.reset();
      this.fetch();
    }
  }
  get taskId() {
    return this._taskId;
  }

  private jobSubscription?: Subscription;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private jobService: JobService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initialized = true;
    this.jobSubscription = this.jobService.subscribe(job => this.jobChanged(job));

    this.fetch();
  }

  ngOnDestroy() {
    this.jobSubscription?.unsubscribe();
  }

  fetch() {
    var params = {
      userId: this.userId,
      type: this.type,
      taskId: this.taskId,
      subType: this.subType,
      startIndex: this.favorites.length,
    }
    this.http.post(`/user/favorites/fetch`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let favorites = result.data.favorites;
        favorites.forEach((job: UserJobListItem) => {
          job.parent = this.favorites;
          this.downloadThumbnail(job)
        });
        this.favorites.push(...favorites);
        this.hasMore = result.data.hasMore;
      }
    });
  }

  downloadThumbnail(job: UserJobListItem) {
    this.http.get(`/job/${job.id}/thumbnail`, { responseType: 'blob' }).subscribe({
      next: async (result: Blob) => {
        job.thumbnailUrl = URL.createObjectURL(result);
      },
    });
  }

  reset() {
    this.favorites = [];
    this.hasMore = false;
    // this.init();
  }


  fetchMoreClick() {
    this.fetch();
  }

  jobChanged(job: UserJobListItem) {
    if (job.favoriteRate == FavoriteTypes.unlike || (job.feedbackRate ?? 0) < 0) {
      for (let i = this.favorites.length - 1; i >= 0; i--) {
        let item = this.favorites[i];
        if (item.id != job.id) {
          continue;
        }
        this.favorites.splice(i, 1);
      }
    }else if (job.favoriteRate == FavoriteTypes.like) {
      let item = this.favorites.find(item => item.id == job.id);
      if (!item) {
        job = { ...job };
        job.parent = this.favorites;
        this.favorites.splice(0, 0, job);
      }
    }

    this.favorites.forEach(item => {
      if (item.id != job.id) {
        return;
      }
      item.feedbackRate = job.feedbackRate;
    });
  }

}
