<!-- <div *ngIf="!!selectedTemplateId && !showMoreTemplates">
  <div class="d-flex align-items-baseline mb-1">
    <div>Selected build scene</div>
    <div class="flex-grow-1"></div>
    <button mat-stroked-button color="primary" (click)="showMoreTemplates=!showMoreTemplates">
      <mat-icon>redo</mat-icon>Reselect the scene
    </button>
  </div>
  <img class="template-image" [src]="value?.url" *ngIf="!!value?.url"/>
</div>

<div *ngIf="showMoreTemplates">
  <div class="d-flex align-items-baseline">
    <div class="flex-grow-1"></div>
    <button mat-stroked-button color="primary" (click)="cancelClick()" *ngIf="!!selectedTemplateId">
      <mat-icon>cancel</mat-icon>Cancel
    </button>
  </div>
  <material-select src="/material/templates" [selectedTemplateId]="selectedTemplateId"
    (selectionChange)="materialTemplateSelected($event)" />
</div> -->

<div>
  <material-select src="/material/templates" [selectedTemplateId]="selectedTemplateId"
    (selectionChange)="materialTemplateSelected($event)" />
</div>
