import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MessageSnackbar } from "../components/message-snackbar"

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private _snackBar: MatSnackBar,
    private ngZone: NgZone,) {

  }

  showInformation(message: string | undefined) {
    this.showMessage(message, {
      panelClass: ['message-snackbar'],
      duration: 5000,
    });
  }

  showWarning(message: string | undefined) {
    this.showMessage(message, {
      panelClass: ['message-snackbar', 'warn'],
      duration: 10000,
    });
  }

  showError(message: string | undefined) {
    this.showMessage(message, {
      panelClass: ['message-snackbar', 'error'],
      // duration: 30000,
    });
  }

  private showMessage(message: string | undefined, config: MatSnackBarConfig) {
    if (!message) {
      return;
    }

    if (!message) {
      //this.hide();
      return;
    }

    this.ngZone.run(() => {
      this._snackBar.openFromComponent(MessageSnackbar, {
        ...{
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          data: message
        }, ...config
      });
    });
  }

  hide() {
    this._snackBar.dismiss();
  }

}

export class MessageResource {
  public static WAITING_FOR_PROCESS = 'The previous process has not yet completed，Temporarily unable to accept new processing requests。';
  public static SAVED_SUCCESSFULLY = 'Saved successfully。';
  public static ALREADY_SAVED = 'Successfully saved。';
  public static MOVE_SUCCESSFULLY = 'Mobile success。';
  public static NO_DATA_MACHED = 'No matching data found。';
  public static NO_IMAGE_SELECTED = 'Please import the image first。';
  public static COMMING_SOON = 'under construction，Stay tuned。';
  public static FAILED_TO_REMOVE_BACKGROUND = 'Automatic background removal failed，Please try again later。';
  public static FAILED_TO_PROCESS = 'An error occurred while processing your request，Please try again later。';
}
