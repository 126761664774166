import { Component, Injector, OnDestroy } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'register-success',
  templateUrl: './register-success.component.html',
  styleUrl: './register-success.component.scss',
})

export class RegisterSuccessComponent extends BaseComponent {
  countdown: number = 5;
  countdownSub: Subscription;

  constructor(Injector: Injector) {
    super(Injector);
    this.countdownSub = new Subscription();
    this.startCountdown();
  }

  startCountdown() {
    this.countdownSub = interval(1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.countdownSub.unsubscribe();
        this.sessionService.navigateToReturnUrl()
      }
    });
  }

  ngOnDestroy() {
    if (this.countdownSub) {
      this.countdownSub.unsubscribe();
    }
  }

  backtoHome(event: MouseEvent) {
    event.stopPropagation();
    this.sessionService.navigateToReturnUrl();
  }

}