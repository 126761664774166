import {
  ChangeDetectionStrategy, Component, ElementRef, EventEmitter,
  Input, NgZone, OnInit, Output, ViewChild,
} from '@angular/core';
import { BehaviorSubject, fromEvent, Subject, Subscription, takeUntil, tap, } from 'rxjs';
import { Point } from '../interfaces';
import { angleBetween } from '../helpers/mask-utils';

@Component({
  selector: 'element-resizable',
  templateUrl: './element-resizable.html',
  styleUrl: './element-resizable.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementResizable implements OnInit {
  @Input() border: boolean = false;
  @Input() dot: boolean = true;
  @ViewChild('box', { static: true }) boxElRef!: ElementRef<HTMLDivElement>;
  @ViewChild('rotate', { static: true })
  rotateElRef!: ElementRef<HTMLDivElement>;

  @Input() width: number = 50;
  @Output() widthChange = new EventEmitter<number>();
  @Input() height: number = 50;
  @Output() heightChange = new EventEmitter<number>();
  @Input() top: number = 50;
  @Output() topChange = new EventEmitter<number>();
  @Input() left: number = 50;
  @Output() leftChange = new EventEmitter<number>();
  @Input() angle: number = 0;
  @Output() angleChange = new EventEmitter<number>();
  @Input() unit: string = UnitTypes.px;

  @Input() set disabled(disabled: boolean) {
    this.active$.next(!disabled);
  }

  private resizingSubs: Subscription[] = [];
  private draggingSub?: Subscription;

  active$ = new BehaviorSubject<boolean>(true);

  mousePressPoint!: Point;

  get box(): HTMLDivElement {
    return this.boxElRef.nativeElement;
  }

  get rotateBtn(): HTMLDivElement {
    return this.rotateElRef.nativeElement;
  }

  private destroy$ = new Subject<void>();

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    this.active$.pipe(takeUntil(this.destroy$)).subscribe((active) => {
      if (active) {
        this.enableResizing();
        this.enableDragging();
      } else {
        this.disableResizing();
        this.disableDrag();
      }
    });

    this.handleRotating();

    this.rotateBox(this.angle, false);
    this.resize(this.width, this.height, false);
    this.repositionElement(this.left, this.top, false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private repositionElement(x: number, y: number, notify: boolean = true): void {
    this.left = x;
    this.top = y;
    this.box.style.left = x + this.unit;
    this.box.style.top = y + this.unit;
    if (notify) {
      this.leftChange.emit(x);
      this.topChange.emit(y);
    }
  }

  private resize(width: number, height: number, notify: boolean = true): void {
    this.width = width;
    this.height = height;
    this.box.style.width = width + this.unit;
    this.box.style.height = height + this.unit;
    if (notify) {
      this.widthChange.emit(width);
      this.heightChange.emit(height);
    }
  }

  private rotateBoxByPI(angle: number): void {
    angle = (angle * 180) / Math.PI;
    this.rotateBox(angle);
  }

  private rotateBox(angle: number, notify: boolean = true): void {
    this.angle = angle;
    this.box.style.transform = `rotate(${angle}deg)`;
    if (notify) {
      this.angleChange.emit(angle);
    }
  }

  enableResizing(): void {
    const leftTopMouseDown$ = fromEvent(
      this.getElement('.left-top')!, 'mousedown'
    ).pipe(tap((e) => this.resizeHandler(e, true, true)));

    const rightTopMouseDown$ = fromEvent(
      this.getElement('.right-top')!, 'mousedown'
    ).pipe(tap((e) => this.resizeHandler(e, false, true)));

    const rightBottomMouseDown$ = fromEvent(
      this.getElement('.right-bottom')!, 'mousedown'
    ).pipe(tap((e) => this.resizeHandler(e, false, false)));

    const leftBottomMouseDown$ = fromEvent(
      this.getElement('.left-bottom')!, 'mousedown'
    ).pipe(tap((e) => this.resizeHandler(e, true, false)));

    this.ngZone.runOutsideAngular(() => {
      this.resizingSubs.push(
        leftTopMouseDown$.subscribe(),
        rightTopMouseDown$.subscribe(),
        rightBottomMouseDown$.subscribe(),
        leftBottomMouseDown$.subscribe()
      );
    });
  }

  disableResizing(): void {
    this.resizingSubs.forEach((sub) => sub.unsubscribe());
  }

  enableDragging(): void {
    const boxWrapperMouseDown$ = fromEvent<MouseEvent>(
      this.box, 'mousedown', { capture: true }
    ).pipe(
      tap((event: any) => {
        if (event.target.className.indexOf('dot') > -1 || !event.target.classList.contains('box-border')) {
          event.preventDefault();
          return;
        }

        this.mousePressPoint = { x: event.clientX, y: event.clientY };

        const eventMoveHandler = (event: any) => {
          const newPoint = { x: event.clientX, y: event.clientY };
          let offset = this.getMouseOffset(newPoint, this.mousePressPoint);
          this.repositionElement(this.left + offset.x, this.top + offset.y);
          this.mousePressPoint = newPoint;
        };

        const eventEndHandler = () => {
          this.box.removeEventListener(
            'mousemove', eventMoveHandler, false
          );
          window.removeEventListener('mouseup', eventEndHandler);
        };

        this.box.addEventListener('mousemove', eventMoveHandler, false);

        window.addEventListener('mouseup', eventEndHandler, false);
      })
    );

    this.ngZone.runOutsideAngular(() => {
      this.draggingSub = boxWrapperMouseDown$.subscribe();
    });
  }

  disableDrag(): void {
    this.draggingSub?.unsubscribe();
  }

  handleRotating(): void {
    this.ngZone.runOutsideAngular(() => {
      this.rotateBtn!.addEventListener(
        'mousedown', () => {
          let rect = this.box.getBoundingClientRect();
          let arrowX = rect.left + rect.width / 2;
          let arrowY = rect.top + rect.height / 2;

          const eventMoveHandler = (event: any) => {
            let angle =
              Math.atan2(event.clientY - arrowY, event.clientX - arrowX) -
              Math.PI / 2;
            this.rotateBoxByPI(angle);
          };

          const eventEndHandler = () => {
            window.removeEventListener('mousemove', eventMoveHandler, false);
            window.removeEventListener('mouseup', eventEndHandler);
          };

          window.addEventListener('mousemove', eventMoveHandler, false);
          window.addEventListener('mouseup', eventEndHandler, false);
        },
        false
      );
    });
  }

  private resizeHandler(event: any, left = false, top = false) {
    const adjustHandler = (event: any) => {
      let angle = (this.angle * Math.PI) / 180;
      let p0: Point = {
        x: this.left + this.width / 2,
        y: this.top + this.height / 2
      }
      let p1: Point = {
        x: left ? this.left + this.width : this.left,
        y: top ? this.top + this.height : this.top,
      }
      if (this.unit == UnitTypes.percent) {
        p0 = this.pointInAbsolute(p0);
        p1 = this.pointInAbsolute(p1);
      }
      const p1_r = this.rotate(p1, p0, angle);
      const p2_r: Point = this.getMouseInput(event);
      const p2: Point = this.rotate(p2_r, p0, -angle);
      let newW = Math.abs(p2.x - p1.x);
      let newH = Math.abs(p2.y - p1.y);

      const p0_r: Point = {
        x: (p2_r.x + p1_r.x) / 2,
        y: (p2_r.y + p1_r.y) / 2,
      }

      let angle1 = angleBetween(p1_r, p2_r);
      let angle2 = Math.atan2(p2.x - p1.x, p2.y - p1.y);

      angle = angle2 - angle1;
      let newTopLeft: Point = this.rotate(p1_r, p0_r, -angle);
      if (top) {
        newTopLeft.y = newTopLeft.y - newH;
      }
      if (left) {
        newTopLeft.x = newTopLeft.x - newW;
      }

      if (this.unit == UnitTypes.percent) {
        let p = this.pointInRelative({ x: newW, y: newH });
        newW = p.x;
        newH = p.y;
        newTopLeft = this.pointInRelative(newTopLeft);
      }

      this.rotateBoxByPI(angle);
      this.resize(newW, newH);
      this.repositionElement(newTopLeft.x, newTopLeft.y);
    };

    window.addEventListener('mousemove', adjustHandler, false);
    window.addEventListener('mouseup',
      function eventEndHandler() {
        window.removeEventListener('mousemove', adjustHandler, false);
        window.removeEventListener('mouseup', adjustHandler);
      },
      false
    );
  }

  private rotate(p1: Point, p0: Point, angle: number): Point {
    return {
      x: (p1.x - p0.x) * Math.cos(angle) - (p1.y - p0.y) * Math.sin(angle) + p0.x,
      y: (p1.x - p0.x) * Math.sin(angle) + (p1.y - p0.y) * Math.cos(angle) + p0.y,
    };
  }

  private getElement(selector: string): HTMLElement | null {
    return this.box.querySelector(selector);
  }

  private getMouseInput(event: any): Point {
    let x = event.clientX;
    let y = event.clientY;
    const parent = this.box.offsetParent;
    if (!parent) {
      return { x, y };
    }
    const rect = parent.getBoundingClientRect();
    x = x - rect.left;
    y = y - rect.top;
    return { x, y };
  }

  private getMouseOffset(endPoint: Point, startPoint: Point): {
    x: number,
    y: number,
  } {
    const offset = {
      x: endPoint.x - startPoint.x,
      y: endPoint.y - startPoint.y,
    }
    if (this.unit == UnitTypes.percent) {
      return this.pointInRelative(offset);
    } else {
      return offset;
    }
  }

  private pointInAbsolute(point: Point) {
    const parent = this.box.offsetParent;
    if (!parent) {
      return point;
    }
    const rect = parent.getBoundingClientRect();
    return { x: point.x / 100 * rect.width, y: point.y / 100 * rect.height };
  }

  private pointInRelative(point: Point) {
    const parent = this.box.offsetParent;
    if (!parent) {
      return point;
    }
    const rect = parent.getBoundingClientRect();
    return { x: point.x / rect.width * 100, y: point.y / rect.height * 100 };
  }

}

class UnitTypes {
  public static px = 'px';
  public static rem = 'rem';
  public static em = 'em';
  public static percent = '%';
}