<div class="page-header">
  <h5 class="title me-5">User Center</h5>
</div>

<div class="d-flex flex-wrap">

  <div class="col-12 col-lg-6 py-2 p-0 px-lg-2">
    <mat-card class="d-flex-column h-100">
      <mat-card-header>
        <mat-card-title>Account Information</mat-card-title>
      </mat-card-header>
      <hr />
      <mat-card-content>
        <form [formGroup]="passwordForm">
          <div class="col-12 col-xxl-8">
            <mat-form-field>
              <mat-label>accountID</mat-label>
              <input matInput formControlName="username">
            </mat-form-field>
          </div>
          <div class="col-12 col-xxl-8" *ngIf="hasPassword">
            <mat-form-field>
              <mat-label>Old Password</mat-label>
              <input matInput formControlName="oldPassword" [type]="hideOldPassword ? 'password' : 'text'"
                maxlength="64">
              <button mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword">
                <mat-icon>{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="col-12 col-xxl-8">
            <mat-form-field>
              <mat-label>New Password</mat-label>
              <input matInput formControlName="newPassword" [type]="hideNewPassword ? 'password' : 'text'"
                maxlength="64">
              <button mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword">
                <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error
                *ngIf="passwordForm.controls['newPassword'].hasError('required') && passwordForm.controls['newPassword'].touched">required.</mat-error>
              <mat-error
                *ngIf="passwordForm.controls['newPassword'].hasError('password') && passwordForm.controls['newPassword'].touched">password
                does not meet policy.</mat-error>
            </mat-form-field>
            <div
              *ngIf="passwordForm.controls['newPassword'].hasError('password') && passwordForm.controls['newPassword'].touched">
              <ul class="mat-mdc-form-field-error error-msg">
                <li>At least 1 upper-case letter</li>
                <li>At least 1 lower-case letter</li>
                <li>At least 1 number</li>
                <li>At least 1 of these special characters !&#64;#$%^&*</li>
                <li>At least 8 characters long</li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-xxl-8">
            <mat-form-field>
              <mat-label>Re-enter new password</mat-label>
              <input matInput formControlName="confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'"
                maxlength="64">
              <button mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error
                *ngIf="passwordForm.controls['confirmPassword'].hasError('required') && passwordForm.controls['confirmPassword'].touched">required.</mat-error>
              <mat-error
                *ngIf="passwordForm.controls['confirmPassword'].hasError('same') && passwordForm.controls['confirmPassword'].touched">password
                is unmatch.</mat-error>
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
      <div class="flex-grow-1"></div>
      <div class="text-end px-2 pb-2">
        <button mat-raised-button class="btn-min-width" color="primary" (click)="updatePasswordClick()"
          [disabled]="!passwordForm.valid"><span>Change Password</span></button>
      </div>
    </mat-card>
  </div>

  <div class="col-12 col-lg-6 py-2 p-0 px-lg-2">
    <mat-card class="d-flex-column h-100">
      <mat-card-header>
        <mat-card-title>personal information</mat-card-title>
      </mat-card-header>
      <hr />
      <mat-card-content>
        <form [formGroup]="profileForm">
          <div class="col-12 col-xxl-6">
            <mat-form-field>
              <mat-label>User Nickname</mat-label>
              <input matInput formControlName="nickname">
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
      <div class="flex-grow-1"></div>
      <div class="text-end px-2 pb-2">
        <button mat-raised-button class="btn-min-width" color="primary"
          (click)="updateProfileClick()"><span>change</span></button>
      </div>
    </mat-card>
  </div>
</div>

<br />
