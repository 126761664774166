
<div>
  <div class="d-flex">
    <mat-icon class="m-2" color="warn">error</mat-icon>
    <h1 mat-dialog-title class="px-0">{{data.title}}</h1>
  </div>
  <div mat-dialog-content class="alert-dialog-content">
    {{data.message}}
  </div>
  <div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" class="btn-min-width mx-auto" (click)="confirmClick()">{{data.confirmButtonName}}</button>
  </div>
</div>
