import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MaterialSearchResults, MaterialTemplateDetails, MaterialTemplateListItem } from '../interfaces';

@Component({
  selector: 'material-select-inline',
  templateUrl: './material-select-inline.html',
  styleUrl: './material-select-inline.scss',
})
export class MaterialSelectInline {
  @Input({ required: true }) category!: number;
  @Input() itemClass = "ratio ratio-1x1";
  @Input() containerClass = "col-3";
  @Input() required = true;
  @Input() unselectedTitle?: string;
  @Input() pageCount = 16;
  @Input() disablePaging = false;

  private initialized = false;
  private fetchMore = false;
  private _labels?: Array<string>;
  @Input() set labels(value: Array<string> | undefined) {
    var changed = this._labels != value;
    this._labels = value;
    if (changed && this.initialized) {
      this.fetchMore = false;
      this.fetchMaterialTemplates();
    }
  }
  get labels() {
    return this._labels;
  }

  templates: MaterialTemplateListItem[] = [];

  _selectedTemplateId?: number;
  @Input() set selectedTemplateId(value: number | undefined) {
    this._selectedTemplateId = value === -1 ? undefined : value;
    this.changeSelectedTemplate(value);
  }
  get selectedTemplateId() {
    return this._selectedTemplateId;
  }
  @Output() selectionChange = new EventEmitter<MaterialTemplateDetails | undefined>();

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {
    this.fetchMore = false;
    this.fetchMaterialTemplates();
    this.initialized = true;
  }

  hasMore = false;
  fetchMaterialTemplates() {
    if (!!this.labels && this.labels.length == 0) {
      return;
    }
    let params = {
      category: this.category,
      labels: this.labels,
      startIndex: this.fetchMore ? this.templates.length : 0,
      pageCount: this.pageCount,
    };
    this.http.post("/material/templates/fetch", params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }

        let templates = this.fetchMore ? [...this.templates] : [];
        let results: MaterialSearchResults[] = result.data.templates;
        let hasMore = result.data.hasMore;
        results.forEach(item => {
          let templateItem: MaterialTemplateListItem = {
            id: item.id,
            name: item.name,
            selected: item.id == this.selectedTemplateId,
            category: 0,
            favorite: undefined
          };
          templates.push(templateItem);
        });
        if (!this.fetchMore && hasMore && !this.required && templates.length > 0) {
          templates.splice(templates.length - 1, 1);
        }
        if (!this.disablePaging && !this.fetchMore && hasMore && templates.length > 0) {
          templates.splice(templates.length - 1, 1);
        }
        this.templates = templates;
        this.hasMore = hasMore;
        this.fetchMore = true;
      },
    });
  }

  reset() {
    this.templates.forEach(item => {
      item.selected = false;
    });
  }

  onTemplateClick(template: MaterialTemplateListItem | undefined) {
    if (!template) {
      if (!this.selectedTemplateId) {
        return;
      } else {
        this.selectedTemplateId = undefined;
        this.changeSelectedTemplate(undefined);
        this.selectionChange.emit(undefined);
        return;
      }
    } else {
      if (this.selectedTemplateId == template.id) {
        if (this.required) {
          return;
        } else {
          this.selectedTemplateId = undefined;
          template.selected = false;
          this.selectionChange.emit(undefined);
          return;
        }
      }
    }

    this.changeSelectedTemplate(template.id);
    this.selectedTemplateId = template.id;

    this.http.get(`/material/templates/${template.id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        var data = result.data;
        this.selectionChange.emit(data);
      }
    });
  }

  getTemplateThumbnailUrl(template: MaterialTemplateListItem) {
    return this.http.getAbsoluteUrl(`/public/material/templates/${template.id}/thumbnail`);
  }

  private changeSelectedTemplate(selectedTemplateId: number | undefined) {
    this.templates.forEach(item => {
      item.selected = item.id == selectedTemplateId ? true : false;
    });
  }

  fetchMoreClick() {
    this.fetchMaterialTemplates();
  }

}

