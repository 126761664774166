<page-header title="AICostume Design">
</page-header>

<div class="flex-grow-1 h-0 d-flex flex-column flex-lg-row">

  <div class="me-1" [class.d-none]="!isExpanded">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.clothDesign" [userId]="userId"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <div class="flex-grow-1 h-lg-100 d-flex flex-column flex-lg-row" *ngIf="!!taskId">

    <flexible-accordion class="flex-grow-1 h-lg-100" [(expanded)]="expanded2">

      <div class="flexible-accordion-header">
        <mat-card class="input-container min-h-100">
          <div class="mb-2">
            <image-picker class="ratio ratio-16x9" #imagePicker [canRemove]="true"
              (changed)="rawImageChanged($event)" />
          </div>
          <div class="mb-2">
            <image-clothing-gender-input [clothingGender]="clothingGender"
              (clothingGenderChange)="clothingGenderChanged($event)" />
          </div>
          <div class="mb-2">
            <image-clothing-style-input [clothingStyle]="clothingStyle"
              (clothingStyleChange)="clothingStyleChanged($event)" />
            <div class="col-12">
              If you don&#39;t want style，Customizable style through content description
            </div>
          </div>

          <div class="mb-2">
            <image-content-input [content]="content" (contentChange)="contentChanged($event)" />
          </div>

          <div class="mb-2">
            <image-textual-input [(value)]="prompts" [(negative)]="negativePrompts" />
          </div>

          <div class="mb-2">
            <image-size-input [aspect]="aspect" (aspectChange)="imageAspectChanged($event)" />
          </div>

          <div class="mb-2">
            <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
          </div>

          <!-- <div>
            <image-resolution-input [(value)]="resolution" />
          </div> -->

          <div class="d-flex py-2">
            <cost-estimator category="image" item="paint" [quantity]="quantity" />
            <div class="flex-grow-1"></div>
            <button mat-flat-button color="primary" (click)="generateClick()">AI Generate</button>
          </div>
        </mat-card>
      </div>

      <div class="h-lg-100 user-operation-area" *ngIf="taskId">

        <div class="d-flex flex-column w-100 h-100 p-lg-2">
          <user-favorite-list [type]="JobTypesEnum.clothDesign" [userId]="userId" [taskId]="taskId" />
          <user-operation-list #userOperationList [userId]="userId" [taskId]="taskId"
            [notificationCategory]="userNotificationCatecory" (clone)="cloneClick($event)"
            (statusChange)="userOperationStatusChange($event)" />
        </div>

      </div>

    </flexible-accordion>
  </div>

</div>
