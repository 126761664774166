<div class="d-flex flex-wrap w-100">
  <div class="image-area template-image mt-2 px-1" [class]="containerClass" (click)="onTemplateClick(undefined)" *ngIf="!required">
    <div [class]="itemClass">
      <img src="/assets/images/none_model.png" [class.selected]="!selectedTemplateId" />
      <div class="unselect-title">{{unselectedTitle}}</div>
    </div>
  </div>
  <div class="image-area template-image mt-2 px-1" [class]="containerClass" (click)="onTemplateClick(template)"
    *ngFor="let template of templates;">
    <div [class]="itemClass">
      <img [src]="getTemplateThumbnailUrl(template)" [class.selected]="template.selected" />
    </div>
  </div>
  <div class="image-area template-image mt-2 px-1" [class]="containerClass" (click)="fetchMoreClick()" *ngIf="!disablePaging && hasMore">
    <div class="fetch-more" [class]="itemClass">
      <div class="title">
        <mat-icon>more_horiz</mat-icon>
        <div>Show more</div>
      </div>
    </div>
  </div>
</div>
