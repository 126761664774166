import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'image-dpi-input',
  templateUrl: './image-dpi-input.html',
  styleUrl: './image-dpi-input.scss',
})
export class ImageDpiInput {

  @Input() dpi:string | undefined;
  @Output() dpiChange = new EventEmitter<string>();

  constructor() {

  }

  dpiChanged(event: Event) {
    this.dpiChange.emit( this.dpi );
  }

}