<div class="flex-grow-1 m-5 p-4 d-flex flex-column">
  <div class="mat-headline-5 fw-bold mb-2">AI INSIDE</div>
  <div class="mat-headline-3 fw-bold mb-4"><span>Welcome to </span><span class="text-primary">Wefocus</span></div>
  <div class="max-width-sm mb-3">Using AIGC technology, we help clients achieve 
    extraordinary efficiency and minimize costs, swiftly addressing the challenges of 
    long production cycles and high expenses associated with traditional product 
    photography. This enables the rapid generation of high-quality, 
    highly usable product marketing images, significantly enhancing marketing 
    effectiveness and improving user experience.
  </div>

  <!-- <div class="mb-3">
    <button mat-flat-button class="px-4" color="primary">Try it now</button>
    </div> -->

  <div class="flex-grow-1 d-flex">

    <div class="d-flex flex-wrap my-auto">
      <div class="service-item me-3">
        <div class="title">
          <mat-icon svgIcon="app:intelligence"></mat-icon>
          <span>Intelligent Services</span>
        </div>
        <div class="content">Intelligent product photography and creative generated images</div>
      </div>
      <div class="service-item me-3">
        <div class="title">
          <mat-icon svgIcon="app:image"></mat-icon>
          <span>Simple and Efficient</span>
        </div>
        <div class="content">Simple operation, Quick generation</div>
      </div>
      <div class="service-item me-3">
        <div class="title">
          <mat-icon svgIcon="app:private"></mat-icon>
          <span>Data Privacy</span>
        </div>
        <div class="content">Corporate accounts, Full supervision</div>
      </div>
    </div>
  </div>

  <div class="flex-grow-1">
    <div class="fs-larger"><span>Demonstration</span></div>
    <div class="manual-container">
      <div class="manual-item" *ngFor="let manual of manuals">
        <video height="180">
          <source [src]="manual.url" type="video/mp4">
        </video>
        <div class="title">
          <div>{{manual.title}}</div>
        </div>
        <div class="play-btn" matRipple [matRippleCentered]="true" (click)="playClick(manual)">
          <div class="play-btn-background"></div>
          <div class="play-btn-title"><span>Play</span></div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="more-videos-btn" matRipple (click)="moreManualClick()">More Demos</div>
    </div>
  </div>
</div>
