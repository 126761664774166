import { Component, Injector } from '@angular/core';
import { BaseComponent } from '../../common/base.component';

@Component({
  selector: 'one-layout',
  templateUrl: './one-layout.component.html',
})
export class OneLayoutComponent extends BaseComponent {

  constructor(injector: Injector) {
    super(injector);
  }
}
