<div class="page-header">
  <h4 class="title me-5">User Management - Modify user information</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="backClick()"><mat-icon color="primary"
      aria-label="return">arrow_back</mat-icon><span>return</span>
  </button>
</div>

<form class="d-flex flex-wrap align-items-start" [formGroup]="inputForm">
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>User Account</mat-label>
      <input matInput formControlName="username" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>password</mat-label>
      <input matInput formControlName="password" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>User Nickname</mat-label>
      <input matInput formControlName="nickname" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>Phone number</mat-label>
      <input matInput formControlName="mobilePhone" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-6">
    <mat-form-field>
      <mat-label>User Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let item of statusList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>

<div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="warn" type="button" (click)="deleteClick()"
    *ngIf="!!id">delete</button>
  <div class="d-inline-block mx-2"></div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="primary" type="button"
    (click)="saveClick()">keep</button>
</div>

<br />
<br />
