import { Component, Injector, OnInit, } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MatDialog } from '@angular/material/dialog';
import { MaterialDetailsDialog } from './material-details.dialog';
import { MaterialCategoryListItem, MaterialTemplateListItem } from '../interfaces';

@Component({
  selector: 'material-list',
  templateUrl: './material-list.component.html',
  styleUrl: './material-list.component.scss',
})
export class MaterialListComponent extends BaseComponent implements OnInit {
  categories: MaterialCategoryListItem[] = [];

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);

  }

  ngOnInit() {
    this.http.get("/material/templates").subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.categories = result.data;
      }
    });
  }

  getTemplateThumbnailUrl(template: MaterialTemplateListItem) {
    return this.http.getAbsoluteUrl(`/public/material/templates/${template.id}/thumbnail`);
  }

  showTemplateDetails(template: MaterialTemplateListItem) {
    this.dialog.open(MaterialDetailsDialog, {
      autoFocus: false,
      data: template
    });
  }

  favoriteClick(event: any, template: MaterialTemplateListItem) {
    event.stopPropagation();
    if(!template.favorite){
      this.addFavorite(template);
    }else{
      this.removeFavorite(template);
    }
  }

  private addFavorite(template: MaterialTemplateListItem) {
    var params = {
      category: '1', //Material templates
      id: template.id,
    }
    this.http.post('/user/favorites', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        template.favorite = result.data;
        this.messageService.showInformation('Successfully collected。');
      },
    });
  }

  private removeFavorite(template: MaterialTemplateListItem) {
    this.http.delete(`/user/favorites/${template.favorite}`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        template.favorite = undefined;
        this.messageService.showInformation('Remove favorite。');
      },
    });
  }

  selectTemplateClick(event: any, template: MaterialTemplateListItem) {
    event.stopPropagation();
    this.router.navigate(['/image/design', { templateId: template.id }]);
  }

}
