import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppHttpClient } from '../common/app.http';

@Component({
  selector: 'image-dialog',
  templateUrl: './image-dialog.html',
  styleUrl: './image-dialog.scss',
})
export class ImageDialog implements OnInit {
  url?:string;

  constructor(
    private dialogRef: MatDialogRef<ImageDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private http: AppHttpClient,) {
  }

  ngOnInit() {

    let imageUrl = this.data.url;
    if(!imageUrl){
      return;
    }
    if(/^blob:http(s)?:/i.test(imageUrl)){
      this.url = imageUrl;
      return ;
    }

    this.http.get(imageUrl, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.url = URL.createObjectURL(result);
      },
    });
  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }
}
