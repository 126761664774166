import { Component, Injector, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { AppHttpClient } from '../common/app.http';
import { AppFormGroup } from '../common/app.forms';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'user-register',
  templateUrl: './user-register.component.html',
  styleUrl: './user-register.component.scss',
})
export class UserRegisterComponent extends BaseComponent implements OnDestroy {
  hidePassword = true;
  form: AppFormGroup;
  checkboxError = false;
  optCountdown: number = 0;
  countdownSub: Subscription;

  constructor(injector: Injector, private http: AppHttpClient) {
    super(injector);

    this.form = new AppFormGroup({
      username: new FormControl('', [Validators.required, Validators.pattern("^[0-9]{11}$")]),
      nickname: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      repeatpassword: new FormControl('', Validators.required),
      otp: new FormControl(''),
      checkbox: new FormControl(false),
      // rememberMe: new FormControl(false),
    }, {
      validators: this.passwordMatchValidator
    });

    this.countdownSub = new Subscription();
  }

  ngOnDestroy() {
    if (this.countdownSub) {
      this.countdownSub.unsubscribe();
    }
  }

  passwordMatchValidator(control: AbstractControl) {
    const password = control.get('password')?.value;
    const repeatpassword = control.get('repeatpassword')?.value;

    return password === repeatpassword ? null : { passwordMismatch: true };
  }

  sendOtp(event: MouseEvent) {
    event.stopPropagation();
    this.form.controls['otp'].clearValidators();
    this.form.controls['otp'].updateValueAndValidity();
    this.checkboxError = false;

    if (!this.form.validWithTouched()) {
      return;
    }

    const params = {
      username: this.form.get('username')?.value,
      purpose: 'register',
    };

    this.http.post('/account/otp/send', params, { silence: true }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }

    });

    this.optCountdown = 60;

    this.countdownSub = interval(1000).subscribe(() => {
      if (this.optCountdown > 0) {
        this.optCountdown--;
      } else {
        this.countdownSub.unsubscribe();
      }
    });
  }

  checkboxChange(selected: any) {
    // selected.stopPropagation();
    if (this.form.get('checkbox')?.value) {
      this.checkboxError = false;
    }
  }

  registerClick() {
    this.form.controls['otp'].addValidators([Validators.pattern("^[0-9]{6}$"), Validators.required]);
    this.form.controls['otp'].updateValueAndValidity();

    if (!this.form.validWithTouched()) {
      return;
    }

    if (this.form.get('checkbox')?.value === false) {
      this.checkboxError = true;
      return;
    }

    const params = this.form.value;

    this.http.post('/account/register', params, { silence: false }).subscribe(response => {
      if (!this.http.isValidResult(response)) {
        return;
      }

      this.sessionService.login(response.data);

      this.router.navigate(['/register/success']);

    });
  }

}
