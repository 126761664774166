import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { AssetType, UserAssetCategoryName } from '../models';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialog } from '../image-dialog/image-dialog';
import { UserAssetCategoryListItem, UserAssetGroupListItem, UserAssetListItem } from '../interfaces';
import { UserAssetGroupEditDialog } from '../user-asset-group/user-asset-group-edit.dialog';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { ImagePicker } from '../image-picker/image-picker';
import { MessageResource } from '../common/message.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'user-asset-list',
  templateUrl: './user-asset-list.component.html',
  styleUrl: './user-asset-list.component.scss',
})
export class UserAssetListComponent extends BaseComponent implements OnInit {
  categories: UserAssetCategoryListItem[] = [];
  @ViewChild('simagePicker') imagePicker!: ImagePicker;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.http.get('/user/assets').subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        result.data.forEach((element: any) => {
          element.name = UserAssetCategoryName.get(element.id);
        });
        this.categories = result.data;
        this.categories.forEach(category => {
          category.groups.forEach(group => {
            group.assets.forEach(asset => {
              this.http.get(`/user/assets/${asset.id}/thumbnail`, { responseType: 'blob' }).subscribe({
                next: (result: Blob) => {
                  asset.url = URL.createObjectURL(result);
                },
              });
            });
          });
        });
      }
    });
  }

  addUserAssetGroupClick(category: UserAssetCategoryListItem) {
    this.showUserAssetGroupDialog({
      category: category.id,
    }, (result) => {
      category.groups.push({
        id: result.data.id,
        name: result.data.name,
        category: category.id,
        assets: [],
        selected: false,
      });
      this.messageService.showInformation('The image group has been saved successfully。');
    });
  }

  editUserAssetGroupClick(category: UserAssetCategoryListItem, group: UserAssetGroupListItem) {
    this.showUserAssetGroupDialog({
      id: group.id,
      name: group.name,
      category: category.id,
    }, (result) => {
      group.name = result.data.name;
      this.messageService.showInformation('Image group renaming completed successfully。');
    });
  }

  shareGroupClick(group: UserAssetGroupListItem) {
    this.messageService.showInformation(MessageResource.COMMING_SOON);
  }

  removeUserAssetGroupClick(category: UserAssetCategoryListItem, group: UserAssetGroupListItem) {
    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the image group?？',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.http.delete(`/user/asset-group/${group.id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          category.groups = category.groups.filter(function (item) {
            return item.id != group.id;
          });
          this.messageService.showInformation('The image group has been successfully deleted。');
        }
      });
    });
  }

  removeUserAssetClick(group: UserAssetGroupListItem, asset: UserAssetListItem) {
    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the image?？',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.http.delete(`/user/assets/${asset.id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          group.assets = group.assets.filter(function (item) {
            return item.id != asset.id;
          });
          this.messageService.showInformation('The image has been successfully deleted。');
        }
      });
    });
  }

  zoomoutClick(asset: UserAssetListItem) {
    this.dialog.open(ImageDialog, {
      data: {
        url: `/user/assets/${asset.id}`,
      }
    });
  }

  private showUserAssetGroupDialog(params: any, callback: (result: any) => void) {
    this.dialog.open(UserAssetGroupEditDialog, {
      disableClose: true,
      minWidth: 'min(90vw, 360px)',
      data: params,
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      callback(result);
    });
  }

  private selectedCategory?: UserAssetCategoryListItem;
  private selectedGroup?: UserAssetGroupListItem;
  addUserAssetClick(category: UserAssetCategoryListItem, group: UserAssetGroupListItem) {
    this.selectedCategory = category;
    this.selectedGroup = group;
    this.imagePicker.click();
  }

  onImageFileChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }

    var formData = new FormData();
    formData.set('category', this.selectedCategory!.id);
    formData.set('groupId', this.selectedGroup!.id.toString());
    formData.set('assetFile', file);
    formData.set('assetType', AssetType.Image);
    this.http.post('/user/assets', formData).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.messageService.showInformation(MessageResource.SAVED_SUCCESSFULLY);
        this.selectedGroup!.assets.splice(0, 0, {
          id: result.data,
          name: '',
          url: URL.createObjectURL(file),
          selected: false,
        });
      },
    });
  }

  drop(event: CdkDragDrop<UserAssetListItem[]>, category: UserAssetCategoryListItem, group: UserAssetGroupListItem) {
    this.selectedCategory = category;
    this.selectedGroup = group;

    let beforeMoveUserAssetListItem = [];
    for (let i = 0; i < group.assets.length; i++) {
      beforeMoveUserAssetListItem.push(group.assets[i].id);
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        0,
      );

      var updateObjectId;
      for (let i = 0; i < event.container.data.length; i++) {
        if (!beforeMoveUserAssetListItem.includes(event.container.data[i].id)) {
          updateObjectId = event.container.data[i]
        }
      }

      var formData = new FormData();
      formData.set('category', this.selectedCategory!.id);
      formData.set('groupId', this.selectedGroup!.id.toString());
      formData.set('id', updateObjectId!.id.toString());
      this.http.post('/user/assets/dragdrop', formData).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.messageService.showInformation(MessageResource.MOVE_SUCCESSFULLY);
        },
      })
    }
  }
}
