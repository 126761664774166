import { Component, OnInit } from '@angular/core';
import { AppHttpClient } from '../common/app.http';
import { SessionService } from '../common/session.service';

@Component({
  selector: 'my-trade',
  templateUrl: './my-trade.component.html',
  styleUrl: './my-trade.component.scss',
})
export class MyTradeComponent implements OnInit {
  type: string = 'transaction';

  constructor(private sessionService: SessionService,
    private http: AppHttpClient) {
  }

  ngOnInit() {

  }

  typeChanged(type: string) {
    this.type = type;
  }
}
