<div class="header-bar background-lighter">

  <button mat-mini-fab class="m-auto mx-2" (click)="menuToggleClick()" *ngIf="menuToggleVisible">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="h-100 px-3 py-2 d-none d-lg-inline-block">
    <img src="{{baseUrl}}assets/images/logo.svg" />
  </div>

  <div class="flex-grow-1 text-center"></div>

  <div class="d-flex">
    <div class="contact-us mx-3 my-auto" matRipple (click)="contactUsClick()">
      <mat-icon color="accent" svgIcon="app:custom_service"></mat-icon>
      <span class="d-none d-lg-inline-block ms-1">Contact Us</span>
    </div>
    <div class="d-flex align-self-center float_list_divider"></div>
    <div class="membership mx-3 my-auto" matRipple (click)="membershipClick()">
      <mat-icon color="accent" svgIcon="app:digital_money"></mat-icon>
      <span class="fw-bold mx-1 fs-larger text-gold">{{points}}</span>
      <span>Smart Beans</span>
      <span class="mx-2"></span>
      <span class="d-none d-lg-inline-block ms-1 text-primary ">Buy</span>
    </div>
  </div>

  <div class="pe-3" *ngIf="isAuthenticated">
    <div class="d-flex justify-content-center" matRipple [matMenuTriggerFor]="menu">
      <img class="icon-lg" src="{{baseUrl}}assets/images/user.png" />
    </div>
    <mat-menu #menu="matMenu" class="user-menu">
      <div class="fs-small">
        <div class="d-flex align-items-center mb-3">
          <div>
            <img class="icon-lg me-2" src="{{baseUrl}}assets/images/user.png" />
          </div>
          <div>
            <div>
              <span>{{user?.userType=='I'? 'Personal Account':'Corporate Accounts'}}</span>
              <span *ngIf="!!user?.nickname">:&nbsp;&nbsp;&nbsp;{{user?.nickname}}</span>
            </div>
            <div class="fs-smaller text-accent">{{user?.username}}</div>
          </div>
        </div>
        <div class="background-accent d-flex my-2 px-1 py-2 rounded-1 cursor-pointer" matRipple routerLink="/membership">
          <mat-icon class="mx-2">workspace_premium</mat-icon>
          <span>My rights</span>
        </div>
        <div class="background-accent d-flex my-2 px-1 py-2 rounded-1 cursor-pointer" matRipple routerLink="/my-trade">
          <mat-icon class="mx-2">receipt_long</mat-icon>
          <span>My Records</span>
        </div>
        <div class="background-accent d-flex my-2 px-1 py-2 rounded-1 cursor-pointer" matRipple routerLink="/user-center">
          <mat-icon class="mx-2">manage_accounts</mat-icon>
          <span>Personal Center</span>
        </div>
        <div class="my-3"></div>
        <div class="d-flex align-items-center">
          <div class="m-2" *ngIf="identities.length>1">Switching identities</div>
          <div class="flex-grow-1"></div>
          <button mat-button color="primary" (click)="logoutClick()">Log out</button>
        </div>
        <div *ngIf="identities.length>1">
          <div class="d-flex align-items-center my-1 p-2 rounded-1 cursor-pointer"
            [class.selected]="identity.active" matRipple *ngFor="let identity of identities"
            (click)="changeIdentity($event, identity)">
            <div>
              <img class="icon-md me-2" src="{{baseUrl}}assets/images/user.png" />
            </div>
            <div class="flex-grow-1 lh-sm">
              <div>{{identity.nickname}}</div>
              <div class="fs-smaller text-accent">{{identity.username}}</div>
            </div>
            <div *ngIf="identity.active">
              <mat-icon class="icon-sm" color="primary">done</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </mat-menu>
  </div>

</div>
