<div class="d-flex flex-row p-2">
  <h2 class="m-0">Image Preview</h2>
  <div class="flex-1"></div>
  <div class="btn-dialog-close">
    <button mat-icon-button class="btn-sm" color="accent" (click)="cancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="image-preview-body flex-1 d-flex flex-column flex-lg-row m-auto">

  <div class="d-flex align-items-center p-2">
    <button mat-mini-fab class="btn-sm m-auto btn-previous" color="accent" (click)="previousJobClick()"
      [disabled]="!hasPreviousJob" [style.visibility]="hasRelatedJobs?'visible':'hidden'">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>

  <div class="d-flex flex-wrap flex-column flex-lg-row position-relative">
    <div class="col-12 col-lg-6 max-h-100 d-flex">
      <div [class.col-6]="rawImageUrls.length>1" *ngFor="let rawImageUrl of rawImageUrls">
        <image-container class="image-container background-light" [width]="job.imageWidth" [height]="job.imageHeight"
          [loading]="!rawImageUrl" *ngIf="hasRawImage(job)">
          <img [src]="rawImageUrl" *ngIf="!!rawImageUrl" />
        </image-container>
      </div>
    </div>
    <div class="col-12 col-lg-6 max-h-100">
      <image-container class="image-container background-light" [width]="job.imageWidth" [height]="job.imageHeight"
        [loading]="!generatedImageUrl">
        <img [src]="generatedImageUrl" *ngIf="!!generatedImageUrl" />
      </image-container>
    </div>
    <div class="image-mask">
      <img src="/assets/images/logo.svg" />
    </div>
  </div>

  <div class="d-flex align-items-center p-2">
    <button mat-mini-fab class="btn-sm m-auto btn-next" color="accent" (click)="nextJobClick()" [disabled]="!hasNextJob"
      [style.visibility]="hasRelatedJobs?'visible':'hidden'">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

</div>

<div class="my-2">

</div>
