<div class="background-lighter user-picker">

  <div class="d-flex flex-row">
    <h2>Select User</h2>
    <div class="flex-1"></div>
    <button mat-icon-button class="btn-sm" color="accent" (click)="cancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form class="d-flex flex-wrap" [formGroup]="inputForm">
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-label>User Account / Phone number</mat-label>
        <input matInput formControlName="username" maxlength="32">
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-label>Company Name</mat-label>
        <input matInput formControlName="companyName" maxlength="32">
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4 text-center py-2">
      <button mat-flat-button mat-button-base color="primary" (click)="searchClick()">Query</button>
    </div>
  </form>

  <div [class.d-none]="!searched">
    <mat-paginator [pageSizeOptions]="[10, 25, 100, 200]" [class.d-none]="dataSource.data.length==0"></mat-paginator>
    <mat-table class="mb-3" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-2 col-lg-1"></mat-header-cell>
        <mat-cell *matCellDef="let row" class="col-2 col-lg-1">
          <mat-icon>{{row.id==selectedUser?.id? 'radio_button_checked' : 'radio_button_unchecked'}}</mat-icon>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-3">User Account</mat-header-cell>
        <mat-cell *matCellDef="let row" class="col-6 col-lg-3">{{row.username}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="nickname">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-3">User Nickname</mat-header-cell>
        <mat-cell *matCellDef="let row" class="col-6 col-lg-3">{{row.nickname}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-5">Company Name</mat-header-cell>
        <mat-cell *matCellDef="let row" class="col-6 col-lg-5">{{row.companyName}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns" class="row g-0"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;" class="row g-0"
        (click)="selectUser(row)"></mat-row>
      <div *matNoDataRow>
        <div class="text-center my-5 py-5">No matching data found</div>
      </div>
    </mat-table>
    <div class="text-center">
      <button class="btn-min-width" mat-flat-button mat-button-base color="primary" (click)="confirmClick()"
        [disabled]="!selectedUser">Sure</button>
    </div>
  </div>


</div>
