
<div class="page-header">
  <h4 class="title me-5">Material Management</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="addNewClick()">
    <mat-icon color="primary">add_circle</mat-icon><span>New Materials</span>
  </button>
</div>

<form class="d-flex flex-wrap" [formGroup]="inputForm">
  <div class="col-12 col-md-3">
    <mat-form-field>
      <mat-label>category</mat-label>
      <mat-select formControlName="category">
        <mat-option [value]="">(All)</mat-option>
        <mat-option *ngFor="let item of categories" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-3">
    <mat-form-field>
      <mat-label>name</mat-label>
      <input matInput formControlName="name" maxlength="32">
    </mat-form-field>
  </div>
  <!-- <mat-form-field class="col-md-3 col-12">
    <mat-label>Tips</mat-label>
    <input matInput formControlName="prompts" maxlength="32">
  </mat-form-field> -->
  <div class="col-12 col-md-3">
    <mat-form-field>
      <mat-label>state</mat-label>
      <mat-select formControlName="active">
        <mat-option [value]="">(All)</mat-option>
        <mat-option value="Y">efficient</mat-option>
        <mat-option value="N">invalid</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-3 text-center py-2">
    <button mat-flat-button mat-button-base color="primary" (click)="searchClick()">Query</button>
  </div>
</form>

<div [class.d-none]="dataSource.data.length==0">

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="categoryName">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">category</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.categoryName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef class="col-6 col-lg-2">Display image</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-2"><div><img class="material-thumnail" [src]="row.url" *ngIf="!!row.url" /></div></mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">name</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="labels">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-3">Label</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-3">{{row.labels}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="sortOrder">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-4 col-lg-1">order</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-4 col-lg-1">{{row.sortOrder}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-4 col-lg-1">state</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-4 col-lg-1">{{row.active=="Y"? "efficient":"invalid"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-4 col-lg-1"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-4 col-lg-1">
        <button mat-icon-button color="primary" matTooltip="Revise" (click)="editClick(row)">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="row g-0"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row g-0"></mat-row>
  </mat-table>
  <mat-paginator pageSize="100" [pageSizeOptions]="[10, 25, 100, 200]"></mat-paginator>

  <br />
  <br />

</div>
