import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'image-dimension-input',
  templateUrl: './image-dimension-input.html',
  styleUrl: './image-dimension-input.scss',
})
export class ImageDimensionInput {

  @Input() width:string | undefined;
  @Input() height:string | undefined;
  @Output() widthChange = new EventEmitter<string>();
  @Output() heightChange = new EventEmitter<string>();

  constructor() {

  }

  widthChanged() {
    this.widthChange.emit( this.width );
  }

  heightChanged() {
    this.heightChange.emit( this.height );
  }

}