import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'progress-dialog',
  templateUrl: './progress-dialog.component.html',
})
export class ProgressDialogComponent implements OnInit, OnDestroy {

  private processSubscription?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (!!this.data.process) {
      this.processSubscription = this.data.process.subscribe((result: boolean) => {
        if (!result) {
          this.dialogRef.close();
        }
      });
    }
  }

  ngOnDestroy() {
    if (!!this.processSubscription) {
      this.processSubscription.unsubscribe();
    }
  }

}
