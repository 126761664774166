import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';

@Component({
  selector: 'user-asset-group-edit',
  templateUrl: './user-asset-group-edit.dialog.html',
  styleUrl: './user-asset-group-edit.dialog.scss',
})
export class UserAssetGroupEditDialog extends BaseComponent implements OnInit {
  inputForm: FormGroup;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialogRef: MatDialogRef<UserAssetGroupEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    this.inputForm = new FormGroup({
      id: new FormControl(),
      category: new FormControl(),
      name: new FormControl(undefined, [Validators.required,]),
      sortOrder: new FormControl(),
    });
    this.inputForm.patchValue(data);
  }

  ngOnInit() {
    let id = this.id.value
    if (!id) {
      return; // add new
    }
    this.http.get(`/user/asset-group/${id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.inputForm.patchValue(result.data);
      }
    });
  }

  cancelClick() {
    this.dialogRef.close();
  }

  saveClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let id = this.id.value
    let url = '/user/asset-group' + (!id ? '' : `/${id}`);
    let params = this.inputForm.value;
    this.http.post(url, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        if (!id) {
          this.inputForm.patchValue({
            id: result.data,
          });
        }
        this.dialogRef.close({
          data: this.inputForm.value,
        });
      }
    });
  }

  get id() { return this.inputForm.controls['id']; }
  get name() { return this.inputForm.controls['name']; }

}

