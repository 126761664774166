<page-header title="AIChange clothes" />

<div class="flex-grow-1 h-0 d-flex flex-column flex-lg-row">

  <div>
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.faceLift" (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <div class="m-1"></div>

  <div class="flex-1" [class]="!taskId? 'd-none':'d-flex flex-column'">

    <flexible-accordion class="h-lg-100">

      <div class="flexible-accordion-header">
        <mat-card class="input-container min-h-100">
          <div class="mb-2">
            <image-picker class="ratio ratio-16x9" #imagePicker (changed)="rawImageChanged($event)" />
          </div>
          <mat-tab-group>
            <mat-tab label="Text description"> 
              <div class="mb-2">
                <image-textual-input [(value)]="prompts" [(negative)]="negativePrompts" />
              </div>
          
              <div class="mb-2">
                <image-size-input [(aspect)]="aspect" />
              </div>
          
              <div class="mb-2">
                <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
              </div> </mat-tab>
              <mat-tab label="Quick Templates">
                <mat-tab-group>
                  <mat-tab label="Fixed template"> 
                    <div class="mb-2">
                      <div class="mx-2">Model selection</div>
                          <div class="template-item" matRipple [matRippleCentered]="true" *ngFor="let item of models;"
                            (click)="modelClick(item)">
                            <img [src]="item.url" *ngIf="!!item.url" />
              
                      </div>
                    </div>  
      
      
                    <div class="mb-2">
                      <material-select src="/material/templates" [selectedTemplateId]="selectedTemplateId"
                        (selectionChange)="onMaterialSelected($event)" />
                    </div>
                  </mat-tab>
                  <mat-tab label="Custom upload">
                    <div class="mb-2">
                      <mat-card class="h-100" [class.d-none]="!!rawImageBlob">
                        <div class="col-12 d-flex flex-column m-auto">
                          <image-picker class="ratio ratio-16x9" #imagePicker (changed)="rawImageChanged($event)" />
                        </div>
                      </mat-card>
                    </div>
                  </mat-tab> 
                </mat-tab-group>
              </mat-tab>  
            </mat-tab-group>



          <div class="d-flex py-2">
            <cost-estimator category="image" item="paint" [quantity]="quantity" />
            <div class="flex-grow-1"></div>
            <button mat-flat-button color="primary" (click)="generateClick()">AI Generate</button>
          </div>
        </mat-card>
      </div>

      <div class="flex-grow-1 h-lg-100">

        <div class="d-flex flex-column w-100 h-100 p-lg-2" [class.d-none]="paintedImages.length==0">
          <painted-image-list #paintedImageList [(value)]="paintedImages" (completed)="onPaintCompleted()" />
        </div>

        <!--<user-task-list #userTasks category="1" (selectionChange)="userTaskSelectionChange($event)" />-->

      </div>

    </flexible-accordion>
  </div>

</div>

