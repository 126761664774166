import { Component, OnInit, ViewChild, Injector, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { SelectListItem } from '../models';
import { MessageResource } from '../common/message.service';

@Component({
  selector: 'material-search',
  templateUrl: './material-search.component.html',
  styleUrl: './material-search.component.scss',
})
export class MaterialSearchComponent extends BaseComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['categoryName', 'image', 'name', 'labels', 'sortOrder', 'active', 'action'];
  categories?: SelectListItem[];
  inputForm: FormGroup;
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      category: new FormControl(),
      name: new FormControl(),
      prompts: new FormControl(),
      active: new FormControl("Y"),
    });
  }

  ngOnInit() {
    this.http.get('/material/templates/options').subscribe(response => {
      this.categories = response.data.categories;
    });

    var preSearchParams = this.sessionService.getItem("admin.material.search");
    if (!!preSearchParams) {
      this.inputForm.patchValue(preSearchParams);
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }
    this.search()
  }

  search() {
    var params = this.inputForm.value;
    this.http.post('/material/templates/search', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let data = result.data??[];
        this.dataSource.data = data;
        if (data.length == 0) {
          this.messageService.showInformation(MessageResource.NO_DATA_MACHED);
          return;
        }
        data.forEach((element:any) => {
          element.url = this.http.getAbsoluteUrl(`/public/material/templates/${element.id}/thumbnail`);
          if(!!element.labels){
            element.labels = JSON.parse(element.labels).join(", ");
          }
        });
        this.sessionService.setItem("admin.material.search", params);
      }
    });
  }

  editClick(row: any) {
    this.router.navigate([`/admin/material/${row.id}/edit`]);
  }

  addNewClick() {
    this.router.navigate(['/admin/material/add']);
  }

}
