<div class="d-flex py-2">
  <div>Scaling</div>
  <div class="flex-grow-1"></div>
  <div class="d-flex align-items-center" matRipple [matRippleCentered]="true" (click)="addScaleClick(-10)">
    <mat-icon>remove</mat-icon>
  </div>
  <input matInput #message maxlength="4" [(ngModel)]="value" (change)="valueChaned()" style="width: 60px;text-align: center;">
  <div class="d-flex align-items-center" matRipple [matRippleCentered]="true" (click)="addScaleClick(10)">
    <mat-icon>add</mat-icon>
  </div>
</div>
