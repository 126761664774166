
<div>
  <div class="d-flex">
    <mat-icon class="m-2" color="accent">help</mat-icon>
    <h1 mat-dialog-title class="px-0">Confirm</h1>
  </div>
  <div mat-dialog-content class="confirm-dialog-content">
    {{data.question}}
  </div>
  <div mat-dialog-actions align="end">
    <button mat-stroked-button class="btn-min-width mx-auto" (click)="cancelClick()">{{data.cancleButtonName}}</button>
    <button mat-flat-button color="primary" class="btn-min-width mx-auto" (click)="confirmClick()">{{data.confirmButtonName}}</button>
  </div>
</div>
