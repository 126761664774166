import { Component, Injector } from '@angular/core';
import { FileInputInfo, InputStatus, SelectListItem, UserInputInfo } from '../models';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { DateTimeHelper } from '../helpers/app.helpers';

@Component({
  selector: 'order-edit',
  templateUrl: './order-edit.component.html',
  styleUrl: './order-edit.component.scss',
})
export class OrderEditComponent extends BaseComponent {
  payTypeList?: SelectListItem[];
  statusList?: SelectListItem[];
  itemTypeList?: SelectListItem[];
  inputForm: FormGroup;
  canEdit = true;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      id: new FormControl(),
      orderNo: new FormControl('', [Validators.required]),
      employeeNo: new FormControl(''),
      employeeName: new FormControl(''),
      user: new FormControl(''),
      // username: new FormControl(''),
      payType: new FormControl(),
      payAccount: new FormControl(),
      payReceivedDate: new FormControl(),
      // payVoucherFile: new FormControl(undefined),
      payVoucherFile: new FormControl(new FileInputInfo()),
      payVoucherFileChanged: new FormControl(),
      status: new FormControl(),
      description: new FormControl(),
      modifiedDate: new FormControl(),
      items: this.formBuilder.array([
        this.buildItemsFormGroup(),
      ]),
    });
  };

  ngOnInit() {
    this.http.get('/orders/options').subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      this.statusList = result.data.statusList;
      this.payTypeList = result.data.payTypeList;
      this.itemTypeList = result.data.itemTypeList;
    });

    this.route.paramMap.subscribe(params => {
      this.init(params);
    });
  }

  init(params: any) {
    let id = params == undefined ? "" : params.get('id');

    if (!id) { //add new
      return;
    } else { //edit
      this.find(id);
    }
  }

  find(id: any) {
    this.http.get(`/orders/${id}/details`).subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      this.patchToForm(result.data);
    });
  };

  saveClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let id = this.id.value
    let url = '/orders' + (!id ? '' : `/${id}`);
    let values = this.inputForm.value;
    values["payReceivedDate"] = DateTimeHelper.format(values["payReceivedDate"], 'yyyy-MM-dd');
    (values["items"] ?? []).forEach((element: any) => {
      element["startDate"] = DateTimeHelper.format(element["startDate"], 'yyyy-MM-dd');
      element["endDate"] = DateTimeHelper.format(element["startDate"], 'yyyy-MM-dd');
    });
    if (!values.imageFileChanged) {
      values.imageFile = undefined;
    }
    let user = values.user;
    if(!!user){
      values.userId = user.id;
      delete values.user;
    }
    let params = this.http.object2FormData(values);
    this.http.post(url, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        // if (!id) {
        //   this.inputForm.patchValue({
        //     id: result.data,
        //   });
        // }
        this.messageService.showInformation('The order has been saved successfully。');
        this.router.navigate(['/admin/orders/search']);
      }
    });
  }

  deleteClick() {
    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the order?？',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      let id = this.id.value
      this.http.delete(`/orders/${id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.messageService.showInformation('The order has been successfully deleted。');
          this.router.navigate(['/admin/orders/search']);
        }
      });
    });
  }

  backClick() {
    this.router.navigate(['/admin/orders/search']);
  };

  imageFileChanged(file: Blob | undefined) {
    if (this.inputForm.controls['imageFile'].value != file) {
      this.inputForm.patchValue({
        imageFileChanged: true,
      });
    }
    this.inputForm.patchValue({
      imageFile: file,
    });
  }

  get id() { return this.inputForm.controls['id']; }

  private buildItemsFormGroup() {
    let formGroup = this.formBuilder.group({
      id: new FormControl(),
      itemType: new FormControl("", Validators.required),
      amount: new FormControl("", [Validators.required, Validators.pattern("^[0-9]+(\.[0-9]{1,2})?$")]),
      totalPoints: new FormControl("", [Validators.required, Validators.pattern("^[0-9]+$")]),
      points: new FormControl("", [Validators.required, Validators.pattern("^[0-9]+$")]),
      startDate: new FormControl(),
      endDate: new FormControl(),
      description: new FormControl(""),
    });
    return formGroup;
  }

  get itemFormArray(): FormArray {
    return <FormArray>this.inputForm.controls['items'];
  }

  addOrderItem() {
    this.itemFormArray.push(this.buildItemsFormGroup());
  }

  removeOrderItem(index: number) {
    this.itemFormArray.removeAt(index);
  }

  private patchToForm(values: any) {
    this.itemFormArray.clear();
    for (let i in values?.items ?? []) {
      this.addOrderItem();
    }
    // values.shiftStartDate = this.parseDate(values.shiftStartDate);
    // values.shiftEndDate = this.parseDate(values.shiftEndDate);
    // values.startDate = this.parseDate(values.startDate);
    // values.endDate = this.parseDate(values.endDate);
    let payVoucherFile = new FileInputInfo();
    payVoucherFile.name = values.payVoucherFile;
    payVoucherFile.status = !!payVoucherFile.name ? InputStatus.Exist : InputStatus.None;
    values.payVoucherFile = payVoucherFile;
    let user = new UserInputInfo();
    user.id = values.userId;
    user.name = values.username;
    user.status = !!user.id ? InputStatus.Exist : InputStatus.None;
    values.user = user;
    this.inputForm.patchValue(values);
  }

}
