<page-header title="Scene Creation Studio">
  <div class="flex-grow-1 d-flex">
    <div class="flex-grow-1"></div>
    <div class="step-container d-flex align-items-center m-auto mb-1" [class.d-none]="!taskId">
      <div *ngFor="let item of steps; index as i;">
        <img class="icon-sm mx-3" src="/assets/images/arrow_right.png" *ngIf="i!=0" />
        <button mat-button class="step-item btn-md" [class.active]="selectedStepperIndex==item.value" color="primary"
          (click)="stepperClick(item.value)">
          <img [src]="'/assets/images/'+item.icon" />
          <span>{{item.name}}</span>
        </button>
      </div>
    </div>
    <div class="flex-grow-1"></div>
    <div class="flex-grow-1"></div>
    <div class="flex-grow-1"></div>
  </div>
</page-header>

<flexible-accordion class="flex-grow-1 h-0 d-flex flex-column flex-lg-row" [(expanded)]="expanded">

  <div class="flexible-accordion-header">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.designImage"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <div class="flex-grow-1 h-lg-100 d-flex flex-column flex-lg-row" *ngIf="!!taskId">

    <image-selector #imageSelector [class.d-none]="selectedStepperIndex != ImageGenerateStepsEnum.Step0010"
      (completed)="imageSelectCompleted($event, true)" (stateChanged)="imageSelectStateChanged($event)" />

    <image-generator #imageGenerator
      [class.d-none]="selectedStepperIndex != ImageGenerateStepsEnum.Step0020 && selectedStepperIndex != ImageGenerateStepsEnum.Step0030"
      [taskId]="taskId" [selectedTemplateId]="selectedTemplateId"
      (expandedChange)="imageGeneratorExpandedChanged($event)" (completed)="imageGeneratorCompleted($event)"
      (stateChanged)="imageGeneratorStateChanged($event)" />

    <image-refactor #imageRefactor [class.d-none]="selectedStepperIndex != ImageGenerateStepsEnum.Step0040"
      [taskId]="taskId" (expandedChange)="imageRefactorExpandedChanged($event)"
      (completed)="imageRefactorCompleted($event)" (stateChanged)="imageRefactorStateChanged($event)" />

    <!--<div [class.d-none]="selectedStepperIndex != ImageGenerateStepsEnum.Step0050"></div>-->

  </div>

</flexible-accordion>
