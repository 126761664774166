<page-header title="Clothing color change" />

<div class="flex-grow-1 h-0 d-flex flex-column flex-lg-row">

  <div class="me-1" [class.d-none]="!isExpanded">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.changeClothColor"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <user-favorite-list [type]="JobTypesEnum.changeColor" *ngIf="!taskId" />

  <div [class]="!taskId? 'd-none':'d-flex flex-column'">

    <flexible-accordion class="h-lg-100">
      <div #inputMatCard class="input-container flexible-accordion-header">

        <mat-card class="h-100 p-0 d-flex flex-column">

          <div class="px-2 task-header background-lighter">

            <div class="d-flex flex-row align-items-center">
              <div class="d-flex align-items-center" matTooltip="task bar" (click)="isExpanded=!isExpanded">
                <mat-icon class="cursor-pointer" matRipple color="primary" svgIcon="app:my_tasks" />
              </div>
              <div class="ms-2 me-3" *ngIf="!taskNameEditable">
                <h3 class="my-0">{{userTaskList.curTask?.name}}</h3>
              </div>

              <!--<text-area class="text-area background-lighter task-name-area ms-2 me-2 mat-subtitle-1" 
               [(ngModel)]="taskName" matInput rows="4" cols="10" placeholder=""
               *ngIf="taskNameEditable"></text-area>-->

              <mat-icon color="accent" class="cursor-pointer" matTooltip="Duplicate naming" svgIcon="app:edit"
                (click)="userTaskList.editUserTaskClick($event,userTaskList.curTask!)"> </mat-icon>
            </div>
            <div class="flex-grow-1"></div>
          </div>

          <div class="mx-2">

            <div class="d-flex my-2">
              <span class="mat-small hint-text"> Upload model pictures，Replace the whole garment color or adjust partial color</span>
            </div>


            <div class="d-flex flex-wrap">

              <div class="raw-image-zone col-12 col-lg-6 ps-0 pe-lg-1">
                <div class="ratio ratio-1x1 background-lighter image-area">
                  <div class="image-label d-flex my-0 px-2 py-1">
                    <p class="mat-small my-0"> Original image</p>
                  </div>

                  <image-picker #imagePicker (changed)="rawImageChanged($event)" [class.d-none]="!!rawImageUrl" />
                  <img class="raw-image" [class.d-none]="!rawImageUrl" [src]="rawImageUrl"
                    (load)="onRawImageLoaded($event)" (click)="rawImagePicker.click()" />
                </div>
              </div>

              <div class="sticker-image-zone col-12 col-lg-6 ps-lg-1 pe-0">

                <div class="sticker-image-area flex-column d-flex align-items-center justify-content-center 
               background-lighter" [ngClass]="{'background-alpha-dark': !stickerEmpty()}">

                  <div class="image-label d-flex my-0 px-2 py-1">
                    <p class="mat-small my-0">District map</p>
                  </div>

                  <div class="sticker-area-mask d-flex flex-column align-items-center justify-content-center"
                    [class.d-none]="!stickerEmpty()">

                    <p class="mat-body my-0">Curing area</p>
                    <p class="mat-small mt-1 mb-0 hint-text">Please select the area you want to keep unchanged</p>

                    <button mat-button class="app-button rounded-pill my-3" color="primary"
                      (click)="segmentPickerClick($event);">
                      <div class="d-flex align-items-center">
                        <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:edit'"></mat-icon>
                        <p class="button-text my-0">Edit Selection&nbsp;</p>
                      </div>
                    </button>
                  </div>

                  <div class="position-absolute start-0 bottom-0" [class.d-none]="stickerEmpty()">
                    <button mat-flat-button class="app-button rounded-pill m-1 ps-0 pe-2" color="primary"
                      (click)="segmentPickerClick($event);">
                      <div class="d-flex align-items-center">
                        <mat-icon class="attri-icon-size" [svgIcon]="'app:edit'"></mat-icon>
                        <p class="button-text my-0 fs-smaller">Re-edit</p>
                      </div>
                    </button>
                  </div>

                  <div class="sticker-container">
                    <img class="sticker-background-image" [src]="stickerBackgroundImageUrl"
                      [class.d-none]="!stickerBackgroundImageUrl" />
                    <image-sticker-input [modelScale]="modelScale" [enableEdit]="false" #stickers />
                  </div>
                  <element-floatable>
                    <div class="bottom-right d-flex">
                      <!-- <button mat-icon-button class="btn-sm bg-white m-1" color="primary" matTooltip="delete"
                       (click)="deleteStickerImageClick()">
                       <mat-icon>delete</mat-icon>
                     </button> -->
                      <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="download"
                        (click)="exportStickerImageClick()">
                        <mat-icon svgIcon="app:download"></mat-icon>
                      </div>
                    </div>

                  </element-floatable>

                </div>
              </div>
            </div>

            <h3 class="mt-3 mb-1">Parameter selection</h3>
            <span class="mat-small hint-text">Support multiple selection,You can choose up to four colors to produce four sheets</span>

            <div class="d-flex flex-wrap">

              <div class="color-item mt-2 col-6 pe-2 d-flex flex-row align-items-center"
                *ngFor="let color of colorArray; let i = index">
                <div class="color-display-area" [style.background]="colorArray[i]" [cpOKButton]="true"
                  cpOKButtonText="Sure" cpOKButtonClass="color-picker-ok-button" [cpCancelButton]="true"
                  cpCancelButtonText="Cancel" cpCancelButtonClass="color-picker-cancel-button" [cpSaveClickOutside]="false"
                  [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                  [cpOutputFormat]="'hex'" [colorPicker]="colorArray[i]" cpPosition="bottom"
                  (colorPickerSelect)="changeColor($event, i)">
                </div>
                <span class="mx-2 mat-small my-0">{{colorArray[i]}}</span>

                <div class="flex-grow-1"></div>

                <mat-icon color="accent" [svgIcon]="'app:delete'" [matTooltip]="'delete'"
                  (click)="deleteColor(i)"></mat-icon>
              </div>

              <div class="mt-2 col-6" [class.d-none]="curColorNum >= 4">
                <button mat-button class="add-color-button" color="primary" [cpOKButton]="true" cpOKButtonText="Sure"
                  cpOKButtonClass="color-picker-ok-button" [cpCancelButton]="true" cpCancelButtonText="Cancel"
                  cpCancelButtonClass="color-picker-cancel-button" [cpSaveClickOutside]="false"
                  [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
                  [cpOutputFormat]="'hex'" [colorPicker]="newColor" cpPosition="bottom"
                  (colorPickerSelect)="addNewColor($event)">
                  <div class="d-flex align-items-center justify-content-center">
                    <mat-icon class="icon-scale" color="primary" svgIcon="app:add_task"></mat-icon>
                    <span class="mat-body">Adding Color</span>
                  </div>
                </button>
              </div>
            </div>


          </div>

          <div class="flex-grow-1"></div>

          <div class="px-2 py-1 background-lighter task-footer w-100 d-flex flex-column">

            <!--<image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />-->

            <div class="d-flex mt-1">
              <cost-estimator category="image" item="paint" [quantity]="curColorNum" />
              <div class="flex-grow-1"></div>
              <button mat-flat-button class="px-4" color="primary" (click)="generateClick()"
                [disabled]="processing">AI Generate</button>
            </div>

          </div>

        </mat-card>

      </div>

      <div class="h-lg-100 user-operation-area" *ngIf="taskId">

        <div class="d-flex flex-column w-100 h-100 p-lg-2">
          <user-favorite-list [type]="JobTypesEnum.changeColor" [taskId]="taskId" />
          <user-operation-list #userOperationList [taskId]="taskId" [notificationCategory]="userNotificationCatecory"
            (clone)="cloneClick($event)" (statusChange)="userOperationStatusChange($event)" />
        </div>
      </div>

    </flexible-accordion>

  </div>

</div>
