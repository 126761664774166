import { Component, ElementRef, Optional, Self, ViewChild, Input, OnDestroy, DoCheck } from '@angular/core';
import { NgControl, ControlValueAccessor, NgForm, FormGroupDirective } from '@angular/forms';
import { CanUpdateErrorState, ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { InputBaseComponent } from './input-base.component';
import { UserInputInfo, InputStatus } from '../models';
import { UserPickerDialog } from '../user-picker-dialog/user-picker-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'user-input',
  templateUrl: './user-input.html',
  styleUrl: './user-input.scss',
  providers: [{ provide: MatFormFieldControl, useExisting: UserInput }],
})
export class UserInput
  extends InputBaseComponent<UserInputInfo>
  implements ControlValueAccessor, MatFormFieldControl<UserInputInfo>, OnDestroy, DoCheck, CanUpdateErrorState {
  static nextId = 0;
  id = `user-input-${UserInput.nextId++}`;

  constructor(
    _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private dialog: MatDialog,
  ) {
    super(_elementRef, ngControl, _parentForm, _parentFormGroup, _defaultErrorStateMatcher);
  }

  override get empty(): boolean {
    return !this.value?.name;
  }

  @Input()
  override get value(): UserInputInfo | null {
    return this._value;
  }
  override set value(value: UserInputInfo | null) {
    if (value !== this._value) {
      this._value = value;
      if (this.ngControl?.control) {
        this.ngControl.control.setValue(value);
      }

      this.stateChanges.next();
    }
  }
  private _value: UserInputInfo | null = null;

  override get shouldLabelFloat(): boolean {
    return true;
  }

  @ViewChild('userInput') userInput!: ElementRef;

  operUserPickerDialog() {
    this.dialog.open(UserPickerDialog, {
      maxWidth: '96vw',
      maxHeight: '96vh',
      // data: {
      //   type: UserTypes.individual,
      // },
    }).afterClosed().subscribe((user) => {
      if (!user) {
        return;
      }
      let value = new UserInputInfo();
      if (!this.value?.id) {
        value.status = InputStatus.AddNew;
      } else {
        value.status = InputStatus.Replaced;
      }
      value.id = user.id;
      value.name = user.username;
      this.value = value;
      this.onTouched();
    });
  }

  resetClick(event: any) {
    event.stopPropagation();
    let value = new UserInputInfo();
    value.id = undefined;
    value.name = '';
    if (!value.id) {
      value.status = InputStatus.None;
    } else {
      value.status = InputStatus.Removed;
    }
    this.value = value;
    this.onTouched();
  }

}
