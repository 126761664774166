import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { ProgressSpinnerDialog } from '../progress-dialog/progress-spinner-dialog';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { FileInputInfo } from '../models';

export enum ActionStatus {
  Success = "success",
  Running = "running",
  Warn = "warn",
  Failed = "failed"
}

export class ActionResult {
  status?: string;
  message?: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AppHttpClient {

  constructor(private http: HttpClient,
    private progressDialog: ProgressSpinnerDialog,
    private messageService: MessageService) {

  }

  get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType?: 'json';
    silence?: boolean;
  }): Observable<ActionResult>
  get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType: 'blob';
    silence?: boolean;
  }): Observable<Blob>
  get(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType?: 'json' | 'blob';
    silence?: boolean;
  }): Observable<any> {
    if (!this.checkSilenceBefore(options?.silence)) {
      return EMPTY;
    }
    url = this.getAbsoluteUrl(url);
    if (options?.responseType === 'blob') {
      return this.http.get(url, { headers: options?.headers, responseType: 'blob' }).pipe(
        catchError(err => this.handleHttpErrorResponse(err, options?.silence))
      );
    } else {
      return this.http.get<ActionResult>(url, { headers: options?.headers, responseType: 'json' }).pipe(map(response => {
        this.checkSilenceAfter(options?.silence);
        return response;
      })).pipe(
        catchError(err => this.handleHttpErrorResponse(err, options?.silence))
      );
    }
  }

  post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType?: 'json';
    silence?: boolean;
  }): Observable<ActionResult>
  post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType: 'blob';
    silence?: boolean;
  }): Observable<Blob>
  post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType?: 'json' | 'blob';
    silence?: boolean;
  }): Observable<any> {
    if (!this.checkSilenceBefore(options?.silence)) {
      return EMPTY;
    }
    url = this.getAbsoluteUrl(url);
    if (options?.responseType === 'blob') {
      return this.http.post(url, body, { headers: options?.headers, responseType: 'blob' }).pipe(
        catchError(err => this.handleHttpErrorResponse(err, options?.silence))
      );
    } else {
      return this.http.post<ActionResult>(url, body, { headers: options?.headers, responseType: 'json' }).pipe(map(response => {
        this.checkSilenceAfter(options?.silence);
        return response;
      })).pipe(
        catchError(err => this.handleHttpErrorResponse(err, options?.silence))
      );
    }
  }

  delete(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    body?: any | null;
    silence?: boolean;
  }): Observable<ActionResult> {
    if (!this.checkSilenceBefore(options?.silence)) {
      return EMPTY;
    }
    url = this.getAbsoluteUrl(url);
    return this.http.delete<ActionResult>(url, { headers: options?.headers, body: options?.body }).pipe(map(response => {
      this.checkSilenceAfter(options?.silence);
      return response;
    })).pipe(
      catchError(err => this.handleHttpErrorResponse(err, options?.silence))
    );
  }

  patch(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType?: 'json';
    silence?: boolean;
  }): Observable<ActionResult>
  patch(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType: 'blob';
    silence?: boolean;
  }): Observable<Blob>
  patch(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    responseType?: 'json' | 'blob';
    silence?: boolean;
  }): Observable<any> {
    if (!this.checkSilenceBefore(options?.silence)) {
      return EMPTY;
    }
    url = this.getAbsoluteUrl(url);
    if (options?.responseType === 'blob') {
      return this.http.patch(url, body, { headers: options?.headers, responseType: 'blob' }).pipe(
        catchError(err => this.handleHttpErrorResponse(err, options?.silence))
      );
    } else {
      return this.http.patch<ActionResult>(url, body, { headers: options?.headers, responseType: 'json' }).pipe(map(response => {
        this.checkSilenceAfter(options?.silence);
        return response;
      })).pipe(
        catchError(err => this.handleHttpErrorResponse(err, options?.silence))
      );
    }
  }

  isValidResult(result: ActionResult): boolean {
    if (result.status === ActionStatus.Success) {
      this.messageService.showInformation(result.message);
      return true;
    } else if (result.status === ActionStatus.Warn) {
      this.messageService.showWarning(result.message);
      return true;
    } else {
      this.messageService.showError(result.message);
      return false;
    }
  }

  private checkSilenceBefore(silence: any): boolean {
    if (silence === false) {
      this.messageService.hide();
      if (!this.progressDialog.open()) {
        this.messageService.showWarning("Server is busing now, please retry later.");
        return false;
      }
    }
    return true;
  }

  private checkSilenceAfter(silence: any) {
    if (silence === false) {
      this.progressDialog.close();
    }
  }

  private handleHttpErrorResponse(error: any, silence: any) {
    this.checkSilenceAfter(silence);
    return throwError(() => error);
  }

  getAbsoluteUrl(url: string): string {
    if (/^http(s)?:/i.test(url)) {
      return url;
    }
    var apiServerUrl = environment.apiServerUrl;
    return apiServerUrl + url;
  }

  object2FormData(data: any, formData?: FormData, prefix?: string): FormData {
    formData = formData ?? new FormData();
    for (var prop in data) {
      let value = data[prop];
      if (!value) {
        continue;
      }
      let key = (!prefix ? '' : prefix + '.') + prop;
      switch (typeof value) {
        case 'string':
          formData.set(key, value);
          break;
        case 'number':
          formData.set(key, String(value));
          break;
        case 'boolean':
          formData.set(key, String(value));
          break;
        case 'object':
          if (value instanceof Array) {
            value.forEach((element, index) => {
              let key2 = `${key}[${index}]`;
              switch (typeof element) {
                case 'string':
                  formData!.set(key2, element);
                  break;
                case 'number':
                  formData!.set(key2, String(element));
                  break;
                case 'boolean':
                  formData!.set(key2, String(element));
                  break;
                case 'object':
                  this.object2FormData(element, formData, key2);
                  break;
              }
            });
          } else if (value instanceof File) {
            formData.set(key, value, value.name);
          } else if (value instanceof Blob) {
            formData.set(key, value);
          } else if (value instanceof FileInputInfo) {
            let file = value.file;
            if(!!file){
              formData.set(key, file, file.name);
            }
            formData.set(key + "Status", value.status??"");
          }
          break;
      }
    }
    return formData;
  }
}


@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private messageService: MessageService,
    private sessionService: SessionService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const requestId = uuidv4();

    let headers: any = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Request-ID': requestId,
      'robots': environment.apiKey
    };
    let xsrf = this.sessionService.user?.xsrfToken;
    if (!!xsrf) {
      headers['X-XSRF-TOKEN'] = xsrf;
    }
    let token = this.sessionService.user?.token;
    if (!!token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const creq = request.clone({ setHeaders: headers });

    return next.handle(creq).pipe(
      tap({
        error: (err: any) => {
          this.onError(err);
        }
      })
    );
  }

  onError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status == 401 || error.status == 403) {
        this.router.navigate(['error', error.status]);
        return;
        //} else {
        //  // The backend returned an unsuccessful response code.
        //  // The response body may contain clues as to what went wrong.
        //  console.error(`Backend returned code ${error.status}, body was: `, error.error);
      }
      //} else {
      //  // A client-side or network error occurred. Handle it accordingly.
      //  console.error('An error occurred in frontend:', error.error);
    }

    // this.messageService.showError("An error occurred while processing your request，Please try again later。");
  }

}

