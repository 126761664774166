import { Component, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild, } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { ActionResult, ActionStatus, AppHttpClient } from '../common/app.http';
import { Subscription } from 'rxjs';
import { AssetType, PaintedImage, UserAssetCategory, UserNotification, UserNotificationCategory, } from '../models';
import { BlobHelper, FileHelper, MediaTypeHelper } from '../helpers/app.helpers';
import { format } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { trimCanvas } from '../helpers/create-cutouts';
import { ModelScaleProps, MaterialTemplateDetails, ImageGenerateSteps, UserAssetGroupListItem, } from '../interfaces';
import { aspectToSize, caculateNearAspect, handleImageScale } from '../helpers/image-helper';
import { ImageSegmentPicker } from '../image-segment-picker/image-segment-picker';
import { IMessage } from '@stomp/rx-stomp';
import { RxStompService } from '../stomp/rx-stomp.service';
import { validRange } from '../common/app.forms';
import { PaintService } from '../services/paint.service';
import { MessageResource } from '../common/message.service';
import { ImageStickerInput } from '../image-generate-input/image-sticker-input';
import { PaintedImageList } from '../components/painted-image-list';
import { MatStepper } from '@angular/material/stepper';
import { UserAssetGroupPicker } from '../user-asset-group/user-asset-group-picker';
import { ImageDesignComponent } from '../image-design/image-design.component';

@Component({
  selector: 'image-generator',
  templateUrl: './image-generator.component.html',
  styleUrl: './image-generator.component.scss',
})
export class ImageGeneratorComponent extends BaseComponent implements OnInit {
  @Input() taskId!: number;
  @Input() expanded: boolean = true;
  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() completed = new EventEmitter<any>();
  @Output() stateChanged = new EventEmitter<any>();

  @Input() selectedTemplateId?: number;
  // @Input() set selectedTemplate(value: MaterialTemplateDetails | undefined) {
  //   this._selectedTemplate = value;
  //   if(!!value){
  //     this.prompts = value.prompts;
  //     this.negativePrompts = value.negativePrompts;
  //   }else{
  //     this.prompts = undefined;
  //     this.negativePrompts = undefined;
  //   }
  // }
  // get selectedTemplate(): MaterialTemplateDetails | undefined {
  //   return this._selectedTemplate;
  // }

  @ViewChild('stepper') stepper!: MatStepper;
  aspect: string | undefined;
  // imageSize?: Size;

  minQuantity: number = 1;
  maxQuantity: number = 4;
  quantity: string = '4';

  prompts?: string;

  negativePrompts?: string;

  _rawImageBlob?: Blob;
  set rawImageBlob(blob: Blob | undefined) {
    this.rawImageUrl = undefined;
    this._rawImageBlob = blob;
    if (!this._rawImageBlob) {
      return;
    }
    this.rawImageUrl = URL.createObjectURL(this._rawImageBlob);
  }
  get rawImageBlob(): Blob | undefined {
    return this._rawImageBlob;
  }

  rawImageUrl?: string;
  modelScale: ModelScaleProps | any = null;
  stickerBackgroundImageUrl?: string;
  @ViewChild('stickers') stickers!: ImageStickerInput;
  paintedImages: PaintedImage[] = [];
  @ViewChild('paintedImageList') paintedImageList!: PaintedImageList;

  private rmbgJobId?: number;
  private rembgSubscription!: Subscription;

  segmenting: boolean = false;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
    private rxStompService: RxStompService,
    private paintService: PaintService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.rembgSubscription = this.rxStompService
      .watch(UserNotificationCategory.RemoveBackground)
      .subscribe((message: IMessage) => {
        var notification = JSON.parse(message.body) as UserNotification;
        if (notification.id != this.rmbgJobId) {
          return;
        }
        var result = notification.data as ActionResult;
        if (!this.http.isValidResult(result)) {
          this.segmenting = false;
          return;
        }
        this.removedBackground(notification.id!);
      });
  }

  init(newImageBlob: Blob | undefined) {
    // if (!!this.rawImageBlob && this.rawImageBlob.size == rawImageBlob?.size) {
    //   return;
    // }
    // this.reset();
    let oldImageBlob = this.rawImageBlob;
    this.rawImageBlob = newImageBlob;
    if (!!oldImageBlob && oldImageBlob.size == newImageBlob?.size && this.stickers.value.length > 0) {
      // The image has not changed，And the picture has been cut out，Just exit
      return;
    }
    this.removeBackground();
  }

  ngOnDestroy() {
    this.rembgSubscription.unsubscribe();
  }

  onRawImageLoaded(event: Event) {
    let img = event.target as HTMLImageElement;
    let modelScale = handleImageScale(img);
    if (!this.aspect) {
      // When the size is not specified, find the closest size based on the uploaded image
      this.aspect = caculateNearAspect(modelScale.width, modelScale.height);
    }
    let size = aspectToSize(this.aspect)!;
    // this.stickers.resize({width:size.width, height: size.height, samScale:1});
    this.modelScale = { width: size.width, height: size.height, samScale: 1 };
    this.stickerBackgroundImageUrl = this.createStickerBackgroundImageUrl(this.aspect);
  }

  createStickerBackgroundImageUrl(aspect: string | undefined): string | undefined {
    let size = aspectToSize(aspect);
    if (!size) {
      return undefined;
    }
    const canvas = document.createElement('canvas');
    canvas.width = size.width;
    canvas.height = size.height;
    return canvas.toDataURL();
  }

  segmentPickerClick(e: any) {
    let rawImageUrl = this.rawImageUrl;
    if (!rawImageUrl) {
      return;
    }
    this.dialog.open(ImageSegmentPicker, {
      disableClose: true,
      data: {
        imageUrl: rawImageUrl,
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.stickers.value = [...this.stickers.value, ...result.stickers];
    });
  }

  reset() {
    // this.expanded = true;
    this.prompts = undefined;
    this.negativePrompts = undefined;
    this.aspect = undefined;
    this.quantity = '4';
    this.paintedImages = [];
    this.stickers.reset();
    this._rawImageBlob = undefined;
    this.rawImageUrl = undefined;
    this.stickerBackgroundImageUrl = undefined;
    this.modelScale = undefined;
    this.resetSegment();
  }

  resetSegment() {
    if (!!this.stickers) {
      this.stickers.reset();
    }
    this.paintedImages = [];
  }

  private stickersToCanvas() {
    let size = aspectToSize(this.aspect)!;
    // return this.stickers.drawToCanvas2(size.width, size.height);
    return this.stickers.drawToCanvas();
  }

  redoSegmentClick() {
    this.resetSegment();
    this.removeBackground();
  }

  exportStickerImageClick() {
    let imageUrl = this.stickersToCanvas().toDataURL('image/png', 1.0);
    FileHelper.download(
      imageUrl,
      `${format(new Date(), 'yyyyMMddHHmmss')}_sticker.png`
    );
  }

  refactorClick(image: PaintedImage) {
    this.completed.emit({
      from: ImageGenerateSteps.Step0030,
      next: ImageGenerateSteps.Step0040,
      data: image.data,
    });
  }

  async removeBackground() {
    let rawImageBlob = this.rawImageBlob
    if (!rawImageBlob) {
      return;
    }
    if (this.segmenting) {
      this.messageService.showWarning(MessageResource.WAITING_FOR_PROCESS);
      return;
    }
    this.segmenting = true;


    let fileName = BlobHelper.getName(rawImageBlob, 'input');
    var formData = new FormData();
    formData.set('file', rawImageBlob, fileName);

    this.http.post('/segment/rembg', formData).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          this.segmenting = false;
          return;
        }
        this.rmbgJobId = result.data;
      },
      error: (err: any) => {
        this.segmenting = false;
      },
    });
  }

  removedBackground(id: string) {
    this.http.get(`/segment/rembg/${id}/download`, { responseType: 'blob' })
      .subscribe({
        next: (result: Blob) => {
          createImageBitmap(result).then((image) => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext('2d', { willReadFrequently: true });
            ctx?.drawImage(
              image, 0, 0, image.width, image.height,
              0, 0, image.width, image.height
            );
            let sticker = trimCanvas(canvas);
            this.segmenting = false;
            setTimeout(() => {
              if (!sticker) {
                return;
              }
              this.stickers.value = [sticker];
              this.notifyStateChanged();
            }, 0);
          },
            (err: any) => {
              this.segmenting = false;
              this.messageService.showError(MessageResource.FAILED_TO_REMOVE_BACKGROUND);
              throw err;
            });
        },
        error: (err: any) => {
          this.segmenting = false;
        },
      });
  }

  generateClick() {
    if (this.stickers.value.length == 0) {
      this.showError('Please select product。');
      return;
    }
    if (!this.prompts) {
      this.showError('Please enter a description of the scene。');
      return;
    }
    if (!this.aspect) {
      this.showError("Please select the image ratio。")
      return;
    }
    if (!validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return;
    }

    let size = aspectToSize(this.aspect)!;
    this.stickersToCanvas().toBlob((maskBlob) => {
      if (!maskBlob) {
        return;
      }
      this.stickers.drawOrigToCanvas().toBlob((rawMaskBlob) => {
        if (!rawMaskBlob) {
          return;
        }
        var rawImageBlob = this.rawImageBlob;
        if (!rawImageBlob) {
          return;
        }
        if (this.validateProcessing()) {
          return;
        }
        this.processing = true;

        this.expandedChange.emit(false); // Hide Panel

        let fileName = BlobHelper.getName(rawImageBlob, 'input');

        let formData = new FormData();
        formData.set('prompts', this.prompts ?? '');
        formData.set('negativePrompts', this.negativePrompts ?? '');
        if (!!rawImageBlob) {
          formData.set('imageFile', rawImageBlob, fileName);
        }
        if (!!maskBlob) {
          formData.set('maskFile', maskBlob, 'mask_' + fileName);
        }
        if (!!rawMaskBlob) {
          formData.set('rawMaskFile', rawMaskBlob, 'raw_mask_' + fileName);
        }
        formData.set('width', size.width.toString());
        formData.set('height', size.height.toString());
        if (!!this.aspect) {
          formData.set('aspect', this.aspect);
        }
        formData.set('quantity', this.quantity);
        // if (!maskBlob) {
        // formData.set('mode', 'txt2img');
        // } else {
        formData.set('mode', 'background'); //TODO:: Later modified totxt2img
        // }
        formData.set('taskId', this.taskId.toString());

        let paintedImages = this.paintService.generate(formData);
        let zone = this.injector.get(NgZone);
        zone.run(() => {
          this.paintedImages = paintedImages;
          this.paintedImageList.caculateGrid(this.quantity, this.aspect);
        });

      });
    });

    this.completed.emit({
      next: ImageGenerateSteps.Step0030,
    });
  }

  onPaintCompleted() {
    let paintedImages = this.paintedImages;
    if (!paintedImages) {
      return;
    }

    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status == ActionStatus.Running) {
        return;
      }
    }
    this.processing = false;

    this.notifyStateChanged();
  }

  // userAssetSelected(asset: UserAssetListItem) {
  //   this.http.get(`/user/assets/${asset.id}`, { responseType: 'blob' })
  //     .subscribe({
  //       next: (result: Blob) => {
  //         this.rawImageBlob = result;
  //       },
  //     });
  // }

  selectedTemplateChanged(template: MaterialTemplateDetails) {
    this.prompts = template.prompts;
    this.negativePrompts = template.negativePrompts;
    this.selectedTemplateId = template.id;
  }

  imageAspectChanged(aspect: string) {
    this.aspect = aspect;
    this.stickerBackgroundImageUrl = this.createStickerBackgroundImageUrl(aspect);
    let size = aspectToSize(this.aspect)!;
    this.modelScale = { width: size.width, height: size.height, samScale: 1 };
  }

  get stickerContainerHeightFixed() {
    let v = this.aspect?.split(':');
    if (!v) {
      return false;
    }
    return Number(v[0]) < Number(v[1]);
  }

  saveToUserAssetClick() {
    this.stickersToCanvas().toBlob((maskBlob) => {
      if (!maskBlob) {
        return;
      }
      this.dialog.open(UserAssetGroupPicker, {
        disableClose: true,
        minWidth: 'min(90vw, 420px)',
        data: {
          category: UserAssetCategory.Material,
        },
      }).afterClosed().subscribe((result) => {
        let group = result?.data as UserAssetGroupListItem;
        if (!group?.id) {
          return;
        }
        var formData = new FormData();
        formData.set('category', group.category);
        formData.set('groupId', group.id.toString());
        formData.set('assetFile', maskBlob);
        formData.set('assetType', AssetType.Image);
        this.http.post('/user/assets', formData).subscribe({
          next: (result: ActionResult) => {
            if (!this.http.isValidResult(result)) {
              return;
            }
            this.messageService.showInformation(MessageResource.SAVED_SUCCESSFULLY);
          },
        });
      });
    });
  }

  private notifyStateChanged() {
    let params = [];

    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }

    let stickersFilename: string | undefined = undefined;
    let jsonText = JSON.stringify(this.stickers.getState());
    if (!!jsonText) {
      stickersFilename = 'stickers.json';
      params.push(new File([jsonText], stickersFilename));
    }

    params.push({
      selectedTemplateId: this.selectedTemplateId,
      prompts: this.prompts,
      negativePrompts: this.negativePrompts,
      aspect: this.aspect,
      quantity: this.quantity,
      paintedImages: this.paintedImages,
      rawImageUrl: rawImageFilename,
      stickersUrl: stickersFilename,
    });
    this.stateChanged.emit(params);
  }

  async restoreState(fatherComponent: ImageDesignComponent, data: any, relativeUrl: string) {
    if (!data) {
      this.reset();
      return;
    }
    this.selectedTemplateId = data.selectedTemplateId;
    this.prompts = data.prompts;
    this.negativePrompts = data.negativePrompts;
    this.aspect = data.aspect;
    this.quantity = data.quantity;
    this.paintedImages = PaintedImage.fromArray(data.paintedImages ?? [], this.injector);
    this.paintedImageList.caculateGrid(this.quantity, this.aspect);

    const restoreImageFromUrl = fatherComponent.restoreImageFromUrl.bind(fatherComponent);
    restoreImageFromUrl("image_select/"+data.rawImageUrl).subscribe ({
      next: (result: Blob) => {
        this.rawImageBlob = result;
      },
    });

    const restoreStickersFromUrl = fatherComponent.restoreStickersFromUrl.bind(fatherComponent);
    restoreStickersFromUrl(this.stickers, "image_generator/"+data.stickersUrl).subscribe ({
      next: (result: any) => {
        this.stickers.setState(result);
      }
    });

  }

  expandedChanged(expanded: boolean) {
    this.expanded = expanded;
    // this.expandedChange.emit(expanded);
  }
}


