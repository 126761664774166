<div mat-dialog-title>Transfer of Zhidou</div>

<mat-dialog-content>
  <form class="d-flex flex-wrap align-items-start" [formGroup]="inputForm">
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">User Zhidou</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput formControlName="totalPoints">
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">Transfer Smart Beans</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput formControlName="points" maxlength="9">
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">Remaining Smart Beans</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput formControlName="remainingPoints">
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">Transfer account</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput formControlName="toUsername" maxlength="24">
        <!-- <button matSuffix mat-stroked-button class="mx-2" (click)="validateUser()" *ngIf="!inputForm.controls['toUsername'].valid">check</button> -->
        <mat-icon matSuffix color="primary" *ngIf="inputForm.controls['toUsername'].valid">done</mat-icon>
        <mat-error *ngIf="inputForm.controls['toUsername'].hasError('required')">Please enter your user account</mat-error>
        <mat-error *ngIf="inputForm.controls['toUsername'].hasError('exists')">User does not exist</mat-error>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex-grow-1"></div>
  <button mat-stroked-button class="btn-min-width" [mat-dialog-close]="false">Cancel</button>
  <div class="flex-grow-1"></div>
  <button mat-flat-button class="btn-min-width" color="primary" (click)="transferClick()">confirm</button>
  <div class="flex-grow-1"></div>
</mat-dialog-actions>
