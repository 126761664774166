import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';
import { ImageAspects } from '../helpers/image-helper';

@Component({
  selector: 'image-similarity-input',
  templateUrl: './image-similarity-input.html',
  styleUrl: './image-similarity-input.scss',
})
export class ImageSimilarityinput implements OnInit {
  
  @Input() initsimi: string | undefined;

  @Output() simiChange = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
    
  }

  simiDrag() {
    this.simiChange.emit(this.initsimi);
  }

}