import { Injectable, Injector } from "@angular/core";
import { ActionResult, ActionStatus, AppHttpClient } from "../common/app.http";
import { JobStatus, PaintedImage } from "../models";
import { forkJoin, of } from "rxjs";
import { UserJobListItem, UserOperationListItem } from '../interfaces';
import { JobService } from "./job.service";

@Injectable({
  providedIn: 'root'
})
export class ClothService {

  constructor(private injector: Injector,
    private http: AppHttpClient,
    private jobService: JobService,) {

  }

  generate(formData: FormData,
    onInit?: (operation: UserOperationListItem) => void,
  ): UserOperationListItem {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');
    let taskId = formData.get('taskId');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let prompts = formData.get('prompts');
    let negativePrompts = formData.get('negativePrompts');

    let now = new Date();
    let jobs: Array<UserJobListItem> = [];
    let operation: UserOperationListItem = {
      modifiedDate: now.getTime(),
      jobs: jobs,
    };

    for (let i = 0; i < Number(quantity); i++) {
      jobs.push({
        status: JobStatus.waiting,
        modifiedDate: now.getTime(),
        imageWidth: Number(formData.get('width')),
        imageHeight: Number(formData.get('height')),
      });
    }

    operationObserable.subscribe({
      next: (opeartionResult: ActionResult) => {
        if (!this.http.isValidResult(opeartionResult)) {
          this.notifyJobError(jobs)
          return;
        }

        operation.id = opeartionResult.data.id;
        operation.modifiedDate = opeartionResult.data.modifiedDate;
        onInit?.call(this, operation); // Callback notification after user operation initialization is completed

        formData.set('operationId', opeartionResult.data.id);

        jobs.forEach((job, index) => {
          this.http.post(`/cloth/paint`, formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                job.status = JobStatus.failed;
                this.jobService.notify(job);
                return;
              }
              var data = result.data;
              job.id = data.id;
              job.type = data.type;
              job.subType = data.subType;
              job.modifiedDate = data.modifiedDate;
              job.status = data.status;
              job.statusDesc = data.statusDesc;
              this.jobService.notify(job);
            },
            error: (err: any) => {
              job.status = JobStatus.failed;
            },
          });
        });
      },
      error: (err: any) => this.notifyJobError(jobs),
    });
    return operation;
  }

  swapModel(formData: FormData,
    onInit?: (operation: UserOperationListItem) => void,
  ): UserOperationListItem {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');

    let taskId = formData.get('taskId');
    let prompts = formData.get('prompts');
    let negativePrompts = formData.get('negativePrompts');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let chatObserable;
    if (!prompts && !negativePrompts) {
      chatObserable = of({
        status: ActionStatus.Success,
        data: {},
      } as ActionResult);
    } else {
      let chatInput = {
        'prompts': prompts,
        'negativePrompts': negativePrompts,
      };
      chatObserable = this.http.post(`/cloth/chat`, chatInput);
    }

    let now = new Date();
    let jobs: Array<UserJobListItem> = [];
    let operation: UserOperationListItem = {
      modifiedDate: now.getTime(),
      jobs: jobs,
    };
    for (let i = 0; i < Number(quantity); i++) {
      jobs.push({
        status: JobStatus.waiting,
        modifiedDate: now.getTime(),
        imageWidth: Number(formData.get('width')),
        imageHeight: Number(formData.get('height')),
      });
    }
    forkJoin([operationObserable, chatObserable]).subscribe({
      next: ([opeartionResult, chatResult]: [ActionResult, ActionResult]) => {
        if (!this.http.isValidResult(opeartionResult)) {
          this.notifyJobError(jobs)
          return;
        }
        if (!this.http.isValidResult(chatResult)) {
          this.notifyJobError(jobs)
          return;
        }

        operation.id = opeartionResult.data.id;
        operation.modifiedDate = opeartionResult.data.modifiedDate;
        onInit?.call(this, operation); // Callback notification after user operation initialization is completed

        formData.set('operationId', opeartionResult.data.id);
        formData.set('prompts', chatResult.data.prompts ?? '');
        formData.set('negativePrompts', chatResult.data.negativePrompts ?? '');

        jobs.forEach((job, index) => {
          this.http.post(`/cloth/swapModel`, formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                job.status = JobStatus.failed;
                this.jobService.notify(job);
                return;
              }
              var data = result.data;
              job.id = data.id;
              job.type = data.type;
              job.subType = data.subType;
              job.modifiedDate = data.modifiedDate;
              job.status = data.status;
              job.statusDesc = data.statusDesc;
              this.jobService.notify(job);
            },
            error: (err: any) => {
              job.status = JobStatus.failed;
            },
          });
        });
      },
      error: (err: any) => this.notifyJobError(jobs),
    });
    return operation;
  }

  swapMannequin(formData: FormData,
    onInit?: (operation: UserOperationListItem) => void,
  ): UserOperationListItem {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');

    let taskId = formData.get('taskId');
    let prompts = formData.get('prompts');
    let negativePrompts = formData.get('negativePrompts');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let chatObserable;
    if (!prompts && !negativePrompts) {
      chatObserable = of({
        status: ActionStatus.Success,
        data: {},
      } as ActionResult);
    } else {
      let chatInput = {
        'prompts': prompts,
        'negativePrompts': negativePrompts,
      };
      chatObserable = this.http.post(`/cloth/chat`, chatInput);
    }

    let now = new Date();
    let jobs: Array<UserJobListItem> = [];
    let operation: UserOperationListItem = {
      modifiedDate: now.getTime(),
      jobs: jobs,
    };
    for (let i = 0; i < Number(quantity); i++) {
      jobs.push({
        status: JobStatus.waiting,
        modifiedDate: now.getTime(),
        imageWidth: Number(formData.get('width')),
        imageHeight: Number(formData.get('height')),
      });
    }
    forkJoin([operationObserable, chatObserable]).subscribe({
      next: ([opeartionResult, chatResult]: [ActionResult, ActionResult]) => {
        if (!this.http.isValidResult(opeartionResult)) {
          this.notifyJobError(jobs)
          return;
        }
        if (!this.http.isValidResult(chatResult)) {
          this.notifyJobError(jobs)
          return;
        }

        operation.id = opeartionResult.data.id;
        operation.modifiedDate = opeartionResult.data.modifiedDate;
        onInit?.call(this, operation); // Callback notification after user operation initialization is completed

        formData.set('operationId', opeartionResult.data.id);
        formData.set('prompts', chatResult.data.prompts ?? '');
        formData.set('negativePrompts', chatResult.data.negativePrompts ?? '');

        jobs.forEach((job, index) => {
          this.http.post(`/cloth/swapMannequin`, formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                job.status = JobStatus.failed;
                this.jobService.notify(job);
                return;
              }
              var data = result.data;
              job.id = data.id;
              job.type = data.type;
              job.subType = data.subType;
              job.modifiedDate = data.modifiedDate;
              job.status = data.status;
              job.statusDesc = data.statusDesc;
              this.jobService.notify(job);
            },
            error: (err: any) => {
              job.status = JobStatus.failed;
            },
          });
        });
      },
      error: (err: any) => this.notifyJobError(jobs),
    });
    return operation;
  }

  freeTryon(formData: FormData,
    onInit?: (operation: UserOperationListItem) => void,
  ): UserOperationListItem {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');
    let taskId = formData.get('taskId');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let now = new Date();
    let jobs: Array<UserJobListItem> = [];
    let operation: UserOperationListItem = {
      modifiedDate: now.getTime(),
      jobs: jobs,
    };
    for (let i = 0; i < Number(quantity); i++) {
      jobs.push({
        status: JobStatus.waiting,
        modifiedDate: now.getTime(),
        imageWidth: Number(formData.get('width')),
        imageHeight: Number(formData.get('height')),
      });
    }
    operationObserable.subscribe({
      next: (opeartionResult: ActionResult) => {
        if (!this.http.isValidResult(opeartionResult)) {
          this.notifyJobError(jobs)
          return;
        }

        operation.id = opeartionResult.data.id;
        operation.modifiedDate = opeartionResult.data.modifiedDate;
        onInit?.call(this, operation); // Callback notification after user operation initialization is completed

        formData.set('operationId', opeartionResult.data.id);

        jobs.forEach((job, index) => {
          this.http.post(`/cloth/freeTryon`, formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                job.status = JobStatus.failed;
                this.jobService.notify(job);
                return;
              }
              var data = result.data;
              job.id = data.id;
              job.type = data.type;
              job.subType = data.subType;
              job.modifiedDate = data.modifiedDate;
              job.status = data.status;
              job.statusDesc = data.statusDesc;
              this.jobService.notify(job);
            },
            error: (err: any) => {
              job.status = JobStatus.failed;
            },
          });
        });
      },
      error: (err: any) => this.notifyJobError(jobs),
    });
    return operation;
  }

  private notifyJobError(jobs: Array<UserJobListItem>) {
    jobs.forEach(job => {
      job.status = JobStatus.failed;
      this.jobService.notify(job);
    });
  }

  changeColor(formData: FormData, colorArray: string[],
    onInit?: (operation: UserOperationListItem) => void,
  ): UserOperationListItem {
    let quantity = colorArray.length;
    formData.set('quantity', '1');

    let taskId = formData.get('taskId');

    let operationInput = {
      taskId: taskId,
    };
    let operationObserable = this.http.post(`/user/operations`, operationInput);

    let now = new Date();
    let jobs: Array<UserJobListItem> = [];
    let operation: UserOperationListItem = {
      modifiedDate: now.getTime(),
      jobs: jobs,
    };
    for (let i = 0; i < Number(quantity); i++) {
      jobs.push({
        status: JobStatus.waiting,
        modifiedDate: now.getTime(),
        imageWidth: Number(formData.get('width')),
        imageHeight: Number(formData.get('height')),
      });
    }
    operationObserable.subscribe({
      next: (opeartionResult: ActionResult) => {
        if (!this.http.isValidResult(opeartionResult)) {
          return;
        }

        operation.id = opeartionResult.data.id;
        operation.modifiedDate = opeartionResult.data.modifiedDate;
        onInit?.call(this, operation); // Callback notification after user operation initialization is completed

        formData.set('operationId', opeartionResult.data.id);

        jobs.forEach((job, index) => {
          formData.set('color', colorArray[index]);
          this.http.post(`/cloth/changeColor`, formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                job.status = JobStatus.failed;
                return;
              }
              var data = result.data;
              job.id = data.id;
              job.type = data.type;
              job.subType = data.subType;
              job.modifiedDate = data.modifiedDate;
              job.status = data.status;
              job.statusDesc = data.statusDesc;
              this.jobService.notify(job);
            },
            error: (err: any) => {
              job.status = JobStatus.failed;
            },
          });
        });
      },
      error: (err: any) => {
        jobs.forEach(job => {
          job.status = JobStatus.failed;
        });
      },
    });
    return operation;
  }

  private onError(target: PaintedImage, err: any) {
    target.stop({
      status: ActionStatus.Failed,
      message: err.message ?? err.toString(),
    });
  }
}
