import { Injectable } from "@angular/core";
import { ActionResult, AppHttpClient } from "../common/app.http";
import { concatMap } from "rxjs";
import { stringify } from "querystring";

@Injectable({
  providedIn: 'root'
})
export class UserTaskService {

  constructor(private http: AppHttpClient) {

  }

  patchTaskState(id: number, data: any, relativeUrl?: string | undefined) {
    if (!Array.isArray(data)) {
      return this.patchOneTaskInput(id, data, relativeUrl);
    }

    let one = this.patchOneTaskInput(id, data[0], relativeUrl);
    for (let i = 1; i < data.length; i++) {
      one = one.pipe(concatMap(response => this.patchOneTaskInput(id, data[i], relativeUrl)));
    }
    return one;
  }

  fetchTaskState(id: number, relativeUrl?: string | undefined) {
    let url = `/user/tasks/${id}/state`;
    if (!!relativeUrl) {
      url = `${url}${relativeUrl}`;
    }
    return this.http.get(url);
  }

  patchOperationState(id: number, data: any, relativeUrl?: string | undefined) {
    if (!Array.isArray(data)) {
      return this.patchOneOperationInput(id, data, relativeUrl);
    }

    let one = this.patchOneOperationInput(id, data[0], relativeUrl);
    for (let i = 1; i < data.length; i++) {
      one = one.pipe(concatMap(response => this.patchOneOperationInput(id, data[i], relativeUrl)));
    }
    return one;
  }

  fetchOperationState(id: number, relativeUrl?: string | undefined) {
    let url = `/user/operations/${id}/state`;
    if (!!relativeUrl) {
      url = `${url}${relativeUrl}`;
    }
    return this.http.get(url);
  }

  uploadThumbnail(id: number, file: Blob) {
    this.http.post(`/user/tasks/${id}/thumbnail`, file).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
      },
    });
  }

  removeThumbnail(id: number) {
    this.http.delete(`/user/tasks/${id}/thumbnail`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
      },
    });
  }

  private patchOneTaskInput(id: number, data: any, relativeUrl?: string | undefined) {
    let url = `/user/tasks/${id}/state`;
    if (!!relativeUrl) {
      url = `${url}${relativeUrl}`;
    }
    if (data instanceof File) {
      url = `${url}/${data.name}`;
    }
    return this.http.patch(url, data);
  }

  private patchOneOperationInput(id: number, data: any, relativeUrl?: string | undefined) {
    let url = `/user/operations/${id}/state`;
    if (!!relativeUrl) {
      url = `${url}${relativeUrl}`;
    }
    if (data instanceof File) {
      url = `${url}/${data.name}`;
    }
    return this.http.patch(url, data);
  }
}