import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
})
export class TestComponent extends BaseComponent implements OnInit {
  @ViewChild('image') rawImageRef!: ElementRef;
  cropper: Cropper | null = null;
  href: string | null = 'https://www.bing.com/th?id=OHR.BisonSnow_EN-CN1289698261_1920x1080.webp&qlt=50';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  onclick() {
    let element = document.getElementById('image') as HTMLImageElement;
    this.cropper = new Cropper(element!, {
      // aspectRatio: 16 / 9,
      // viewMode: 2,
    });
  }

}