<div class="page-header">
  <h4 class="title me-5">Material Management - Modify the material</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="backClick()"><mat-icon color="primary"
      aria-label="return">arrow_back</mat-icon><span>return</span>
  </button>
</div>

<form [formGroup]="inputForm">
  <div class="d-flex flex-wrap align-items-start">
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>category</mat-label>
        <mat-select formControlName="category">
          <mat-option [value]=""></mat-option>
          <mat-option *ngFor="let item of categories" [value]="item.key">{{item.value}}</mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.controls['category'].invalid">required.</mat-error>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>name</mat-label>
        <input matInput formControlName="name" maxlength="32">
        <mat-error *ngIf="inputForm.controls['name'].invalid">required.</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="d-flex flex-wrap align-items-start mb-3">
    <div class="col-12 col-md-6">
      <image-picker #imagePicker (changed)="imageFileChanged($event)" />
      <mat-error
        *ngIf="inputForm.controls['imageFile'].invalid && inputForm.controls['imageFile'].touched">required.</mat-error>
    </div>
    <div class="col-12 col-md-6">

      <div class="col-12">
        <mat-form-field>
          <mat-label>Scenario Description(Chinese)</mat-label>
          <textarea matInput formControlName="prompts" rows="4" maxlength="150"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>Unwanted content(Chinese)</mat-label>
          <textarea matInput formControlName="negativePrompts" rows="4" maxlength="150"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>Scenario Description(English)</mat-label>
          <textarea matInput formControlName="promptsEnglish" rows="4" maxlength="450"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field>
          <mat-label>Unwanted content(English)</mat-label>
          <textarea matInput formControlName="negativePromptsEnglish" rows="4" maxlength="150"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12">
        <div class="d-flex flex-wrap align-items-start">
          <div class="col-12 col-md-6">
            <mat-form-field>
              <mat-label>Display Order</mat-label>
              <input matInput formControlName="sortOrder" maxlength="9">
              <!-- <mat-error *ngIf="inputForm.controls['sortOrder'].invalid">required.</mat-error> -->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field>
              <mat-label>state</mat-label>
              <mat-select formControlName="active">
                <mat-option value="Y">efficient</mat-option>
                <mat-option value="N">invalid</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-wrap align-items-start">
    <div class="col-12 col-md-6">
      <mat-chip-set class="label-container">
        <mat-chip class="lable-item" *ngFor="let label of selectedLabels">
          {{label}}
          <button matChipRemove (click)="removeLabel(label)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <button mat-button class="lable-item add-label" color="primary" [matMenuTriggerFor]="labelCategoryMenu">
          <mat-icon color="primary">add</mat-icon>
          New Tags
        </button>
      </mat-chip-set>
    </div>
    <div class="col-12 col-md-6">
      <mat-form-field>
        <mat-label>describe</mat-label>
        <textarea matInput formControlName="description" rows="4" maxlength="150"></textarea>
      </mat-form-field>
    </div>
  </div>

</form>

<div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="warn" type="button" (click)="deleteClick()"
    *ngIf="!!id">delete</button>
  <div class="d-inline-block mx-2"></div>
  <button mat-flat-button mat-button-base class="btn-min-width" color="primary" type="button"
    (click)="saveClick()">keep</button>
</div>

<br />
<br />

<mat-menu #labelCategoryMenu="matMenu" class="label-menu-1">
  <button mat-menu-item [matMenuTriggerFor]="labelMenu" [matMenuTriggerData]="{children: category.children}"
    *ngFor="let category of labels">{{category.name}}</button>
</mat-menu>

<mat-menu #labelMenu="matMenu" class="label-menu-2">
  <ng-template matMenuContent let-children="children">
    <button mat-menu-item (click)="addLabel(label.name)" *ngFor="let label of children">{{label.name}}</button>
  </ng-template>
</mat-menu>
