import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'flexible-accordion',
  templateUrl: './flexible-accordion.html',
  styleUrl: './flexible-accordion.scss',
})
export class FlexibleAccordion {
  @Input() expanded:boolean = true;
  @Output() expandedChange = new EventEmitter<boolean>();

  @Input() hideToggle:boolean = false;

  constructor() {
      
  }
  
  toggle() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

}
