<page-header title="History Creative Library" />

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab [label]="category.name" *ngFor="let category of categories;">
    <div class="d-flex py-2">
      <div class="flex-grow-1"></div>
      <button mat-flat-button color="primary" (click)="addUserAssetGroupClick(category)">
        <mat-icon>photo_library</mat-icon><span>Add image grouping</span>
      </button>
    </div>
    <div cdkDropListGroup class="group-list">
      <div class="group-item" *ngFor="let group of category.groups;" >
        <div cdkDropList class="asset-list" [cdkDropListData]="group.assets" (cdkDropListDropped)="drop($event,category, group)">
          <div cdkDrag  class="asset-item" *ngFor="let asset of group.assets;" >
            <img [src]="asset.url" *ngIf="!!asset.url" />
            <element-floatable>
              <button mat-icon-button class="bottom-left btn-sm bg-white m-1" color="primary" matTooltip="delete"
                (click)="removeUserAssetClick(group, asset)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-icon-button class="bottom-right btn-sm bg-white m-1" color="primary" matTooltip="enlarge"
                (click)="zoomoutClick(asset)">
                <mat-icon>zoom_out_map</mat-icon>
              </button>
            </element-floatable>
          </div>
          <div class="no-asset-item" *ngIf="group.assets.length==0">
            <span class="m-auto">No files added</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <span>{{group.name}}</span>
          <div class="flex-grow-1"></div>
          <button mat-icon-button class="btn-sm m-1" matTooltip="Upload pictures" (click)="addUserAssetClick(category, group)">
            <mat-icon>upload_file</mat-icon>
          </button>
          <button mat-icon-button class="btn-sm m-1" matTooltip="More Operations" [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{category, group}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-category="category" let-group="group">
    <button mat-menu-item (click)="editUserAssetGroupClick(category, group)">
      <mat-icon>edit</mat-icon>
      <span>Duplicate naming</span>
    </button>
    <button mat-menu-item (click)="shareGroupClick(group)">
      <mat-icon>share</mat-icon>
      <span>Share Gallery</span>
    </button>
    <button mat-menu-item (click)="removeUserAssetGroupClick(category, group)">
      <mat-icon>delete</mat-icon>
      <span>Delete Gallery</span>
    </button>
  </ng-template>
</mat-menu>

<image-picker #simagePicker [hasDropZone]="false" [hasImageZone]="false" (changed)="onImageFileChanged($event)" />
