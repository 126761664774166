import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-content-input',
  templateUrl: './image-content-input.html',
})
export class ImageContentInput implements OnInit {
  contents: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'flowers', value: 'flowers', selected: false },
    { key: 'Tropical plants', value: 'Tropical plants', selected: false },
    { key: 'Leaves', value: 'Leaves', selected: false },
    { key: 'cactus', value: 'cactus', selected: false },
    { key: 'fruit', value: 'fruit', selected: false },
    { key: 'tiger', value: 'tiger', selected: false },
    { key: 'lion', value: 'lion', selected: false },
    { key: 'dragon', value: 'dragon', selected: false },
    { key: 'Bear', value: 'Bear', selected: false },
    { key: 'deer', value: 'deer', selected: false },
    { key: 'elephant', value: 'elephant', selected: false },
    { key: 'penguin', value: 'penguin', selected: false },
  ];
  _content?: string;
  @Input() set content(value: string | undefined) {
    this._content = value;
    let item = this.contents.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.contents.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get content() {
    return this._content;
  }

  @Output() contentChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  contentClick(selectedContent: SelectListItem) {
    this.content = selectedContent.key;
    this.contentChange.emit(this.content);
  }

}
