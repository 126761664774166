import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'video-player-dialog',
  templateUrl: './video-player-dialog.html',
  styleUrl: './video-player-dialog.scss',
})
export class VideoPlayerDialog implements OnInit {
  url: string;

  constructor(
    private dialogRef: MatDialogRef<VideoPlayerDialog>,
    @Inject(MAT_DIALOG_DATA) private data: { url: string },) {
    this.url = data.url;
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }

}
