<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="occurredTime">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-3">Time Consumption</mat-header-cell>
    <mat-cell *matCellDef="let row" class="col-6 col-lg-3">{{row.occurredTime}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="usedPoints">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">Consume Smart Beans</mat-header-cell>
    <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.usedPoints}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="points">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">Remaining Smart Beans</mat-header-cell>
    <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.points}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="statusName">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-2">Build Status</mat-header-cell>
    <mat-cell *matCellDef="let row" class="col-6 col-lg-2">{{row.statusName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-3">operate</mat-header-cell>
    <mat-cell *matCellDef="let row" class="col-6 col-lg-3">{{row.description}}</mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns" class="row g-0"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" class="row g-0"></mat-row>
  <div class="mat-row d-flex" *matNoDataRow>
    <div class="mat-cell text-center py-5 m-auto">
      <span *ngIf="!loading">No data matching the criteria</span>
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  </div>
</mat-table>

<div class="text-end my-3" *ngIf="dataSource.data.length>0 && hasMore">
  <button mat-button class="fw-bold" color="primary" (click)="fetchMoreClick()">Show more...</button>
</div>
