import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { BaseComponent } from '../../common/base.component';
import { ActionResult, AppHttpClient } from '../../common/app.http';
import { IUser, UserIdentity } from 'src/app/interfaces';
import { WechatContactDialog } from 'src/app/contact-us/wechat-contact-dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss',
})
export class AppHeaderComponent extends BaseComponent {
  user: IUser | null | undefined;
  points?: number;

  public mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  private _isAuthenticated: boolean = false;
  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  constructor(
    injector: Injector,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private http: AppHttpClient,
    private dialog: MatDialog,) {
    super(injector);
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    this.sessionService.getUser().subscribe(user => {
      this._isAuthenticated = !!user;
      this.user = user;
      this.points = user?.points;
    });
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
  }

  @Input() menuToggleVisible: boolean = false;

  @Output() menuToggleChanged: EventEmitter<any> = new EventEmitter();
  public menuToggleClick() {
    this.menuToggleChanged.emit(null);
  }

  public logoutClick() {
    if (!this.sessionService.isAuthenticated) {
      this.router.navigate(['/account/login']);
      return;
    }

    const params = {};
    this.http.post('/account/logout', params, { silence: false }).subscribe((result: any) => {
      this.sessionService.logout();
      this.router.navigate(['/account/login']);
    });
  }

  public changeIdentity(event: any, identity: UserIdentity) {
    event.stopPropagation();

    var chargeTo = identity.id;
    let params = {
      chargeTo: chargeTo,
    };
    this.http.post(`/users/${this.user?.id}/charge/switch`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let user = this.sessionService.user;
        if (!!user) {
          user?.identities.forEach(element => {
            element.active = element.id == chargeTo;
          });
        }
        this.sessionService.user = user;
      }
    });
  }

  get identities() {
    return this.user?.identities ?? [];
  }

  contactUsClick() {
    this.dialog.open(WechatContactDialog, {
      disableClose: false,
    });
  }

  membershipClick() {
    this.router.navigate(['/membership']);
  }

}
