import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AssetType, JobStatus, UserAssetCategory } from '../models';
import { ImageDialog } from '../image-dialog/image-dialog';
import { MatDialog } from '@angular/material/dialog';
import { format } from 'date-fns';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { BlobHelper, FileHelper, MediaTypeHelper } from '../helpers/app.helpers';
import { MessageResource, MessageService } from '../common/message.service';
import { UserAssetGroupPicker } from '../user-asset-group/user-asset-group-picker';
import { UserJobListItem, UserAssetGroupListItem, FavoriteTypes } from '../interfaces';
import { BASE_URL } from '../app.module';
import { JobService } from '../services/job.service';
import { ImagePreviewDialog } from '../image-preview-dialog/image-preview-dialog';
import { ImageUpscaleDialog } from '../image-upscale-dialog/image-upscale-dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'user-job-item',
  templateUrl: './user-job-item.html',
  styleUrl: './user-job-item.scss',
})
export class UserJobItem {
  JobStatusEnum = JobStatus;
  @Input({ required: true }) value!: UserJobListItem;
  @Input() editable = false;
  @Input() actionBarVisible = true;
  @Output() edit = new EventEmitter<UserJobListItem>();
  @Input() useBackgroundAlpha: boolean = false;
  @Input() enableSave: boolean = true;
  @Input() showTiffMenu: boolean = false;
  thumbUp = false;
  thumbDown = false;
  saved = false;

  curDownloadFormat: string = 'RGB';
  downloadResolution: string = '300';

  minResolution: number = 72;
  maxResolution: number = 300;

  constructor(
    @Inject(BASE_URL) public baseUrl: string,
    private http: AppHttpClient,
    private dialog: MatDialog,
    private messageService: MessageService,
    private jobService: JobService,) {

  }

  editClick() {
    this.edit.emit(this.value);
  }

  zoomoutPaintedImageClick() {
    let job = this.value;
    if (!job) {
      return;
    }
    this.dialog.open(ImageDialog, {
      data: {
        url: job.url ?? `/job/${job.id}/download`,
      }
    });
  }

  handleExportContainClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('export-button')) {
      event.stopPropagation();
    }
  }

  exportClick() {
    switch (this.curDownloadFormat) {
      case 'RGB':
        this.exportPaintedImageClick();
        break;
      case 'CMYK':
        this.exportTiffClick();
        break;
    }
  }

  exportPaintedImageClick() {
    let job = this.value;
    if (!job) {
      return;
    }
    this.fetchImage(
      (result: Blob) => {
        let name = 'generated_image' + MediaTypeHelper.resolveFileExtension(result.type);
        BlobHelper.download(`${format(new Date(), 'yyyyMMddHHmmss')}_${name}`, result);
      }
    );
  }

  fetchImage(callback: (result: Blob) => void) {
    let job = this.value;
    if (!job) {
      return;
    }
    let url = `/job/${job.id}/download`;
    this.http.get(url, { responseType: 'blob' }).subscribe({
      next: async (result: Blob) => {
        callback(result);
      },
    });
  }

  exportTiffClick() {
    let job = this.value;
    if (!job) {
      return;
    }

    let numberResolution = Number(this.downloadResolution);
    if (numberResolution > this.maxResolution) {
      this.messageService.showInformation("The input resolution exceeds the maximum limit300，by300dpiDownload for you。");
      this.downloadResolution = this.maxResolution.toString();
    }
    else if (numberResolution < this.minResolution) {
      this.messageService.showInformation("The input resolution is lower than the minimum limit72，by72dpiDownload for you。")
      this.downloadResolution = this.minResolution.toString();
    }

    this.fetchImage(
      (result: Blob) => {
        //TODO:: Later, it was changed to directly convert it into the required format on the server side and then download it，Reduce unnecessary network interactions
        let name = 'generated_image' + MediaTypeHelper.resolveFileExtension(result.type);
        let tiffFileName = MediaTypeHelper.replaceFileExtension(name, 'tiff');
        let formData = new FormData();
        formData.set('imageFile', result, tiffFileName);
        formData.set('format', DownloadType.TIFF);
        formData.set('resolution', this.downloadResolution);
        this.http.post('/image/convert', formData, { responseType: 'blob' }).subscribe({
          next: (result: Blob) => {
            if (!result) {
              return;
            }
            let tiffUrl = URL.createObjectURL(result);
            FileHelper.download(tiffUrl, `${format(new Date(), 'yyyyMMddHHmmss')}_${tiffFileName}`);
          }
        });
      }
    );
  }

  downloadFormatChanged(format: string) {
    this.curDownloadFormat = format;
  }

  downloadResolutionChanged(dpi: string) {
    this.downloadResolution = dpi;
  }

  userFeedbackClick(rate: number) {
    let job = { ...this.value };
    job.feedbackRate = rate;
    this.jobService.changeFeedback(job).subscribe();
  }

  saveToUserAssetClick() {
    let job = this.value;
    if (this.saved) {
      this.messageService.showWarning(MessageResource.ALREADY_SAVED);
      return;
    }
    this.dialog.open(UserAssetGroupPicker, {
      disableClose: true,
      minWidth: 'min(90vw, 420px)',
      data: {
        category: UserAssetCategory.Product,
      },
    }).afterClosed().subscribe((result) => {
      let group = result?.data as UserAssetGroupListItem;
      if (!group?.id) {
        return;
      }

      this.fetchImage(
        (result: Blob) => {
          //TODO:: It may be better to save it directly on the server later.，Depends on future situation
          var formData = new FormData();
          formData.set('category', group.category);
          formData.set('groupId', group.id.toString());
          formData.set('assetFile', result);
          formData.set('assetType', AssetType.Image);
          formData.set('relatedId', job.id?.toString() ?? "");
          this.http.post('/user/assets', formData).subscribe({
            next: (result: ActionResult) => {
              if (!this.http.isValidResult(result)) {
                return;
              }
              this.saved = true;
              this.messageService.showInformation(MessageResource.SAVED_SUCCESSFULLY);
            },
          });
        }
      );
    });
  }

  get status() {
    return this.value.status;
  }

  get statusDetails(): Array<string> {
    switch (this.value.status) {
      case JobStatus.waiting:
        return ['In the queue', this.value.statusDesc ?? ''];
      case JobStatus.running:
        return ['Generating', 'Expected30About seconds'];
      default:
        return [];
    }
  }

  get url() {
    return this.value.url ?? this.value.thumbnailUrl;
  }

  get favorited() {
    return this.value.favoriteRate == FavoriteTypes.like;
  }

  favoriteClick() {
    let job = { ...this.value };
    job.favoriteRate = job.favoriteRate == FavoriteTypes.like ? FavoriteTypes.unlike : FavoriteTypes.like;
    this.jobService.changeFavorite(job).subscribe();
  }

  previewClick() {
    let job = this.value;
    if (!job) {
      return;
    }
    // let width = '96vw';
    // let height = '96vh';
    // if(!!job.imageWidth && !!job.imageHeight) {
    //   width = `min(${width}, ${job.imageWidth * 2 + 108}px)`;
    //   height = `min(${height}, ${job.imageHeight + 54}px)`;
    // }
    this.dialog.open(ImagePreviewDialog, {
      // width: width,
      // height: height,
      maxWidth: '96vw',
      maxHeight: '96vh',
      data: job,
    });
  }

  get thumbsUp() {
    return this.value.feedbackRate == 1;
  }

  get thumbsDown() {
    return this.value.feedbackRate == -1;
  }

  upscaleImageClick(upscaleRatio: number) {
    this.dialog.open(ImageUpscaleDialog, {
      disableClose: true,
      data: {
        jobId: this.value.id,
        upscaleRatio: upscaleRatio,
      },
    });
  }

  get isDevelopment() {
    return !environment.production;
  }
}

export enum DownloadType {
  TIFF = 'TIFF',
}
