import { Injector } from "@angular/core";
import { BaseComponent } from "./base.component";
import { UserTaskService } from "../services/user-task.service";
import { UserTaskList } from "../user-task/user-task-list";
import { UserTaskListItem } from "../interfaces";
import { AppHttpClient } from "./app.http";
import { Observable, filter, switchMap, of } from "rxjs";
import { ImageStickerInput } from '../image-generate-input/image-sticker-input';

export abstract class TaskBaseComponent extends BaseComponent {
  protected taskService: UserTaskService;
  taskId?: number;
  taskName?: string;
  restoring: boolean = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.taskService = this.injector.get(UserTaskService);
  }

  abstract reset(): void;
  abstract getUserTaskList(): UserTaskList;
  abstract restoreTaskState(): void;
  abstract saveTaskState(): void;

  protected patchTaskState(data: any, relativeUrl?: string | undefined) {
    this.taskService.patchTaskState(this.taskId!, data, relativeUrl).subscribe();
  }

  protected fetchTaskState(relativeUrl?: string | undefined) {
    return this.taskService.fetchTaskState(this.taskId!, relativeUrl);
  }

  protected patchOperationState(id: number, data: any, relativeUrl?: string | undefined) {
    this.taskService.patchOperationState(id, data, relativeUrl).subscribe();
  }

  protected fetchOperationState(id: number, relativeUrl?: string | undefined) {
    this.restoring = true;
    return this.taskService.fetchOperationState(id, relativeUrl);
  }

  protected saveTaskThumbnail(file: Blob | undefined) {
    if (!file) {
      return;
    }
    this.taskService.uploadThumbnail(this.taskId!, file);
    this.getUserTaskList().updateTask({
      id: this.taskId!,
      url: URL.createObjectURL(file),
    });
  }

  protected removeTaskThumbnail() {
    this.taskService.removeThumbnail(this.taskId!);
    this.getUserTaskList().updateTask({
      id: this.taskId!,
      url: '/assets/images/image.svg',
    });
  }

  userTaskSelectionChange(task: UserTaskListItem | undefined) {
    // this.processing = false;
    this.restoring = false;
    if (!task) {
      if (!this.getUserTaskList().tasks.find(o => o.id == this.taskId)) {
        // Deleting the currently displayed task
        this.reset();
        this.taskId = undefined;
        this.taskName = undefined;
      }
      //Deleting tasks that are not displayed
      return;
    }
    this.taskId = task.id;
    this.taskName = task.name;
    this.restoring = true;
    this.restoreTaskState();
  }

  isRestoring(): boolean {
    return this.restoring;
  }

  restoreComplete() {
    this.restoring = false;
  }

  buildStickerState(stickerState: any, segmentTaskId?: string): any {
    let params = [];

    let stickersFilename: string | undefined = undefined;
    let jsonText = JSON.stringify(stickerState);
    if (!!jsonText) {
      stickersFilename = 'stickers.json';
      params.push(new File([jsonText], stickersFilename));
    }

    params.push({
      stickersUrl: stickersFilename,
    });

    if (!!segmentTaskId) {
      params.push({
        segmentTaskId: segmentTaskId,
      });
    }

    return params;
  }

  restoreImageFromUrl(url: string, operationId?: number): Observable<Blob> {
    if (!url) {
      return of();
    }

    let http = this.injector.get(AppHttpClient);
    let restoringTaskId = operationId ? operationId : this.taskId;

    let getUrl = operationId ?`${this.getOperationStateUrl(operationId)}/${url}` : `${this.taskStateUrl}/${url}`;

    return http.get(getUrl, { responseType: 'blob' }).pipe(
      filter(() => {
        return operationId ? restoringTaskId === operationId : restoringTaskId === this.taskId;
      }));
  }

  restoreStickersFromUrl(stickers: ImageStickerInput, url: string, operationId?: number): Observable<any> {
    if (!url || !stickers) {
      return of();
    }

    let http = this.injector.get(AppHttpClient);
    let restoringTaskId = operationId ? operationId: this.taskId;

    let getUrl = operationId ?`${this.getOperationStateUrl(operationId)}/${url}` : `${this.taskStateUrl}/${url}`;

    return http.get(getUrl).pipe(
      switchMap((stickerJson) => {
        return stickers.restoreState(stickerJson);
      })
    ).pipe(
      filter(() => {
        return operationId ? restoringTaskId === operationId : restoringTaskId === this.taskId;
      }));

  }

  getOperationStateUrl(id: number): string {
    return `/user/operations/${id}/state`;
  }

  get taskStateUrl() {
    return `/user/tasks/${this.taskId}/state`;
  }

}
