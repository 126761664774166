<div class="my-2">

  <h1 mat-dialog-title>Instructions</h1>

  <div mat-dialog-content class="mt-2">
    <h2>Please perform the following operations in the upper left image work area，To extract the part of the image you need</h2>
    <h3 class="my-3">Click Extract</h3>
    <span>Left click single/Click several times on the object you wish to extract.</span>
    <span><br>Right click on the object you do not want to extract</span>
    <span><br>The blue outline previews the extracted range，If it meets your expectations, please click the Region Extraction button to extract.</span>

    <h3 class="my-3">Box selection extraction</h3>
    <span>Select the area you want to extract.</span>
  </div>

  <div mat-dialog-actions [align]="'end'">
    <div class="mx-4 mb-2">
      <button mat-raised-button mat-dialog-close color="primary"> I see</button>
    </div>
  </div>

</div>
