<div class="d-flex w-100 h-100 job-background rounded-3" *ngIf="status!=JobStatusEnum.success">
  <div class="m-auto">
    <div class="d-flex flex-column align-items-center" *ngIf="status!=JobStatusEnum.failed">
      <div class="m-auto">
        <img src="{{ baseUrl }}assets/images/progress-bar.gif" />
      </div>
      <div class="text-center" *ngFor="let statusDesc of statusDetails">{{statusDesc}}</div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="status==JobStatusEnum.failed">
      <img class="mx-3" src="{{ baseUrl }}assets/images/job/job_fail.png" />
      <span class="mat-body my-0 hint-text">Generating failed</span>
    </div>
  </div>
</div>
<div class="h-100 w-100 m-auto" *ngIf="!!url" [ngClass]="{ 'background-alpha' : useBackgroundAlpha}">
  <div class="w-100 h-100 position-relative">
    <img class="rounded-3" [src]="url" />
    <element-floatable *ngIf="actionBarVisible">
      <div class="top-left d-flex">
        <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="Like"
          (click)="userFeedbackClick(thumbsUp? 0:1)">
          <mat-icon svgIcon="app:thumbs_up" [color]="thumbsUp? 'primary': undefined"></mat-icon>
        </div>
        <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="Negative reviews"
          (click)="userFeedbackClick(thumbsDown? 0:-1)">
          <mat-icon svgIcon="app:thumbs_down" [color]="thumbsDown? 'primary': undefined"></mat-icon>
        </div>
      </div>
      <div class="center-center preview-btn" matRipple [matRippleCentered]="true" (click)="previewClick()">
        <div class="preview-btn-background"></div>
        <div class="preview-btn-title"><span>Preview</span></div>
      </div>
      <div class="d-flex position-absolute bottom-0 w-100">
        <div class="d-flex flex-wrap">
          <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="collect" (click)="favoriteClick()">
            <mat-icon svgIcon="app:favorite_like" color="primary" *ngIf="favorited"></mat-icon>
            <mat-icon svgIcon="app:favorite_unlike" *ngIf="!favorited"></mat-icon>
          </div>
          <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="download"
            [matMenuTriggerFor]="downloadMenu" *ngIf="!showTiffMenu">
            <mat-icon svgIcon="app:download"></mat-icon>
          </div>
          <div class="app-icon-btn" matRipple [matRippleCentered]="true" matTooltip="download" [matMenuTriggerFor]="tiffMenu"
            *ngIf="showTiffMenu">
            <mat-icon svgIcon="app:download"></mat-icon>
          </div>
        </div>
        <div class="flex-grow-1"></div>
        <div class="d-flex align-items-end">
          <div class="app-icon-btn text-primary" matRipple [matRippleCentered]="true" *ngIf="isDevelopment">
            <mat-icon class="me-1" svgIcon="app:image"></mat-icon>
            <span class="text-nowrap">Image Retouching</span>
          </div>
          <!-- <button mat-icon-button class="m-1" class="btn-sm bg-white m-1" color="primary" matTooltip="Save to the History Library"
            (click)="saveToUserAssetClick()" [class.d-none]="!enableSave">
            <mat-icon>photo_libaray</mat-icon>
          </button> -->
        </div>
      </div>
    </element-floatable>
    <button mat-stroked-button class="position-absolute top-0 end-0 btn-md bg-white m-1" color="primary"
      (click)="editClick()" *ngIf="editable">
      <img src="/assets/images/image-generate0040.png" />
      <span>Continue to refine</span>
    </button>
  </div>
</div>

<mat-menu #downloadMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="exportPaintedImageClick()">
      <!--<mat-icon>low_priority</mat-icon>--><span>Regular downloads</span>
    </button>
    <button mat-menu-item (click)="upscaleImageClick(2.0)" disabled="true"><!-- Temporarily disable HD -->
      <!--<mat-icon>move_up</mat-icon>--><span>HD Download (2k)</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #tiffMenu="matMenu">
  <div class="container p-2" (click)="handleExportContainClick($event)">
    <image-format-input [imageFormat]="'RGB'" (imageFormatChange)="downloadFormatChanged($event)" />
    <div class="m-2" *ngIf="curDownloadFormat==='CMYK'"></div>
    <image-dpi-input [dpi]="'300'" (dpiChange)="downloadResolutionChanged($event)" *ngIf="curDownloadFormat==='CMYK'" />
    <div class="mb-3"> </div>
    <div class="d-flex justify-content-start px-2">
      <button mat-raised-button class="export-button" color="primary" (click)="exportClick()">Download Now</button>
    </div>
  </div>
</mat-menu>
