<div class="m-2">
  <div class="daily-operation-container" *ngFor="let daily of dailyOperations">
    <div class="history-operation-container">
      <div *ngFor="let operation of daily.operations; let idx = index;">
        <div class="d-flex align-items-end">
          <div class="title">
            <div *ngIf="idx==0">{{formatDate(daily.createdDate)}}</div>
            <div class="fs-smaller ms-3 lh-1">{{formatTime(operation.modifiedDate)}}</div>
          </div>
          <div class="flex-1"></div>
          <button class="clone-btn" mat-stroked-button color="primary" (click)="onClone(operation)"
            *ngIf="operation.hasState==true">
            <mat-icon svgIcon="app:edit"></mat-icon>
            <span>Edit again</span>
          </button>
        </div>
        <div class="d-flex flex-wrap">
          <div class="history-job-item" [style.aspect-ratio]="calculateAspect(job)" *ngFor="let job of operation.jobs">
            <user-job-item [value]="job" [editable]="editable" [useBackgroundAlpha]="useBackgroundAlpha"
              [enableSave]="enableSave" [showTiffMenu]="showTiffMenu" (edit)="onEdit($event)"></user-job-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-end" *ngIf="dailyOperations.length>0 && hasMore">
    <button mat-button class="fw-bold" color="primary" (click)="fetchMoreClick()">Show more...</button>
  </div>
</div>
