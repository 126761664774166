import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { addDays, addMonths, format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})

export class StringHelper {
  public static isEmpty(value: any): boolean {
    if (value == undefined || value == null)
      return true;
    if (Array.isArray(value)) {
      return value.length == 0
    } else {
      return value == '';
    }
  }
}

export class DateTimeHelper {
  public static parseDate(value: any): Date {
    if (StringHelper.isEmpty(value)) {
      throw new Error(value + " is invalid date.");
    }
    if (typeof value === "string") {
      if (/^[0-9]{4}[/-][0-9]{1,2}[/-][0-9]{1,2}$/.test(value)) {
        let values = value.replace(/[/]/gi, '-').split('-');
        return new Date(Number(values[0]), Number(values[1]) - 1, Number(values[2]));
      } else if (/^[0-9]{4}[/-][0-9]{1,2}[/-][0-9]{1,2} [0-9]{1,2}:[0-9]{1,2}$/.test(value)) {
        let values = value.replace(/[/: ]/gi, '-').split('-');
        return new Date(Number(values[0]), Number(values[1]) - 1, Number(values[2]), Number(values[3]), Number(values[4]));
      } else if (/^[0-9]{4}[/-][0-9]{1,2}[/-][0-9]{1,2} [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/.test(value)) {
        let values = value.replace(/[/: ]/gi, '-').split('-');
        return new Date(Number(values[0]), Number(values[1]) - 1, Number(values[2]), Number(values[3]), Number(values[4]), Number(values[5]));
      }
    }
    return new Date(value);
  }

  public static format(value: any, fmat: any): string {
    if (StringHelper.isEmpty(value)) {
      return "";
    }
    if (typeof value === "string") {
      let dt = DateTimeHelper.parseDate(value);
      if (dt == undefined) {
        return "";
      }
      return format(dt, fmat);
    }
    return format(new Date(value), fmat);
  }

  public static getNow(): Date {
    return new Date();
  }

  public static getToday(): Date {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return new Date(date);
  }

  public static getDate(value: any): Date {
    if (value instanceof Date) {
      return value;
    } else {
      var date = value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate();
      return new Date(date);
    }
  }
  public static addDays(date: Date | number, amount: number): Date {
    return addDays(date, amount);
  }

  public static addMonths(date: Date | number, amount: number): Date {
    return addMonths(date, amount);
  }

}

export class FormGroupHelper {
  public static isValid(formGroup: FormGroup): boolean {

    return formGroup.valid;
  }
}

export class FileHelper {
  public static getExtension(filename: string): string {
    if (!filename) {
      return '';
    }
    var idx = filename.lastIndexOf('.');
    if (idx < 0) {
      return '';
    }
    return filename.substr(idx + 1).toLowerCase();
  }

  public static download(href: string, fileName?: string) {
    let elink = document.createElement('a');
    elink.href = href;
    elink.target = '_blank';
    if (!!fileName) {
      elink.download = fileName;
    }
    document.body.appendChild(elink);
    elink.click()
    document.body.removeChild(elink);
  }
}

export class BlobHelper {
  public static download(fileName: string, blob: Blob) {
    let url = URL.createObjectURL(blob);
    FileHelper.download(url, fileName);
    URL.revokeObjectURL(url);
  }

  public static getName(blob: Blob, defaultName: string) {
    if (blob instanceof File) {
      return blob.name;
    }
    if (defaultName.indexOf('.') >= 0) {
      return defaultName;
    }
    let ext = MediaTypeHelper.resolveFileExtension(blob.type);
    return `${defaultName}${ext}`;
  }

  public static toBase64Async(blob: Blob | undefined) {
    return new Promise((resolve: (value: string | undefined) => void, reject) => {
      if (!blob) {
        return resolve(undefined);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrlPrefix = `data:${blob.type};base64,`;
        const base64WithDataUrlPrefix = reader.result as string;
        const base64 = base64WithDataUrlPrefix.replace(dataUrlPrefix, '');
        resolve(base64);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  public static toDataUrlAsync(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  public static dataURLtoBlob(dataurl: string) {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)![1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], {
      type: mime
    });
  }
}

export class ImageHelper {

  public static resizeFromBlob(blob: Blob, newWidth: number, newHeight: number, callback: (paramBlob: Blob) => void, trim?: boolean) {
    createImageBitmap(blob).then((image) => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      ctx?.drawImage(
        image, 0, 0, image.width, image.height,
        0, 0, image.width, image.height
      );
      let resultCanvas = CanvasHelper.resizeCanvas(canvas, newWidth, newHeight);
      if (!!trim) {
        resultCanvas = CanvasHelper.trimCanvas(resultCanvas);
      }
      resultCanvas.toBlob((newBlob) => {
        if (newBlob) {
          callback(newBlob);
        }
      }, blob.type);
    });
  }

  public static fitFromBlob(blob: Blob, maxWidth: number, maxHeight: number, callback: (paramBlob: Blob) => void) {
    createImageBitmap(blob).then((image) => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      ctx?.drawImage(
        image, 0, 0, image.width, image.height,
        0, 0, image.width, image.height
      );

      const ratioWidth = maxWidth / image.width;
      const ratioHeight = maxHeight / image.height;
      const ratio = Math.min(ratioWidth, ratioHeight);
      let newWidth = image.width * (ratio < 1 ? ratio : 1.0);
      let newHeight = image.height * (ratio < 1 ? ratio : 1.0);

      let resultCanvas = CanvasHelper.resizeCanvas(canvas, newWidth, newHeight);
      resultCanvas.toBlob((newBlob) => {
        if (newBlob) {
          callback(newBlob);
        }
      }, blob.type);
    });
  }

}

export class ArrayBufferHelper {
  public static toBase64(buffer: ArrayBuffer): string {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  public static fromBase64(base64: string): ArrayBuffer {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}

export class MediaTypeHelper {
  public static contentType: string = 'content-type';
  public static resolveFileExtension(mediaType: string): string {
    switch (mediaType) {
      case "audio/wav":
        return ".wav";
      case "audio/x-wav":
        return ".wav";
      case "image/jpeg":
        return ".jpeg";
      case "image/png":
        return ".png";
      case "image/webp":
        return ".webp";
      case "video/mp4":
        return ".mp4";
      case "video/webm":
        return ".webm";
      case "text/plain":
        return ".txt";
      case "audio/mpeg":
        return ".mp3";
      case "audio/webm":
        return ".weba";
      case "application/zip":
        return ".zip";
      case "image/tiff":
        return ".tiff";
    }
    return "";
  }

  public static lookup(filename: string): string {
    var mediaType = 'application/octet-stream';
    var idx = filename.lastIndexOf('.');
    if (idx < 0) {
      return mediaType;
    }
    var ext = filename.substring(idx).toLowerCase();
    if (ext == '.wav') {
      mediaType = 'audio/x-wav';
    } else if (ext == '.jpe' || ext == '.jpeg' || ext == '.jpg') {
      mediaType = 'image/jpeg';
    } else if (ext == '.png') {
      mediaType = 'image/png';
    } else if (ext == '.webp') {
      mediaType = 'image/webp';
    } else if (ext == '.mp4') {
      mediaType = 'video/mp4';
    } else if (ext == '.webm') {
      mediaType = 'video/webm';
    } else if (ext == '.txt') {
      mediaType = 'text/plain';
    } else if (ext == '.mp3') {
      mediaType = 'audio/mpeg';
    } else if (ext == '.weba') {
      mediaType = 'audio/webm';
    } else if (ext == '.zip') {
      mediaType = 'application/zip';
    } else if (ext == '.tif' || ext == '.tiff') {
      mediaType = 'image/tiff';
    }
    return mediaType;
  }

  public static replaceFileExtension(filename: string, newExtension: string) {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      const nameWithoutExtension = filename.substring(0, lastDotIndex);
      return nameWithoutExtension + '.' + newExtension;
    } else {
      return filename + '.' + newExtension;
    }
  }
}

export class CanvasHelper {
  public static fromDataUrlAsync(dataurl: string): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var img = new Image();
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);
        resolve(canvas);
      };
      img.src = dataurl;
    });
  }

  public static resizeCanvas(canvas: HTMLCanvasElement, newWidth: number, newHeight: number, padding?: number): HTMLCanvasElement {

    const originWidth = canvas.width;
    const originHeight = canvas.height;

    const scaleAfterPad = padding ? 1 - padding : 1;

    const scaleWidth = newWidth / originWidth;
    const scaleHeight = newHeight / originHeight;

    let scaledWidth: number;
    let scaledHeight: number;

    if (scaleWidth < scaleHeight) {
      scaledWidth = newWidth;
      scaledHeight = Math.floor(originHeight * newWidth / originWidth);
    } else {
      scaledHeight = newHeight;
      scaledWidth = Math.floor(originWidth * newHeight / originHeight);
    }

    //const scaledWidth = Math.floor(originWidth * scale);
    //const scaledHeight = Math.floor(originHeight * scale);

    const newCanvas = document.createElement('canvas');
    newCanvas.width = newWidth;
    newCanvas.height = newHeight;
    const ctxNew = newCanvas.getContext('2d');

    let left = scaledWidth * scaleAfterPad / 2 * -1;
    let top = scaledHeight * scaleAfterPad / 2 * -1;

    if (!ctxNew) {
      return canvas;
    }

    ctxNew.translate(newWidth / 2, newHeight / 2);
    ctxNew.drawImage(canvas, 0, 0, originWidth, originHeight, left, top, scaledWidth * scaleAfterPad, scaledHeight * scaleAfterPad);

    return newCanvas;
  }

  public static trimCanvas(canvas: HTMLCanvasElement): HTMLCanvasElement {
    let ctx = canvas.getContext('2d');
    if (!ctx) {
      return canvas;
    }
    const width = canvas.width;
    const height = canvas.height;
    let pixels = ctx.getImageData(0, 0, width, height);
    let l = pixels.data.length;
    let bound: {
      top: number | null,
      left: number | null,
      right: number | null,
      bottom: number | null,
    } = {
      top: null,
      left: null,
      right: null,
      bottom: null
    };
    let x: number, y: number;

    // Iterate over every pixel to find the highest
    // and where it ends on every axis ()
    for (let i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % width;
        y = ~~((i / 4) / width);

        if (bound.top === null) {
          bound.top = y;
        }

        if (bound.left === null) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }

        if (bound.right === null) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }

        if (bound.bottom === null) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }

    if (bound.top == null || bound.left == null || bound.right == null || bound.bottom == null) {
      return canvas;
    }

    // Calculate the height and width of the content
    var trimHeight = bound.bottom - bound.top + 1,
      trimWidth = bound.right - bound.left + 1,
      trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    ctx.clearRect(0, 0, width, height);

    canvas.width = trimWidth;
    canvas.height = trimHeight;

    const newctx = canvas.getContext('2d');
    if (!newctx) {
      return canvas;
    }

    newctx.putImageData(trimmed, 0, 0);

    return canvas;

  }
}