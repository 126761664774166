import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MatDialog } from '@angular/material/dialog';
import { testItem, testForder, evaFeedback } from '../interfaces';
import { AppFormGroup } from '../common/app.forms';
import { FormControl } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'picture-audit',
  templateUrl: './picture-audit.component.html',
  styleUrl: './picture-audit.component.scss',
})
export class PictureAuditComponent extends BaseComponent implements OnInit {

  testList: testForder[] = [];
  inputList: testItem[] = [];
  resultList: testItem[] = [];
  curindex?: number;
  feedbackList: evaFeedback[] = [];
  displayedColumns: string[] = ['uuid', 'betterCount', 'equalCount', 'worseCount', 'feedbackDate'];
  resultNameMap: Map<string, string> = new Map<string, string>;
  dataSource?: MatTableDataSource<evaFeedback>;
  @ViewChild(MatTable) table!: MatTable<evaFeedback>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  betterRate: number = 0;
  equalRate: number = 0;
  worseRate: number = 0;

  evaForm: AppFormGroup;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog
  ) {
    super(injector);
    this.evaForm = new AppFormGroup({
      eva: new FormControl(''),
    });
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.http.get('/evaluation/assets').subscribe({
      next: (result: ActionResult) => {
        if (!!result) {
          let data: string[][] = result.data.testData;
          data.forEach(forder => {
            let fileList: testItem[] = [];
            forder.forEach(file => {
              fileList.push({ path: file, url: undefined })
            });
            this.testList.push({ fileList: fileList });
          });

          this.testList.forEach(folder => {
            folder.fileList.forEach(file => {
              this.http.get('/evaluation/assets/' + file.path, { responseType: 'blob' }).subscribe({
                next: (result: Blob) => {
                  if (!!result) {
                    file.url = URL.createObjectURL(result);
                  }
                },
              });
            });
          });
        }
      },
    });

    this.http.get('/evaluation/feedback').subscribe({
      next: (result: ActionResult) => {
        if (!!result) {
          let data: Record<string, any> = result.data;
          for (let key in data) {
            let value = data[key];
            let count: number = value.better_count + value.worse_count + value.equal_count;
            if (value.uuid.lastIndexOf(".") === -1) {
              continue;
            }
            this.feedbackList.push({
              uuid: value.uuid, betterCount: value.better_count / count,
              equalCount: value.equal_count / count, worseCount: value.worse_count / count, feedbackDate: new Date(value.feedback_date)
            });
          }
          this.dataSource = new MatTableDataSource(this.feedbackList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.table.renderRows();
          this.calcResult();
        }
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource!.filter = filterValue.trim().toLowerCase();

    if (this.dataSource!.paginator) {
      this.dataSource!.paginator.firstPage();
    }

    this.calcResult();
  }

  calcResult() {
    let filteredData = this.dataSource?.filteredData;
    let tot_better = 0, tot_equal = 0, tot_worse = 0;
    if (!!filteredData) {
      filteredData.forEach(element => {
        tot_better = tot_better + element.betterCount;
        tot_equal = tot_equal + element.equalCount;
        tot_worse = tot_worse + element.worseCount;
      });
    }
    const tot = tot_better + tot_equal + tot_worse;

    this.betterRate = tot_better / tot;
    this.equalRate = tot_equal / tot;
    this.worseRate = tot_worse / tot;
  }

  getSuffix(str: string): string {
    let lastIndex: number = str.lastIndexOf("/");

    if (lastIndex !== -1 && lastIndex < str.length - 1) {
      let afterLastSlash: string = str.substr(lastIndex + 1);
      return afterLastSlash;
    } else {
      return "";
    }
  }

  getWorkflowName(str: string): string {
    while (str.startsWith("prev")) {
      let firstIndex: number = str.indexOf("_");

      if (firstIndex !== -1 && firstIndex < str.length - 1) {
        let subStr: string = str.substring(firstIndex + 1);
        str = subStr;
      }
    }

    let firstIndex: number = str.indexOf("_");

    if (firstIndex !== -1 && firstIndex < str.length - 1) {
      if (str.substring(0, firstIndex) !== "workflow") {
        return "";
      }
      let secondIndex: number = str.indexOf("_", firstIndex + 1);

      if (secondIndex !== -1) {
        return "workflow_" + str.substring(firstIndex + 1, secondIndex);
      }
    }
    return "";
  }

  navClick(forder: testForder, index: number) {
    this.resultList = []
    this.inputList = []
    this.evaForm.reset();
    this.curindex = index;
    forder.fileList.forEach(file => {
      let suffix = this.getSuffix(file.path!);
      if (suffix.length > 32) {
        this.resultNameMap.set(file.path ?? "", this.getWorkflowName(suffix));

        if (suffix.startsWith("prev")) {
          this.resultList.push(file);
        } else {
          this.resultList.splice(0, 0, file);
        }
      } else {
        this.inputList.push(file);
      }
    });

    this.resultList.sort((a, b) => {
      if (this.resultNameMap.get(a.path ?? "")! === this.resultNameMap.get(b.path ?? "")!) {
        if (!this.getSuffix(a.path ?? "").startsWith("prev")) {
          return -1;
        }
        return 0;
      } else {
        if (this.resultNameMap.get(a.path ?? "")! < this.resultNameMap.get(b.path ?? "")!) {
          return -1;
        }
        return 1;
      }
    })
  }

  nextClick() {
    this.submitEva(this.getSuffix(this.resultList[0].path!));
    this.navClick(this.testList[this.curindex! + 1], this.curindex! + 1);
  }

  submitEva(uuid: string) {
    const params = {
      eva: this.evaForm.get('eva')?.value,
      uuid: uuid,
    }
    if (!params.eva) {
      return;
    }

    this.http.post('/evaluation/feedback', params, { silence: false }).subscribe(response => {
    });
  }

}
