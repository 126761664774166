import { Injectable } from '@angular/core';
import { IMessage, RxStomp, RxStompConfig, StompHeaders } from '@stomp/rx-stomp';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RxStompService {
  private rxStomp: RxStomp;
  constructor() {
    this.rxStomp = new RxStomp();
  }

  configure(rxStompConfig: RxStompConfig): void {
    this.rxStomp.configure(rxStompConfig);
  }

  activate() {
    if (this.rxStomp.connected()) {
      return;
    }
    this.rxStomp.activate();
  }

  watch(destination: string, headers?: StompHeaders): Observable<IMessage> {
    this.activate();
    return this.rxStomp.watch(destination, headers);
  }

  deactivate() {
    if (!this.rxStomp.connected()) {
      return;
    }
    this.rxStomp.deactivate();
  }
}