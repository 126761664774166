import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserOrderListItem } from '../interfaces';


@Component({
  selector: 'my-order-list',
  templateUrl: './my-order-list.html',
  styleUrl: './my-order-list.scss',
})
export class MyOrderList implements OnInit, AfterViewInit {
  // startIndex: number = 0;
  // count: number = 20;
  loading = true;
  displayedColumns: string[] = ['orderNo', 'payReceivedDate', 'itemTypeName', 'amount', 'statusName'];
  dataSource = new MatTableDataSource<UserOrderListItem>([]);
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {
    this.fetchOrders();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  hasMore = false;
  private fetchOrders() {
    if(this.dataSource.data.length==0){
      this.loading = true;
    }
    var params = {
      startIndex: this.dataSource.data.length, //this.startIndex,
      // count: this.count,
    }
    this.http.post('/user/orders/fetch', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dataSource.data = [...this.dataSource.data, ...result.data.orders];
        this.hasMore = result.data.hasMore;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  fetchMoreClick() {
    this.fetchOrders();
  }
}

