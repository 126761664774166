<div class="flex-grow-1"></div>

<mat-card class="content-card  text-primary">
  <mat-card-header class="d-flex flex-column p-1 p-lg-3">

    <div class="logo">
      <img src="{{baseUrl}}assets/images/logo.svg">
    </div>

    <div class="row mb-3 w-100">
      <div class="col-auto">
        <mat-card-title>Account Registration</mat-card-title>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="p-1 p-lg-3">

    <form [formGroup]="form" novalidate>
      <div class="row mt-4">
        <mat-form-field>
          <mat-label>Please set your nickname</mat-label>
          <input matInput formControlName="nickname" maxlength="128">
          <mat-error *ngIf="form.controls['nickname'].hasError('required') && form.controls['nickname'].touched">
            Please enter your nickname</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Please enter your password</mat-label>
          <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" maxlength="64">
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched">
            Please enter your password</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Please enter your password again</mat-label>
          <input matInput formControlName="repeatpassword" [type]="hidePassword ? 'password' : 'text'" maxlength="64">
          <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error
            *ngIf="form.controls['repeatpassword'].hasError('required') && form.controls['repeatpassword'].touched">
            Please enter your password again</mat-error>
        </mat-form-field>
        <mat-error class="mat-small"
          *ngIf="form.hasError('passwordMismatch') && form.controls['repeatpassword'].touched && !form.controls['repeatpassword'].hasError('required')">
          Passwords do not match，Please check your input</mat-error>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Please enter your mobile number</mat-label>
          <span class="hint-text" matPrefix>+86&nbsp;&nbsp;&nbsp;</span>
          <input matInput formControlName="username" maxlength="128">
          <mat-error *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched">
            Please enter your mobile number</mat-error>
          <mat-error *ngIf="form.controls['username'].hasError('pattern') && form.controls['username'].touched">
            Please enter your phone number in the correct format</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field>
          <mat-label>Please enter the verification code</mat-label>
          <input matInput formControlName="otp" maxlength="128">
          <button mat-button color="primary" class="otp-button" (click)="sendOtp($event)" [disabled]="optCountdown > 0">
            <span class="mat-body-1">Get verification code</span>
            <span class="mat-body-1" *ngIf="optCountdown > 0">({{ optCountdown }} s)</span>
          </button>
          <mat-error
            *ngIf="form.controls['otp'].hasError('required') && form.controls['otp'].touched">Please enter the verification code</mat-error>
          <mat-error
            *ngIf="form.controls['otp'].hasError('pattern') && form.controls['otp'].touched">Please enter the verification code in the correct format</mat-error>
        </mat-form-field>
      </div>

      <div class="row">
        <label class="mat-body hint-text">
          <input type="checkbox" formControlName="checkbox" (change)="checkboxChange($event)">
          Log in/By registering you agree<a href="{{baseUrl}}/account/useragent" class="text-primary" target="_blank">《User Agreement》</a>
          and<a href="{{baseUrl}}/account/privacypolicy" class="text-primary" target="_blank">《Privacy Policy》</a>
        </label>
        <mat-error class="mat-small" *ngIf="checkboxError">
          Please tick the box to agree to the terms and conditions</mat-error>
      </div>

      <button mat-flat-button class="w-100 mt-4 login-button" color="primary" (click)="registerClick()">
        <h3 class="my-0">Register Now</h3>
      </button>

      <button mat-button class="w-100 mt-2 mb-3 login-button" color="primary" routerLink="/account/login">
        <h4 class="my-0">Already have an account，Go to login</h4>
      </button>
    </form>


  </mat-card-content>
</mat-card>

<div class="flex-grow-1"></div>
<div class="flex-grow-1 d-none d-lg-flex"></div>
