<h2 class="mt-1 mb-1" mat-dialog-title>
  {{!id.value?'Adding a new task':'Rename Task'}}
</h2>

<mat-dialog-content class="py-0">
  <form [formGroup]="inputForm">
    <p class="hint-text mat-small mt-0 mb-2">name</p>
    <mat-form-field class="task-name-input" color="accent" appearance="outline">
      <!--<mat-label>name</mat-label>-->
      <input matInput formControlName="name" maxlength="32">
      <mat-error *ngIf="inputForm.controls['name'].invalid">required.</mat-error>
    </mat-form-field>
  </form>
  
</mat-dialog-content>

<mat-dialog-actions class="pt-0 pb-4 px-4" align="end">
  <button mat-stroked-button (click)="cancelClick()"><span class="my-0 mat-body hint-text">Cancel</span></button>
  <button mat-flat-button color="primary" (click)="saveClick()"><span class="my-0 mat-body">{{buttonLabel}}</span></button>
</mat-dialog-actions>
