<flexible-accordion class="h-lg-100" [(expanded)]="expanded" (expandedChange)="expandedChanged($event)">

  <div class="flexible-accordion-header">
    <mat-card class="input-container min-h-100">

      <div class="d-flex flex-wrap">
  
        <div class="raw-image-zone col-lg-6 pe-lg-2">
          <img class="raw-image" [class.d-none]="!rawImageUrl" [src]="rawImageUrl" (load)="onRawImageLoaded($event)">
        </div>
  
        <div class="sticker-image-zone col-lg-6 ps-lg-2">
          <div class="d-flex flex-column align-items-center m-auto" *ngIf="segmenting">
            <mat-spinner></mat-spinner>
            <div class="my-3">Image background removal，Please wait。</div>
          </div>
  
          <div class="sticker-image-container image-border" [class.h-100]="stickerContainerHeightFixed"
            [class.d-none]="segmenting || !stickerBackgroundImageUrl">
            <img class="sticker-background-image" [src]="stickerBackgroundImageUrl" />
            <element-floatable>
              <div class="top-left">
                <button mat-icon-button class="btn-sm bg-white m-1" color="primary" matTooltip="Re-cut the image with one click"
                  (click)="redoSegmentClick()">
                  <mat-icon>redo</mat-icon>
                </button>
              </div>
              <div class="top-right d-flex">
                <button mat-icon-button class="btn-sm bg-white m-1" color="primary" matTooltip="download"
                  (click)="exportStickerImageClick()">
                  <mat-icon>save_alt</mat-icon>
                </button>
                <button mat-icon-button class="btn-sm bg-white m-1" color="primary" matTooltip="Save to the History Library"
                  (click)="saveToUserAssetClick()">
                  <mat-icon>photo_libaray</mat-icon>
                </button>
              </div>
              <!-- <div class="bottom-left">
                <button mat-stroked-button class="btn-md" color="primary" matTooltip="download" (click)="exportStickerImageClick()">
                  <img src="{{ baseUrl }}assets/images/download.png" />
                  <span>download</span>
                </button>
                <button mat-stroked-button class="btn-md" color="primary" matTooltip="Save to the History Library" (click)="saveToUserAssetClick()">
                  <img src="{{ baseUrl }}assets/images/image_download.png" />
                  <span>Save to the History Library</span>
                </button>
              </div> -->
  
            </element-floatable>
            <image-sticker-input [modelScale]="modelScale" #stickers />
          </div>
        </div>
  
        <div class="col-12 text-center py-2">
          <div>
            <span class="pe-3">If you are not satisfied with the automatic cutout effect，Please click to enter:</span>
            <button mat-stroked-button color="primary" (click)="segmentPickerClick($event)" [disabled]="segmenting">
              <mat-icon>format_shapes</mat-icon>Manual Cutout
            </button>
          </div>
        </div>
  
      </div>
  
      <div class="mb-2">
        <image-style-input [selectedTemplateId]="selectedTemplateId" (valueChange)="selectedTemplateChanged($event)" />
      </div>
  
      <div class="mb-2">
        <image-prompt-input [(value)]="prompts" [(negative)]="negativePrompts" 
        [placeHolder]="'TIPS:Scene description tilt model area、age、Style, etc.，And describe the background description you want to switch\n\nFor example：An Asian model，About25age，Sweet style，On the beach，The sea in the distance'" />
      </div>
  
      <div class="mb-2">
        <image-size-input [aspect]="aspect" (aspectChange)="imageAspectChanged($event)" />
      </div>
  
      <div class="mb-2">
        <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
      </div>
  
      <div class="d-flex py-2" *ngIf="!!stickers&&stickers.value.length >= 0">
        <cost-estimator category="image" item="paint" [quantity]="quantity" />
        <div class="flex-grow-1"></div>
        <button mat-flat-button color="primary" (click)="generateClick()">AI Generate</button>
      </div>
  
    </mat-card>
  </div>

  <div class="flex-grow-1 h-lg-100 output-container">

    <div class="d-flex flex-column w-100 h-100 p-lg-2" [class.d-none]="paintedImages.length==0">
      <painted-image-list #paintedImageList [(value)]="paintedImages" [editable]="true" (edit)="refactorClick($event)"
        (completed)="onPaintCompleted()" />
    </div>

  </div>

</flexible-accordion>
