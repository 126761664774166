import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FileHelper } from '../helpers/app.helpers';
import { MessageService } from '../common/message.service';
import { BASE_URL } from '../app.module';

@Component({
  selector: 'image-picker',
  templateUrl: './image-picker.html',
  styleUrl: './image-picker.scss',
})
export class ImagePicker {
  @ViewChild('fileInput') fileInputRef!: ElementRef;
  url?: string;
  @Input() acceptTypes: Array<string>= ['png','jpeg','jpg','webp'];
  @Input() hasDropZone: boolean = true;
  @Input() hasImageZone: boolean = true;
  @Input() showHint: boolean = true;
  @Output() changed = new EventEmitter<Blob | undefined>();
  @Output() loaded = new EventEmitter<any>();
  @Input() canRemove: boolean = false;
  @Input() style: imagePickerStyle = imagePickerStyle.normal;
  @Input() inlineHintText:string = "";
  @Input() title:string = "Upload pictures";

  constructor(private messageService: MessageService,
    @Inject(BASE_URL) public baseUrl: string) {

  }

  dropFile(event: any) {
    event.preventDefault();
    let selectedFiles = event.dataTransfer.files;
    if (!selectedFiles || selectedFiles.length == 0) {
      return;
    }
    this.loadFile(selectedFiles[0]);
  }

  dragoverFile(event: any) {
    event.preventDefault();
  }

  chooseFileClick(event: any){
    event.stopPropagation();
    this.click();
  }

  click() {
    this.fileInputRef.nativeElement.click();
  }

  onFileChanged(event: any) {
    let selectedFiles = event.target.files || event.dataTransfer.files;

    if (!selectedFiles || selectedFiles.length == 0) {
      return;
    }
    
    let file = selectedFiles[0];
    let ext = FileHelper.getExtension(file.name);
    if (!ext || !this.acceptTypes.find(o=> o===ext)) {
      this.messageService.showError(`Please select${this.acceptTypes.join("、")}Format of pictures。`);
      return;
    }

    this.loadFile(file);
  }

  loadFile(blob: Blob, silence = false) {
    this.url = URL.createObjectURL(blob);
    this.fileInputRef.nativeElement.value = '';
    if(!silence){
      this.changed.emit(blob);
    }
  }

  onLoaded(event: Event) {
    this.loaded.emit(event);
  }

  resetClick(event: any){
    event.stopPropagation();
    this.reset();
    this.changed.emit(undefined);
  }

  reset(){
    this.url= undefined;
    this.fileInputRef.nativeElement.value = '';
  }

}

export enum imagePickerStyle{
  normal = 'normal',
  inline = 'inline',
}
