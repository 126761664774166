
<page-header title="Creative Supply Station" />

<div class="d-flex flex-wrap">

  <div class="col-12 col-md-6 col-lg-4 col-xxl-3 p-2" *ngFor="let tool of tools">
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><img class="icon-lg" [src]="baseUrl + tool.icon" /></div>
        <mat-card-title>{{tool.title}}</mat-card-title>
        <div class="flex-grow-1 d-flex flex-row-reverse">
          <div class="d-flex">
            <mat-icon class="me-2">people</mat-icon>
            <span>456</span>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content>
        <p>
          {{tool.description}}
        </p>
      </mat-card-content>
      <mat-card-actions>
        <div matRipple class="border rounded p-1 fs-small">Image Tools</div>
        <div class="flex-grow-1"></div>
        <button mat-flat-button color="primary">Get Started</button>
      </mat-card-actions>
    </mat-card>
  </div>

</div>
