import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_URL } from '../app.module';
import { SessionService } from '../common/session.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  status: any = "";
  title: string = "";
  messages: string[] = [];

  constructor(private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    @Inject(BASE_URL) public baseUrl: string) {
    
  }

  ngOnInit() {

    // close all dialog
    this.dialog.closeAll();

    this.route.paramMap.subscribe(params => this.init(params));
  }

  init(params: any) {
    if (params?.has('status')) {
      this.status = params.get('status');
    }
    if (this.status == '401') {
      this.title = "Unauthorized";
      this.messages = [
        "You are not authorized, or the session has timed out.",
        "Please login again."];
    } else if (this.status == '403') {
      this.title = "Access Denied";
      this.messages = [
        "You do not have permission to view this page.",
        "Please contact administrator."];
    } else if (this.status == '404') {
      this.title = "Not Found";
      this.messages = [
        "Oops. Looks like the page you're looking for no longer exists.",
        "But we're here to bring you back to safety."
      ];
    } else {
      this.title = "Error";
      this.messages = [
        "An error occurred while processing your request.",
        "please try again later."
      ];
    }
  }

  back() {
    if (this.status != '404') {
      this.sessionService.logout();
      window.location.href = "/";
      return;
    }

    this.router.navigate(['/home']);
  }
}
