import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';
import { ImageAspects } from '../helpers/image-helper';

@Component({
  selector: 'image-size-input',
  templateUrl: './image-size-input.html',
  styleUrl: './image-size-input.scss',
})
export class ImageSizeInput implements OnInit {
  aspects = [...ImageAspects];
  _aspect?: string;
  @Input() set aspect(value: string | undefined){
    this._aspect = value;
    let item = this.aspects.find(o=> o.key == value);
    if(!item){
      return;
    }
    this.aspects.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get aspect(){
    return this._aspect;
  }

  @Output() aspectChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {
    
  }

  aspectClick(selectedAspect: SelectListItem) {
    this.aspect = selectedAspect.key;
    this.aspectChange.emit(this.aspect);
  }

}