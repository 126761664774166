import { Component, Injector, ViewChild } from '@angular/core';
import { SelectListItem } from '../models';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { ImagePicker } from '../image-picker/image-picker';

@Component({
  selector: 'material-edit',
  templateUrl: './material-edit.component.html',
  styleUrl: './material-edit.component.scss',
})
export class MaterialEditComponent extends BaseComponent {
  labels?: Array<any>;
  categories?: SelectListItem[];
  inputForm: FormGroup;
  @ViewChild('imagePicker') imagePicker!: ImagePicker;
  selectedLabels: Array<string> = [];

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      id: new FormControl(),
      category: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      prompts: new FormControl('', [Validators.required]),
      negativePrompts: new FormControl(),
      promptsEnglish: new FormControl(),
      negativePromptsEnglish: new FormControl(),
      imageFile: new FormControl(undefined, [Validators.required]),
      imageFileChanged: new FormControl(),
      sortOrder: new FormControl(undefined, [Validators.pattern('^[0-9]*$')]),
      active: new FormControl('Y', [Validators.required]),
      description: new FormControl(),
      updateDate: new FormControl(),
    });
  };

  ngOnInit() {
    this.http.get('/material/templates/options').subscribe(response => {
      this.categories = response.data.categories;
      this.labels = response.data.labels;
    });

    this.route.paramMap.subscribe(params => {
      this.init(params);
    });
  }

  init(params: any) {
    let id = params == undefined ? "" : params.get('id');

    if (!id) { //add new
      return;
    } else { //edit
      this.find(id);
    }
  }

  find(id: any) {
    this.http.get(`/material/templates/${id}/details`).subscribe(result => {
      if (!this.http.isValidResult(result)) {
        return;
      }
      let data = result.data;
      data.category = String(data.category);
      let lables = result.data.labels;
      if (!!lables) {
        this.selectedLabels = JSON.parse(lables);
      }
      this.inputForm.patchValue(result.data);
    });
    this.http.get(`/material/templates/${id}`, { responseType: 'blob' })
      .subscribe({
        next: (result: Blob) => {
          this.inputForm.patchValue({
            imageFile: result,
          });
          this.imagePicker.loadFile(result);
        },
      });
  };

  saveClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let id = this.id.value
    let url = '/material/templates' + (!id ? '' : `/${id}`);
    let values = this.inputForm.value;
    if (!values.imageFileChanged) {
      values.imageFile = undefined;
    }
    let selectedLabels = this.selectedLabels ?? [];
    values.labels = selectedLabels.length > 0 ? JSON.stringify(selectedLabels) : undefined;
    let params = this.http.object2FormData(values);
    this.http.post(url, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.messageService.showInformation('The material has been successfully saved。');
        this.router.navigate(['/admin/material/search']);
      }
    });
  }

  deleteClick() {
    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the material?？',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      let id = this.id.value
      this.http.delete(`/material/templates/${id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.messageService.showInformation('The material has been successfully deleted。');
          this.router.navigate(['/admin/material/search']);
        }
      });
    });
  }

  backClick() {
    this.router.navigate(['/admin/material/search']);
  };

  imageFileChanged(file: Blob | undefined) {
    if (this.inputForm.controls['imageFile'].value != file) {
      this.inputForm.patchValue({
        imageFileChanged: true,
      });
    }
    this.inputForm.patchValue({
      imageFile: file,
    });
  }

  get id() { return this.inputForm.controls['id']; }

  removeLabel(label: string): void {
    let selectedLabels = this.selectedLabels;
    for (let i = 0; i < selectedLabels.length; i++) {
      if (selectedLabels[i] == label) {
        selectedLabels.splice(i, 1);
        return;
      }
    }
  }

  resetLabels() {
    this.selectedLabels = [];
  }

  addLabel(value: string | undefined): void {
    const label = (value || '').trim();
    if (!this.isLabelExist(label)) {
      return;
    }
    if (label) {
      let selectedLabels = this.selectedLabels;
      for (let i = 0; i < selectedLabels.length; i++) {
        if (selectedLabels[i] == label) {
          return;
        }
      }
      selectedLabels.push(label);
    }
  }

  isLabelExist(value: string | undefined): boolean {
    const label = (value || '').trim();
    let labels = this.labels ?? [];
    for (let i = 0; i < labels.length; i++) {
      if (!!labels[i].children?.find((element: any) => element.name == label)) {
        return true;
      }
    }
    return false;
  }

}
