import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sticker } from '../models';
import { AppHttpClient } from '../common/app.http';
import { PathTypes, Point, ModelScaleProps } from '../interfaces';
import { angleBetween, distanceBetween } from '../helpers/mask-utils';
import {
  buildEraseBrushPath, cropImageByEllipse, cropImageByEraseBrush,
  cropImageByPath, cropImageByRect, getBoundingBox
} from '../helpers/create-cutouts';

@Component({
  selector: 'image-mask-input',
  templateUrl: './image-mask-input.html',
  styleUrl: './image-mask-input.scss',
})
export class ImageMaskInput {
  rawImage!: HTMLImageElement;
  modelScale!: ModelScaleProps;
  @ViewChild('maskCanvas') maskCanvasRef!: ElementRef;
  @Input() pathType?: PathTypes;
  @Input() penWidth = 1;
  // maskCanvasCtx: CanvasRenderingContext2D | null = null;
  pathPoints: Array<Point> | null = null;
  @Input() value: Array<Sticker> = [];
  @Output() valueChange = new EventEmitter<Array<Sticker>>();
  @Output() completed = new EventEmitter<MouseEvent>();

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {

  }

  init(rawImage: HTMLImageElement, modelScale: ModelScaleProps) {
    this.rawImage = rawImage;
    this.modelScale = modelScale;
    // let maskCanvas = document.getElementById('maskCanvas') as HTMLCanvasElement;
    this.maskCanvas.width = modelScale.width;
    this.maskCanvas.height = modelScale.height;
    // this.maskCanvasCtx = maskCanvas.getContext("2d");
  }

  reset() {
    let maskCanvasCtx = this.maskCanvas.getContext("2d");
    if (!!maskCanvasCtx) {
      maskCanvasCtx.clearRect(0, 0, this.maskCanvas.width, this.maskCanvas.height);
    }
    this.pathPoints = null;
    this.value = [];
  }

  get maskCanvas(): HTMLCanvasElement {
    return this.maskCanvasRef.nativeElement as HTMLCanvasElement;
  }

  onMouseDown(event: MouseEvent) {
    let maskCanvasCtx = this.maskCanvas.getContext("2d");
    if (!maskCanvasCtx) {
      return;
    }
    maskCanvasCtx.lineCap = "round";
    maskCanvasCtx.lineJoin = "round";
    maskCanvasCtx.strokeStyle = 'red';
    maskCanvasCtx.fillStyle = 'red';
    maskCanvasCtx.lineWidth = Math.ceil(Math.min(this.modelScale.width, this.modelScale.height) * this.penWidth / 100);
    this.reset();
    let point = this.getMouseInputOnRawImage(event);
    switch (this.pathType) {
      case PathTypes.FreeLine:
        maskCanvasCtx.beginPath();
        maskCanvasCtx.moveTo(point.x, point.y);
        this.pathPoints = [{ x: point.x, y: point.y }];
        break;
      case PathTypes.Rect:
        this.pathPoints = [{ x: point.x, y: point.y }];
        break;
      case PathTypes.Circle:
        this.pathPoints = [{ x: point.x, y: point.y }];
        break;
      case PathTypes.EraseBrush:
        let width = this.maskCanvas.width;
        let height = this.maskCanvas.height;
        // maskCanvasCtx.lineWidth = maskCanvasCtx.lineWidth * 8;
        this.pathPoints = [{ x: point.x, y: point.y }];
        break;
      default:
        return;
    }

    const mouseMoveHandler = (event: MouseEvent) => {
      this.onMouseMove(event);
    };

    const mouseUpHandler = (event: MouseEvent) => {
      this.onMouseUp(event);
      window.removeEventListener('mousemove', mouseMoveHandler, false);
      window.removeEventListener('mouseup', mouseUpHandler);
    };

    window.addEventListener('mousemove', mouseMoveHandler, false);
    window.addEventListener('mouseup', mouseUpHandler, false);
  }

  onMouseMove(event: MouseEvent) {
    let maskCanvasCtx = this.maskCanvas.getContext("2d");
    if (!maskCanvasCtx) {
      return;
    }
    if (!this.pathPoints || this.pathPoints.length == 0) {
      return;
    }
    let startPoint: Point;
    // let endPoint: Point;
    let point = this.getMouseInputOnRawImage(event);
    switch (this.pathType) {
      case PathTypes.FreeLine:
        maskCanvasCtx.lineTo(point.x, point.y);
        maskCanvasCtx.stroke();
        this.pathPoints.push({ x: point.x, y: point.y });
        break;
      case PathTypes.Rect:
        startPoint = this.pathPoints[0];
        if (this.pathPoints.length > 1) {
          this.clearRect(maskCanvasCtx, this.pathPoints);
        }
        this.pathPoints = [startPoint, point];
        this.drawRect(maskCanvasCtx, this.pathPoints);
        break;
      case PathTypes.Circle:
        startPoint = this.pathPoints[0];
        if (this.pathPoints.length > 1) {
          this.clearRect(maskCanvasCtx, this.pathPoints);
        }
        this.pathPoints = [startPoint, point];
        this.drawEllipse(maskCanvasCtx, this.pathPoints);
        break;
      case PathTypes.EraseBrush:
        this.pathPoints.push({ x: point.x, y: point.y });
        // [startPoint, endPoint] = this.pathPoints.slice(-2);
        // this.drawRoundRect(maskCanvasCtx, startPoint, endPoint);
        maskCanvasCtx.clearRect(0, 0, maskCanvasCtx.canvas.width, maskCanvasCtx.canvas.height);
        maskCanvasCtx.fill(buildEraseBrushPath(this.pathPoints, maskCanvasCtx.lineWidth));
        break;
      default:
        return;
    }
  }

  onMouseUp(event: MouseEvent) {
    let maskCanvasCtx = this.maskCanvas.getContext("2d");
    if (!maskCanvasCtx) {
      return;
    }
    if (!this.pathPoints || this.pathPoints.length == 0) {
      return;
    }
    let image = this.rawImage;
    let modelScale = this.modelScale;
    if (!image || !modelScale) {
      return;
    }
    let sticker: Sticker;
    let startPoint: Point;
    // let endPoint: Point;
    let point = this.getMouseInputOnRawImage(event);
    switch (this.pathType) {
      case PathTypes.FreeLine:
        maskCanvasCtx.lineTo(point.x, point.y);
        maskCanvasCtx.closePath();
        maskCanvasCtx.stroke();
        this.pathPoints.push({ x: point.x, y: point.y });
        sticker = cropImageByPath(image, modelScale, this.pathPoints);
        this.value.push(sticker);
        break;
      case PathTypes.Rect:
        startPoint = this.pathPoints[0];
        if (this.pathPoints.length > 1) {
          this.clearRect(maskCanvasCtx, this.pathPoints);
        }
        this.pathPoints = [startPoint, { x: point.x, y: point.y }];
        this.drawRect(maskCanvasCtx, this.pathPoints);
        sticker = cropImageByRect(image, modelScale, this.pathPoints);
        this.value.push(sticker);
        break;
      case PathTypes.Circle:
        startPoint = this.pathPoints[0];
        if (this.pathPoints.length > 1) {
          this.clearRect(maskCanvasCtx, this.pathPoints);
        }
        this.pathPoints = [startPoint, point];
        this.drawEllipse(maskCanvasCtx, this.pathPoints);
        sticker = cropImageByEllipse(image, modelScale, this.pathPoints);
        this.value.push(sticker);
        break;
      case PathTypes.EraseBrush:
        this.pathPoints.push({ x: point.x, y: point.y });
        // [startPoint, endPoint] = this.pathPoints.slice(-2);
        // this.drawRoundRect(maskCanvasCtx, startPoint, endPoint);
        maskCanvasCtx.clearRect(0, 0, maskCanvasCtx.canvas.width, maskCanvasCtx.canvas.height);
        maskCanvasCtx.fill(buildEraseBrushPath(this.pathPoints, maskCanvasCtx.lineWidth));
        sticker = cropImageByEraseBrush(image, modelScale, this.pathPoints, maskCanvasCtx.lineWidth);
        this.value.push(sticker);
        break;
      default:
        break;
    }
    this.pathPoints = null;
    this.completed.emit(event);
  }

  getMouseInputOnRawImage(event: MouseEvent): { x: number, y: number } {
    let point = this.getMouseInput(event);
    let target = event.target as HTMLElement;
    if (!target) {
      return point;
    }
    let width = target.clientWidth;
    let height = target.clientHeight;
    point.x *= this.maskCanvas.width / width;
    point.y *= this.maskCanvas.height / height;
    return point;
  }

  getMouseInput(event: MouseEvent): { x: number, y: number } {
    let el = event.target as HTMLElement;
    const rect = el.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    return { x, y };
  };


  clearRect(canvasContext: CanvasRenderingContext2D, points: Array<Point>) {
    let lineWidth = canvasContext.lineWidth;
    let { x, y, width, height } = getBoundingBox(points);
    x = x - lineWidth;
    width = width + lineWidth * 2;
    y = y - lineWidth;
    height = height + lineWidth * 2;
    canvasContext.clearRect(x, y, width, height);
  }

  drawRect(canvasContext: CanvasRenderingContext2D, points: Array<Point>) {
    let { x, y, width, height } = getBoundingBox(points);
    canvasContext.strokeRect(x, y, width, height);
  }

  drawEllipse(canvasContext: CanvasRenderingContext2D, points: Array<Point>) {
    let { x, y, width, height } = getBoundingBox(points);
    canvasContext.beginPath();
    canvasContext.ellipse(x + width / 2, y + height / 2, width / 2, height / 2, 0, 0, Math.PI * 2);
    canvasContext.stroke();
  }

  drawRoundRect(canvasContext: CanvasRenderingContext2D, startPoint: Point, endPoint: Point) {
    let distance = distanceBetween(startPoint, endPoint);
    let angle = angleBetween(startPoint, endPoint);
    let lineWidth = canvasContext.lineWidth;
    canvasContext.translate(startPoint.x, startPoint.y);
    canvasContext.rotate(Math.PI / 2 - angle);
    canvasContext.beginPath();
    canvasContext.roundRect(0, 0, distance, 0, [lineWidth / 2]);
    canvasContext.stroke();
    canvasContext.setTransform(1, 0, 0, 1, 0, 0);
  }

  drawToCanvas(): HTMLCanvasElement {
    const width = this.maskCanvas.width;
    const height = this.maskCanvas.height;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    this.value.forEach((sticker) => {
      ctx?.drawImage(
        sticker.element,
        sticker.origLeft / 100 * width,
        sticker.origTop / 100 * height,
        sticker.origWidth / 100 * width,
        sticker.origHeight / 100 * height,
      );
    });
    return canvas;
  }
}