import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResult, ActionStatus, AppHttpClient } from '../common/app.http';
import { UserAssetDetails, UserAssetGroupListItem, UserAssetListItem } from '../interfaces';
import { imagePickerStyle } from '../image-picker/image-picker';
import { AssetType } from '../models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { of } from 'rxjs';
import { filter } from 'jszip';

@Component({
  selector: 'user-asset-inline',
  templateUrl: './user-asset-inline.html',
  styleUrl: './user-asset-inline.scss',
})
export class UserAssetInline {
  groupAssets: UserAssetListItem[] = [];
  selectedAsset: number = -1;
  groupId?: number;

  imagePickerInline: imagePickerStyle = imagePickerStyle.inline;

  @Input() itemClass = "ratio ratio-1x1";
  @Input({ required: true }) groupType!: string;
  @Input({ required: true }) inlineHintText!: string;
  @Input()
  set selected(flag: boolean) {
    if (!flag) {
      this.groupAssets.forEach(asset => {
        asset.selected = false;
      });
      this.selectedAsset = -1;
    }
  }
  @Input()
  set initSelectedId(id: number) {
    this.groupAssets.forEach(asset => {
      if (asset.id == id) {
        asset.selected = true;
      }
      else {
        asset.selected = false;
      }
    });
  }
  @Output() selectionChange = new EventEmitter<UserAssetDetails>();

  constructor(
    private http: AppHttpClient,
    private dialog: MatDialog,) {

  }


  ngOnInit() {
    this.http.get("/user/assets/category/1").subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let groups: UserAssetGroupListItem[] = result.data;
        groups.forEach(group => {
          if (group.type != this.groupType) {
            return;
          }
          this.groupId = group.id;
          group.assets.forEach(asset => {
            this.http.get(`/user/assets/${asset.id}/thumbnail`, { responseType: 'blob' }).subscribe({
              next: (result: Blob) => {
                asset.url = URL.createObjectURL(result);
                this.groupAssets = [...this.groupAssets, asset];
              },
            });
          });
        })
      }
    });
  }

  reset() {
    this.selectedAsset = -1;
  }

  imageUploaded(file: Blob | undefined) {
    if (!file) {
      return;
    }

    let obserable;
    if (!this.groupId) {
      let input = {
        'type': this.groupType,
      };
      obserable = this.http.post(`/user/asset-group/special`, input);
    } else {
      obserable = of({
        status: ActionStatus.Success,
        data: this.groupId,
      } as ActionResult);
    }

    obserable.subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let groupId = result.data;
        if (!groupId) {
          return;
        }
        this.groupId = groupId;

        var formData = new FormData();
        formData.set('category', "1");
        formData.set('groupId', groupId);
        formData.set('assetFile', file);
        formData.set('assetType', AssetType.Image);
        this.http.post('/user/assets', formData).subscribe({
          next: (result: ActionResult) => {
            if (!this.http.isValidResult(result)) {
              return;
            }
            let asset = {
              id: result.data,
              name: '',
              url: URL.createObjectURL(file),
              selected: false,
            };
            this.groupAssets.splice(0, 0, asset);
            this.imageClick(asset);
          },
        });
      },
    });
  }

  imageClick(asset: UserAssetListItem) {
    if (this.selectedAsset == asset.id) {
      return;
    }

    this.groupAssets?.forEach(item => {
      item.selected = false;
    });

    asset.selected = true;
    this.selectedAsset = asset.id;

    this.http.get(`/user/assets/${asset.id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        var data = result.data;
        this.selectionChange.emit(data);
      }
    });
  }

  deleteImage(asset: UserAssetListItem, event: any) {
    event.stopPropagation();
    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
        question: 'Are you sure you want to delete the image?？',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.http.delete(`/user/assets/${asset.id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.groupAssets = this.groupAssets.filter(function (item) {
            return item.id != asset.id;
          });
        }
      });
    });
  }

}

