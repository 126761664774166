<div class="d-flex">
  <div>size</div>
  <div class="remark align-self-center mx-2">Pixel(0~768)</div>
  <div class="flex-grow-1"></div>
</div>

<div class="d-flex mt-2 justify-content-between">

  <input class="mx-2 inputstyle" [(ngModel)]="width" (change)="widthChanged()"
  placeholder="Please enter the width，maximum768"/>

  <span>*</span>

  <input class="mx-2 inputstyle" [(ngModel)]="height" (change)="heightChanged()"
  placeholder="Please enter your height，maximum768"/>

  <div class="flex-grow-1"> </div>

</div>

