import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, } from '@angular/material/dialog';
import { ActionResult, ActionStatus, AppHttpClient } from '../common/app.http';
import { OrderItemTypes, UserNotification, UserNotificationCategory } from '../models';
import { Subscription } from 'rxjs';
import { RxStompService } from '../stomp/rx-stomp.service';
import { IMessage } from '@stomp/rx-stomp';
import { SessionService } from '../common/session.service';
import { MessageService } from '../common/message.service';

@Component({
  selector: 'membership-purchase-dialog',
  templateUrl: './membership-purchase-dialog.html',
  styleUrl: './membership-purchase-dialog.scss',
})

export class MembershipPurchaseDialog implements OnInit {
  url?: string;
  period?: string;
  type?: string;
  paymentId?: number;
  private paymentSubscription?: Subscription;
  private timer?: NodeJS.Timeout;

  constructor(
    private http: AppHttpClient,
    private sessionService: SessionService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<MembershipPurchaseDialog>,
    private rxStompService: RxStompService,
    @Inject(MAT_DIALOG_DATA) public data: OrderItemTypes,) {
    data = data ?? OrderItemTypes.PersonMonth;
    let arr = data.split('-');
    this.type = arr[0];
    this.period = arr[1];
  }

  ngOnInit() {
    this.paymentSubscription = this.rxStompService
      .watch(UserNotificationCategory.Membership)
      .subscribe((message: IMessage) => {
        let notification = JSON.parse(message.body) as UserNotification;
        let paymentId = notification.id;
        if (this.paymentId != paymentId) {
          return;
        }
        let result = notification.data;
        this.completedPayment(result);
      });
    this.timer = setInterval(() => this.checkPaymentStatus(), 20 * 1000);
    this.prepay();
  }

  ngOnDestroy() {
    this.paymentSubscription?.unsubscribe();
    this.stopTimer();
  }

  stopTimer() {
    if (!!this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = undefined;
  }

  prepay() {
    this.cancelPayment();

    this.url = undefined;
    let params = {
      orderItem: `${this.type}-${this.period}`,
    };
    this.http.post('/payments/prepay', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let data = result.data;
        this.paymentId = data.id;
        this.url = `data:image/png;base64, ${data.encodedQrCode}`;
      },
    });
  }

  checkPaymentStatus() {
    let id = this.paymentId;
    if (!id) {
      return;
    }
    this.http.get(`/payments/${id}/query`).subscribe({
      next: (result: ActionResult) => {
        if (result.status == ActionStatus.Running) {
          // Paying(unfinished)，Continue to query later
          return;
        }
        this.completedPayment(result);
      }
    });
  }

  completedPayment(result: ActionResult) {
    this.stopTimer();
    this.dialogRef.close();
    if (!this.http.isValidResult(result)) {
      return;
    }
    let data = result.data;
    let user = this.sessionService.user;
    if (!!user) {
      user.points = data.points;
      user.totalPoints = data.totalPoints;
      user.accountType = data.accountType;
      user.memberStartDate = data.memberStartDate;
      user.memberEndDate = data.memberEndDate;
    }
    this.sessionService.user = user;
    this.messageService.showInformation('Payment successful。');
  }

  cancelPayment() {
    let id = this.paymentId;
    if (!id) {
      return;
    }
    this.paymentId = undefined;
    let params = {};
    this.http.post(`/payments/${id}/cancel`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
      },
    });
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  memeberPeriodChanged(value: string) {
    this.period = value;
    this.prepay();
  }

  memeberTypeChanged(value: string) {
    this.type = value;
    this.prepay();
  }

}
