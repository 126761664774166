import { Component, EventEmitter, Input, Output } from '@angular/core';
import { validRange } from '../common/app.forms';

@Component({
  selector: 'image-quantity-input',
  templateUrl: './image-quantity-input.html',
  styleUrl: './image-quantity-input.scss',
})
export class ImageQuantityInput {
  @Input() min?: number;
  @Input() max?: number;
  @Input() value?: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {

  }

  addQuantityClick(step: number) {
    if (!validRange(this.value, this.min, this.max)) {
      this.value = '1';
      return;
    }
    var v = Number(this.value) + step;
    if (!!this.min && v < this.min || !!this.max && v > this.max) {
      return;
    }
    this.value = v.toString();
    this.valueChange.emit(this.value);
  }

  valueChaned() {
    this.valueChange.emit(this.value);
  }
}