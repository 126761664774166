<flexible-accordion class="h-lg-100" [(expanded)]="expanded" (expandedChange)="expandedChanged($event)">

  <div class="flexible-accordion-header">
    <mat-card class="min-h-100">
      <div class="mb-3">
        <img class="raw-image" [src]="rawImageUrl" />
      </div>
      <div class="d-flex mb-4">
        <div class="flex-grow-1" *ngIf="returnTo!=undefined"></div>
        <button class="btn-md btn-min-width" mat-stroked-button (click)="backClick()" *ngIf="returnTo!=undefined">
          <!-- <img src="{{ baseUrl }}assets/images/redo.png" /> -->
          <span>return</span>
        </button>
        <div class="flex-grow-1"></div>
        <button class="btn-md btn-min-width" mat-stroked-button (click)="uploadRawImageClick()">
          <!-- <img src="{{ baseUrl }}assets/images/redo.png" /> -->
          <span>Upload</span>
        </button>
        <div class="flex-grow-1"></div>
      </div>
    </mat-card>
  </div>

  <div class="flex-grow-1 d-flex flex-column">

    <div class="raw-image-zone flex-lg-row">

      <div class="flex-grow-1 mx-2 mb-2">
        <div class="flex-1 d-flex flex-column mh-100 m-auto" [style.aspect-ratio]="imageAspect"
          [class.d-none]="!inputImageLoaded">
          <div class="raw-image-container">
            <img class="raw-image" #inputImage [src]="selectedImageUrl" (load)="inputImageLoad($event)" />
            <image-mask-input #maskInput [pathType]="pathType" [penWidth]="penWidth"
              (completed)="onMaskCompleted($event)" />
            <image-sticker-input [modelScale]="modelScale" #stickerInput />
          </div>
        </div>
      </div>

      <mat-card class="image-actions-bar mb-2">
        <div class="mb-2">
          <div class="fs-larger">Select Edit Mode</div>
          <div class="d-inline-block mb-4">
            <div class="paint-mode-container">
              <div class="paint-mode-item" [class.active]="paintMode==item.key" matRipple [matRippleCentered]="true"
                (click)="paintModeChanged(item.key)" *ngFor="let item of paintModelList">
                {{item.value}}
              </div>
            </div>
          </div>

          <div>Pen width</div>
          <div class="pen-width">
            <mat-slider [max]="penMaxWidth" [min]="penMinWidth" step="1">
              <input matSliderThumb [(ngModel)]="penWidth">
            </mat-slider>
          </div>
        </div>
        <!-- <div class="mb-2" *ngIf="pathTypeVisible">
          <div class="m-1 d-inline-block" *ngFor="let item of pathTypeList">
            <button mat-stroked-button class="path-type-item" [class.active]="pathType==item.key"
              (click)="pathTypeChanged(item.key)">
              <mat-icon>{{item.value}}</mat-icon><span>{{item.name}}</span>
            </button>
          </div>
          <div class="m-1 d-inline-block">
            <button mat-stroked-button color="primary" (click)="segmentPickerClick()">
              <mat-icon>format_shapes</mat-icon><span>Smart Selection</span>
            </button>
          </div>
        </div> -->
        <div class="flex-grow-1 d-flex align-items-center justify-content-center">
          <div [class.d-none]="!inputImageLoaded">
            <div class="painted-image-container d-flex align-items-center flex-row flex-lg-column"
              *ngIf="paintedImages.length>0">
              <div class="d-flex align-items-center flex-column flex-lg-row">
                <div class="arrow-container">
                  <div class="arrow-icon" [class.d-none]="selectedImageIndex!=undefined"></div>
                </div>
                <div class="painted-image-item" [class.active]="selectedImageIndex==undefined" matRipple
                  [matRippleCentered]="true" (click)="refactorClick(undefined)">
                  <img [src]="inputImageUrl" [style.aspect-ratio]="imageAspect" *ngIf="!!inputImageUrl" />
                  <span class="title">Original image</span>
                </div>
              </div>
              <div class="d-flex align-items-center flex-column flex-lg-row"
                *ngFor="let item of paintedImages; let i = index;">
                <div class="arrow-container">
                  <div class="arrow-icon" [class.d-none]="selectedImageIndex != i"></div>
                </div>
                <div class="painted-image-item" [class.active]="selectedImageIndex == i" matRipple
                  [matRippleCentered]="true" (click)="refactorClick(i)">
                  <painted-image-item [style.aspect-ratio]="imageAspect" [actionBarVisible]="false"
                    [(value)]="paintedImages[i]" (completed)="onPaintCompleted()"></painted-image-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <mat-card class="input-zone d-flex flex-wrap">
      <div class="d-lg-flex h-lg-100">
        <div class="flex-grow-1">
          <image-prompt-input [(value)]="prompts" [(negative)]="negativePrompts"
            *ngIf="paintMode == PaintModeEnum.Replace || paintMode == PaintModeEnum.Add" />
        </div>
        <div class=" d-flex align-items-center">
          <div class="flex-grow-1 d-flex flex-column">
            <!-- <div class="ms-2 mb-3">
              <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
            </div> -->
            <div class="d-flex flex-wrap align-items-baseline">
              <button mat-flat-button class="mx-2" color="primary" (click)="generateClick($event)">{{paintMode ==
                PaintModeEnum.Remove?'Smart Clear':'AI Generate'}}</button>
              <div class="flex-grow-1"></div>
              <cost-estimator class="m-2" category="image" item="paint" [quantity]="quantity" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="paintMode==PaintModeEnum.Merge">
        <user-asset-select src="/user/assets/category/1" (selectionChange)="onCloudStickerSelected($event)" />
        <div>
          <span>Or select a texture file with a transparent background from your local computer to add：</span>
          <button mat-stroked-button color="primary" (click)="stickerFilePicker.click()">
            <mat-icon>format_shapes</mat-icon>Browse Stickers
          </button>
        </div>
      </div>
      <div *ngIf="paintMode==PaintModeEnum.Zoom">
        <image-scale-input [(value)]="scale" [min]="minScale" [max]="maxScale" />
      </div>
      <div class="d-lg-flex">
        <div class="flex-grow-1 d-flex flex-row-reverse my-2">
          <cost-estimator category="image" item="paint" [quantity]="quantity" />
        </div>
        <div class="d-flex">
          <button mat-stroked-button class="btn-min-width mx-2" (click)="cancelClick()">Cancel</button>
          <div class="flex-grow-1"></div>
          <button mat-flat-button class="mx-2" color="primary" (click)="generateClick($event)">AI Generate</button>
        </div>
      </div> -->
    </mat-card>

  </div>

</flexible-accordion>

<image-picker #rawImagePicker [hasDropZone]="false" [hasImageZone]="false" (changed)="rawImageChanged($event)" />

<image-picker #stickerFilePicker [acceptTypes]="['png', 'webp']" [hasDropZone]="false" [hasImageZone]="false"
  (changed)="onStickerFileChanged($event)" />
