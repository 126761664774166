import { Component, Injector } from '@angular/core';
import { BaseComponent } from '../../common/base.component';

@Component({
  selector: 'account-layout',
  templateUrl: './account-layout.component.html',
  styleUrl: './account-layout.component.scss',
})
export class AccountLayoutComponent extends BaseComponent {

  constructor(injector: Injector) {
    super(injector);
  }
}
