<div class="m-3" *ngFor="let category of categories">
  <div class="fs-larger"><span>{{category.name}}</span></div>
  <div class="manual-container">
    <div class="manual-item" *ngFor="let manual of category.manuals">
      <video height="180">
        <source [src]="manual.url" type="video/mp4">
      </video>
      <div class="title">
        <div>{{manual.title}}</div>
      </div>
      <div class="play-btn" matRipple [matRippleCentered]="true" (click)="playClick(manual)">
        <div class="play-btn-background"></div>
        <div class="play-btn-title"><span>Play</span></div>
      </div>
    </div>
  </div>
</div>
