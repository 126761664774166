import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule, AppRouteReuseStrategy } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { ProgressDialogComponent } from './progress-dialog/progress-dialog.component';
import { ProgressSpinnerComponent } from './progress-dialog/progress-spinner.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppHttpInterceptor } from './common/app.http';
import { MessageSnackbar } from './components/message-snackbar';
import { ImageGeneratorComponent } from './image-generator/image-generator.component';
import { SimilarImageDesignComponent } from './similar-image-design/similar-image-design';
import { ImageRefactorComponent } from './image-refactor/image-refactor.component';
import { AccountLoginComponent } from './account/account-login.component';
import { ImageDialog } from './image-dialog/image-dialog';
import { AccountLayoutComponent } from './layouts/account-layout/account-layout.component';
import { OneLayoutComponent } from './layouts/one-layout/one-layout.component';
import { MasterLayoutComponent } from './layouts/master-layout/master-layout.component';
import { AppHeaderComponent } from './layouts/app-header/app-header.component';
import { RxStompService } from './stomp/rx-stomp.service';
import { rxStompServiceFactory } from './stomp/rx-stomp-service-factory';
import { TestComponent } from './test/test.component';
import { ImagePicker } from './image-picker/image-picker';
import { TextToImageComponent } from './text-to-image/text-to-image.component';
import { ImageQuantityInput } from './image-generate-input/image-quantity-input';
import { ImageScaleInput } from './image-generate-input/image-scale-input';
import { ImagePromptInput } from './image-generate-input/image-prompt-input';
import { ImageSizeInput } from './image-generate-input/image-size-input';
import { PictureAuditComponent } from './picture-audit/picture-audit.component';
import { PaintedImageItem } from './components/painted-image-item';
import { PaintedImageList } from './components/painted-image-list';
import { ElementResizable } from './components/element-resizable';
import { MaterialListComponent } from './material/material-list.component';
import { UserAssetListComponent } from './user-asset/user-asset-list.component';
import { ElementFloatable } from './components/element-floatable';
import { MaterialDetailsDialog } from './material/material-details.dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserAssetSelect } from './user-asset/user-asset-select';
import { MaterialSelect } from './material/material-select';
import { ImageResolutionInput } from './image-generate-input/image-resolution-input';
import { ToolListComponent } from './toolkit/tool-list.component';
import { PageHeader } from './layouts/master-layout/page-header';
import { FlexibleAccordion } from './components/flexible-accordion';
import { ImageStickerInput } from './image-generate-input/image-sticker-input';
import { ImageMaskInput } from './image-generate-input/image-mask-input';
import { UserAssetGroupSelect } from './user-asset-group/user-asset-group-select';
import { UserAssetGroupPicker } from './user-asset-group/user-asset-group-picker';
import { SessionService } from './common/session.service';
import { ImageSegmentPicker } from './image-segment-picker/image-segment-picker';
import { UserAssetGroupEditDialog } from './user-asset-group/user-asset-group-edit.dialog';
import { UserTaskList } from './user-task/user-task-list';
import { CostEstimator } from './components/cost-estimator';
import { PasswordResetComponent } from './account/password-reset.component';
import { UserRegisterComponent } from './account/user-register.component';
import { RegisterSuccessComponent } from './account/register-success.component';
import { DesignToClothingComponent } from './design-to-clothing/design-to-clothing.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ImageDesignComponent } from './image-design/image-design.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { ImageStyleInput } from './image-generate-input/image-style-input';
import { MaterialEditComponent } from './material-admin/material-edit.component';
import { MaterialSearchComponent } from './material-admin/material-search.component';
import { OrderEditComponent } from './order-admin/order-edit.component';
import { OrderSearchComponent } from './order-admin/order-search.component';
import { OrderWithdrawDialog } from './order-admin/order-withdraw-dialog';
import { UserEditComponent } from './user-admin/user-edit.component';
import { UserSearchComponent } from './user-admin/user-search.component';
import { CompanyEditComponent } from './user-admin/company-edit.component';
import { CompanySearchComponent } from './user-admin/company-search.component';
import { PointsTransferDialog } from './points-transfer-dialog/points-transfer-dialog';
import { MyMembershipComponent } from './membership/my-membership.component';
import { MembershipPurchaseDialog } from './membership/membership-purchase-dialog';
import { PointsPurchaseDialog } from './membership/points-purchase-dialog';
import { MannequinToClothingComponent } from './mannequin-to-clothing/mannequin-to-clothing.component';
import { FaceliftToClothingComponent } from './facelift-to-clothing/facelift-to-clothing.component';
import { ImageComplexionInput } from './image-generate-input/image-complexion-input';
import { ImageGenderInput } from './image-generate-input/image-gender-input';
import { ImageSimilarityinput } from './image-generate-input/image-similarity-input';
import { ImageAgeInput } from './image-generate-input/image-age-input';
import { ImageHairColorInput } from './image-generate-input/image-hair-color-input';
import { ImageTextualInput } from './image-generate-input/image-textual-input';
import { UserTaskEditDialog } from './user-task/user-task-edit.dialog';
import { FittingRoomComponent } from './fittingg-room/fittingg-room.component';
import { ImageClothingStyleInput } from './image-generate-input/image-clothing-style-input';
import { ImageFormatInput } from './image-generate-input/image-format-input';
import { ImageDimensionInput } from './image-generate-input/image-dimension-input';
import { ImageDpiInput } from './image-generate-input/image-dpi-input';
import { ImageContentInput } from './image-generate-input/image-content-input';
import { ImageClothingGenderInput } from './image-generate-input/image-clothing-gender-input';
import { UserCenterComponent } from './user-center/user-center.component';
import {MyTradeComponent} from './user-center/my-trade.component';
import { MyOrderList } from './user-center/my-order-list';
import { MyTransactionList } from './user-center/my-transaction-list';
import { ImageSegment } from './image-segment/image-segment';
import { UserArtifactHistory } from './user-artifact/user-artifact-history';
import { SegmentAlertBox } from './image-segment/segment-alert-box';
import { UserOperationList } from './user-operation/user-operation-list';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';
import { UserJobItem } from './user-job/user-job-item';
import { IconService } from './services/icon.service';
import { MatIconModule } from '@angular/material/icon';
import { ModelToClothingComponent } from './mannequin-to-clothing/model-to-clothing.component';
import { ClothingFreeTryonComponent } from './mannequin-to-clothing/clothing-free-tryon.component';
import { UserFavoriteList } from './user-favorite/user-favorite-list';
import { ImagePreviewDialog } from './image-preview-dialog/image-preview-dialog';
import { UserPickerDialog } from './user-picker-dialog/user-picker-dialog';
import { ErrorHandlerService } from './services/error-handler.service';
import { UserAssetInline } from './user-asset/user-asset-inline';
import { ImageContainer } from './components/image-container';
import { MaterialSelectInline } from './material/material-select-inline';
import { ClothChangeColorComponent } from './mannequin-to-clothing/cloth-change-color.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PictureAuditParamCompareComponent } from './picture-audit/picture-audit-param-compare.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FileInput } from './components/file-input';
import { UserInput } from './components/user-input';
import { ImageUpscaleDialog } from './image-upscale-dialog/image-upscale-dialog';
import { WechatContactDialog } from './contact-us/wechat-contact-dialog'
import { WechatBarcode } from './contact-us/wechat-barcode'
import { UserManualComponent } from './user-manual/user-manual.component';
import { VideoPlayerDialog } from './video-player-dialog/video-player-dialog';

export const BASE_URL = new InjectionToken<string>('BASE_URL');

function getBaseUrl() {
  return (
    document.getElementsByTagName('base')[0].attributes.getNamedItem('href')
      ?.value ?? '/'
  );
}

@NgModule({
  declarations: [
    AppComponent,
    ProgressDialogComponent,
    ProgressSpinnerComponent,
    MessageSnackbar,
    AlertDialogComponent,
    ConfirmDialog,
    ErrorComponent,
    HomeComponent,
    ImageSelectorComponent,
    ImageGeneratorComponent,
    SimilarImageDesignComponent,
    ImageRefactorComponent,
    ImageDesignComponent,
    AccountLoginComponent,
    PasswordResetComponent,
    UserRegisterComponent,
    RegisterSuccessComponent,
    ImageDialog,
    AppHeaderComponent,
    AccountLayoutComponent,
    OneLayoutComponent,
    MasterLayoutComponent,
    PageHeader,
    ImageSegmentPicker,
    ImagePicker,
    TextToImageComponent,
    ImageSimilarityinput,
    ImageFormatInput,
    ImageDimensionInput,
    ImageDpiInput,
    ImageQuantityInput,
    ImageScaleInput,
    ImagePromptInput,
    ImageSizeInput,
    ImageResolutionInput,
    ImageStickerInput,
    ImageMaskInput,
    ImageStyleInput,
    ImageComplexionInput,
    ImageGenderInput,
    ImageAgeInput,
    ImageHairColorInput,
    ImageTextualInput,
    ImageClothingStyleInput,
    ImageUpscaleDialog,
    ImageContentInput,
    ImageClothingGenderInput,
    PictureAuditComponent,
    PaintedImageItem,
    PaintedImageList,
    ElementResizable,
    FileInput,
    UserInput,
    ElementFloatable,
    CostEstimator,
    MaterialListComponent,
    MaterialDetailsDialog,
    MaterialSelect,
    UserAssetListComponent,
    UserAssetSelect,
    UserAssetGroupSelect,
    UserAssetGroupPicker,
    UserAssetGroupEditDialog,
    ToolListComponent,
    FlexibleAccordion,
    UserTaskList,
    UserTaskEditDialog,
    TestComponent,
    DesignToClothingComponent,
    MaterialEditComponent,
    MaterialSearchComponent,
    UserEditComponent,
    UserSearchComponent,
    CompanyEditComponent,
    CompanySearchComponent,
    PointsTransferDialog,
    OrderEditComponent,
    OrderSearchComponent,
    OrderWithdrawDialog,
    MannequinToClothingComponent,
    MyMembershipComponent,
    MembershipPurchaseDialog,
    PointsPurchaseDialog,
    FaceliftToClothingComponent,
    FittingRoomComponent,
    UserCenterComponent,
    MyTradeComponent,
    MyOrderList,
    MyTransactionList,
    ImageSegment,
    UserArtifactHistory,
    SegmentAlertBox,
    UserOperationList,
    UserJobItem,
    UserFavoriteList,
    CommingSoonComponent,
    ModelToClothingComponent,
    ClothingFreeTryonComponent,
    ImagePreviewDialog,
    UserPickerDialog,
    UserAssetInline,
    ImageContainer,
    MaterialSelectInline,
    ClothChangeColorComponent,
    PictureAuditParamCompareComponent,
    WechatContactDialog,
    WechatBarcode,
    UserManualComponent,
    VideoPlayerDialog,
  ],
  imports: [
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatIconModule,
    ColorPickerModule,
    NgxChartsModule,
  ],
  providers: [
    { provide: BASE_URL, useFactory: getBaseUrl, deps: [] },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [SessionService],
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: 'min(90vw, 1400px)', maxHeight: '90vh' } },
    { provide: ErrorHandler, useClass: ErrorHandlerService},
    IconService,
    //{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'legacy'} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
