import { ActionResult, ActionStatus, AppHttpClient } from './common/app.http';
import { UserFunction } from './interfaces';
import { BlobHelper } from './helpers/app.helpers';
import { Injector } from '@angular/core';

export enum InputStatus {
  None = 'None',
  AddNew = 'AddNew',
  Exist = 'Exist',
  Replaced = 'Replaced',
  Removed = 'Removed',
}

export class FileInputInfo {
  id?: string;
  name?: string;
  status?: InputStatus;
  file?: File;
}

export class UserInputInfo {
  id?: string;
  name?: string;
  status?: InputStatus;
}

export class Sticker {
  // points: Point[];
  // origElement: HTMLCanvasElement;
  origLeft: number = 0; // unit: percentage
  origTop: number = 0; // unit: percentage
  origWidth: number; // unit: percentage
  origHeight: number; // unit: percentage
  element: HTMLCanvasElement;
  left: number = 0; // unit: percentage
  top: number = 0; // unit: percentage
  width: number; // unit: percentage
  height: number; // unit: percentage
  angle: number; // 0 ~ 360
  src: string;

  constructor(
    // points: Point[],
    element: HTMLCanvasElement,
    left: number,
    top: number,
    width: number,
    height: number,
    angle?: number,
    origLeft?: number,
    origTop?: number,
    origWidth?: number,
    origHeight?: number,
    src?: string
  ) {
    // this.points = points;
    // this.element = this.origElement = element;
    this.element = element;
    this.left = left;
    this.top = this.origTop = top;
    this.width = width;
    this.height = height;
    this.angle = angle ?? 0;
    this.origLeft = origLeft ?? left;
    this.origTop = origTop ?? top;
    this.origWidth = origWidth ?? width;
    this.origHeight = origHeight ?? height;
    this.src = src ?? element.toDataURL();
  }

  get imageRect(): {
    left: number;
    top: number;
    width: number;
    height: number;
  } {
    let radio = Math.min(
      this.width / this.origWidth,
      this.height / this.origHeight
    );
    let left = this.left + this.width / 2 - (this.origWidth * radio) / 2;
    let top = this.top + this.height / 2 - (this.origHeight * radio) / 2;
    let width = this.origWidth * radio;
    let height = this.origHeight * radio;
    return {
      left: left,
      top: top,
      width: width,
      height: height,
    };
  }

  public clone() {
    return new Sticker(
      // this.points,
      this.element,
      this.left,
      this.top,
      this.width,
      this.height,
      this.angle,
      this.origLeft,
      this.origTop,
      this.origWidth,
      this.origHeight,
      this.src,
    );
  }

  public static fromObject(value: any) {
    return new Sticker(
      // value.points,
      value.element,
      value.left,
      value.top,
      value.width,
      value.height,
      value.angle,
      value.origLeft,
      value.origTop,
      value.origWidth,
      value.origHeight,
      value.src,
    );
  }

  toJSON() {
    return {
      // points: this.points,
      element: this.element,
      left: this.left,
      top: this.top,
      width: this.width,
      height: this.height,
      angle: this.angle,
      origLeft: this.origLeft,
      origTop: this.origTop,
      origWidth: this.origWidth,
      origHeight: this.origHeight,
      src: this.src,
    };
  }

  // public redraw() {
  //   let { left, top, width, height } = this.imageRect;
  //   let dx = Math.max(left, 0);
  //   let dy = Math.max(top, 0);
  //   let dx2 = Math.min(left + width, 1);
  //   let dy2 = Math.min(top + height, 1);
  //   let dw = dx2 - dx;
  //   let dh = dy2 - dy;

  //   dx = dx - left;
  //   dy = dy - top;

  //   let wRadio = this.origElement.width / width;
  //   let hRadio = this.origElement.height / height;
  //   dx = dx * wRadio;
  //   dw = dw * wRadio;
  //   dy = dy * hRadio;
  //   dh = dh * hRadio;

  //   const element = document.createElement('canvas');
  //   element.width = this.origElement.width;
  //   element.height = this.origElement.height;
  //   const ctx = element.getContext('2d');
  //   if (ctx != null) {
  //     ctx.rect(dx, dy, dw, dh);
  //     ctx.clip();
  //     ctx.drawImage(
  //       this.origElement,
  //       0, 0, element.width, element.height,
  //       0, 0, element.width, element.height
  //     );
  //   }

  //   this.element = element;
  //   this.src = element.toDataURL();
  // }
}

export class SelectListItem {
  key?: string;
  value?: string;
  selected?: boolean;
}

export enum UserNotificationCategory {
  Paint = '/user/queue/notification/paint',
  Segment = '/user/queue/notification/segment',
  RemoveBackground = '/user/queue/notification/rembg',
  Points = '/user/queue/notification/points',
  FittingRoom = '/user/queue/notification/fitting-room',
  SimiGenerate = '/user/queue/notification/simi-generate',
  ClothDesign = '/user/queue/notification/cloth-design',
  ClothSwapModel = '/user/queue/notification/swap-model',
  ClothSwapMannequin = '/user/queue/notification/swap-mannequin',
  ClothChangeColor = '/user/queue/notification/change-color',
  ImageUpscale = '/user/queue/notification/upscale-image',
  Membership = '/user/queue/notification/membership',
  FreeTryon = '/user/queue/notification/free-tryon',
}

export class UserNotification {
  category?: string;
  id?: string;
  data?: any;
}

export class PaintedImage {
  id?: string;
  url?: string; // local url
  _data?: Blob;
  name?: string;
  thumbUp: boolean = false;
  thumbDown?: boolean = false;
  result?: ActionResult;
  private timer?: NodeJS.Timeout;
  saved: boolean = false;
  src?: string; //server url

  constructor(
    private injector: Injector,
  ) {

  }

  get data() {
    return this._data;
  }

  set data(value: Blob | undefined) {
    this._data = value;
    if (!value) {
      this.url = undefined;
    } else {
      this.url = URL.createObjectURL(value);
    }
  }

  start() {
    this.result = {
      status: ActionStatus.Running,
    };
    this.timer = setTimeout(() => {
      this.timer = undefined;
      this.stop({
        status: ActionStatus.Failed,
        message: 'Server Busy，Handling timeouts。',
      });
    }, 180 * 1000); // unit：Second
  }

  stop(result: ActionResult) {
    if (!!this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    //this.id = undefined;
    this.result = result;
  }

  public static async blobToDataUrlAsync(paintedImages: Array<PaintedImage>) {
    for (let i = 0; i < paintedImages.length; i++) {
      let item = paintedImages[i];
      if (!item.data) {
        return;
      }
      item.url = await BlobHelper.toDataUrlAsync(item.data) as string;
    }
  }

  public download(downloadCallback: (result: boolean) => void) {
    let http = this.injector.get(AppHttpClient);
    if (!this.src) {
      return downloadCallback(false);
    }
    http.get(this.src, { responseType: 'blob' }).subscribe({
      next: async (result: Blob) => {
        this.data = result;
        return downloadCallback(true);
      },
    });
  }

  public static firstImage(paintedImages: Array<PaintedImage>): PaintedImage | undefined {
    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status != ActionStatus.Success) {
        continue;
      }
      return paintedImages[i]
    }
    return undefined;
  }

  public static fromObject(value: any, injector: Injector, downloadCallback: (result: boolean) => void) {
    var obj = new PaintedImage(injector);
    obj.id = value.id;
    // obj.url = value.url;
    // obj.data = value.data;
    obj.name = value.name;
    obj.thumbUp = value.thumbUp;
    obj.thumbDown = value.thumbDown;
    obj.result = value.result;
    obj.saved = value.saved;
    obj.src = value.src;
    obj.download(downloadCallback);
    return obj;
  }

  public static fromArray(values: any[], injector: Injector, downloadCallback?: (result: boolean, index: number) => void) {
    let result: Array<PaintedImage> = [];
    for (let i = 0; i < values.length; i++) {
      result.push(PaintedImage.fromObject(values[i], injector, (result) => {
        if(!downloadCallback){
          return;
        }
        downloadCallback(result, i);
      }));
    }
    return result;
  }

  toJSON() {
    return {
      id: this.id,
      // obj.url = value.url;
      // obj.data = value.data;
      name: this.name,
      thumbUp: this.thumbUp,
      thumbDown: this.thumbDown,
      result: this.result,
      saved: this.saved,
      src: this.src,
    };
  }
}

export class clickItem {
  url: string | null = null;
  title: string | null = null;
}

export enum UserAssetCategory {
  Material = '1', // Personal material
  SemiFinishedProduct = '2', // Semi-finished products
  Product = '3', // Finished Product
}

export enum AssetType {
  Image = 'image',
}

export enum AssetGroupTypes {
  MyBackground = '1', // My Background
  MyModel = '2', // My Model
  MyPose = '3', // My Posture
}

export var UserAssetCategoryName = new Map([
  ['1', 'Personal material'],
  ['2', 'Semi-finished products'],
  ['3', 'Finished Product']
])

export var UserFunctionSiteMap = new Map([
  [UserFunction.ImageSimulator, '/image/simidesign'],
])

export class JobTypes {
  static segment = "segment";
  static paint = "paint";
  static simiGenerate = "simi-generate";
  static rembg = "rembg";
  static fittingRoom = "fitting-room";
  static clothDesign = "cloth-design";
  static swapModel = "swap-model";
  static swapMannequin = "swap-mannequin";
  static changeColor = "change-color";
  static freeTryon = "free-tryon";
}

export class JobSubTypes {
  static txt2img = "txt2img";
  static both = "both";
}

export class JobStatus {
  static waiting = 'waiting';
  static running = 'running';
  static failed = 'failed';
  static success = 'success';
}

export class UserTypes {
  static individual = 'I';
  static company = 'C';
}

export class UserStatus {
  static new = 'new';
  static validated = 'validated';
  static locked = 'locked';
  static disabled = 'disabled';
  static mfa = 'mfa';
  static reset = 'reset';
}

export class TaskCategory {
  static txt2Image = '1'; // Vincent Figure;
  static designImage = '2'; // Product image;
  static refactImage = '3'; // Retouched picture;
  static clothDesign = '4'; // AICostume Design;
  static swapMannequin = '5'; // AIChange Model-Figure;
  static faceLift = '6'; // AIChange clothes;
  static simiGenerate = '7'; // Micro-innovation engine;
  static swapModel = '8'; // AIChange Model-Real person picture;
  static changeClothColor = '9'; // Clothing color change
  static freeTryon = '10'; // AIChange Model-Free pose;
}

export enum OrderItemTypes {
  Free = 'free', // Trial Version
  PersonMonth = 'person-month', // Professional Edition Monthly Subscription
  PersonYear = 'person-year', // Professional Edition Yearly Package
  TeamMonth = 'team-month', // Team Edition Monthly Subscription
  TeamYear = 'team-year', // Team Edition Annual Package
  Points1000 = 'points-1000', // top up1000Smart Beans
  Points6000 = 'points-6000', // top up6000Smart Beans
  Points10000 = 'points-10000', // top up10000Smart Beans
  Delivery = 'delivery', // Fixed Open
  Others = 'others', // other
  Customize = 'customize', // Customization
}

export enum MaterialCategory {
  ClothingModel = 4, // Clothing Model Library
  ClothingBackground = 5, // Clothing Background Library
  ClothingReference = 6, // Clothing Reference Library
  ClothingTops = 7, // Tops Sample Library
  ClothingBottoms = 8, // Clothing Sample Library
  ClothingJumpsuit = 9, // Bodysuit Sample Library
}
