<page-header title="Mobile phone case creation">
  <!--<div class="flex-grow-1 d-flex">
    <div class="flex-grow-1"></div>
    <div class="step-container d-flex align-items-center m-auto mb-1" [class.d-none]="!taskId">
      <div *ngFor="let item of steps; index as i;">
        <img class="icon-sm mx-3" src="/assets/images/arrow_right.png" *ngIf="i!=0" />
        <button mat-button class="step-item btn-md" [class.active]="selectedStepperIndex==item.value" color="primary"
          (click)="stepperClick(item.value)">
          <img [src]="'/assets/images/'+item.icon" />
          <span>{{item.name}}</span>
        </button>
      </div>
    </div>
    <div class="flex-grow-1"></div>
    <div class="flex-grow-1"></div>
    <div class="flex-grow-1"></div>
  </div>-->
</page-header>

<flexible-accordion class="flex-grow-1 h-0 d-flex flex-column flex-lg-row" [expanded]="taskListExpanded">

  <div class="flexible-accordion-header">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.simiGenerate"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <div class="m-1"></div>

  <flexible-accordion [expanded]="inputExpanded" [class.d-none]="!taskId">

    <div class="flex-grow-1  flexible-accordion-header h-lg-100 d-flex flex-column flex-lg-row">

      <mat-card class="input-container min-h-100">

        <div class="d-flex flex-row help-info" (click)="openHelpInfoDialog()" [class.d-none]="!imagePickerCompleted">

          <svg class="align-self-center" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1558_553)">
              <path
                d="M1.66406 2.33301C1.66406 1.78072 2.11178 1.33301 2.66406 1.33301H10.6641C11.2164 1.33301 11.6641 1.78072 11.6641 2.33301V14.6663H2.66406C2.11178 14.6663 1.66406 14.2186 1.66406 13.6663V2.33301Z"
                stroke="#5F4DF3" stroke-width="1.5" stroke-linejoin="round" />
              <path
                d="M11.6641 7.99967C11.6641 7.63147 11.9625 7.33301 12.3307 7.33301H13.6641C14.0323 7.33301 14.3307 7.63147 14.3307 7.99967V13.6663C14.3307 14.2186 13.883 14.6663 13.3307 14.6663H11.6641V7.99967Z"
                stroke="#5F4DF3" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M3.66406 4H6.33073" stroke="#5F4DF3" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M3.66406 6.33301H7.66406" stroke="#5F4DF3" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_1558_553">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <span class="align-self-center help-info-text">Instructions</span>
        </div>

        <image-picker class="ratio ratio-16x9 mb-3" #rawImagePicker (changed)="rawImageChanged($event)"
          [class.d-none]="imagePickerCompleted" />

        <image-segment #imageSegment [rawImageUrl]="rawImageUrl"
          [segmentClickType]="SegmentClickTypesEnum.MultipleClick" (modelScaleChange)="modelScaleChanged($event)"
          (predictCompleted)="predictCompleted($event)" [class.d-none]="!imagePickerCompleted"
          [processDisplayMode]="processDisplayMode" />

        <div class="d-flex flex-column" [class.d-none]="!imagePickerCompleted">
          <div class="row">
            <div class="col">
              <button class="btn-md w-100" mat-stroked-button (click)="rawImagePicker.click();">
                <img src="{{ baseUrl }}assets/images/redo.png" />
                <span>Re-upload</span>
              </button>
            </div>

            <div class="col">
              <button class="btn-md w-100" mat-stroked-button color="primary" (click)="pickupClick();">
                <img src="{{ baseUrl }}assets/images/image-generate0020.png" />
                <span>Region Extraction</span>
              </button>
            </div>
          </div>

          <div class="mb-2 mt-3">
            <image-prompt-input [(value)]="prompts" [(negative)]="negativePrompts" [placeHolder]="'Please describe the painting you wish to generate'" />
          </div>

          <div clas="mb-2">
            <image-similarity-input [initsimi]="similarity" (simiChange)="imageSimiChanged($event)" />
          </div>

          <!--<div class="mb-2">
              <image-format-input [imageFormat]="imageFormat" (imageFormatChange)="imageFormatChanged($event)" />
            </div>-->

          <div class="mb-2">
            <image-dimension-input [width]="expectedWidth" [height]="expectedHeight"
              (widthChange)="widthChanged($event)" (heightChange)="heightChanged($event)" />
          </div>

          <!--<div class="mb-2">
              <image-dpi-input [dpi]="dpi" (dpiChange)="dpiChanged($event)" />
            </div>-->

          <div class="mb-2">
            <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" />
          </div>

          <div class="d-flex py-2">
            <cost-estimator category="image" item="paint" [quantity]="quantity" />
            <div class="flex-grow-1"></div>
            <button mat-flat-button color="primary" (click)="generateClick()">AI Generate</button>
          </div>
        </div>
      </mat-card>

    </div>

  </flexible-accordion>

  <div class="flex-grow-1 h-lg-100">

    <div class="d-flex flex-column w-100 h-100 p-lg-2" [class.d-none]="paintedImages.length==0">
      <painted-image-list #paintedImageList [(value)]="paintedImages" [notificationCategory]="userNotificationCatecory"
        [gridCalculationMode]="'fixed'" [useBackgroundAlpha]="true" [enableSave]="false" [showTiffMenu]="true"
        (completed)="onPaintCompleted()" />
    </div>

  </div>

</flexible-accordion>
