import { Component, Injector, OnInit, ViewChild, } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { ParamMap } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ImageSelectorComponent } from '../image-selector/image-selector.component';
import { ImageGeneratorComponent } from '../image-generator/image-generator.component';
import { ImageRefactorComponent } from '../image-refactor/image-refactor.component';
import { ImageGenerateSteps } from '../interfaces';
import { MessageResource } from '../common/message.service';
import { UserTaskList } from '../user-task/user-task-list';
import { TaskCategory } from '../models';

@Component({
  selector: 'image-design',
  templateUrl: './image-design.component.html',
  styleUrl: './image-design.component.scss',
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ImageDesignComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;
  expanded: boolean = true;
  @ViewChild('userTaskList') userTaskList!: UserTaskList;
  @ViewChild('imageSelector') imageSelector!: ImageSelectorComponent;
  @ViewChild('imageGenerator') imageGenerator!: ImageGeneratorComponent;
  @ViewChild('imageRefactor') imageRefactor!: ImageRefactorComponent;

  steps = [
    { value: ImageGenerateSteps.Step0010, name: 'Importing pictures', icon: 'image-generate0010.png' },
    { value: ImageGenerateSteps.Step0020, name: 'Smart Cutout', icon: 'image-generate0020.png' },
    { value: ImageGenerateSteps.Step0030, name: 'Generate creative main image', icon: 'image-generate0030.png' },
    { value: ImageGenerateSteps.Step0040, name: 'Retouched pictures', icon: 'image-generate0040.png' },
    { value: ImageGenerateSteps.Step0050, name: 'share/Download Creative', icon: 'image-generate0050.png' },
  ];

  selectedTemplateId?: number;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.selectedTemplateId = Number(params.get('templateId'));
    });
  }

  ImageGenerateStepsEnum = ImageGenerateSteps;

  _selectedStepperIndex = ImageGenerateSteps.Step0010;
  set selectedStepperIndex(value: ImageGenerateSteps) {
    this._selectedStepperIndex = value;
    this.saveTaskState();
  }
  get selectedStepperIndex() {
    return this._selectedStepperIndex;
  }

  stepperClick(stepperIndex: ImageGenerateSteps) {
    if (stepperIndex == ImageGenerateSteps.Step0020 || stepperIndex == ImageGenerateSteps.Step0030 || stepperIndex == ImageGenerateSteps.Step0040) {
      if (!this.imageSelector.rawImageBlob) {
        this.messageService.showError(MessageResource.NO_IMAGE_SELECTED);
        return;
      }
    } else if (stepperIndex == ImageGenerateSteps.Step0050) {
      this.messageService.showInformation(MessageResource.COMMING_SOON);
      return;
    }
    this.stepChanged({
      next: stepperIndex,
      data: this.imageSelector.rawImageBlob,
    }, false);
  }

  stepChanged(event: any, force: boolean) {
    let next = event.next;
    this.selectedStepperIndex = next;
    switch (next) {
      case ImageGenerateSteps.Step0010:
        break;
      case ImageGenerateSteps.Step0020:
        this.imageGenerator.init(event.data);
        break;
      case ImageGenerateSteps.Step0030:
        this.imageGenerator.init(event.data);
        break;
      case ImageGenerateSteps.Step0040:
        this.imageRefactor.init(event.data, force, event.from);
        break;
    }
  }

  imageSelectCompleted(event: any, force: boolean) {
    event.data = this.imageSelector.rawImageBlob;
    this.stepChanged(event, force);

  }

  imageGeneratorCompleted(event: any) {
    event.data = event.data ?? this.imageSelector.rawImageBlob;
    this.stepChanged(event, true);
  }

  imageRefactorCompleted(event: any) {
    let next = event.next;
    this.selectedStepperIndex = next;
  }

  override reset() {
    this.selectedTemplateId = undefined;
    this.taskId = undefined;
    this._selectedStepperIndex = ImageGenerateSteps.Step0010;
    this.imageSelector.reset();
    this.imageGenerator.reset();
    this.imageRefactor.reset();
  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  override restoreTaskState() {
    let taskStateUrl = this.taskStateUrl;
    this.fetchTaskState().subscribe((result: any) => {
      if (!result) {
         // Create a new task
        let taskId = this.taskId;
        this.reset();
        this.taskId = taskId;
        this.stepChanged({ next: ImageGenerateSteps.Step0010 }, false)
        return;
      }
      this._selectedStepperIndex = result.selectedStepperIndex;
    });
    this.fetchTaskState('/image_select')?.subscribe((result: any) => {
      this.imageSelector.restoreState(result, `${taskStateUrl}/image_select`);
    });
    this.fetchTaskState('/image_generator')?.subscribe((result: any) => {
      this.imageGenerator.restoreState(this, result, `${taskStateUrl}/image_generator`);
    });
    this.fetchTaskState('/image_refactor')?.subscribe((result: any) => {
      this.imageRefactor.restoreState(result, `${taskStateUrl}/image_refactor`);
    });
  }

  override saveTaskState() {
    this.patchTaskState({
      selectedStepperIndex: this.selectedStepperIndex,
    });
  }

  async imageSelectStateChanged(event: any[]) {
    this.patchTaskState(event, '/image_select');
    this.saveTaskThumbnail(event.find(o => o instanceof File && o.name.startsWith('raw_image')));
  }

  async imageGeneratorStateChanged(event: any[]) {
    this.patchTaskState(event, '/image_generator');
  }

  async imageRefactorStateChanged(event: any[]) {
    this.patchTaskState(event, '/image_refactor');
  }

  imageGeneratorExpandedChanged(expanded: boolean) {
    this.expanded = expanded;
  }

  imageRefactorExpandedChanged(expanded: boolean) {
    this.expanded = expanded;
  }

}

