import { Injectable } from "@angular/core";


export class AppSettings {
  attachmentMaxSize: number = 20971520;
  attachmentTypes: string[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _settings: AppSettings = new AppSettings();
  get appSettings(): AppSettings {
    return this._settings;
  }

  set appSettings(value: AppSettings) {
    this._settings = value;
  }
}
