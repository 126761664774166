import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Sticker } from '../models';
import { MatMenuTrigger } from '@angular/material/menu';
import { ModelScaleProps } from '../interfaces';
import { CanvasHelper, FileHelper } from '../helpers/app.helpers';
import { format } from 'date-fns';
import { AUTO_STYLE } from '@angular/animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'image-sticker-input',
  templateUrl: './image-sticker-input.html',
  styleUrl: './image-sticker-input.scss',
})
export class ImageStickerInput {

  private _modelScale?: ModelScaleProps;
  @Input({ required: true }) set modelScale(value: ModelScaleProps | undefined) {
    this.resize(value);
    this._modelScale = value;
  }
  get modelScale() {
    return this._modelScale;
  }
  @Input() value: Array<Sticker> = [];
  @Input() enableEdit: boolean = true;
  @Output() valueChange = new EventEmitter<Array<Sticker>>();

  constructor() {

  }

  ngOnInit() {

  }

  reset() {
    // this._modelScale = undefined;
    this.value = [];
  }


  bringStickerToTopClick(sticker: Sticker) {
    let stickers = this.removeSticker(this.value, sticker);
    this.value = [...stickers, sticker];
  }

  bringStickerToFrontClick(sticker: Sticker) {
    let idx = this.value.indexOf(sticker);
    if (idx < 0) {
      return;
    }
    if (idx >= this.value.length - 1) {
      return;
    }
    let nextSticker = this.value.splice(idx + 1, 1)[0];
    this.value.splice(idx, 0, nextSticker);
  }

  bringStickerToBehindClick(sticker: Sticker) {
    let idx = this.value.indexOf(sticker);
    if (idx < 1) {
      return;
    }
    let previousSticker = this.value.splice(idx - 1, 1)[0];
    this.value.splice(idx, 0, previousSticker);
  }

  bringStickerToBottomClick(sticker: Sticker) {
    let stickers = this.removeSticker(this.value, sticker);
    this.value = [sticker, ...stickers];
  }

  removeStickerClick(sticker: Sticker) {
    this.value = this.removeSticker(this.value, sticker);
  }

  removeSticker(stickers: Array<Sticker>, sticker: Sticker): Array<Sticker> {
    let idx = stickers.indexOf(sticker);
    if (idx < 0) {
      return stickers;
    }
    stickers.splice(idx, 1);
    return stickers;
  }

  @ViewChild(MatMenuTrigger) stickerContextMenu!: MatMenuTrigger;
  stickerContextMenuPosition = { x: '0px', y: '0px' };
  onStickerContextMenu(event: MouseEvent, sticker: Sticker) {
    event.preventDefault();
    this.stickerContextMenuPosition.x = event.clientX + 'px';
    this.stickerContextMenuPosition.y = event.clientY + 'px';
    this.stickerContextMenu.menuData = { sticker: sticker };
    this.stickerContextMenu.openMenu();
  }

  drawOrigToCanvas(): HTMLCanvasElement {
    const { width, height, samScale } = this.modelScale!;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    this.value.forEach((sticker) => {
      ctx?.drawImage(
        sticker.element,
        sticker.origLeft / 100 * width,
        sticker.origTop / 100 * height,
        sticker.origWidth / 100 * width,
        sticker.origHeight / 100 * height
      );
    });
    return canvas;
  }

  drawToCanvas(): HTMLCanvasElement {
    const { width, height, samScale } = this.modelScale!;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return canvas;
    }
    this.value.forEach((sticker) => {
      const rect = sticker.imageRect;
      ctx.translate((rect.left + rect.width / 2) / 100 * width, (rect.top + rect.height / 2) / 100 * height);
      ctx.rotate(sticker.angle * Math.PI / 180);
      ctx.drawImage(
        sticker.element,
        rect.width / 2 / 100 * width * -1,
        rect.height / 2 / 100 * height * -1,
        rect.width / 100 * width,
        rect.height / 100 * height
      );
      ctx.resetTransform();
    });
    return canvas;
  }

  exportStickerImageClick(sticker: Sticker) {
    const { width, height, samScale } = this.modelScale!;
    const canvas = document.createElement('canvas');
    const rect = sticker.imageRect;
    canvas.width = rect.width / 100 * width;
    canvas.height = rect.height / 100 * height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const x = rect.width / 2 / 100 * width;
    const y = rect.height / 100 * height;
    ctx.translate(x, y);
    ctx.rotate(sticker.angle * Math.PI / 180);
    ctx.drawImage(
      sticker.element,
      x * -1,
      y * -1,
      rect.width / 100 * width,
      rect.height / 100 * height
    );
    ctx.resetTransform();
    let imageUrl = canvas.toDataURL('image/png', 1.0);
    FileHelper.download(
      imageUrl,
      `${format(new Date(), 'yyyyMMddHHmmss')}_sticker.png`
    );
  }

  resize(newModelScale: ModelScaleProps | undefined) {
    let oldModelScale = this._modelScale;
    if (!oldModelScale || !newModelScale) {
      return;
    }
    let oldWidth = oldModelScale.width;
    let oldHeight = oldModelScale.height;
    let newWidth = newModelScale.width;
    let newHeight = newModelScale.height;
    if (oldWidth == newWidth && oldHeight == newHeight) {
      return;
    }
    let oldRatio = oldWidth / oldHeight;
    let newRatio = newWidth / newHeight;
    let ratio: number, width: number, height: number;
    if (oldRatio < newRatio) {
      width = oldRatio / newRatio * newWidth
      height = newHeight;
    } else {
      width = newWidth;
      height = newRatio / oldRatio * newHeight;
    }
    ratio = width / oldWidth;
    let wRatio = oldWidth * ratio / newWidth;
    let hRatio = oldHeight * ratio / newHeight;
    let xOffset = (newWidth - width) / 2;
    let yOffset = (newHeight - height) / 2;
    let newStickers: Array<Sticker> = [];
    this.value.forEach((sticker) => {
      sticker = sticker.clone();
      sticker.origLeft = (sticker.origLeft / 100 * oldWidth * ratio + xOffset) / newWidth * 100;
      sticker.origTop = (sticker.origTop / 100 * oldHeight * ratio + yOffset) / newHeight * 100;
      sticker.origWidth = sticker.origWidth * wRatio;
      sticker.origHeight = sticker.origHeight * hRatio;
      sticker.left = (sticker.left / 100 * oldWidth * ratio + xOffset) / newWidth * 100;
      sticker.top = (sticker.top / 100 * oldHeight * ratio + yOffset) / newHeight * 100;
      sticker.width = sticker.width * wRatio;
      sticker.height = sticker.height * hRatio;
      newStickers.push(sticker);
    });
    this.value = newStickers;
  }

  getState() {
    return {
      modelScale: this._modelScale,
      value: this.value,
    }
  }

  isEmpty() {
    return this.value.length === 0;
  }

  async restoreStateAsync(data: any):Promise <any> {
    if (!data) {
      return [];
    }
    this._modelScale = data.modelScale;
    let values:Sticker[] =[];
    for(let i=0;i<data.value.length;i++){
      let value = data.value[i];
      value.element = await CanvasHelper.fromDataUrlAsync(value.src);
      values.push(Sticker.fromObject(value));
    }
    return values;
  }

  restoreState(data: any):Observable <any> {
    return new Observable<string>(observer => {
      this.restoreStateAsync(data).then(
        result => {
          observer.next(result);
          observer.complete(); 
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  setState(values: any) {
    this.value = values;
  }
}

