import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { Subscription } from 'rxjs';
import { RxStompService } from '../stomp/rx-stomp.service';
import { UserNotification, UserNotificationCategory } from '../models';
import { IMessage } from '@stomp/rx-stomp';
import { BlobHelper, MediaTypeHelper } from '../helpers/app.helpers';
import { format } from 'date-fns';

@Component({
  selector: 'image-upscale-dialog',
  templateUrl: './image-upscale-dialog.html',
  styleUrl: './image-upscale-dialog.scss',
})
export class ImageUpscaleDialog implements OnInit, OnDestroy {
  private id?: string;
  private segmentSubscription!: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ImageUpscaleDialog>,
    @Inject(MAT_DIALOG_DATA) private data: {
      jobId?: number,
      upscaleRatio?: number,
    },
    private http: AppHttpClient,
    private rxStompService: RxStompService,
  ) {

  }

  ngOnInit() {
    this.segmentSubscription = this.rxStompService
      .watch(UserNotificationCategory.ImageUpscale)
      .subscribe((message: IMessage) => {
        var notification = JSON.parse(message.body) as UserNotification;
        if (notification.id != this.id) {
          return;
        }
        var result = notification.data as ActionResult;
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.download();
      });

    let formData = new FormData();
    formData.set('jobId', this.data.jobId?.toString() ?? '');
    formData.set('upscaleRatio', this.data.upscaleRatio?.toString() ?? '');
    this.http.post('/image/upscale', formData).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          this.dialogRef.close();
          return;
        }
        this.id = result.data.id;
      },
      error: (err: any) => {
        this.dialogRef.close();
        throw err;
      },
    });
  }

  ngOnDestroy() {
    this.segmentSubscription.unsubscribe();
  }

  private download() {
    let url = `/job/${this.id}/download`;
    this.http.get(url, { responseType: 'blob' }).subscribe({
      next: async (result: Blob) => {
        let name = 'generated_image' + MediaTypeHelper.resolveFileExtension(result.type);
        BlobHelper.download(`${format(new Date(), 'yyyyMMddHHmmss')}_${name}`, result);
        this.dialogRef.close();
      },
      error: (err: any) => {
        this.dialogRef.close();
        throw err;
      },
    });
  }

}
