import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class AppFormGroup extends FormGroup {
  override get valid(): boolean {
    return super.valid;
  }

  override get invalid(): boolean {
    return !this.valid;
  }

  validWithTouched(): boolean {
    Object.keys(this.controls).forEach(field => {
      const control = this.get(field);
      this.validControlWithTouched(control);
    });

    return this.valid;
  }

  private validControlWithTouched(control: AbstractControl | null) {
    if (!control) {
      return;
    }

    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: false });
      control.updateValueAndValidity();
    } else if (control instanceof AppFormGroup) {
      Object.keys(control.controls).forEach(field => {
        const child = control.get(field);
        this.validControlWithTouched(child);
      });
    } else if (control instanceof FormArray) {
      for (let i = 0; i < control.length; i++) {
        this.validControlWithTouched(control.at(i));
      }
    }
  }
}


export class AppValidators {

  static minLength(minLength: number): ValidatorFn {
    return minLengthValidator(minLength);
  }

  static email(control: AbstractControl): ValidationErrors | null {
    return emailValidator(control);
  }

  static multipleEmail(control: AbstractControl): ValidationErrors | null {
    return multipleEmailValidator(control);
  }

  static sameWith(anotherControlName: string): ValidatorFn {
    return sameWithValidator(anotherControlName);
  }

  static password(control: AbstractControl): ValidationErrors | null {
    return passwordValidator(control);
  }

  static fileRequired(control: AbstractControl): ValidationErrors | null {
    return fileRequiredValidator(control);
  }

  static range(min?: number, max?: number): ValidatorFn {
    return rangeValidator(min, max);
  }
}


function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

function hasValidLength(value: any): boolean {
  // non-strict comparison is intentional, to check for both `null` and `undefined` values
  return value != null && typeof value.length === 'number';
}

const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
function isEmailValue(value: any): boolean {
  value = trimValue(value);
  if (isEmptyInputValue(value)) {
    return true;
  }
  return EMAIL_REGEXP.test(value);
}

const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
function isPasswordValue(value: any): boolean {
  value = trimValue(value);
  if (isEmptyInputValue(value)) {
    return true;
  }
  return PASSWORD_REGEXP.test(value);
}

function trimValue(value: any): any {
  if (typeof value === 'string') {
    return value?.trim();
  } else {
    return value;
  }
}

export function minLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = trimValue(control.value);
    if (isEmptyInputValue(value) || !hasValidLength(value)) {
      // don't validate empty values to allow optional controls
      // don't validate values without `length` property
      return null;
    }

    return value.length < minLength ?
      { 'minlength': { 'requiredLength': minLength, 'actualLength': value.length } } :
      null;
  };
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  return isEmailValue(control.value) ? null : { 'email': true };
}

export function multipleEmailValidator(control: AbstractControl): ValidationErrors | null {
  let value = trimValue(control.value);
  if (isEmailValue(value)) {
    return null;
  }
  value = value.replaceAll(';', ',');
  let emails = value.split(',');
  for (let i = 0; i < emails.length; i++) {
    if (!isEmailValue(emails[i])) {
      return { 'email': true };
    }
  }
  return null;
}

export function sameWithValidator(anotherControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let formGroup = control.parent as FormGroup;
    if (!formGroup) {
      return null;
    }
    let anotherControl = formGroup.controls[anotherControlName];
    if (anotherControl.invalid) {
      return null;
    }

    let value1 = control.value;
    let value2 = anotherControl.value;
    if (!value1 || !value2) {
      return null;
    }

    return value1 != value2 ? { 'same': true } : null;
  };
}

export function passwordValidator(control: AbstractControl): ValidationErrors | null {
  return isPasswordValue(control.value) ? null : { 'password': true };
}

export function fileRequiredValidator(control: AbstractControl): ValidationErrors | null {
  let value = control.value;
  let name = value?.name;
  return isEmptyInputValue(name) ? { 'required': true } : null;
}

export function rangeValidator(min?: number, max?: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let value = trimValue(control.value);
    if(!value){
      return null;
    }
    let num = Number(value);
    if (isNaN(num)) {
      return { 'number': true };
    }
    if (!!min && num < min || !!max && num > max) {
      return { 'range': true };
    }
    return null;
  };
}

export function validRange(value?: string, min?: number, max?: number): boolean {
  if(!value){
    return true;
  }
  let num = Number(value);
  if (isNaN(num)) {
    return false;
  }
  if (!!min && num < min || !!max && num > max) {
    return false;
  }
  return true;
}