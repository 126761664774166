import { Component, Inject, Injector, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ImageSegment, SegmentClickTypes, SegmentResults } from '../image-segment/image-segment';
import { SegmentAlertBox } from '../image-segment/segment-alert-box';

@Component({
  selector: 'image-segment-picker',
  templateUrl: './image-segment-picker.html',
  styleUrl: './image-segment-picker.scss',
})
export class ImageSegmentPicker extends BaseComponent implements OnInit, AfterViewInit {
  SegmentClickTypesEnum = SegmentClickTypes;

  @ViewChild('imageSegment') imageSegment!: ImageSegment;
  segmentClickType: SegmentClickTypes = SegmentClickTypes.SignleClick;
  hideClickTypeRadio: boolean = false;
  rawImageUrl: string | null = null;
  segmentTaskId?: string;

  constructor(injector: Injector,
    private dialogRef: MatDialogRef<ImageSegmentPicker>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    this.rawImageUrl = data.imageUrl;
    this.segmentTaskId = data.segmentTaskId;

    if (!!data.clickTypes) {
      if (data.clickTypes === 'multi') {
       // this.segmentClickType = SegmentClickTypes.MultipleClick;
        this.hideClickTypeRadio = true;
      }
    }

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.segmentTaskId) {
        this.imageSegment.predict();
      }
      else {
        this.imageSegment.restoreSegment(this.segmentTaskId);
      }
    }, 0);  
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  cancelClick() {
    this.dialogRef.close({
      taskId: this.segmentTaskId,
    });
  }

  resetClick() {
    this.imageSegment.resetSegment();
  }

  confirmClick() {
    this.dialogRef.close({
      stickers: this.imageSegment.stickers.value,
      taskId: this.segmentTaskId,
    });
  }

  pickupClick() {
    this.imageSegment.pickup();
  }

  segmentClickTypeChange() {
    this.imageSegment.resetMask();
  }


  async backClick() {
    await this.imageSegment.back();
  }

  get canBack() {
    return this.imageSegment.clicks.length > 0;
  }

  get isMultipleClick() {
    return this.segmentClickType == this.SegmentClickTypesEnum.MultipleClick;
  }

  openHelpInfoDialog () {
    this.dialog.open(SegmentAlertBox, {

    })
  }

  previousSegmentClick() {
    this.imageSegment.previous();
  }

  segmentComplete(predictResult: string) {

    if (predictResult === SegmentResults.fail) {
      return;
    }
    else if (predictResult === SegmentResults.cached) {
      return;
    }

    const taskId: number = Number(predictResult);
    if (isNaN(taskId) || !isFinite(taskId)) {
      return;
    }

    this.segmentTaskId = predictResult;

  }

}