import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { UserTaskListItem } from '../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { UserTaskEditDialog } from './user-task-edit.dialog';
import { BaseComponent } from '../common/base.component';
import { ConfirmDialog } from '../confirm-dialog/confirm-dialog';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'user-task-list',
  templateUrl: './user-task-list.html',
  styleUrl: './user-task-list.scss',
})
export class UserTaskList extends BaseComponent implements OnInit {
  tasks: Array<UserTaskListItem> = [];
  curTask?: UserTaskListItem;

  @Input({ required: true }) category!: string;
  @Input() userId?: string;
  @Output() selectionChange = new EventEmitter<UserTaskListItem | undefined>();
  @ViewChild(MatMenuTrigger) taskMenu!: MatMenuTrigger;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.fetchTasks();
  }

  ngOnDestroy() {

  }

  hasMore = false;
  private fetchTasks() {
    var params ={
      categoryId: this.category,
      userId: this.userId,
      startIndex: this.tasks.length,
    }
    this.http.post(`/user/tasks/fetch`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let tasks  = [...this.tasks];
        result.data.tasks.forEach((task: UserTaskListItem) => {
          for(let i=0;i<tasks.length;i++){
            if(task.id == tasks[i].id){
              return;
            }
          }
          this.fetchTaskThumbnail(task);
          tasks.push(task);
        });
        this.tasks = tasks;
        this.hasMore = result.data.hasMore;
      }
    });
  }

  private fetchTaskThumbnail(task: UserTaskListItem) {
    this.http.get(`/user/tasks/${task.id}/thumbnail`, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        task.url = URL.createObjectURL(result);
      },
    });
  }

  selectTask(task: UserTaskListItem) {
    this.tasks.forEach(item => {
      item.selected = item.id == task.id;
    });
    this.selectionChange.emit(task);
    this.curTask = task;
  }

  addUserTaskClick() {
    this.showUserTaskDialog({
      category: this.category,
	  buttonLabel : 'Create',
    }, (result) => {
      let newTask = {
        id: result.data.id,
        name: result.data.name,
		
      };
      this.fetchTaskThumbnail(newTask)
      this.tasks = [newTask, ...this.tasks];
      this.selectTask(newTask);
      this.messageService.showInformation('Task created successfully，Please start your creation。');
    });
  }

  editUserTaskClick(event: MouseEvent, task: UserTaskListItem) {
    event.stopPropagation();
    this.taskMenu.closeMenu();

    this.showUserTaskDialog({
      id: task.id,
      name: task.name,
	  buttonLabel : 'Save',
    }, (result) => {
      task.name = result.data.name;
      this.messageService.showInformation('Task rename completed successfully。');
    });
  }

  showUserTaskDialog(params: any, callback: (result: any) => void) {
    this.dialog.open(UserTaskEditDialog, {
      disableClose: true,
      minWidth: 'min(90vw, 360px)',
      data: params,
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      callback(result);
    });
  }

  removeUserTaskClick(event: MouseEvent, task: UserTaskListItem) {
    event.stopPropagation();
    this.taskMenu.closeMenu();

    this.dialog.open(ConfirmDialog, {
      disableClose: true,
      data: {
		confirmButtonName : 'Delete',
        question: 'Are you sure you want to delete the task?',
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.http.delete(`/user/tasks/${task.id}`).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          this.tasks = this.tasks.filter(function (item) {
            return item.id != task.id;
          });
          this.messageService.showInformation('The task has been successfully deleted。');
          this.selectionChange.emit(undefined);
        }
      });
    });

  }

  updateTask(value: UserTaskListItem) {
    let task = this.tasks;
    for (let i = 0; i < task.length; i++) {
      if (value.id != task[i].id) {
        continue;
      }
      task[i] = { ...task[i], ...value };
      break;
    }
  }

  fetchMoreClick() {
    this.fetchTasks();
  }

}
