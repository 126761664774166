import { Component, Injector } from '@angular/core';
import { AppHttpClient } from '../common/app.http';
import { BaseComponent } from '../common/base.component';

@Component({
  selector: 'tool-list',
  templateUrl: './tool-list.component.html',
  styleUrl: './tool-list.component.scss',
})
export class ToolListComponent extends BaseComponent {

  tools = [
    {
      title: 'Remove watermark',
      icon: 'assets/images/tool-010.png',
      description: 'Watermark removal refers to the use of certain technical means，The process of removing watermarks from images or videos。In pictures and videos，Watermarks are usually added to the screen...',
    },
    {
      title: 'Image Enlargement',
      icon: 'assets/images/tool-020.png',
      description: 'Image enlargement refers to the function of enlarging the original image to a larger size through software or hardware devices.。In digital image processing，Commonly used amplification technology packages...',
    },
    {
      title: 'Face restoration',
      icon: 'assets/images/tool-030.png',
      description: 'Face restoration is a technology，Aims to repair or enhance the clarity and quality of facial images through various technical means。This technology can be applied to various...',
    },
    {
      title: 'Image enhancement',
      icon: 'assets/images/tool-040.png',
      description: 'Image enhancement is a technique used to enhance the color or details of an image.，The process of improving the quality of an image or highlighting its subject。This technology can...',
    },
  ];

  constructor(
    injector: Injector,
    private http: AppHttpClient) {
    super(injector);

  }

  ngOnInit() {

  }

}

