<div class="d-flex mx-2">
  <div>Text description</div>
  <div class="flex-grow-1"></div>
  <div>{{value?.length}} / 150</div>
</div>
<div class="mx-2 mt-2">
  <textarea class="w-100" matInput rows="4" [(ngModel)]="value" (change)="valueChanged()"
    placeholder="TIPS:Please describe the clothing you wish to generate，For example：&#10;&#10;·Blue long cheongsam with lace，The style is the same as the uploaded picture&#10;·Red cardigan，With plant pattern"></textarea>
  <div class="d-flex" *ngIf="!negativeVisible">
    <div class="flex-grow-1"></div>
    <div class="negative-title" (click)="negativeVisible=!negativeVisible">
      <mat-icon class="icon-xs">arrow_left</mat-icon><span>Adding unwanted content</span>
    </div>
  </div>
</div>

<div class="d-flex mx-2" *ngIf="negativeVisible">
  <div class="negative-title" (click)="negativeVisible=!negativeVisible">
    <mat-icon class="icon-xs">arrow_right</mat-icon><span>Adding unwanted content</span>
  </div>
  <div class="flex-grow-1"></div>
  <div *ngIf="negativeVisible">{{value?.length}} / 150</div>
</div>
<div class="m-2" *ngIf="negativeVisible">
  <textarea class="w-100" matInput rows="3" [(ngModel)]="negative" (change)="negativeChanged()"
    placeholder="Please enter the content you do not want to appear"></textarea>
</div>
