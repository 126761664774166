import { Injectable, Injector } from "@angular/core";
import { ActionResult, ActionStatus, AppHttpClient } from "../common/app.http";
import { PaintedImage } from "../models";
import { of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SimiGenerateService {

  constructor(private injector: Injector, private http: AppHttpClient) {

  }

  single_generate(formData: FormData) {
    
  }

  generate(formData: FormData) {
    let quantity = formData.get('quantity');
    formData.set('quantity', '1');
    let paintedImages = new Array<PaintedImage>();
    for (let i = 0; i < Number(quantity); i++) {
      let item = new PaintedImage(this.injector);
      item.start();
      paintedImages.push(item);
    }

    let prompts = formData.get('prompts');
    let negativePrompts = formData.get('negativePrompts');

    let chatObserable;
    if (!prompts && !negativePrompts) {
      chatObserable = of({
        status: ActionStatus.Success,
        data: {},
      } as ActionResult);
    } else {
      let chatInput = {
        'prompts': prompts,
        'negativePrompts': negativePrompts,
      };
      chatObserable = this.http.post(`/picture/chat`, chatInput);
    }

    chatObserable.subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          paintedImages.forEach((item) => {
            item.stop(result);
          });
          return;
        }

        let chatResult = result.data;
        formData.set('prompts', chatResult.prompts ?? '');
        formData.set('negativePrompts', chatResult.negativePrompts ?? '');

        paintedImages.forEach((item, index) => {
          setTimeout(() => {
            this.http.post(`/picture/simipaint`, formData).subscribe({
              next: (result: ActionResult) => {
                if (!this.http.isValidResult(result)) {
                  item.stop(result);
                  return;
                }
                item.id = result.data;
              },
              error: (err: any) => this.onError(item, err),
            });
          }, index * 250);
        });
      },
      error: (err: any) => {
        paintedImages.forEach((item) => {
          this.onError(item, err)
        });
      },
    });

    return paintedImages;
  }

  private onError(target: PaintedImage, err: any) {
    target.stop({
      status: ActionStatus.Failed,
      message: err.message ?? err.toString(),
    });
  }
}