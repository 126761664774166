
<div class="page-header">
  <h4 class="title me-5">Order Management</h4>
  <div class="flex-grow-1"></div>
  <button mat-button class="btn-min-width" (click)="addNewClick()">
    <mat-icon color="primary">add_circle</mat-icon><span>Add an order</span>
  </button>
</div>

<form class="d-flex flex-wrap" [formGroup]="inputForm">
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>Order Number</mat-label>
      <input matInput formControlName="orderNo" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>User Account</mat-label>
      <input matInput formControlName="username" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>Order Status</mat-label>
      <mat-select formControlName="status">
        <mat-option value="">(All)</mat-option>
        <mat-option *ngFor="let item of statusList" [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12 col-md-4 col-xl-2">
    <mat-form-field>
      <mat-label>Customer Specialist</mat-label>
      <input matInput formControlName="employee" maxlength="32">
    </mat-form-field>
  </div>
  <div class="col-12 col-md-4 col-xl-2 text-center py-2">
    <button mat-flat-button mat-button-base color="primary" (click)="searchClick()">Query</button>
  </div>
</form>

<div [class.d-none]="dataSource.data.length==0">

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="orderNo">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Order Number</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.orderNo}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">User Account</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.username}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Service Content</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.description}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Amount</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.amount}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="totalPoints">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Buy Smart Beans</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.totalPoints}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="points">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Remaining Smart Beans</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.points}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="payType">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Payment Methods</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.payType}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="payAccount">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Payment Account</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.payAccount}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Creation time</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.createdDate}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Order Status</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.status}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="employeeName">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1">Customer Specialist</mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">{{row.employeeName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="col-6 col-lg-1"></mat-header-cell>
      <mat-cell *matCellDef="let row" class="col-6 col-lg-1">
        <button mat-icon-button class="btn-sm m-1" matTooltip="More Operations" [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{row}">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="row g-0"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="row g-0"></mat-row>
  </mat-table>
  <mat-paginator pageSize="100" [pageSizeOptions]="[10, 25, 100, 200]"></mat-paginator>

  <br />
  <br />

</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-row="row">
    <button mat-menu-item color="primary" (click)="editClick(row)">
      <mat-icon>edit</mat-icon>Order Modification
    </button>
    <button mat-menu-item color="primary" (click)="withdrawClick(row)">
      <mat-icon>attach_money</mat-icon>Account recharge
    </button>
  </ng-template>
</mat-menu>
