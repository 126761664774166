<div class="box" #box>
  <ng-content></ng-content>
  <div class="box-border" *ngIf="border"></div>
  <div [ngStyle]="{ display: (active$ | async) &&dot ? '' : 'none' }">
    <div class="dot rotate cursor-rotate" #rotate></div>
    <div class="dot left-top"></div>
    <div class="dot left-bottom"></div>
    <div class="dot right-bottom"></div>
    <div class="dot right-top"></div>
    <div class="rotate-link"></div>
  </div>
</div>