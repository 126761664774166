import { ErrorHandler, Injectable } from '@angular/core';
import { MessageResource, MessageService } from '../common/message.service';
import { AppHttpClient } from '../common/app.http';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private http: AppHttpClient,
    private messageService: MessageService,) {
  }

  handleError(error: any): void {
    let errorMsg: string | undefined = error?.stack;
    if(!errorMsg){
      errorMsg = error?.message?.toString();
      let traceMsg = error?.error?.trace;
      if(!!traceMsg){
        errorMsg += '\r\n' + traceMsg;
      }
    }
    if (errorMsg != null && errorMsg.length > 2048) {
      errorMsg = errorMsg.substring(0, 2045) + "...";
    }
    const params = {
      details: errorMsg,
    };
    this.http.post('/system/log', params, { silence: true }).subscribe({
      error: (err: any) => {
        // neglect
      }
    });

    // Log the error to the console.
    console.error(error);

    this.messageService.showError(MessageResource.FAILED_TO_PROCESS);
  }
}
