import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { DateTimeHelper } from '../helpers/app.helpers';

@Component({
  selector: 'user-task-edit',
  templateUrl: './user-task-edit.dialog.html',
  styleUrl: './user-task-edit.dialog.scss',
})
export class UserTaskEditDialog extends BaseComponent implements OnInit {
  inputForm: FormGroup;
  buttonLabel: String;
  
  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialogRef: MatDialogRef<UserTaskEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    this.inputForm = new FormGroup({
      id: new FormControl(),
      name: new FormControl(undefined, [Validators.required,]),
      category: new FormControl(),
    });
    this.inputForm.patchValue(data);
	this.buttonLabel=data['buttonLabel'];
  }

  ngOnInit() {
    let id = this.id.value
    if (!id) {
      let now = DateTimeHelper.getNow();
      this.inputForm.patchValue({
        name: 'Task-' + DateTimeHelper.format(now, 'yyMMddHHmm')
      });
      return; // add new
    }
    this.http.get(`/user/tasks/${id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.inputForm.patchValue(result.data);
      }
    });
  }

  cancelClick() {
    this.dialogRef.close();
  }

  saveClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }

    let id = this.id.value
    let url = '/user/tasks' + (!id ? '' : `/${id}`);
    let params = this.inputForm.value;
    this.http.post(url, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        if (!id) {
          this.inputForm.patchValue({
            id: result.data,
          });
        }
        this.dialogRef.close({
          data: this.inputForm.value,
        });
      }
    });
  }

  get id() { return this.inputForm.controls['id']; }
  get name() { return this.inputForm.controls['name']; }

}

