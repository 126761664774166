import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-age-input',
  templateUrl: './image-age-input.html',
})
export class ImageAgeInput implements OnInit {
  ages: SelectListItem[] = [
    { key: 'Unlimited', value: 'Unlimited', selected: true },
    { key: 'child', value: 'child', selected: false },
    { key: 'juvenile', value: 'juvenile', selected: false },
    { key: 'youth', value: 'youth', selected: false },
    { key: 'middle aged', value: 'middle aged', selected: false },
    { key: 'elderly', value: 'elderly', selected: false },
  ];
  _age?: string;
  @Input() set age(value: string | undefined) {
    this._age = value;
    let item = this.ages.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.ages.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get age() {
    return this._age;
  }

  @Output() ageChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  ageClick(selectedAge: SelectListItem) {
    this.age = selectedAge.key;
    this.ageChange.emit(this.age);
  }

}
