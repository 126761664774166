import { AfterViewInit, Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserInfo } from '../interfaces';

@Component({
  selector: 'user-picker-dialog',
  templateUrl: './user-picker-dialog.html',
  styleUrl: './user-picker-dialog.scss',
})
export class UserPickerDialog extends BaseComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['action', 'username', 'nickname', 'companyName'];
  inputForm: FormGroup;
  dataSource = new MatTableDataSource<UserInfo[]>([]);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  searched = false;
  selectedUser?: UserInfo;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialogRef: MatDialogRef<UserPickerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    this.inputForm = this.formBuilder.group({
      companyName: new FormControl(),
      username: new FormControl(),
      type: new FormControl(data?.type),
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {

  }

  searchClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }
    this.search()
  }

  search() {
    this.searched = true;
    var params = this.inputForm.value;
    this.http.post('/users/search', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dataSource.data = result.data;
      }
    });
  }

  selectUser(row: UserInfo) {
    this.selectedUser = row;
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  confirmClick() {
    this.dialogRef.close(this.selectedUser);
  }

}
