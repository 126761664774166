<page-header title="Try On" i18n-title="@@menuLabelTryon"/>

<div class="flex-grow-1 h-0 d-flex flex-column flex-lg-row">

  <div class="me-1" [class.d-none]="!isExpanded">
    <user-task-list #userTaskList class="min-h-100" [category]="TaskCategoryEnum.freeTryon" [userId]="userId"
      (selectionChange)="userTaskSelectionChange($event)" />
  </div>

  <user-favorite-list [type]="JobTypesEnum.freeTryon" [userId]="userId" *ngIf="!taskId" />

  <div [class]="!taskId? 'd-none':'d-flex flex-column'">

    <flexible-accordion class="h-lg-100">
      <div #inputMatCard class="input-container flexible-accordion-header">

        <mat-card class="p-0 d-flex flex-column">

          <div class="px-2 task-header background-lighter">

            <div class="d-flex flex-row align-items-center">
              <div class="d-flex align-items-center" matTooltip="task bar" (click)="isExpanded=!isExpanded">
                <mat-icon class="cursor-pointer" matRipple color="primary" svgIcon="app:my_tasks" />
              </div>
              <div class="ms-2 me-3" *ngIf="!taskNameEditable">
                <h3 class="my-0">{{userTaskList.curTask?.name}}</h3>
              </div>

              <!--<text-area class="text-area background-lighter task-name-area ms-2 me-2 mat-subtitle-1" 
                [(ngModel)]="taskName" matInput rows="4" cols="10" placeholder=""
                *ngIf="taskNameEditable"></text-area>-->

              <mat-icon color="accent" class="cursor-pointer" matTooltip="Duplicate naming" svgIcon="app:edit"
                (click)="userTaskList.editUserTaskClick($event,userTaskList.curTask!)"> </mat-icon>
            </div>
            <div class="flex-grow-1"></div>
          </div>

          <div class="mx-2">

            <!-- <div class="d-flex my-3">
              <span class="mat-small hint-text">Four steps to create a free pose model</span>
            </div> -->
            <div class="d-flex my-3 flex-row align-items-center">
              <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:clothing-type'"></mat-icon>
              <p class="my-0 mat-body">Select clothing type</p>
            </div>
            <div class="d-flex mb-3">
              <div class="col-3">
                <div class="clothing-type-item" [class.active]="prompts=='tops'" matRipple
                  (click)="clothingTypeChanged('tops')">
                  <div class="m-auto d-flex">
                    <mat-icon color="primary" [svgIcon]="'app:clothing-tops'"></mat-icon>
                    <span class="mx-2">Tops</span>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="clothing-type-item" [class.active]="prompts=='bottoms'" matRipple
                  (click)="clothingTypeChanged('bottoms')">
                  <div class="m-auto d-flex">
                    <mat-icon color="primary" [svgIcon]="'app:clothing-bottoms'"></mat-icon>
                    <span class="mx-2">Bottoms</span>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="clothing-type-item" [class.active]="prompts=='jumpsuit'" matRipple
                  (click)="clothingTypeChanged('jumpsuit')">
                  <div class="m-auto d-flex">
                    <mat-icon color="primary" [svgIcon]="'app:clothing-jumpsuit'"></mat-icon>
                    <span class="mx-2">One-Pieces</span>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="clothing-type-item" [class.active]="prompts=='both'" matRipple
                  (click)="clothingTypeChanged('both')">
                  <div class="m-auto d-flex">
                    <mat-icon color="primary" [svgIcon]="'app:clothing-both'"></mat-icon>
                    <span class="mx-2">Outfits</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="raw-image-zone mb-3">
              <div class="ratio ratio-1x1 background-lighter image-area mx-1"
                [class.d-none]="prompts!='tops' && prompts!='both'">
                <div class="image-label d-flex my-0 px-2 py-1">
                  <p class="mat-small my-0">Tops</p>
                </div>
                <image-picker #topsImagePicker title="Upload Tops" i18n-title="@@buttonTitleUploadTops" (changed)="topsImageChanged($event)"
                  [class.d-none]="topsImageUrl">
                  <div class="cloth-container">
                    <material-select-inline containerClass="col-4" itemClass="ratio ratio-3x4"
                      [category]="MaterialCategoryEnum.ClothingTops" (selectionChange)="topsSelected($event)"
                      (click)="$event.stopPropagation()" />
                  </div>
                </image-picker>
                <img class="raw-image" [class.d-none]="!topsImageUrl" [src]="topsImageUrl"
                  (click)="topsImagePicker.click()" />
              </div>
              <div class="ratio ratio-1x1 background-lighter image-area mx-1"
                [class.d-none]="prompts!='bottoms' && prompts!='both'">
                <div class="image-label d-flex my-0 px-2 py-1">
                  <p class="mat-small my-0">Bottoms</p>
                </div>
                <image-picker #bottomsImagePicker title="Upload Bottoms" i18n-title="@@buttonTitleUploadBottoms" (changed)="bottomsImageChanged($event)"
                  [class.d-none]="bottomsImageUrl">
                  <div class="cloth-container">
                    <material-select-inline containerClass="col-4" itemClass="ratio ratio-3x4"
                      [category]="MaterialCategoryEnum.ClothingBottoms" (selectionChange)="bottomsSelected($event)"
                      (click)="$event.stopPropagation()" />
                  </div>
                </image-picker>
                <img class="raw-image" [class.d-none]="!bottomsImageUrl" [src]="bottomsImageUrl"
                  (click)="bottomsImagePicker.click()" />
              </div>
              <div class="ratio ratio-1x1 background-lighter image-area mx-1" [class.d-none]="prompts!='jumpsuit'">
                <div class="image-label d-flex my-0 px-2 py-1">
                  <p class="mat-small my-0">One-piece suit</p>
                </div>
                <image-picker #jumpsuitImagePicker title="Upload One-Piece Outfits" i18n-title="@@buttonTitleUploadOnePiece" (changed)="jumpsuitImageChanged($event)"
                  [class.d-none]="jumpsuitImageUrl">
                  <div class="cloth-container">
                    <material-select-inline containerClass="col-4" itemClass="ratio ratio-3x4"
                      [category]="MaterialCategoryEnum.ClothingJumpsuit" (selectionChange)="jumpsuitSelected($event)"
                      (click)="$event.stopPropagation()" />
                  </div>
                </image-picker>
                <img class="raw-image" [class.d-none]="!jumpsuitImageUrl" [src]="jumpsuitImageUrl"
                  (click)="jumpsuitImagePicker.click()" />
              </div>
            </div>

            <div class="d-flex flex-row align-items-center">
              <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:scence'"></mat-icon>
              <p class="my-0 mat-body">Select Model Pose Scene</p>
              <!-- <p class="mx-2 mt-1 mb-0 mat-small hint-text">Product image background parameter settings </p> -->
            </div>
            <mat-tab-group class="mb-3" mat-stretch-tabs="false" mat-align-tabs="start"
              [selectedIndex]="scenceActiveTab" [dynamicHeight]="true" fitInkBarToContent="true">
              <mat-tab>
                <ng-template mat-tab-label>
                  <span class="mat-small">Custom poses</span>
                </ng-template>
                <div class="mt-2"> </div>
                <user-asset-inline #scenceAsset itemClass="ratio ratio-3x4" [groupType]="AssetGroupTypesEnum.MyPose"
                  inlineHintText="Upload pose" [selected]="assetScenceSelected" [initSelectedId]="initAssetScenceId"
                  (selectionChange)="assetScenceImageSelected($event)" />
                <div class="mb-1"></div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <span class="mat-small">Pose Library</span>
                </ng-template>
                <div class="mt-2 mb-1">
                  <material-select-inline #scenceSelect itemClass="ratio ratio-3x4"
                    [category]="MaterialCategoryEnum.ClothingReference" [selectedTemplateId]="selectedScence"
                    [labels]="scenceLabels" (selectionChange)="templateScenceSelected($event)" [disablePaging]="true" />
                </div>

              </mat-tab>

            </mat-tab-group>

            <div class="d-flex flex-row align-items-center">
              <mat-icon class="attri-icon-size" color="primary" [svgIcon]="'app:model'"></mat-icon>
              <p class="my-0 mat-body">Model selection</p>
              <p class="mx-2 mt-1 mb-0 mat-small hint-text">Asia/Europe/Africa, etc.</p>
            </div>
            <mat-tab-group class="mb-3" mat-stretch-tabs="false" mat-align-tabs="start" [selectedIndex]="modelActiveTab"
              [dynamicHeight]="true" fitInkBarToContent="true">
              <!-- Temporarily hide this feature -->
              <!-- <mat-tab>
                <ng-template mat-tab-label>
                  <span class="mat-small">Custom Models </span>
                </ng-template>

                <div class="mt-2"> </div>
                
                <user-asset-inline #modelAsset [groupType]="AssetGroupTypesEnum.MyModel" [inlineHintText]="'Upload Model'" [selected]="assetModelSelected"
                [initSelectedId]="initAssetModelId" (selectionChange)="assetModelImageSelected($event)"/>

                <div class="mb-1"></div>

              </mat-tab> -->

              <mat-tab>
                <ng-template mat-tab-label>
                  <span class="mat-small">Model Library</span>
                </ng-template>
                <div class="mt-2 mb-1">
                  <material-select-inline #modelSelect [category]="MaterialCategoryEnum.ClothingModel"
                    [selectedTemplateId]="selectedModel" [required]="false" unselectedTitle="Pose Library Models"
                    (selectionChange)="templateModelSeleted($event)" />
                </div>
              </mat-tab>
            </mat-tab-group>

            <!-- <div class="my-3">
              <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [dynamicHeight]="true"
                fitInkBarToContent="true">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 class="mt-0 mb-2">Quick Templates </h4>
                  </ng-template>

                  <p class="mt-3 mb-0 mat-small">Background Description</p>
                  <textarea class="text-area w-100 mt-3 background-lighter" matInput rows="4" [(ngModel)]="prompts"
                  placeholder="Please enter the background text description you would like to generate for the product image"></textarea>

                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>
                    <h4 class="mt-0 mb-2">Freedom to create</h4>
                  </ng-template>
                  <textarea class="text-area w-100 mt-3 background-lighter" matInput rows="4" [(ngModel)]="prompts"
                  placeholder="Please enter the text description you wish to generate for the product image"></textarea>
                </mat-tab>
              </mat-tab-group>
            </div> -->

          </div>

          <div class="flex-grow-1"></div>

          <div class="px-2 py-1 background-lighter task-footer w-100 d-flex flex-column">

            <!-- <image-quantity-input [(value)]="quantity" [min]="minQuantity" [max]="maxQuantity" /> -->

            <div class="d-flex mt-2">
              <cost-estimator category="image" item="paint" [quantity]="quantity" />
              <div class="flex-grow-1"></div>
              <button mat-flat-button class="px-4" color="primary" (click)="generateClick()"
                [disabled]="processing || !!userId" i18n="@@buttonTitleGenerate">Generate</button>
            </div>

          </div>

        </mat-card>

      </div>

      <div class="h-lg-100 user-operation-area" *ngIf="taskId">

        <div class="d-flex flex-column w-100 h-100 p-lg-2">
          <user-favorite-list [type]="JobTypesEnum.freeTryon" [userId]="userId" [taskId]="taskId" />
          <user-operation-list #userOperationList [userId]="userId" [taskId]="taskId"
            [notificationCategory]="userNotificationCatecory" (clone)="cloneClick($event)"
            (statusChange)="userOperationStatusChange($event)" />
        </div>

      </div>

    </flexible-accordion>

  </div>

</div>
