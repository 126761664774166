import { Component, OnInit } from '@angular/core';
import { AppHttpClient } from '../common/app.http';

@Component({
  selector: 'wechat-barcode',
  templateUrl: './wechat-barcode.html',
  styleUrl: './wechat-barcode.scss',
})
export class WechatBarcode implements OnInit {
  url?: string;

  constructor(
    private http: AppHttpClient,
  ) {

  }

  ngOnInit() {
    this.http.get('/public/contact-us/wechat', { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.url = URL.createObjectURL(result);
      },
    });
  }

}
