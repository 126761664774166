import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AssetType, PaintedImage, UserAssetCategory, UserNotification, UserNotificationCategory } from '../models';
import { ImageDialog } from '../image-dialog/image-dialog';
import { MatDialog } from '@angular/material/dialog';
import { format } from 'date-fns';
import { ActionResult, ActionStatus, AppHttpClient } from '../common/app.http';
import { FileHelper, MediaTypeHelper } from '../helpers/app.helpers';
import { RxStompService } from '../stomp/rx-stomp.service';
import { Subscription } from 'rxjs';
import { IMessage } from '@stomp/rx-stomp';
import { MessageResource, MessageService } from '../common/message.service';
import { UserAssetGroupPicker } from '../user-asset-group/user-asset-group-picker';
import { UserAssetGroupListItem } from '../interfaces';
import { BASE_URL } from '../app.module';

@Component({
  selector: 'painted-image-item',
  templateUrl: './painted-image-item.html',
  styleUrl: './painted-image-item.scss',
})
export class PaintedImageItem implements OnInit {

  @Input({ required: true }) value!: PaintedImage;
  @Output() valueChange = new EventEmitter<PaintedImage>();
  @Output() completed = new EventEmitter<PaintedImage>();
  @Input() editable = false;
  @Input() actionBarVisible = true;
  @Input() notificationCategory: UserNotificationCategory = UserNotificationCategory.Paint;
  @Input() backgroundColor: string = 'rgba(0, 0, 0, 0)';
  @Output() edit = new EventEmitter<PaintedImage>();
  @Input() useBackgroundAlpha: boolean = false;
  @Input() enableSave: boolean = true;
  @Input() showTiffMenu: boolean = false;

  private timer?: NodeJS.Timeout;

  private paintSubscription!: Subscription;

  curDownloadFormat: string = 'RGB';
  downloadResolution: string = '300';

  minResolution: number = 72;
  maxResolution: number = 300;

  constructor(
    @Inject(BASE_URL) public baseUrl: string,
    private http: AppHttpClient,
    private dialog: MatDialog,
    private messageService: MessageService,
    private rxStompService: RxStompService,) {

  }

  ngOnInit() {
    if (this.value?.result?.status == ActionStatus.Running) {
      this.timer = setInterval(() => {
        let status = this.value?.result?.status;
        if (status == ActionStatus.Success || status == ActionStatus.Failed) {
          this.stopTimer();
          this.completed.emit(this.value);
        }
      }, 1 * 1000);
    }

    this.paintSubscription = this.rxStompService
      .watch(this.notificationCategory)
      .subscribe((message: IMessage) => {
        var notification = JSON.parse(message.body) as UserNotification;
        if (notification.id != this.value?.id) {
          return;
        }
        if (this.value.result?.status == ActionStatus.Failed || this.value.result?.status == ActionStatus.Success) {
          return;
        }
        var result = notification.data as ActionResult;
        if (result.status !== ActionStatus.Success) {
          let paintedImage = this.value;
          paintedImage.stop(result);
          this.stopTimer();
          this.completed.emit(paintedImage);
          return;
        }
        this.completedGenerate(notification.id!);
      });
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  stopTimer() {
    if (!!this.timer) {
      clearTimeout(this.timer);
    }
    this.paintSubscription.unsubscribe();
  }

  completedGenerate(id: string) {
    let paintedImage = this.value;
    let url = `/job/${id}/download`;
    this.http.get(url, { responseType: 'blob' }).subscribe({
      next: async (result: Blob) => {
        paintedImage.src = url;
        paintedImage.data = result;
        // paintedImage.url = URL.createObjectURL(blob);
        paintedImage.name = 'generated_image' + MediaTypeHelper.resolveFileExtension(result.type);
        paintedImage.thumbUp = false;
        paintedImage.thumbDown = false;
        paintedImage.stop({
          status: ActionStatus.Success,
        });
        this.stopTimer();
        this.completed.emit(paintedImage);
      },
    });
  }

  editClick() {
    this.edit.emit(this.value);
  }

  zoomoutPaintedImageClick() {
    let image = this.value;
    if (!image) {
      return;
    }
    this.dialog.open(ImageDialog, {
      data: {
        url: image.url,
      }
    });
  }

  handleExportContainClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains('export-button')) {
      event.stopPropagation();
    }
  }

  exportClick() {
    switch(this.curDownloadFormat) {
      case 'RGB':
        this.exportPaintedImageClick();
        break;
      case 'CMYK':
        this.exportTiffClick();
        break;
    }
  }

  exportPaintedImageClick() {
    let image = this.value;
    if (!image) {
      return;
    }
    let href = image.url;
    if (!href) {
      return;
    }
    FileHelper.download(href, `${format(new Date(), 'yyyyMMddHHmmss')}_${image.name}`);
  }

  exportTiffClick() {
    let image = this.value;
    if (!image) {
      return;
    }
    let href = image.url;
    if (!href) {
      return;
    }

    let numberResolution = Number(this.downloadResolution);
    if (numberResolution > this.maxResolution) {
      this.messageService.showInformation("The input resolution exceeds the maximum limit300，by300dpiDownload for you。");
      this.downloadResolution = this.maxResolution.toString();
    }
    else if (numberResolution < this.minResolution){
      this.messageService.showInformation("The input resolution is lower than the minimum limit72，by72dpiDownload for you。")
      this.downloadResolution = this.minResolution.toString();
    }

    if (!image.data || !image.name) {
      return;
    }

    let tiffFileName = MediaTypeHelper.replaceFileExtension(image.name, 'tiff');

    let formData = new FormData();
    formData.set('imageFile', image.data, tiffFileName);
    formData.set('format', DownloadType.TIFF);
    formData.set('resolution', this.downloadResolution);
    
    this.http.post('/image/convert', formData, { responseType: 'blob' }).subscribe ({
      next: (result: Blob) => {
        if (!result) {
          return;
        }
        let tiffUrl = URL.createObjectURL(result);
        FileHelper.download(tiffUrl, `${format(new Date(), 'yyyyMMddHHmmss')}_${tiffFileName}`);
      }
    });
  }

  downloadFormatChanged(format: string) {
    this.curDownloadFormat = format;
  }

  downloadResolutionChanged(dpi: string) {
    this.downloadResolution = dpi;
  }

  userFeedbackClick(category: string) {
    let image = this.value;
    if (!image) {
      return;
    }

    if (category == '0000') {
      image.thumbUp = true;
    } else if (category = '1000') {
      image.thumbDown = true;
    }

    let imageFile = image.data;
    if (!imageFile) {
      return;
    }

    var formData = new FormData();
    formData.set('rate', '0');
    formData.set('category', category);
    formData.set('imageFile', imageFile);
    var relatedId = image.id?.toString();
    if(!!relatedId){
      formData.set('relatedId', relatedId);
    }

    this.http.post("/user/feedback", formData).subscribe();
  }

  get generating(): boolean {
    return this.value?.result?.status == ActionStatus.Running;
  }

  get hasError(): boolean {
    return this.value?.result?.status == ActionStatus.Failed;
  }

  get errorMessage(): string | undefined {
    return this.value?.result?.message;
  }

  saveToUserAssetClick() {
    let image = this.value;
    if (image.saved) {
      this.messageService.showWarning(MessageResource.ALREADY_SAVED);
      return;
    }
    this.dialog.open(UserAssetGroupPicker, {
      disableClose: true,
      minWidth: 'min(90vw, 420px)',
      data: {
        category: UserAssetCategory.Product,
      },
    }).afterClosed().subscribe((result) => {
      let group = result?.data as UserAssetGroupListItem;
      if (!group?.id) {
        return;
      }
      var formData = new FormData();
      formData.set('category', group.category);
      formData.set('groupId', group.id.toString());
      formData.set('assetFile', image.data!);
      formData.set('assetType', AssetType.Image);
      formData.set('relatedId', image.id!);
      this.http.post('/user/assets', formData).subscribe({
        next: (result: ActionResult) => {
          if (!this.http.isValidResult(result)) {
            return;
          }
          image.saved = true;
          this.messageService.showInformation(MessageResource.SAVED_SUCCESSFULLY);
        },
      });
    });
  }

}

export enum DownloadType {
  TIFF = 'TIFF',
}
