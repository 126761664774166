import { Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { ActionStatus, AppHttpClient } from '../common/app.http';
import { BlobHelper, MediaTypeHelper } from '../helpers/app.helpers';
import { UserAssetListItem, PaintMode, PathTypes, ModelScaleProps, ImageGenerateSteps } from '../interfaces';
import { PaintedImage, SelectListItem } from '../models';
import { trimCanvas } from '../helpers/create-cutouts';
import { handleImageScale } from '../helpers/image-helper';
import { validRange } from '../common/app.forms';
import { PaintService } from '../services/paint.service';
import { ImageStickerInput } from '../image-generate-input/image-sticker-input';
import { ImageMaskInput } from '../image-generate-input/image-mask-input';
import { ImagePicker } from '../image-picker/image-picker';
import { MatDialog } from '@angular/material/dialog';
import { ImageSegmentPicker } from '../image-segment-picker/image-segment-picker';

@Component({
  selector: 'image-refactor',
  templateUrl: './image-refactor.component.html',
  styleUrl: './image-refactor.component.scss',
})
export class ImageRefactorComponent extends BaseComponent implements OnInit {
  @Input() taskId!: number;
  @Input() expanded: boolean = false;
  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() completed = new EventEmitter<any>();
  returnTo?: ImageGenerateSteps;
  @Output() stateChanged = new EventEmitter<any>();

  PathTypesEnum = PathTypes;
  PaintModeEnum = PaintMode;

  @ViewChild('inputImage') inputImageRef!: ElementRef;
  @ViewChild('maskInput') maskInput!: ImageMaskInput;

  minQuantity: number = 1;
  maxQuantity: number = 4;
  quantity: string = "1";

  minScale: number = 10;
  maxScale: number = 300;
  scale: string = "100%";

  prompts?: string;
  negativePrompts?: string;

  _rawImageBlob?: Blob;
  set rawImageBlob(blob: Blob | undefined) {
    this.rawImageUrl = undefined;
    this._rawImageBlob = blob;
    if (!this._rawImageBlob) {
      return;
    }
    this.rawImageUrl = URL.createObjectURL(this._rawImageBlob);
  }
  get rawImageBlob(): Blob | undefined {
    return this._rawImageBlob;
  }
  rawImageUrl?: string;

  _inputImageBlob?: Blob;
  set inputImageBlob(blob: Blob | undefined) {
    this.inputImageUrl = undefined;
    this._inputImageBlob = blob;
    if (!this._inputImageBlob) {
      return;
    }
    this.inputImageUrl = URL.createObjectURL(this._inputImageBlob);
  }
  get inputImageBlob(): Blob | undefined {
    return this._inputImageBlob;
  }
  inputImageUrl?: string;
  private _selectedImageIndex?: number = undefined;
  set selectedImageIndex(value: number | undefined) {
    this._selectedImageIndex = value;
    let blob = this.selectedImageBlob;
    this.selectedImageUrl = !blob ? undefined : URL.createObjectURL(blob);
  }
  get selectedImageIndex() {
    return this._selectedImageIndex;
  }
  selectedImageUrl?: string;

  private get selectedImageBlob() {
    if (this.selectedImageIndex == undefined) {
      return this.inputImageBlob;
    }
    if (this.selectedImageIndex >= this.paintedImages.length) {
      return this.inputImageBlob;
    }
    return this.paintedImages[this.selectedImageIndex].data ?? this.inputImageBlob;
  }

  modelScale: ModelScaleProps | any = null;
  @ViewChild('rawImagePicker') rawImagePicker!: ImagePicker;
  @ViewChild('stickerFilePicker') stickerFilePicker!: ImagePicker;
  @ViewChild('stickerInput') stickerInput!: ImageStickerInput;
  paintedImages: PaintedImage[] = [];

  penMaxWidth = 19;
  penMinWidth = 1;
  penWidth = (this.penMaxWidth + this.penMinWidth) / 2;

  // inputZoneVisible: boolean = false;
  // inputZoneExpanded: boolean = true;

  constructor(injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
    private paintService: PaintService) {
    super(injector);
  }

  ngOnInit() {

  }

  init(newImageBlob: Blob | undefined, force: boolean, returnTo?: ImageGenerateSteps) {
    this.returnTo = returnTo;
    this.expanded = false;
    let oldImageBlob = this.rawImageBlob;
    if (force) {
      this.resetPicture();
    }
    this.rawImageBlob = newImageBlob;
    if (!this.inputImageBlob) {
      this.inputImageBlob = newImageBlob;
      this.selectedImageIndex = undefined;
    }
    if (!force && !!oldImageBlob && oldImageBlob.size == newImageBlob?.size) {
      return;
    }
    // this.reset();
    // this.rawImageBlob = newImageBlob;
    // let origImage = new PaintedImage(this.injector);
    // origImage.data = rawImageBlob;
    // this.paintedImages.push(origImage);
    // this.selectedImageIndex = undefined;
    this.notifyStateChanged();
  }

  // exportImageClick() {

  //   if (!this.inputImageBlob) {
  //     return;
  //   }
  //   let filename = `${format(new Date(), 'yyyyMMddHHmmss')}_image${MediaTypeHelper.resolveFileExtension(this.inputImageBlob.type)}`;
  //   BlobHelper.download(filename, this.inputImageBlob);
  // }

  reset() {
    // this.expanded = false;
    this.prompts = undefined;
    this.negativePrompts = undefined;
    this.scale = "100%";
    this.quantity = "1";
    this.rawImageBlob = undefined;
    this.resetPicture();
  }

  resetPictureClick() {
    this.resetPicture();
  }

  resetPicture() {
    this.inputImageBlob = undefined;
    this.selectedImageIndex = undefined;
    this.paintedImages = [];
    this.stickerInput?.reset();
    this.maskInput?.reset();
    this.pathType = PathTypes.EraseBrush;
    this.paintMode = PaintMode.Replace;
    // this.inputZoneVisible = false;
    this.modelScale = undefined;
    this.inputImageLoaded = false;
  }

  async refactorClick(selectedIndex: number | undefined) {
    // if (!paintedImage.url) {
    //   return;
    // }
    // let result = await fetch(paintedImage.url);
    // this.inputImageBlob = await result.blob();
    this.selectedImageIndex = selectedIndex;
  }

  inputImageLoaded = false;
  inputImageLoad(event: Event) {
    let img = event.target as HTMLImageElement;
    let modelScale = handleImageScale(img);
    this.modelScale = modelScale;
    this.maskInput.reset();
    this.maskInput.init(this.inputImageRef.nativeElement, this.modelScale);
    this.stickerInput.reset();
    this.inputImageLoaded = true;
  }

  scaleImageClick(mode: PaintMode) {
    this.paintMode = mode;
    let rawImageBlob = this.selectedImageBlob;
    if (!rawImageBlob) {
      this.showError("Please upload a picture。")
      return;
    }
    let scale = this.scale.replace('%', '');
    if (!validRange(scale, this.minScale, this.maxScale)) {
      this.showError(`Please specify the image scaling，The range is${this.minScale}%～${this.maxScale}%。`);
      return;
    }
    let numScale = `${(Number(scale) / 100).toFixed(2)}`;

    let rawImageFileName = BlobHelper.getName(rawImageBlob, 'input');

    let formData = new FormData();
    formData.set('imageFile', rawImageBlob, rawImageFileName);
    formData.set('width', this.modelScale.width);
    formData.set('height', this.modelScale.height);
    formData.set('upscaleRatio', numScale);
    formData.set('quantity', '1');
    formData.set('mode', mode);
    this.generate(formData);
  }

  mergeImage() {
    let mode = this.paintMode;
    if (!mode) {
      return;
    }
    let rawImageBlob = this.selectedImageBlob;
    if (!rawImageBlob) {
      this.showError("Please upload a picture。")
      return;
    }
    if (this.stickerInput.value.length == 0) {
      this.showError("Please add a sticker。")
      return;
    }

    let rawImageFileName = BlobHelper.getName(rawImageBlob, 'input');

    let formData = new FormData();
    formData.set('imageFile', rawImageBlob, rawImageFileName);
    formData.set('width', this.modelScale.width);
    formData.set('height', this.modelScale.height);
    formData.set('quantity', '1');
    formData.set('mode', mode);
    this.stickerInput.drawToCanvas().toBlob((maskBlob) => {
      if (!maskBlob) {
        return;
      }
      formData.set('rawMaskFile', maskBlob, "raw_mask_" + rawImageFileName);
      this.generate(formData);
    });
  }

  onMaskCompleted(event: MouseEvent) {
    // this.inputZoneExpanded = true;
    // this.inputZoneVisible = true;
  }

  generateClick(event: any) {
    event.stopPropagation();
    // this.inputZoneVisible = false;
    switch (this.paintMode) {
      case PaintMode.Replace:
        this.redrawImage();
        break;
      case PaintMode.Add:
        this.redrawImage();
        break;
      case PaintMode.Remove:
        this.redrawImage();
        break;
      case PaintMode.Merge:
        this.mergeImage();
        break;
    }
  }

  redrawImage() {
    let mode = this.paintMode;
    if (!mode) {
      return;
    }
    let rawImageBlob = this.selectedImageBlob;
    if (!rawImageBlob) {
      this.showError("Please upload a picture。")
      return;
    }
    if (this.maskInput.value.length == 0) {
      this.showError(`Please select an area in the image to redraw。`)
      return;
    }
    if ((mode == PaintMode.Replace || mode == PaintMode.Add) && !this.prompts) {
      this.showError("Please enter a description of the scene。")
      return;
    }
    if (!validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return;
    }

    let rawImageFileName = BlobHelper.getName(rawImageBlob, 'input');
    let formData = new FormData();
    formData.set('prompts', this.prompts ?? '');
    formData.set('negativePrompts', this.negativePrompts ?? '');
    formData.set('imageFile', rawImageBlob!, rawImageFileName);
    formData.set('width', this.modelScale.width);
    formData.set('height', this.modelScale.height);
    if (mode == PaintMode.Replace || mode == PaintMode.Add) {
      formData.set('quantity', this.quantity);
    } else {
      formData.set('quantity', '1');
    }
    formData.set('mode', mode);
    this.maskInput.drawToCanvas().toBlob((maskImageBlob) => {
      if (!maskImageBlob) {
        return;
      }
      formData.set('rawMaskFile', maskImageBlob, "raw_mask_" + rawImageFileName);
      this.generate(formData);
    });

  }

  generate(formData: FormData) {
    if (this.validateProcessing()) {
      return;
    }
    this.processing = true;

    this.expandedChange.emit(false); // Hide Panel
    this.expanded = false;
    formData.set('taskId', this.taskId.toString());

    let paintedImages = this.paintService.generate(formData);
    let zone = this.injector.get(NgZone);
    zone.run(() => {
      this.inputImageBlob = this.selectedImageBlob;
      // this.selectedImageIndex = undefined;
      this.paintedImages = paintedImages;
    });
  }

  onPaintCompleted() {
    let paintedImages = this.paintedImages;
    if (!paintedImages) {
      return;
    }

    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status == ActionStatus.Running) {
        return;
      }
    }
    this.processing = false;

    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status == ActionStatus.Success) {
        this.selectedImageIndex = i;
        break;
      }
    }

    this.notifyStateChanged();
  }

  onStickerFileChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }

    createImageBitmap(file).then((image) => {
      const modelScale = this.modelScale;
      let width = Math.min(modelScale.width, image.width);
      let height = Math.min(modelScale.height, image.height);
      var ratio = Math.min(width / image.width, height / image.height);
      width = image.width * ratio;
      height = image.height * ratio;
      const canvas = document.createElement("canvas");
      canvas.width = modelScale.width;
      canvas.height = modelScale.height;
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      ctx?.drawImage(image, 0, 0, image.width, image.height, (modelScale.width - width) / 2, (modelScale.height - height) / 2, width, height);
      var sticker = trimCanvas(canvas);
      if (!sticker) {
        return;
      }
      this.stickerInput.value = [...this.stickerInput.value, sticker];
    });

  }

  onCloudStickerSelected(asset: UserAssetListItem) {
    this.http.get(`/user/assets/${asset.id}`, { responseType: 'blob' })
      .subscribe({
        next: (result: Blob) => {
          this.onStickerFileChanged(result);
        },
      });
  }

  private notifyStateChanged() {
    let params = [];

    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }

    let inputImageFilename: string | undefined = undefined;
    blob = this.inputImageBlob;
    if (!!blob) {
      inputImageFilename = 'input_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], inputImageFilename));
    }

    params.push({
      // returnTo: this.returnTo,
      modelScale: this.modelScale,
      prompts: this.prompts,
      negativePrompts: this.negativePrompts,
      scale: this.scale,
      pathType: this.pathType,
      quantity: this.quantity,
      paintMode: this.paintMode,
      selectedImageIndex: this.selectedImageIndex,
      paintedImages: this.paintedImages,
      rawImageUrl: rawImageFilename,
      inputImageUrl: inputImageFilename,
    });
    this.stateChanged.emit(params);
  }

  restoreState(data: any, relativeUrl: string) {
    if (!data) {
      this.reset();
      return;
    }
    this.inputImageLoaded = false;
    // this.returnTo = data.returnTo;
    this.modelScale = data.modelScale;
    this.prompts = data.prompts;
    this.negativePrompts = data.negativePrompts;
    this.scale = data.scale;
    this.pathType = data.pathType;
    this.quantity = data.quantity;
    this.paintMode = data.paintMode;
    // this.selectedImageIndex= data.selectedImageIndex;
    this.paintedImages = PaintedImage.fromArray(data.paintedImages ?? [], this.injector, (success, index) => {
      if (!success || index != data.selectedImageIndex) {
        return;
      }
      this.selectedImageIndex = data.selectedImageIndex;
    });

    let rawImageUrl = `${relativeUrl}/${data.rawImageUrl}`;
    this.http.get(rawImageUrl, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.rawImageBlob = result;
      },
    });

    let inputImageUrl = `${relativeUrl}/${data.inputImageUrl}`;
    this.http.get(inputImageUrl, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.inputImageBlob = result;
        this.selectedImageIndex = data.selectedImageIndex;
      },
    });
  }

  segmentPickerClick() {
    let imageUrl = this.inputImageUrl;
    if (!imageUrl) {
      return;
    }
    this.dialog.open(ImageSegmentPicker, {
      disableClose: true,
      data: {
        imageUrl: imageUrl,
      },
    }).afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      // this.stickers.value = [...this.stickers.value, ...result.stickers];
    });
  }

  reloadRawImageClick() {
    this.resetPicture();
    this.inputImageBlob = this.rawImageBlob;
    this.selectedImageIndex = undefined;
  }

  paintModelList: SelectListItem[] = [
    { key: PaintMode.Replace, value: 'Partial Replacement' },
    { key: PaintMode.Add, value: 'Local addition' },
    { key: PaintMode.Remove, value: 'Local removal' },
    // { key: PaintMode.Zoom, value: 'Image Enlargement' },
    // { key: PaintMode.Merge, value: 'Adding Textures' },
  ];

  paintMode?: PaintMode = PaintMode.Replace;
  paintModeChanged(paintMode: string | undefined) {
    this.paintMode = paintMode as PaintMode;
    switch (paintMode) {
      case PaintMode.Merge:
        this.maskInput.reset();
        // this.inputZoneExpanded = true;
        // this.inputZoneVisible = true;
        break;
      case PaintMode.Zoom:
        this.maskInput.reset();
        this.stickerInput.reset();
        // this.inputZoneExpanded = true;
        // this.inputZoneVisible = true;
        break;
      case PaintMode.Replace:
        this.stickerInput.reset();
        break;
      case PaintMode.Add:
        this.stickerInput.reset();
        break;
      case PaintMode.Remove:
        this.stickerInput.reset();
        break;
    }
  }

  // pathTypeList: any[] = [
  //   { key: PathTypes.EraseBrush, value: 'brush', name: 'Brush Tool' },
  //   { key: PathTypes.FreeLine, value: 'gesture', name: 'Free constituency' },
  //   { key: PathTypes.Rect, value: 'crop_square', name: 'Rectangular Selection' },
  //   { key: PathTypes.Circle, value: 'radio_button_unchecked', name: 'Ellipse selection' },
  //   // { key: PathTypes.Sticker, value: 'add_photo_alternate' },
  // ];

  pathType?: PathTypes = PathTypes.EraseBrush;
  pathTypeChanged(pathType: string | undefined) {
    this.pathType = pathType as PathTypes;
  }

  // get pathTypeVisible() {
  //   return this.paintMode == PaintMode.Replace || this.paintMode == PaintMode.Add || this.paintMode == PaintMode.Remove;
  // }

  get inputImage() {
    return this.inputImageRef.nativeElement;
  }

  get imageAspect() {
    return !this.modelScale ? 'auto' : `${this.modelScale.width}/${this.modelScale.height}`;
  }

  // cancelClick() {
  //   this.maskInput.reset();
  //   this.stickerInput.reset();
  //   this.inputZoneVisible = false;
  // }

  expandedChanged(expanded: boolean) {
    this.expanded = expanded;
    // this.expandedChange.emit(expanded);
  }

  backClick() {
    if (this.returnTo == undefined) {
      return;
    }
    this.completed.emit({
      next: this.returnTo,
    });
  }

  uploadRawImageClick() {
    this.rawImagePicker.click();
  }

  rawImageChanged(blob: Blob | undefined) {
    if (!blob) {
      return;
    }
    this.init(blob, true, this.returnTo);
  }
}
