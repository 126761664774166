import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { AppHttpClient } from '../common/app.http';

@Component({
  selector: 'user-artifact-history',
  templateUrl: './user-artifact-history.html',
  styleUrl: './user-artifact-history.scss',
})
export class UserArtifactHistory extends BaseComponent implements OnInit {
  // categories: UserAssetCategoryListItem[] = [];

  constructor(
    injector: Injector,
    private http: AppHttpClient,
  ) {
    super(injector);
  }

  ngOnInit() {
    // this.http.get('/user/assets').subscribe({
    //   next: (result: ActionResult) => {
    //     if (!this.http.isValidResult(result)) {
    //       return;
    //     }
    //     result.data.forEach((element: any) => {
    //       element.name = UserAssetCategoryName.get(element.id);
    //     });
    //     this.categories = result.data;
    //     this.categories.forEach(category => {
    //       category.groups.forEach(group => {
    //         group.assets.forEach(asset => {
    //           this.http.get(`/user/assets/${asset.id}/thumbnail`, { responseType: 'blob' }).subscribe({
    //             next: (result: Blob) => {
    //               asset.url = URL.createObjectURL(result);
    //             },
    //           });
    //         });
    //       });
    //     });
    //   }
    // });
  }
}