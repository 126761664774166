import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '../common/base.component';
import { AppHttpClient } from '../common/app.http';
import { MatDialog } from '@angular/material/dialog';
import { MembershipPurchaseDialog } from './membership-purchase-dialog';
import { PointsPurchaseDialog } from './points-purchase-dialog';
import { OrderItemTypes } from '../models';

export interface DialogData {
  type: string;
  price: number;
}

@Component({
  selector: 'my-membership',
  templateUrl: './my-membership.component.html',
  styleUrl: './my-membership.component.scss',
})
export class MyMembershipComponent extends BaseComponent implements OnInit {
  /*
   * [Function Name, Trial Version, Professional Edition Monthly Subscription, Professional Edition Yearly Package, Team Edition Monthly Subscription, Team Edition Annual Package, Customized version]
   * true: efficient, false: invalid, undefined: Do not display
   */
  features = [
    ["1000gold（Valid for one month，Daily usage limit200gold）", true, undefined, undefined, undefined, undefined, undefined],
    ["20000gold/moon", undefined, true, true, undefined, undefined, undefined],
    ["90000gold/moon", undefined, undefined, undefined, true, true, undefined],
    ["SD image download", true, undefined, undefined, undefined, undefined, undefined],
    ["Single task generation", true, undefined, undefined, undefined, undefined, undefined],
    ["HD(2K)Image Download", false, true, true, true, true, true],
    ["Batch Generation", false, true, true, true, true, true],
    ["Custom scenes", false, true, true, true, true, undefined],
    ["Custom Models", false, true, true, true, true, undefined],
    ["VIPDedicated customer service", false, false, false, true, true, true],
    ["Customize enterprise-specific scenarios", undefined, false, false, false, false, true],
    ["Customized enterprise exclusive model", undefined, false, false, false, false, true],
    ["Computing power guarantee", false, false, false, false, false, true],
  ];
  memberships = [
    undefined,
    { name: 'Trial Version', price: 'free', period: '1Month' },
    { name: 'Professional Edition', price: 'Payment', period: '1Month' },
    { name: 'Professional Edition', price: 'Payment', period: '1Year' },
    { name: 'Team Edition', price: 'Payment', period: '1Month' },
    { name: 'Team Edition', price: 'Payment', period: '1Year' },
    { name: 'Customized version', price: 'Payment', period: 'Agreement' },
  ]

  OrderItemTypesEnum = OrderItemTypes;
  personMembership: OrderItemTypes = OrderItemTypes.PersonMonth;
  teamMembership: OrderItemTypes = OrderItemTypes.TeamMonth;
  points?: number;
  totalPoints?: number;
  accountType?: string;
  memberStartDate?: string;
  memberEndDate?: string;
  membership?: any;

  constructor(injector: Injector, private http: AppHttpClient, public dialog: MatDialog) {
    super(injector);
    this.sessionService.getUser().subscribe(user => {
      if (!user) {
        return;
      }
      this.points = user.points;
      this.totalPoints = user.totalPoints;
      this.accountType = user.accountType;
      this.memberStartDate = user.memberStartDate;
      this.memberEndDate = user.memberEndDate;
      this.membership = this.memberships[this.caculateMetaIndex(this.accountType)];
    });
  }

  ngOnInit() {

  }

  fetchAccounttype() {
    return this.http.get('/account/type');
  }

  purchaseMembershipClick(orderItemType?: OrderItemTypes) {
    this.dialog.open(MembershipPurchaseDialog, {
      disableClose: true,
      data: orderItemType,
    });
  }

  purchasePointsClick(orderItemType?: OrderItemTypes) {
    this.dialog.open(PointsPurchaseDialog, {
      disableClose: true,
      data: orderItemType,
    });
  }

  getFeatures(orderItemType?: string) {
    if (!orderItemType) {
      orderItemType = OrderItemTypes.Free;
    }
    let idx = this.caculateMetaIndex(orderItemType);
    return this.features.filter(o => o[idx] !== undefined).map(o => [o[0], o[idx]]);
  }

  getEnabledFeatures(orderItemType: string) {
    let idx = this.caculateMetaIndex(orderItemType);
    return this.features.filter(o => o[idx] === true).map(o => [o[0], o[idx]]);
  }

  private caculateMetaIndex(orderItemType: string): number {
    switch (orderItemType) {
      case OrderItemTypes.Free:
        return 1;
      case OrderItemTypes.PersonMonth:
        return 2;
      case OrderItemTypes.PersonYear:
        return 3;
      case OrderItemTypes.TeamMonth:
        return 4;
      case OrderItemTypes.TeamYear:
        return 5;
      case OrderItemTypes.Customize:
        return 6;
      default:
        return 1;
    }
  }

  personMembershipClick() {
    if (this.personMembership == OrderItemTypes.PersonMonth) {
      this.personMembership = OrderItemTypes.PersonYear;
    } else {
      this.personMembership = OrderItemTypes.PersonMonth;
    }
  }

  teamMembershipClick() {
    if (this.teamMembership == OrderItemTypes.TeamMonth) {
      this.teamMembership = OrderItemTypes.TeamYear;
    } else {
      this.teamMembership = OrderItemTypes.TeamMonth;
    }
  }

}
