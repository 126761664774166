import { Component, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { TaskBaseComponent } from '../common/task-base.component';
import { PaintedImage, TaskCategory, UserNotificationCategory, } from '../models';
import { ModelScaleProps, } from '../interfaces';
import { ActionStatus, AppHttpClient } from '../common/app.http';
import { MediaTypeHelper, CanvasHelper } from '../helpers/app.helpers';
import { MatDialog } from '@angular/material/dialog';
import { RxStompService } from '../stomp/rx-stomp.service';
import { validRange } from '../common/app.forms';
import { SimiGenerateService } from '../services/simigenerate.serve';
import { PaintedImageList } from '../components/painted-image-list';
import { ImagePicker } from '../image-picker/image-picker';
import { ImageSegment, ProcessDisplayMode, SegmentClickTypes, SegmentResults } from '../image-segment/image-segment';
import { UserTaskList } from '../user-task/user-task-list';
import { SegmentAlertBox } from '../image-segment/segment-alert-box';

@Component({
  selector: 'similar-image-design',
  templateUrl: './similar-image-design.html',
  styleUrl: './similar-image-design.scss',
})

export class SimilarImageDesignComponent extends TaskBaseComponent implements OnInit {
  TaskCategoryEnum = TaskCategory;

  SegmentClickTypesEnum = SegmentClickTypes;

  @ViewChild('userTaskList') userTaskList!: UserTaskList;
  @ViewChild('rawImagePicker') rawImagePicker!: ImagePicker;
  @ViewChild('imageSegment') imageSegment!: ImageSegment;

  selectedUserAssetId?: string;

  imagePickerCompleted: boolean = false;

  _rawImageBlob?: Blob;
  set rawImageBlob(blob: Blob | undefined) {
    this.rawImageUrl = null;
    this._rawImageBlob = blob;
    if (!this._rawImageBlob) {
      return;
    }
    this.rawImageUrl = URL.createObjectURL(this._rawImageBlob);
  }
  get rawImageBlob(): Blob | undefined {
    return this._rawImageBlob;
  }

  similarity: string = '0.7';

  minQuantity: number = 1;
  maxQuantity: number = 8;
  quantity: string = '1';

  maxWidth: number = 768;
  maxHeight: number = 768;

  _expectedWidth: string | undefined = undefined;
  _expectedHeight: string | undefined = undefined;

  rawImageParamsBeforeSave: any[] = [];

  set expectedWidth(width: string) {
    let scale = Number(width) / Number(this._expectedWidth);
    this._expectedWidth = width;
    this._expectedHeight = Math.floor(Number(this._expectedHeight) * scale).toString();
    /*if (!!this.imageSegment.modelScale) {
      this.imageSegment.modelScale = {width: Number(width), height: Number(this._expectedHeight), SamScale:1};
    }*/
  }

  get expectedWidth(): string | undefined {
    return this._expectedWidth;
  }

  set expectedHeight(height: string) {
    let scale = Number(height) / Number(this._expectedHeight);
    this._expectedHeight = height;
    this._expectedWidth = Math.floor(Number(this._expectedWidth) * scale).toString();
    /*if (!!this.imageSegment.modelScale) {
      this.imageSegment.modelScale = {width: Number(this._expectedWidth), height: Number(height), SamScale:1};
    }*/
  }

  get expectedHeight(): string | undefined {
    return this._expectedHeight;
  }

  dpi: string = '72';
  minDpi: number = 72;
  maxDpi: number = 300;

  prompts?: string;

  negativePrompts?: string;

  imageFormat: string = 'rgb';

  taskListExpanded: boolean = true;
  inputExpanded: boolean = true;

  gridWidth: number = 250;
  gridHeight: number = 250;

  processDisplayMode: ProcessDisplayMode = ProcessDisplayMode.dialog;
  userNotificationCatecory: UserNotificationCategory = UserNotificationCategory.SimiGenerate;

  rawImageUrl: string | null = null;
  paintedImages: PaintedImage[] = [];
  @ViewChild('paintedImageList') paintedImageList!: PaintedImageList;


  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
    private rxStompService: RxStompService,
    private simiGenerateService: SimiGenerateService,
  ) {
    super(injector);
  }

  ngOnInit() {

  }

  override getUserTaskList(): UserTaskList {
    return this.userTaskList;
  }

  simiInputInit() {
    this.prompts = '';
    this.negativePrompts = '';
    this.similarity = '0.65';
    this.imageFormat = 'rgb';
    this.dpi = '72';
    this.quantity = '4';
  }

  override reset() {
    if (!!this.rawImagePicker) {
      this.rawImagePicker.reset();
    }

    this.imageSegment.resetSegment();

    this.paintedImages = [];

    this.rawImageBlob = undefined;

    this.imagePickerCompleted = false;

    this.simiInputInit();

    this.taskListExpanded = true;
    this.inputExpanded = true;
  }

  override saveTaskState() {

    let params = [];

    let stickersFilename: string | undefined = undefined;
    let jsonText = JSON.stringify(this.imageSegment.stickers.getState());
    if (!!jsonText) {
      stickersFilename = 'stickers.json';
      params.push(new File([jsonText], stickersFilename));
    }

    params.push({
      imagePickerCompleted: this.imagePickerCompleted,
      prompts: this.prompts,
      negativePrompts: this.negativePrompts,
      similarity: this.similarity,
      imageFormat: this.imageFormat,
      expectedWidth: this.expectedWidth,
      expectedHeight: this.expectedHeight,
      dpi: this.dpi,
      quantity: this.quantity,
      paintedImages: this.paintedImages,
      stickersUrl: stickersFilename,
      //modelScale: this.imageSegment.modelScale,
      taskListExpanded: this.taskListExpanded,
      inputExpanded: this.inputExpanded,
    });

    this.patchTaskState(params);
  }

  override restoreTaskState(): void {
    this.fetchTaskState().subscribe((data: any) => {
      if (!data) {
        this.reset();
        return;
      }


      this.imageSegment.resetMask();

      this.imagePickerCompleted = data.imagePickerCompleted ?? false;
      this.prompts = data.prompts;
      this.negativePrompts = data.negativePrompts;
      this.similarity = data.similarity;
      this.imageFormat = data.imageFormat;
      this._expectedWidth = data.expectedWidth;
      this._expectedHeight = data.expectedHeight;
      //this.imageSegment.modelScale = data.modelScale;
      this.dpi = data.dpi;
      this.quantity = data.quantity;
      this.paintedImages = PaintedImage.fromArray(data.paintedImages ?? [], this.injector);
      this.taskListExpanded = data.taskListExpanded ?? true;
      this.inputExpanded = data.inputExpanded ?? true;
      this.calcGrid(this.gridWidth, this.gridWidth);

      let restoringTaskId = this.taskId;
      let rawImageUrl = `${this.taskStateUrl}/${data.rawImageUrl}`;
      this.http.get(rawImageUrl, { responseType: 'blob' }).subscribe({
        next: (result: Blob) => {
          this.rawImageBlob = result;
          this.imageSegment.rawImageUrl = this.rawImageUrl;

          let segmentTaskId = data.segmentTaskId;
          this.imageSegment.restoreSegment(segmentTaskId);

          if (this.imagePickerCompleted) {

            let stickers = this.imageSegment.stickers;

            if (!!stickers) {
              this.restoreStickersFromUrl(stickers, data.stickersUrl).subscribe({
                next: (result: any) => {
                  stickers.setState(result);
                }
              })
            }


          }

        },
      });


    });
  }

  calcGrid(width: number, height: number) {
    this.paintedImageList.calcFixedGrid(this.quantity, width, height);
  }

  resetPaintedList() {

    this.paintedImages = [];
    let params = [];
    params.push({
      paintedImages: this.paintedImages,
    });

    this.patchTaskState(params);

  }

  rawImageChangedReset() {
    this.imageSegment.resetSegment();

    this.resetPaintedList();

    this.StickerChanged();
  }

  rawImageChanged(file: Blob | undefined) {
    if (!file) {
      return;
    }

    this.rawImageChangedReset();

    this.rawImageBlob = file;
    this.rawImageUrl = URL.createObjectURL(this.rawImageBlob);

    let params = [];
    let rawImageFilename: string | undefined = undefined;
    let blob = this.rawImageBlob;
    if (!!blob) {
      rawImageFilename = 'raw_image' + MediaTypeHelper.resolveFileExtension(blob.type);
      params.push(new File([blob], rawImageFilename));
    }
    params.push({
      rawImageUrl: rawImageFilename,
    });

    //this.patchTaskState(params); save raw image after predict complete with segment information. see predictCompleted().
    this.rawImageParamsBeforeSave = params;

    setTimeout(() => {
      this.imageSegment.predict();
    }, 0);

  }

  saveSegment(segmentTaskId: string, params: any[]) {
    if (!segmentTaskId) {
      return;
    }

    params.push({
      segmentTaskId: segmentTaskId,
    });

    this.patchTaskState(params);
    this.saveTaskThumbnail(this.rawImageBlob);

  }

  StickerChanged() {
    let params = [];

    let stickersFilename: string | undefined = undefined;
    let jsonText = JSON.stringify(this.imageSegment.stickers.getState());
    if (!!jsonText) {
      stickersFilename = 'stickers.json';
      params.push(new File([jsonText], stickersFilename));
    }

    params.push({
      stickersUrl: stickersFilename,
    });

    this.patchTaskState(params);
  }

  pickupClick() {
    this.imageSegment.pickup();

    if (!!this.imageSegment.stickers) {
      this.StickerChanged();
    }
  }

  saveImageDimension() {
    let params = [];
    params.push({
      expectedWidth: this.expectedWidth,
      expectedHeight: this.expectedHeight,
    })
    this.patchTaskState(params);
  }

  responsiveScaling() {

    if (validRange(this.expectedWidth, 1, this.maxWidth) && validRange(this.expectedHeight, 1, this.maxHeight)) {
      return;
    }

    let width = Number(this.expectedWidth), height = Number(this.expectedHeight);

    let widthScale = this.maxWidth / width, heightScale = this.maxHeight / height;
    if (widthScale < heightScale) {
      this.expectedWidth = this.maxWidth.toString();
    } else {
      this.expectedHeight = this.maxHeight.toString();
    }
  }

  inputValidCheck(): boolean {
    /*if (!this.prompts) {
      this.showError('Please enter a description of the scene。');
      return;
    }*/

    let stickers = this.imageSegment.stickers;

    if (!stickers || stickers.value.length === 0) {
      this.showError('Please extract the image and click Generate。');
      return false;
    }

    if (!this.quantity || !validRange(this.quantity, this.minQuantity, this.maxQuantity)) {
      this.showError(`Please specify the number of builds，The range is${this.minQuantity}～${this.maxQuantity}。`);
      return false;
    }

    if (!this.dpi || !validRange(this.dpi, this.minDpi, this.maxDpi)) {
      this.showError('Please specify the range72~300(dpi)Resolution。');
      return false;
    }

    if (!this.expectedWidth) {// || !validRange(this.expectedWidth, 1, this.maxWidth)) {
      this.showError('Please specify the range1~768Image width。');
      return false;
    }

    if (!this.expectedHeight) {//|| !validRange(this.expectedHeight, 1, this.maxHeight)) {
      this.showError('Please specify the range1~768Image height。');
      return false;
    }

    return true;

  }

  generateClick() {

    if (!this.inputValidCheck()) {
      return;
    }

    if (!validRange(this.expectedWidth, 1, this.maxWidth) || !validRange(this.expectedHeight, 1, this.maxHeight)) {
      this.showInformation('The image size you entered does not meet the requirements，The image size has been automatically adapted for you。');
    }

    this.responsiveScaling();

    this.saveTaskState();

    let stickers = this.imageSegment.stickers;

    CanvasHelper.resizeCanvas(CanvasHelper.trimCanvas(stickers.drawToCanvas()), Number(this.expectedWidth), Number(this.expectedHeight), 0.1).toBlob((rawImageBlob) => {
      //stickers.drawToCanvas().toBlob((rawImageBlob) => {

      if (!rawImageBlob) {
        rawImageBlob = this.rawImageBlob!;
      }

      if (this.validateProcessing()) {
        return;
      }
      this.processing = true;

      this.taskListExpanded = false; // Hide Input Panel


      let fileName = 'raw_image' + MediaTypeHelper.resolveFileExtension(rawImageBlob!.type);

      let formData = new FormData();
      if (!this.prompts) {
        if (!this.negativePrompts) {
          formData.set('prompts', '');
        }
        else {
          formData.set('prompts', ' ');
        }
      } else {
        formData.set('prompts', this.prompts);
      }

      formData.set('negativePrompts', this.negativePrompts ?? '');
      if (!!rawImageBlob) {
        formData.set('imageFile', rawImageBlob, fileName);
      }

      formData.set('width', this.expectedWidth!);
      formData.set('height', this.expectedHeight!);
      formData.set('similarity', this.similarity);
      formData.set('dpi', this.dpi);
      formData.set('quantity', this.quantity);
      formData.set('imageFormat', this.imageFormat);

      //formData.set('mode', 'background'); //TODO:: Later modified totxt2img

      let paintedImages = this.simiGenerateService.generate(formData);
      let zone = this.injector.get(NgZone);
      zone.run(() => {
        this.paintedImages = [...paintedImages, ...this.paintedImages];
        this.calcGrid(this.gridWidth, this.gridHeight);
      });

    });

  }

  onPaintCompleted() {
    let paintedImages = this.paintedImages;
    if (!paintedImages) {
      return;
    }

    for (let i = 0; i < paintedImages.length; i++) {
      if (paintedImages[i].result?.status == ActionStatus.Running) {
        return;
      }
    }
    this.processing = false;

    this.saveTaskState();
  }

  imageSimiChanged(simi: string) {
    this.similarity = simi;
  }

  imageFormatChanged(imageFormat: string) {
    this.imageFormat = imageFormat;
  }

  widthChanged(width: string) {
    this._expectedWidth = width;
  }

  heightChanged(height: string) {
    this._expectedHeight = height;
  }

  dpiChanged(dpi: string) {
    this.dpi = dpi;
  }

  modelScaleChanged(modelScale: ModelScaleProps) {

    if (this.isRestoring()) {
      return;
    }

    this._expectedWidth = modelScale.width.toString();
    this._expectedHeight = modelScale.height.toString();
    this.responsiveScaling();
    this.saveImageDimension();
  }

  openHelpInfoDialog() {
    this.dialog.open(SegmentAlertBox, {

    })
  }

  predictCompleted(segmentTaskId: string) {

    if (segmentTaskId === SegmentResults.fail) {
      if (!this.imagePickerCompleted) {
        this.rawImagePicker.reset();
      }
      else {

      }
      return;
    }

    if (!this.isRestoring()) {
      this.saveSegment(segmentTaskId, this.rawImageParamsBeforeSave);
    }

    if (!this.imagePickerCompleted) {
      this.imagePickerCompleted = true;
      this.saveSegment(segmentTaskId, this.rawImageParamsBeforeSave);
      this.simiInputInit();
      this.saveTaskState();
    }

    this.restoreComplete();
  }


}



