import { Point } from "../interfaces";
import { convertSegmentsToSVG, generatePolygonSegments } from "./custom-tracer";


export function maskDataToFortranArrayToRle(
    input: any,
    nrows: number,
    ncols: number
  ) {
    const result: number[] = [];
    let count = 0;
    let bit = false;
    for (let c = 0; c < ncols; c++) {
      for (let r = 0; r < nrows; r++) {
        var i = c + r * ncols;
        if (i < input.length) {
          const filled = input[i] > 0.0;
          if (filled !== bit) {
            result.push(count);
            bit = !bit;
            count = 1;
          } else count++;
        }
      }
    }
    if (count > 0) result.push(count);
    return result;
  }

  export function traceRleToSVG(
    rleMask:
      | Array<number>
      | string[]
      | Uint8Array
      | Float32Array
      | Int8Array
      | Uint16Array
      | Int16Array
      | Int32Array
      | BigInt64Array
      | Float64Array
      | Uint32Array
      | BigUint64Array,
    height: number
  ) {
    const polySegments = generatePolygonSegments(rleMask, height);
    const svgStr = convertSegmentsToSVG(polySegments);
    return svgStr;
  }

  export function areaUnderLine(x0: number, y0: number, x1: number, y1: number) {
    // A vertical line has no area
    if (x0 === x1) return 0;
    // Square piece
    const ymin = Math.min(y0, y1);
    const squareArea = (x1 - x0) * ymin;
    // Triangle piece
    const ymax = Math.max(y0, y1);
    const triangleArea = Math.trunc((x1 - x0) * (ymax - ymin) / 2);
    return squareArea + triangleArea;
  }

  export function svgCoordToInt(input: string) {
    if ((input.charAt(0) === "L") || (input.charAt(0) === "M")) {
      return parseInt(input.slice(1));
    }
    return parseInt(input);
  }

  export function areaOfSVGPolygon(input: string) {
    let coords = input.split(" ");
    if (coords.length < 4) return 0;
    if (coords.length % 2 != 0) return 0;
    let area = 0;
    // We need to close the polygon loop, so start with the last coords.
    let old_x = svgCoordToInt(coords[coords.length - 2]);
    let old_y = svgCoordToInt(coords[coords.length - 1]);
    for (let i = 0; i < coords.length; i = i + 2) {
      let new_x = svgCoordToInt(coords[i]);
      let new_y = svgCoordToInt(coords[i + 1]);
      area = area + areaUnderLine(old_x, old_y, new_x, new_y);
      old_x = new_x;
      old_y = new_y;
    }
    return area;
  }

  
  export function filterSmallSVGRegions(
    input: string[], maxRegionSize: number = 100
  ) {
    const filtered_regions = input.filter(
      (region: string) => Math.abs(areaOfSVGPolygon(region)) > maxRegionSize
    );
    if (filtered_regions.length === 0) {
      const areas = input.map((region: string) => areaOfSVGPolygon(region));
      const bestIdx = areas.indexOf(Math.max(...areas));
      return [input[bestIdx]];
    }
    return filtered_regions;
  }

  
  export function traceOnnxMaskToSVG(
    maskData:
      | string[]
      | Uint8Array
      | Uint8ClampedArray
      | Float32Array
      | Int8Array
      | Uint16Array
      | Int16Array
      | Int32Array
      | BigInt64Array
      | Float64Array
      | Uint32Array
      | BigUint64Array,
    height: number,
    width: number
  ) {
    const rleMask = maskDataToFortranArrayToRle(maskData, width, height);
    let svgStr = traceRleToSVG(rleMask, width);
    svgStr = filterSmallSVGRegions(svgStr);
    return svgStr;
  }

  
  export function imageDataToCanvas(imageData: ImageData) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx?.putImageData(imageData, 0, 0);
    return canvas;
  }

  export function distanceBetween(point1: Point, point2: Point) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
  }

  export function angleBetween(point1: Point, point2: Point) {
    return Math.atan2( point2.x - point1.x, point2.y - point1.y );
  }