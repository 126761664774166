<div class="d-flex flex-row p-2">
  <div mat-dialog-title>Buy Smart Beans</div>
  <div class="flex-1"></div>
  <div class="btn-dialog-close">
    <button mat-icon-button class="btn-sm" color="accent" (click)="cancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content class="d-flex px-3">

  <div class="mx-2">
    <div>
      <div class="membership-item px-3 py-4 mb-3" [class.active]="orderItem==OrderItemTypesEnum.Points1000" matRipple
        (click)="orderItemChanged(OrderItemTypesEnum.Points1000)">
        <div class="d-flex align-items-center">
          <mat-icon color="accent" svgIcon="app:digital_money"></mat-icon>
          <span class="fw-bold">1000Smart Beans</span>
        </div>
        <div class="price my-3">￥20</div>
        <div>About generation500Zhang Tu，Clear after package expires</div>
      </div>
      <div class="membership-item px-3 py-4 mb-3" [class.active]="orderItem==OrderItemTypesEnum.Points6000" matRipple
        (click)="orderItemChanged(OrderItemTypesEnum.Points6000)">
        <div class="d-flex align-items-center">
          <mat-icon color="accent" svgIcon="app:digital_money"></mat-icon>
          <span class="fw-bold">6000Smart Beans</span>
        </div>
        <div class="price my-3">￥100</div>
        <div>About generation5000Zhang Tu，Clear after package expires</div>
      </div>
      <div class="membership-item px-3 py-4 mb-3" [class.active]="orderItem==OrderItemTypesEnum.Points10000" matRipple
        (click)="orderItemChanged(OrderItemTypesEnum.Points10000)">
        <div class="d-flex align-items-center">
          <mat-icon color="accent" svgIcon="app:digital_money"></mat-icon>
          <span class="fw-bold">10000Smart Beans</span>
        </div>
        <div class="price my-3">￥150</div>
        <div>About generation1000Zhang Tu，Clear after package expires</div>
      </div>
    </div>
  </div>

  <div class="qrcode-container background-accent mx-2 rounded-2">
    <div class="m-auto d-flex flex-column align-items-center fs-large" *ngIf="!orderItem">
      <div class="d-flex align-items-center">
        <mat-icon class="m-2" svgIcon="app:hand-point-left"></mat-icon>
        <span>Please click on the left,</span>
      </div>
      <div><span>Select the number of Smart Beans you want to purchase</span></div>
    </div>
    <div *ngIf="!!orderItem">
      <div>
        <div class="qrcode mx-4 my-3" [class.background-white]="!url">
          <div class="m-auto" *ngIf="!url"><mat-spinner></mat-spinner></div>
          <img [src]="url" *ngIf="!!url" />
        </div>
      </div>
      <div class="text-white text-center my-4">
        <span>Scan code to pay：</span><span class="price">￥{{getPrice()}}</span>
      </div>
      <div class="d-flex my-2">
        <div class="d-flex align-items-center m-auto">
          <mat-icon class="me-2" svgIcon="app:wechat"></mat-icon>
          <span class="text-white">Please use WeChat to scan the QR code to pay</span>
        </div>
      </div>
      <div class="d-flex">
        <div class="fs-small m-auto">
          <span>After payment is completed, you agree</span><a class="text-primary" href="#">Related Agreement Terms</a>
        </div>
      </div>
      <div class="text-center fs-smaller my-4">
        <span>Virtual Goods，No refunds，The final right of interpretation belongs to Zhuanxinzhizhi</span>
      </div>
    </div>
  </div>

</mat-dialog-content>

<!-- <mat-dialog-actions>
  <div class="flex-grow-1"></div>
  <button mat-stroked-button class="btn-min-width" [mat-dialog-close]="false">Cancel</button>
  <div class="flex-grow-1"></div>
  <button mat-flat-button class="btn-min-width" color="primary" (click)="withdrawClick()">confirm</button>
  <div class="flex-grow-1"></div>
</mat-dialog-actions> -->
