import { Injectable, Injector } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BASE_URL } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  baseUrl: string;
  private icons = [
    { namespace: 'app', iconName: 'account_balance', iconUrl: 'assets/icons/account_balance.svg' },
    { namespace: 'app', iconName: 'add_task', iconUrl: 'assets/icons/add_task.svg' },
    { namespace: 'app', iconName: 'agile', iconUrl: 'assets/icons/agile.svg' },
    { namespace: 'app', iconName: 'arrow_left', iconUrl: 'assets/icons/arrow_left.svg' },
    { namespace: 'app', iconName: 'arrow_next', iconUrl: 'assets/icons/thumbs_down.svg' },
    { namespace: 'app', iconName: 'arrow_previous', iconUrl: 'assets/icons/thumbs_down.svg' },
    { namespace: 'app', iconName: 'arrow_right', iconUrl: 'assets/icons/arrow_right.svg' },
    { namespace: 'app', iconName: 'benefits', iconUrl: 'assets/icons/benefits.svg' },
    { namespace: 'app', iconName: 'check_circle', iconUrl: 'assets/icons/check_circle.svg' },
    { namespace: 'app', iconName: 'cloth', iconUrl: 'assets/icons/cloth.svg' },
    { namespace: 'app', iconName: 'clothing-both', iconUrl: 'assets/icons/clothing-both.svg' },
    { namespace: 'app', iconName: 'clothing-bottoms', iconUrl: 'assets/icons/clothing-bottoms.svg' },
    { namespace: 'app', iconName: 'clothing-jumpsuit', iconUrl: 'assets/icons/clothing-jumpsuit.svg' },
    { namespace: 'app', iconName: 'clothing-tops', iconUrl: 'assets/icons/clothing-tops.svg' },
    { namespace: 'app', iconName: 'clothing-type', iconUrl: 'assets/icons/clothing-type.svg' },
    { namespace: 'app', iconName: 'cooperation', iconUrl: 'assets/icons/cooperation.svg' },
    { namespace: 'app', iconName: 'custom_service', iconUrl: 'assets/icons/custom_service.svg' },
    { namespace: 'app', iconName: 'delete', iconUrl: 'assets/icons/delete.svg' },
    { namespace: 'app', iconName: 'digital_money', iconUrl: 'assets/icons/digital_money.svg' },
    { namespace: 'app', iconName: 'download', iconUrl: 'assets/icons/download.svg' },
    { namespace: 'app', iconName: 'edit', iconUrl: 'assets/icons/edit.svg' },
    { namespace: 'app', iconName: 'favorite_like', iconUrl: 'assets/icons/favorite_like.svg' },
    { namespace: 'app', iconName: 'favorite_unlike', iconUrl: 'assets/icons/favorite_unlike.svg' },
    { namespace: 'app', iconName: 'hand-point-left', iconUrl: 'assets/icons/hand-point-left.svg' },
    { namespace: 'app', iconName: 'home', iconUrl: 'assets/icons/home.svg' },
    { namespace: 'app', iconName: 'image', iconUrl: 'assets/icons/image.svg' },
    { namespace: 'app', iconName: 'image_library', iconUrl: 'assets/icons/image_library.svg' },
    { namespace: 'app', iconName: 'image_favorite', iconUrl: 'assets/icons/image_favorite.svg' },
    { namespace: 'app', iconName: 'intelligence', iconUrl: 'assets/icons/intelligence.svg' },
    { namespace: 'app', iconName: 'membership', iconUrl: 'assets/icons/membership.svg' },
    { namespace: 'app', iconName: 'model', iconUrl: 'assets/icons/model.svg' },
    { namespace: 'app', iconName: 'my_favorites', iconUrl: 'assets/icons/my_favorites.svg' },
    { namespace: 'app', iconName: 'notifications', iconUrl: 'assets/icons/notifications.svg' },
    { namespace: 'app', iconName: 'points', iconUrl: 'assets/icons/points.svg' },
    { namespace: 'app', iconName: 'private', iconUrl: 'assets/icons/private.svg' },
    { namespace: 'app', iconName: 'remove_circle', iconUrl: 'assets/icons/remove_circle.svg' },
    { namespace: 'app', iconName: 'reupload', iconUrl: 'assets/icons/reupload.svg' },
    { namespace: 'app', iconName: 'scence', iconUrl: 'assets/icons/scence.svg' },
    { namespace: 'app', iconName: 'settings', iconUrl: 'assets/icons/settings.svg' },
    { namespace: 'app', iconName: 'my_tasks', iconUrl: 'assets/icons/my_tasks.svg' },
    { namespace: 'app', iconName: 'tools', iconUrl: 'assets/icons/tools.svg' },
    { namespace: 'app', iconName: 'thumbs_down', iconUrl: 'assets/icons/thumbs_down.svg' },
    { namespace: 'app', iconName: 'thumbs_up', iconUrl: 'assets/icons/thumbs_up.svg' },
    { namespace: 'app', iconName: 'upload', iconUrl: 'assets/icons/upload.svg' },
    { namespace: 'app', iconName: 'wechat', iconUrl: 'assets/icons/wechat.svg' },
  ];

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    injector: Injector,) {
    this.baseUrl = injector.get(BASE_URL);
  }

  registerIcons() {
    this.icons.forEach(icon => {
      this.matIconRegistry.addSvgIconInNamespace(
        icon.namespace, icon.iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.baseUrl}${icon.iconUrl}`),
      );
    })
  }
}