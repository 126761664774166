import { Component, OnInit, ViewChild, Injector, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { SelectListItem } from '../models';
import { MessageResource } from '../common/message.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderWithdrawDialog } from './order-withdraw-dialog';
import { OrderListItem } from '../interfaces';

@Component({
  selector: 'order-search',
  templateUrl: './order-search.component.html',
  styleUrl: './order-search.component.scss',
})
export class OrderSearchComponent extends BaseComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['orderNo', 'username', 'description', 'amount', 'totalPoints', 'points', 'payType', 'payAccount', 'createdDate', 'status', 'employeeName', 'action'];
  statusList?: SelectListItem[];
  inputForm: FormGroup;
  dataSource = new MatTableDataSource<OrderListItem>([]);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    injector: Injector,
    private http: AppHttpClient,
    private dialog: MatDialog,
  ) {
    super(injector);

    this.inputForm = this.formBuilder.group({
      orderNo: new FormControl(),
      username: new FormControl(),
      status: new FormControl(""),
      employee: new FormControl(),
    });
  }

  ngOnInit() {
    this.http.get('/orders/options').subscribe(response => {
      this.statusList = response.data.statusList;
    });

    var preSearchParams = this.sessionService.getItem("admin.orders.search");
    if (!!preSearchParams) {
      this.inputForm.patchValue(preSearchParams);
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchClick() {
    this.inputForm.markAllAsTouched();
    if (!this.inputForm.valid) {
      return;
    }
    this.search()
  }

  search() {
    var params = this.inputForm.value;
    this.http.post('/orders/search', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.dataSource.data = result.data;
        if (this.dataSource.data.length == 0) {
          this.messageService.showInformation(MessageResource.NO_DATA_MACHED);
        } else {
          this.sessionService.setItem("admin.orders.search", params);
        }
      }
    });
  }

  editClick(row: OrderListItem) {
    this.router.navigate([`/admin/orders/${row.id}/edit`]);
  }

  addNewClick() {
    this.router.navigate(['/admin/orders/add']);
  }

  withdrawClick(row: OrderListItem) {
    this.dialog.open(OrderWithdrawDialog, {
      disableClose: true,
      data: {
        id: row.id,
      },
    }).afterClosed().subscribe((success) => {
      if (!success) {
        return;
      }
      this.search();
    });
  }

}

