import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { BaseComponent } from '../common/base.component';
import { MaterialTemplateListItem } from '../interfaces';

@Component({
  selector: 'material-details',
  templateUrl: './material-details.dialog.html',
  styleUrl: './material-details.dialog.scss',
})
export class MaterialDetailsDialog extends BaseComponent implements OnInit {
  url?: string;
  template: any;

  constructor(
    injector: Injector,
    private dialogRef: MatDialogRef<MaterialDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private http: AppHttpClient,) {
    super(injector);
    this.template = data;
  }

  ngOnInit() {
    let id = this.data.id;
    this.http.get(`/material/templates/${id}`, { responseType: 'blob' }).subscribe({
      next: (result: Blob) => {
        this.url = URL.createObjectURL(result);
      },
    });
    this.http.get(`/material/templates/${id}/details`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.template.category = result.data.category;
        this.template.prompts = result.data.prompts;
        this.template.negativePrompts = result.data.negativePrompts;
        this.template.description = result.data.description;
      }
    });
  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }

  favoriteClick() {
    if (!this.template.favorite) {
      this.addFavorite(this.template);
    } else {
      this.removeFavorite(this.template);
    }
  }

  private addFavorite(template: MaterialTemplateListItem) {
    var params = {
      category: '1', //Material templates
      id: template.id,
    }
    this.http.post('/user/favorites', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        template.favorite = result.data;
        this.messageService.showInformation('Successfully collected。');
      },
    });
  }

  private removeFavorite(template: MaterialTemplateListItem) {
    this.http.delete(`/user/favorites/${template.favorite}`).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        template.favorite = undefined;
        this.messageService.showInformation('Remove favorite。');
      },
    });
  }

  selectTemplateClick() {
    this.dialogRef.close(true);
    this.router.navigate(['/image/desgin', { templateId: this.template.id }]);
  }
}
