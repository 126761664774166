import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: 'message-snackbar',
  templateUrl: "./message-snackbar.html",
})
export class MessageSnackbar {
  constructor(public sbRef: MatSnackBarRef<MessageSnackbar>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string) {

  }
}
