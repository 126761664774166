import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectListItem } from '../models';
import { Size } from '../interfaces';

@Component({
  selector: 'image-format-input',
  templateUrl: './image-format-input.html',
})
export class ImageFormatInput implements OnInit {
  imageFormats: SelectListItem[] = [
    { key: 'RGB', value: 'RGB', selected: true },
    { key: 'CMYK', value: 'CMYK', selected: false },
  ];
  _imageFormat?: string;
  @Input() set imageFormat(value: string | undefined) {
    this._imageFormat = value;
    let item = this.imageFormats.find(o => o.key == value);
    if (!item) {
      return;
    }
    this.imageFormats.forEach(item => {
      item.selected = false;
    });
    item.selected = true;
  }
  get imageFormat() {
    return this._imageFormat;
  }

  @Output() imageFormatChange = new EventEmitter<string>();

  constructor() {

  }

  ngOnInit() {

  }

  imageFormatClick(selectedimageFormat: SelectListItem, event: Event) {
    this.imageFormat = selectedimageFormat.key;
    this.imageFormatChange.emit(this.imageFormat);
  }

}