<div class="d-flex py-2 align-items-center">
  <div>Generate quantity</div>
  <div class="flex-grow-1"></div>
  <div class="d-flex align-items-center justify-content-center icon-border" matRipple [matRippleCentered]="true" (click)="addQuantityClick(-1)">
    <mat-icon class="remove-add-icon">remove</mat-icon>
  </div>
  <input matInput #message maxlength="2" class="mx-2 quantity-input" [(ngModel)]="value" (change)="valueChaned()" style="width: 60px;text-align: center;">
  <div class="d-flex align-items-center justify-content-center icon-border" matRipple [matRippleCentered]="true" (click)="addQuantityClick(1)">
    <mat-icon class="remove-add-icon">add</mat-icon>
  </div>  
</div>
