import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { ImageGeneratorComponent } from './image-generator/image-generator.component';
import { ImageRefactorComponent } from './image-refactor/image-refactor.component';
import { AccountLoginComponent } from './account/account-login.component';
import { AccountLayoutComponent } from './layouts/account-layout/account-layout.component';
import { MasterLayoutComponent } from './layouts/master-layout/master-layout.component';
import { UserGuard } from './common/authorization.guard';
import { TestComponent } from './test/test.component';
import { TextToImageComponent } from './text-to-image/text-to-image.component';
import { PictureAuditComponent } from './picture-audit/picture-audit.component';
import { MaterialListComponent } from './material/material-list.component';
import { UserAssetListComponent } from './user-asset/user-asset-list.component';
import { ToolListComponent } from './toolkit/tool-list.component';
import { PasswordResetComponent } from './account/password-reset.component';
import { UserRegisterComponent } from './account/user-register.component';
import { RegisterSuccessComponent } from './account/register-success.component';
import { DesignToClothingComponent } from './design-to-clothing/design-to-clothing.component';
import { ImageDesignComponent } from './image-design/image-design.component';
import { MaterialSearchComponent } from './material-admin/material-search.component';
import { MaterialEditComponent } from './material-admin/material-edit.component';
import { MannequinToClothingComponent } from './mannequin-to-clothing/mannequin-to-clothing.component';
import { MyMembershipComponent } from './membership/my-membership.component';
import { FaceliftToClothingComponent } from './facelift-to-clothing/facelift-to-clothing.component';
import { FittingRoomComponent } from './fittingg-room/fittingg-room.component';
import { SimilarImageDesignComponent } from './similar-image-design/similar-image-design';
import { UserCenterComponent } from './user-center/user-center.component';
import { ModelToClothingComponent } from './mannequin-to-clothing/model-to-clothing.component';
import { ClothingFreeTryonComponent } from './mannequin-to-clothing/clothing-free-tryon.component';
import { ClothChangeColorComponent } from './mannequin-to-clothing/cloth-change-color.component';
import { PictureAuditParamCompareComponent } from './picture-audit/picture-audit-param-compare.component';
import { HomeComponent } from './home/home.component';
import { OrderSearchComponent } from './order-admin/order-search.component';
import { OrderEditComponent } from './order-admin/order-edit.component';
import { UserSearchComponent } from './user-admin/user-search.component';
import { UserEditComponent } from './user-admin/user-edit.component';
import { CompanySearchComponent } from './user-admin/company-search.component';
import { CompanyEditComponent } from './user-admin/company-edit.component';
import { MyTradeComponent } from './user-center/my-trade.component';
import { UserManualComponent } from './user-manual/user-manual.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  {
    path: 'account', component: AccountLayoutComponent,
    children: [
      { path: 'login', pathMatch: 'full', component: AccountLoginComponent },
      {
        path: 'register', pathMatch: 'full', component: UserRegisterComponent,

      },
      {
        path: 'password',
        children: [
          { path: 'reset', pathMatch: 'full', component: PasswordResetComponent, },
        ],
      },
    ],
  },
  {
    path: 'register',
    children: [
      { path: 'success', pathMatch: 'full', component: RegisterSuccessComponent, },
    ],
  },
  {
    path: '', component: MasterLayoutComponent,
    children: [
      { path: 'home', pathMatch: 'full', component: HomeComponent },
      { path: 'assets', pathMatch: 'full', component: UserAssetListComponent, },
      { path: 'material', pathMatch: 'full', component: MaterialListComponent, },
      {
        path: 'image',
        children: [
          { path: 'design', pathMatch: 'full', component: ImageDesignComponent, },
          { path: 'generate', pathMatch: 'full', component: ImageGeneratorComponent, },
          { path: 'refactor', pathMatch: 'full', component: ImageRefactorComponent, },
          { path: 'text', pathMatch: 'full', component: TextToImageComponent, },
          { path: 'simidesign', pathMatch: 'full', component: SimilarImageDesignComponent, }
        ],
      },
      {
        path: 'clothing',
        children: [
          { path: 'design', pathMatch: 'full', component: DesignToClothingComponent, },
          { path: 'mannequin', pathMatch: 'full', component: MannequinToClothingComponent, },
          { path: 'model', pathMatch: 'full', component: ModelToClothingComponent, },
          { path: 'tryon', pathMatch: 'full', component: ClothingFreeTryonComponent, },
          { path: 'facelift', pathMatch: 'full', component: FaceliftToClothingComponent, },
          { path: 'color', pathMatch: 'full', component: ClothChangeColorComponent, },
        ],
      },
      { path: 'toolkit', pathMatch: 'full', component: ToolListComponent, },
      { path: 'user-center', pathMatch: 'full', component: UserCenterComponent, },
      { path: 'membership', pathMatch: 'full', component: MyMembershipComponent, },
      { path: 'my-trade', pathMatch: 'full', component: MyTradeComponent, },
      {
        path: 'audit',
        children: [
          { path: 'common', pathMatch: 'full', component: PictureAuditComponent, },
          { path: 'param', pathMatch: 'full', component: PictureAuditParamCompareComponent, },
        ],
      },
      { path: 'toolkit', pathMatch: 'full', component: ToolListComponent, },
      { path: 'user-manual', pathMatch: 'full', component: UserManualComponent, },
      { path: 'audit', pathMatch: 'full', component: PictureAuditComponent, },
      { path: 'test', pathMatch: 'full', component: TestComponent },
      {
        path: 'admin',
        children: [
          { path: 'material/search', pathMatch: 'full', component: MaterialSearchComponent, },
          { path: 'material/add', pathMatch: 'full', component: MaterialEditComponent, },
          { path: 'material/:id/edit', pathMatch: 'full', component: MaterialEditComponent, },
          { path: 'orders/search', pathMatch: 'full', component: OrderSearchComponent, },
          { path: 'orders/add', pathMatch: 'full', component: OrderEditComponent, },
          { path: 'orders/:id/edit', pathMatch: 'full', component: OrderEditComponent, },
          { path: 'orders/:id/details', pathMatch: 'full', component: OrderEditComponent, },
          { path: 'users/search', pathMatch: 'full', component: UserSearchComponent, },
          { path: 'users/add', pathMatch: 'full', component: UserEditComponent, },
          { path: 'users/:id/edit', pathMatch: 'full', component: UserEditComponent, },
          { path: 'users/:id/details', pathMatch: 'full', component: UserEditComponent, },
          { path: 'companies/search', pathMatch: 'full', component: CompanySearchComponent, },
          { path: 'companies/add', pathMatch: 'full', component: CompanyEditComponent, },
          { path: 'companies/:id/edit', pathMatch: 'full', component: CompanyEditComponent, },
          { path: 'companies/:id/details', pathMatch: 'full', component: CompanyEditComponent, },
        ],
      },
      { path: 'fitting-room', pathMatch: 'full', component: FittingRoomComponent, },
    ],
    canActivate: [UserGuard],
  },
  { path: 'error/:status', component: ErrorComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return false;
  }
}
