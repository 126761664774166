import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { UserAssetGroupListItem, UserAssetListItem } from '../interfaces';

@Component({
  selector: 'user-asset-select',
  templateUrl: './user-asset-select.html',
  styleUrl: './user-asset-select.scss',
})
export class UserAssetSelect {
  groups: Array<UserAssetGroupListItem> = [];

  @Input({ required: true }) src!: string;
  _value?: string | UserAssetListItem;
  @Input() set value(value: string | UserAssetListItem | undefined) {
    this._value = value;
    this.setSelectedUserAsset(value);
  }
  get value() {
    return this._value;
  }

  @Output() selectionChange = new EventEmitter<UserAssetListItem>();
  @Output() loaded = new EventEmitter<Array<UserAssetGroupListItem>>();

  constructor(private http: AppHttpClient) {

  }

  ngOnInit() {
    this.http.get(this.src).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        this.groups = result.data;
        this.groups.forEach(group => {
          group.assets.forEach(asset => {
            this.http.get(`/user/assets/${asset.id}/thumbnail`, { responseType: 'blob' }).subscribe({
              next: (result: Blob) => {
                asset.url = URL.createObjectURL(result);
              },
            });
          });
        });
        this.setSelectedUserAsset(this.value);
        this.loaded.emit(this.groups);
      }
    });
  }

  private setSelectedUserAsset(asset: string | UserAssetListItem | undefined) {
    let selectedUserAssetId = typeof asset === 'string' ? asset : asset?.id;
    if (!this.groups) {
      return;
    }
    this.groups.forEach(group => {
      group.assets.forEach(asset => {
        asset.selected = asset.id == selectedUserAssetId;
      });
    });
  }

  onAssetClick(asset: UserAssetListItem) {
    if (this.value == asset) {
      return;
    }
    this.value = asset;
    this.selectionChange.emit(asset);

    this.setSelectedUserAsset(asset);
  }

}

