import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'image-prompt-input',
  templateUrl: './image-prompt-input.html',
  styleUrl: './image-prompt-input.scss',
})
export class ImagePromptInput {
  @Input() value?: string;
  @Output() valueChange = new EventEmitter<string>();
  @Input() negative?: string;
  @Output() negativeChange = new EventEmitter<string>();
  @Input() placeHolder?: string;
  negativeVisible: boolean = false;

  constructor() {

  }

  valueChanged() {
    this.valueChange.emit(this.value);
  }

  negativeChanged() {
    this.negativeChange.emit(this.negative);
  }

}