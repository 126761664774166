import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.html',
  styleUrl: './confirm-dialog.scss',
})
export class ConfirmDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.data = {
      question: 'Are you sure you want to continue?？',
      confirmButtonName: "Sure",
      cancleButtonName: "Cancel",
      ...data
    }
  }

  cancelClick(): void {
    this.dialogRef.close(false);
  }

  confirmClick(): void {
    this.dialogRef.close(true);
  }
}
