import { Component, } from '@angular/core';

@Component({
  selector: 'element-floatable',
  templateUrl: './element-floatable.html',
  styleUrl: './element-floatable.scss',
})
export class ElementFloatable {

  visible:boolean =false;
  handleMouseEnter(event:any){
    if(this.visible){
      return;
    }
    this.visible = true;

  }

  handleMouseLeave(event:any) {
    if(!this.visible){
      return;
    }
    this.visible = false;
  }
}
