import { Component, OnInit, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../common/base.component';
import { ActionResult, AppHttpClient } from '../common/app.http';
import { AppValidators } from '../common/app.forms';

@Component({
  selector: 'user-center',
  templateUrl: './user-center.component.html',
  styleUrl: './user-center.component.scss',
})
export class UserCenterComponent extends BaseComponent implements OnInit {
  id: number;
  username: string;
  hasPassword: boolean;
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  profileForm: FormGroup;
  passwordForm: FormGroup;


  constructor(injector: Injector, private http: AppHttpClient) {
    super(injector);
    let user = this.sessionService.user!;
    this.id = user.id;
    this.username = user.username
    this.hasPassword = user.hasPassword == true;
    this.profileForm = this.formBuilder.group({
      id: new FormControl(this.id),
      nickname: new FormControl(''),
    });
    this.passwordForm = this.formBuilder.group({
      username: new FormControl({ value: this.username, disabled: true }),
      //oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, AppValidators.password]),
      confirmPassword: new FormControl('', [Validators.required, AppValidators.sameWith('newPassword')]),
    });
    if (this.hasPassword) {
      this.passwordForm.addControl('oldPassword', new FormControl('', [Validators.required]));
    }
  }

  ngOnInit() {
    this.http.get(`/users/${this.id}/details`).subscribe(response => {
      this.profileForm.patchValue(response.data);
    });
  }

  updateProfileClick() {
    this.profileForm.markAllAsTouched();
    if (!this.profileForm.valid) {
      return;
    }

    let params = this.profileForm.value;
    this.http.post(`/users/${this.id}/profile`, params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let user = this.sessionService.user;
        if (!!user) {
          user.nickname = params.nickname;
        }
        this.sessionService.user = user;
        this.messageService.showInformation('User information saved successfully。');
      }
    });
  }

  updatePasswordClick() {
    this.passwordForm.markAllAsTouched();
    if (!this.passwordForm.valid) {
      return;
    }

    let params = this.passwordForm.value;
    this.http.post('/account/password/change', params).subscribe({
      next: (result: ActionResult) => {
        if (!this.http.isValidResult(result)) {
          return;
        }
        let user = this.sessionService.user!;
        if (!this.hasPassword) {
          this.passwordForm.addControl('oldPassword', new FormControl('', [Validators.required]));
          this.hasPassword = true;
          user.hasPassword = true;
          this.sessionService.user = user;
        }
        this.passwordForm.reset();
        this.passwordForm.patchValue({
          username: user.username,
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
        this.messageService.showInformation('User password changed successfully。');
      }
    });
  }

}
