<div mat-dialog-title>Account recharge</div>

<mat-dialog-content>
  <form class="d-flex flex-wrap align-items-start" [formGroup]="inputForm">
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">Order Smart Beans</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput [value]="orderItemList?.at(0)?.points" *ngIf="(orderItemList??[]).length<=1" disabled>
        <mat-select formControlName="orderItemId" *ngIf="(orderItemList??[]).length>1">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let item of orderItemList" [value]="item.id">{{item.points}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">User Account</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput [value]="userList?.at(0)?.nickname" *ngIf="(userList??[]).length<=1" disabled>
        <mat-select formControlName="userId" *ngIf="(userList??[]).length>1">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let item of userList" [value]="item.id">{{item.nickname}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">Recharge Zhidou</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput formControlName="points" maxlength="9">
      </mat-form-field>
    </div>
    <div class="col-12 d-flex flex-wrap align-items-baseline">
      <div class="col-4">Remaining Smart Beans</div>
      <mat-form-field class="col-8" appearance="outline">
        <input matInput formControlName="remainingPoints">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex-grow-1"></div>
  <button mat-stroked-button class="btn-min-width" [mat-dialog-close]="false">Cancel</button>
  <div class="flex-grow-1"></div>
  <button mat-flat-button class="btn-min-width" color="primary" (click)="withdrawClick()">confirm</button>
  <div class="flex-grow-1"></div>
</mat-dialog-actions>
