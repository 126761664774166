import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectListItem } from '../models';

@Component({
  selector: 'image-resolution-input',
  templateUrl: './image-resolution-input.html',
})
export class ImageResolutionInput {
  resolutions: SelectListItem[] = [
    { key: "500*500", value: "500*500", selected: false },
    { key: "1000*1000", value: "1000*1000", selected: false },
    { key: "1500*1500", value: "1500*1500", selected: true },
    { key: "3000*3000", value: "3000*3000", selected: false },
    { key: "", value: "custom made", selected: false },
  ];

  @Input() value?: string;
  @Output() valueChange = new EventEmitter<string>();

  constructor() {

  }

  resolutionClick(selectedResolution: SelectListItem) {
    this.resolutions.forEach(item => {
      item.selected = false;
    });
    selectedResolution.selected = true;
    this.value = selectedResolution.key ?? undefined;
    this.valueChange.emit(this.value);
  }

}
